import type { Ref } from 'vue';
import { computed, ref } from 'vue';

import type { Category as RawCategory, CategorySlug } from '@caff/category-api-model';
import { sortAlphabeticallyByKey } from '@caff/isomorphic-collection';
import type { UseFlatPaginatedQueryReturnValue } from '@caff/frontend-use-query';

import { getCategoryFrontpageRoute } from '../../router';
import { getKeyForCategory, setCategory } from '../categories';
import type { UseAxiosQueryResult } from '../useAxiosQuery';
import { useAxiosFlatPaginatedQuery, useAxiosQuery } from '../useAxiosQuery';

export const useCategory = (
  categorySlug: Ref<CategorySlug | null>,
): UseAxiosQueryResult<RawCategory> & {
  category: Ref<RawCategory | null>;
} => {
  const { data, ...rest } = useAxiosQuery({
    queryKey: computed(() => getKeyForCategory(categorySlug)),
    async queryFn({ axiosInstance }) {
      if (!categorySlug.value) {
        return null;
      }

      const {
        data: category,
      }: {
        data: RawCategory;
      } = await axiosInstance.get(`/category/${categorySlug.value}`);

      return category;
    },
  });

  return {
    ...rest,
    category: data,
  };
};

export const useCategories = (): Omit<
  UseFlatPaginatedQueryReturnValue<Array<RawCategory>, Error>,
  'data'
> & {
  sortedCategories: Ref<Array<RawCategory>>;
} => {
  const { data, ...rest } = useAxiosFlatPaginatedQuery<Array<RawCategory>, number, Error>({
    getQueryKey: (cursor) => ref(`categories/${cursor ?? 1}`),
    async queryPageFn({ axiosInstance, cursor = 1, setQueryData }) {
      const {
        data: rawCategories,
      }: {
        data: Array<RawCategory>;
      } = await axiosInstance.get(`/categories/${cursor}`);

      for (const singleCategory of rawCategories) {
        await setCategory({
          category: singleCategory,
          setQueryData,
        });
      }

      return {
        data: rawCategories,
        nextCursor: rawCategories.length > 0 ? cursor + 1 : null,
      };
    },
  });

  const sortedCategories = computed(() =>
    data.value ? sortAlphabeticallyByKey(data.value.flat(), 'name') : [],
  );

  return { ...rest, sortedCategories };
};

export const useCategoryFrontpageRoute = (
  categorySlug: Ref<CategorySlug | null>,
): Ref<ReturnType<typeof getCategoryFrontpageRoute> | null> =>
  computed(() => (categorySlug.value ? getCategoryFrontpageRoute(categorySlug.value) : null));
