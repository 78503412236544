import type { Ref } from 'vue';
import { computed } from 'vue';

import type { CategorySlug } from '@caff/category-api-model';
import { getBannerImage } from '@caff/category-isomorphic-model';
import type { Image } from '@caff/image-isomorphic-model';

import { useS3ConfigForImageKind } from '../useS3';
import { useCategory } from './simple';

export const useCategoryBannerImage = (
  categorySlug: Ref<CategorySlug | null>,
): Ref<Image | null> => {
  const { category } = useCategory(categorySlug);
  const s3ConfigForImageKind = useS3ConfigForImageKind();
  const image = computed(() =>
    category.value
      ? getBannerImage({
          category: category.value,
          s3ConfigForImageKind: s3ConfigForImageKind.value,
        })
      : null,
  );

  return image;
};
