<template>
  <Pill :rgbColor="category.tintColor" :large="large" :tag="tag" :to="to">
    <template #prefix>
      <Avatar :large="large" :name="category.name" :rgbColor="category.tintColor" :image="image" />
    </template>

    {{ category.name }}
  </Pill>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue';
import type { RouteLocationRaw } from 'vue-router';

import { Category } from '@caff/category-api-model';
import { getAvatar } from '@caff/category-isomorphic-model';
import { Avatar } from '@caff/cds-avatar';
import { Pill } from '@caff/cds-pill';
import { s3BucketConfigForImageKindVueComponentProperty as s3BucketConfigForImageKind } from '@caff/image-frontend-model';

export default defineComponent({
  name: 'CategoryPill',
  components: { Avatar, Pill },
  props: {
    category: {
      type: Object as PropType<Category>,
      required: true,
    },
    to: {
      type: null as unknown as PropType<RouteLocationRaw | null>,
      required: false,
    },
    large: {
      type: Boolean,
      default: false,
    },
    s3BucketConfigForImageKind,
  },
  setup(props) {
    const tag = computed(() => (props.to ? 'router-link' : 'div'));

    const image = computed(() =>
      getAvatar({
        category: props.category,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        s3ConfigForImageKind: props.s3BucketConfigForImageKind!,
      }),
    );

    return { tag, image };
  },
});
</script>
