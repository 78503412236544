<template>
  <Popover :opened="opened" :popupClass="popupClass" @click-outside="handleClickOutside($event)">
    <template #toggleButton>
      <slot
        name="toggleButton"
        :opened="opened"
        :openPopover="openPopover"
        :closePopover="closePopover"
        :togglePopover="togglePopover"
      >
        <Button :active="opened" :type="toggleButtonType" @click="togglePopover()">
          <FontAwesomeIcon :icon="faMoreMenu" fixed-width />
        </Button>
      </slot>
    </template>

    <template #popoverContent>
      <nav>
        <slot
          :openPopover="openPopover"
          :closePopover="closePopover"
          :togglePopover="togglePopover"
        />
      </nav>
    </template>
  </Popover>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import { Button, ButtonType } from '@caff/cds-button';
import { faMoreMenu } from '@caff/cds-icons';
import { Popover } from '@caff/cds-popover';

export default defineComponent({
  name: 'DropdownMenu',
  components: { Button, FontAwesomeIcon, Popover },
  props: {
    alwaysOpened: {
      type: Boolean,
      default: false,
    },

    /**
     * Additional CSS classes to be applied to the popup itself.
     */
    popupClass: {
      type: [String, Array, Object],
      required: false,
    },
  },
  emits: ['open', 'close', 'click-outside'],
  setup(props, { emit }) {
    const manuallyOpened = ref(false);
    const opened = computed(() => props.alwaysOpened || manuallyOpened.value);
    const toggleButtonType = ButtonType.secondary;

    const openPopover = () => {
      manuallyOpened.value = true;

      /**
       * User opened this dropdown menu manually.
       *
       * @event open
       */
      emit('open');
    };
    const closePopover = () => {
      manuallyOpened.value = false;

      /**
       * User closed this dropdown menu manually.
       *
       * @event close
       */
      emit('close');
    };
    const togglePopover = () => {
      if (manuallyOpened.value) {
        closePopover();
      } else {
        openPopover();
      }
    };
    const handleClickOutside = ($event: MouseEvent) => {
      closePopover();

      /**
       * User clicked outside toggle button or popup of this menu.
       *
       * @event click-outside
       * @type {MouseEvent}
       */
      emit('click-outside', $event);
    };

    return {
      opened,
      faMoreMenu,
      toggleButtonType,
      openPopover,
      closePopover,
      togglePopover,
      handleClickOutside,
    };
  },
});
</script>
