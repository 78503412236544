<template>
  <Transition
    :css="false"
    @before-enter="beforeEnter"
    @enter="enter"
    @before-leave="beforeLeave"
    @leave="leave"
  >
    <slot />
  </Transition>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import {
  enterAnimationDurationInMilliseconds,
  leaveAnimationDurationInMilliseconds,
} from './animation';

export default defineComponent({
  name: 'ModalTransitionAnimation',
  setup() {
    const beforeEnter = (el: Element) => {
      el.classList.add('c-modal-transition-animation--v-enter');
      el.classList.add('c-modal-transition-animation--v-enter-active');
      el.classList.remove('c-modal-transition-animation--v-enter-to');
      el.classList.remove('c-modal-transition-animation--v-leave');
      el.classList.remove('c-modal-transition-animation--v-leave-active');
      el.classList.remove('c-modal-transition-animation--v-leave-to');
    };

    const enter = (el: Element, done: () => void) => {
      setTimeout(() => {
        el.classList.remove('c-modal-transition-animation--v-enter');
        el.classList.add('c-modal-transition-animation--v-enter-active');
        el.classList.add('c-modal-transition-animation--v-enter-to');
        el.classList.remove('c-modal-transition-animation--v-leave');
        el.classList.remove('c-modal-transition-animation--v-leave-active');
        el.classList.remove('c-modal-transition-animation--v-leave-to');

        setTimeout(() => {
          done();
        }, enterAnimationDurationInMilliseconds);
      }, 0);
    };

    const beforeLeave = (el: Element) => {
      el.classList.remove('c-modal-transition-animation--v-enter');
      el.classList.remove('c-modal-transition-animation--v-enter-active');
      el.classList.remove('c-modal-transition-animation--v-enter-to');
      el.classList.add('c-modal-transition-animation--v-leave');
      el.classList.add('c-modal-transition-animation--v-leave-active');
      el.classList.remove('c-modal-transition-animation--v-leave-to');
    };

    const leave = (el: Element, done: () => void) => {
      setTimeout(() => {
        el.classList.remove('c-modal-transition-animation--v-enter');
        el.classList.remove('c-modal-transition-animation--v-enter-active');
        el.classList.remove('c-modal-transition-animation--v-enter-to');
        el.classList.remove('c-modal-transition-animation--v-leave');
        el.classList.add('c-modal-transition-animation--v-leave-active');
        el.classList.add('c-modal-transition-animation--v-leave-to');

        setTimeout(() => {
          done();
        }, leaveAnimationDurationInMilliseconds);
      }, 0);
    };

    return {
      beforeEnter,
      enter,
      beforeLeave,
      leave,
    };
  },
});
</script>

<style lang="scss">
@import '@caff/cds-scss-core';

@mixin c-modal-transition--hidden {
  opacity: 0;
}
@mixin c-modal-transition--visible {
  opacity: 1;
}
@mixin c-modal-transition--do-not-animate {
  transition: none;
}
@mixin c-modal-transition--animate {
  transition: all 0.25s ease-in-out;
  transition-property: opacity;
}

@mixin c-modal-transition--translated-out-of-canvas {
  transform: translateY(100vh);
}
@mixin c-modal-transition--displayed-in-canvas {
  transform: none;
}
@mixin c-modal-transition--animate-translation-and-opacity {
  transition-property: opacity, transform;
}

.c-modal-transition-animation--v-enter {
  &,
  .c-modal__content {
    @include c-modal-transition--hidden;
    // @include c-modal-transition--animate;
    @include c-modal-transition--do-not-animate;
  }
}

.c-modal-transition-animation--v-enter-to {
  &,
  .c-modal__content {
    @include c-modal-transition--visible;
    @include c-modal-transition--animate;
  }
}

.c-modal-transition-animation--v-leave {
  &,
  .c-modal__content {
    @include c-modal-transition--visible;
    @include c-modal-transition--do-not-animate;
  }
}

.c-modal-transition-animation--v-leave-to {
  &,
  .c-modal__content {
    @include c-modal-transition--hidden;
    @include c-modal-transition--animate;
  }
}

@include t-responsive--smaller-than($responsive-breakpoint-tablet-medium) {
  .c-modal-transition-animation--v-enter .c-modal__content {
    @include c-modal-transition--hidden;
    @include c-modal-transition--do-not-animate;
    @include c-modal-transition--translated-out-of-canvas;
  }
  .c-modal-transition-animation--v-enter-to .c-modal__content {
    @include c-modal-transition--visible;
    @include c-modal-transition--animate;
    @include c-modal-transition--displayed-in-canvas;
    @include c-modal-transition--animate-translation-and-opacity;
  }

  .c-modal-transition-animation--v-leave .c-modal__content {
    @include c-modal-transition--visible;
    @include c-modal-transition--do-not-animate;
    @include c-modal-transition--displayed-in-canvas;
  }

  .c-modal-transition-animation--v-leave-to .c-modal__content {
    @include c-modal-transition--hidden;
    @include c-modal-transition--animate;
    @include c-modal-transition--translated-out-of-canvas;
    @include c-modal-transition--animate-translation-and-opacity;
  }
}
</style>
