<template>
  <SecondaryButton :to="notificationsRoute" :active="!!unseenNotificationsCount">
    <FontAwesomeIcon
      :icon="faBell"
      :class="{
        'c-main-navbar-notifications-button--animated': bellAnimationTimeout,
        'c-main-navbar-notifications-button--notifications-unseen': unseenNotificationsCount,
      }"
      fixed-width
    />

    <template v-if="unseenNotificationsCount">
      {{ unseenNotificationsCount }}
    </template>
  </SecondaryButton>
</template>

<script lang="ts" setup>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { ref, watch } from 'vue';

import { SecondaryButton } from '@caff/cds-button';
import { faBell } from '@caff/cds-icons';

import { useUnseenNotificationsCount } from '../../composition';
import { notificationsRoute } from '../../router';

const bellAnimationTimeout = ref<ReturnType<typeof setTimeout> | null>(null);

const { unseenNotificationsCount } = useUnseenNotificationsCount();

watch(unseenNotificationsCount, (newValue, oldValue) => {
  if (typeof newValue !== 'number' || typeof oldValue !== 'number' || newValue <= oldValue) {
    return;
  }

  const idTimeout = setTimeout(() => {
    if (idTimeout === bellAnimationTimeout.value) {
      bellAnimationTimeout.value = null;
    } else {
      clearTimeout(idTimeout);
    }
  }, 4000);

  bellAnimationTimeout.value = idTimeout;
});
</script>

<style lang="scss" scoped>
@import '@caff/cds-scss-core';

@keyframes c-main-navbar-notifications-button__ring-animation {
  0% {
    -webkit-transform: rotateZ(0);
  }

  1% {
    -webkit-transform: rotateZ(30deg);
  }

  3% {
    -webkit-transform: rotateZ(-28deg);
  }

  5% {
    -webkit-transform: rotateZ(34deg);
  }

  7% {
    -webkit-transform: rotateZ(-32deg);
  }

  9% {
    -webkit-transform: rotateZ(30deg);
  }

  11% {
    -webkit-transform: rotateZ(-28deg);
  }

  13% {
    -webkit-transform: rotateZ(26deg);
  }

  15% {
    -webkit-transform: rotateZ(-24deg);
  }

  17% {
    -webkit-transform: rotateZ(22deg);
  }

  19% {
    -webkit-transform: rotateZ(-20deg);
  }

  21% {
    -webkit-transform: rotateZ(18deg);
  }

  23% {
    -webkit-transform: rotateZ(-16deg);
  }

  25% {
    -webkit-transform: rotateZ(14deg);
  }

  27% {
    -webkit-transform: rotateZ(-12deg);
  }

  29% {
    -webkit-transform: rotateZ(10deg);
  }

  31% {
    -webkit-transform: rotateZ(-8deg);
  }

  33% {
    -webkit-transform: rotateZ(6deg);
  }

  35% {
    -webkit-transform: rotateZ(-4deg);
  }

  37% {
    -webkit-transform: rotateZ(2deg);
  }

  39% {
    -webkit-transform: rotateZ(-1deg);
  }

  41% {
    -webkit-transform: rotateZ(1deg);
  }

  43% {
    -webkit-transform: rotateZ(0);
  }

  100% {
    -webkit-transform: rotateZ(0);
  }
}

.c-main-navbar-notifications-button--animated {
  animation-duration: 4s;
  animation-iteration-count: 1;
  animation-name: c-main-navbar-notifications-button__ring-animation;
  transform-origin: 12px 2px;
}

.c-main-navbar-notifications-button--notifications-unseen {
  @extend %t-margin-r-xxs;
}
</style>
