import { isServer } from '@caff/isomorphic-is-server';
import { ServerSideRenderingSharedContext } from '@caff/server-side-rendering-context-isomorphic-model';

/**
 * Returns the API Base URL based on the passed initial state:
 *
 * - When the initial state has a truthy `apiBaseUrl`, that is the returned
 *   value.
 * - Otherwise, it prefixes the requested URL with `api` subdomain, in an
 *   isomorphic way (server-side requests take the requested URL from
 *   `requestedUrl` attribute in the initial state while client-side requests
 *   take it from `window.location.href`).
 *
 * @param initialState
 * @returns
 */
export const getApiBaseUrl = (
  initialState: Pick<ServerSideRenderingSharedContext, 'requestedUrl'>,
): URL => {
  const currentUrlString = isServer() ? initialState.requestedUrl : window.location.href;

  const apiUrl = new URL(currentUrlString);

  apiUrl.host = `api.${apiUrl.host}`;
  apiUrl.pathname = '';

  return apiUrl;
};
