import { useCssVar } from '@vueuse/core';
import { Ref, watchEffect } from 'vue';

import { useVisualViewportCssVars } from './useVisualViewport';

export const useFullscreenModalCssVars = (
  el: Ref<HTMLElement | SVGElement | null>,
): {
  forcedModalVSpaceToEdges: Ref<string>;
  forcedBorderRadiusInMobile: Ref<string>;
} => {
  const { offsetTop } = useVisualViewportCssVars(el);

  const forcedModalVSpaceToEdges = useCssVar('--forced-modal-vspace-to-edges', el);
  const forcedBorderRadiusInMobile = useCssVar('--forced-border-radius-in-mobile', el);

  watchEffect(() => {
    if (offsetTop.value === '0px') {
      forcedModalVSpaceToEdges.value = '0px';
      forcedBorderRadiusInMobile.value = '0px';
    } else {
      forcedModalVSpaceToEdges.value = '';
      forcedBorderRadiusInMobile.value = '';
    }
  });

  return {
    forcedModalVSpaceToEdges,
    forcedBorderRadiusInMobile,
  };
};
