<template>
  <form class="c-login-form" @submit.prevent="login()">
    <Input
      v-model="usernameOrEmail"
      type="text"
      :placeholder="t('usernameOrEmail.placeholder')"
      :label="t('usernameOrEmail.accessibilityLabel')"
      :disabled="loading"
      class="c-login-form__field"
      data-testid="username-field"
    />

    <Input
      v-model="password"
      type="password"
      :placeholder="t('password.placeholder')"
      :label="t('password.accessibilityLabel')"
      :disabled="loading"
      class="c-login-form__field"
      data-testid="password-field"
    />

    <i18n-t
      keypath="cookiesAlert.body"
      tag="p"
      class="c-login-form__cookies-alert"
      data-testid="cookies-info"
    >
      <template v-slot:privacyLink>
        <router-link to="#">{{ t('cookiesAlert.privacyLinkCallToAction') }}</router-link>
      </template>
    </i18n-t>

    <Input
      type="submit"
      :value="t('action.login')"
      :disabled="isSubmitDisabled"
      class="c-login-form__hidden-field"
    />

    <PrimaryButton
      :disabled="isSubmitDisabled"
      :loading="loading"
      class="c-login-form__login-button"
      data-testid="login-button"
      @click="login()"
    >
      {{ t('action.login') }}
    </PrimaryButton>
  </form>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import { PrimaryButton } from '@caff/cds-button';
import { Input } from '@caff/cds-input';

export interface LoginEventPayload {
  usernameOrEmail: string;
  password: string;
  rememberLogin: boolean;
}

const props = withDefaults(
  defineProps<{
    loginError?: string | null;
    loading?: boolean;
  }>(),
  { loading: false },
);
const emit = defineEmits<{
  login: [LoginEventPayload];
}>();

const { t } = useI18n();

const usernameOrEmail = ref('');
const password = ref('');
const isSubmitDisabled = computed(() => !usernameOrEmail.value || !password.value || props.loading);

const login = () => {
  if (isSubmitDisabled.value || props.loading) {
    return;
  }

  emit('login', {
    usernameOrEmail: usernameOrEmail.value,
    password: password.value,
    rememberLogin: true,
  });
};
</script>

<i18n lang="json" locale="es">
{
  "action": {
    "login": "Iniciar sesión"
  },
  "cookiesAlert": {
    "body": "Para mantener tu sesión iniciada es necesario el uso de cookies, descubre cómo {privacyLink}.",
    "privacyLinkCallToAction": "aquí"
  },
  "password": {
    "accessibilityLabel": "Contraseña",
    "placeholder": "Contraseña"
  },
  "usernameOrEmail": {
    "accessibilityLabel": "Nombre de usuario o email",
    "placeholder": "Nombre de usuario o email"
  }
}
</i18n>

<style lang="scss" scoped>
@import '@caff/cds-scss-core';

.c-login-form__field,
.c-login-form__login-button {
  width: 100%;
}

.c-login-form__field + .c-login-form__field {
  margin-top: var(--spacing-s);
}
.c-login-form__cookies-alert {
  @include t-font($font-body-soft);

  margin-top: var(--spacing-s);

  a {
    color: var(--colors-orange-500);
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.c-login-form__hidden-field {
  display: none !important;
}
</style>
