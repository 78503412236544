<template src="./Button.html"></template>

<script lang="ts">
import { buttonFactory } from './Button';
import { ButtonType } from './Button';

export default buttonFactory({
  name: 'TertiaryButton',
  type: ButtonType.tertiary,
});
</script>

<style lang="scss" src="./Button.scss" scoped></style>
