<template>
  <span class="c-main-navbar-user-title">
    <template v-if="user">
      <UserAvatar
        :s3BucketConfigForImageKind="s3BucketConfigForImageKind"
        :user="user"
        class="u-margin-r-xs"
      />

      <Pill tag="span">{{ user.displayName }}</Pill>
    </template>
    <template v-else>
      {{ t('title.loading') }}
    </template>
  </span>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import { Pill } from '@caff/cds-pill';
import { Username } from '@caff/user-api-model';
import { UserAvatar } from '@caff/user-avatar-frontend-component';

import { useS3ConfigForImageKind, useUser } from '../../composition';

const props = defineProps<{
  username?: Username;
}>();

const { t } = useI18n();

const s3BucketConfigForImageKind = useS3ConfigForImageKind();

const { user } = useUser(computed(() => props.username ?? null));
</script>

<i18n lang="json" locale="es">
{
  "title": {
    "loading": "Cargando perfil…"
  }
}
</i18n>

<style lang="scss" scoped>
.c-main-navbar-user-title {
  align-items: center;
  display: flex;
  flex-direction: row;
}
</style>
