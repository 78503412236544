import type { IconDefinition, IconName, IconPrefix } from '@fortawesome/fontawesome-common-types';

export const prefix = 'caff' as IconPrefix;
export const iconName = 'caff-logo' as IconName;
export const width = 14;
export const height = 14;
export const ligatures = [];
export const unicode = 'f811';
export const svgPathData =
  'm6.97397504 14c-1.34752456 0-1.99917938-.0627184-2.61904617-.1567959-.61986678-.0940776-1.04900533-.4076695-1.33509769-.8623777s-.72098163-1.0537738-1.06489935-2.2735411c-.34391773-1.21976744-.59011029-2.25091306-.11125814-3.0104821.41324452-.65550034 1.2873962-.7839797 5.13030135-.7839797 3.82180806.00055777 4.69307676.12989596 5.10542816.7839797.4788522.75956904.2326596 1.79071466-.1112581 3.0104821-.3439177 1.2197673-.778807 1.8188329-1.0648994 2.2735411-.2860923.4547082-.7152309.7683001-1.33509765.8623777-.6160407.0934968-1.26347823.1560205-2.59417302.1567959zm-2.8423599-9.88960687c-.18438432-.66994553.07008659-1.07585502.26642031-1.35756103.34635493-.49696132.87699587-.79237314.9895612-.94275072.24549019-.32795375.36434293-.83443989.52514276-1.16901089.22445142-.46700881.66417461-.64107049 1.0276213-.64107049.66556999 0 1.71831453.62379367 2.32166314 1.65924126.67322434 1.15536609.5028728 2.08603188.15224019 2.71512207-.16991768.30485912-.94619113 1.08888086-2.47390333 1.08888086-1.52771219 0-2.62436125-.68290553-2.80874557-1.35285106z';

export const definition = {
  prefix,
  iconName,
  icon: [width, height, ligatures, unicode, svgPathData],
} as IconDefinition;

export const faCaffLogo = definition;
