<template>
  <Modal>
    <div class="u-padding-m">
      <ModalBanner :progress="progress" />

      <UsernameAndEmailStep
        v-if="currentStep === usernameAndEmailStep"
        :username="username"
        :usernameMinLength="usernameMinLength"
        :usernameMaxLength="usernameMaxLength"
        :isUsernameAsyncValidationLoading="isUsernameAsyncValidationLoading"
        :email="email"
        :isEmailAsyncValidationLoading="isEmailAsyncValidationLoading"
        :isSubmitDisabled="!areUsernameAndEmailValid"
        @username-input="handleUsernameInput($event)"
        @email-input="handleEmailInput($event)"
        @dismiss="dismiss()"
        @submit="goToPasswordStep()"
      >
        <template v-if="usernameError" #usernameError>
          <span class="u-color-red-500 u-margin-r-1em">{{ usernameError }}</span>
          <i18n-t
            v-if="usernameAsyncValidationError"
            keypath="hint.forgotPassword.message"
            tag="span"
          >
            <Button
              :type="link"
              data-testid="forgot-password-button"
              @click.prevent.stop="forgotPassword()"
            >
              {{ t('hint.forgotPassword.callToAction') }}
            </Button>
          </i18n-t>
        </template>

        <template v-if="emailError" #emailError>
          <span class="u-color-red-500 u-margin-r-1em">{{ emailError }}</span>
          <i18n-t v-if="emailAsyncValidationError" keypath="hint.forgotPassword.message" tag="span">
            <Button
              :type="link"
              data-testid="forgot-password-button"
              @click.prevent.stop="forgotPassword()"
            >
              {{ t('hint.forgotPassword.callToAction') }}
            </Button>
          </i18n-t>
        </template>
      </UsernameAndEmailStep>
      <PasswordStep
        v-else-if="currentStep === passwordStep"
        :password="password"
        :passwordError="passwordError"
        :repeatPassword="repeatPassword"
        :repeatPasswordError="repeatPasswordError"
        :isSubmitDisabled="!arePasswordsValid"
        :isCreatingAccount="isCreatingAccount"
        @password-input="handlePasswordInput($event)"
        @repeat-password-input="handleRepeatPaswordInput($event)"
        @dismiss="dismiss()"
        @go-back="goToUsernameAndEmailStep()"
        @submit="createAccount()"
      />
      <SuccessStep
        v-else-if="currentStep === successStep"
        :title="t('success.title')"
        :body="t('success.body')"
        :close="t('success.close')"
        @dismiss="dismiss()"
      />
      <ErrorStep
        v-else-if="currentStep === errorStep"
        @dismiss="dismiss()"
        @go-back="goToPasswordStep()"
        @go-to-start="goToUsernameAndEmailStep()"
      />
      <UsernameUnavailableStep
        v-else-if="currentStep === usernameUnavailableStep"
        :username="username"
        :usernameMinLength="usernameMinLength"
        :usernameMaxLength="usernameMaxLength"
        :isUsernameAsyncValidationLoading="isUsernameAsyncValidationLoading"
        :isSubmitDisabled="!areUsernameAndEmailValid"
        :isCreatingAccount="isCreatingAccount"
        @username-input="handleUsernameInput($event)"
        @dismiss="dismiss()"
        @go-back="goToPasswordStep()"
        @submit="createAccount()"
      >
        <template v-if="usernameError" #usernameError>
          <span class="u-color-red-500 u-margin-r-1em">{{ usernameError }}</span>
          <i18n-t
            v-if="usernameAsyncValidationError"
            keypath="hint.forgotPassword.message"
            tag="span"
          >
            <Button
              :type="link"
              data-testid="forgot-password-button"
              @click.prevent.stop="forgotPassword()"
            >
              {{ t('hint.forgotPassword.callToAction') }}
            </Button>
          </i18n-t>
        </template>
      </UsernameUnavailableStep>
      <EmailUnavailableStep
        v-else-if="currentStep === emailUnavailableStep"
        :email="email"
        :isEmailAsyncValidationLoading="isEmailAsyncValidationLoading"
        :isSubmitDisabled="!areUsernameAndEmailValid"
        :isCreatingAccount="isCreatingAccount"
        @email-input="handleEmailInput($event)"
        @dismiss="dismiss()"
        @go-back="goToPasswordStep()"
        @submit="createAccount()"
      >
        <template v-if="emailError" #emailError>
          <span class="u-color-red-500 u-margin-r-1em">{{ emailError }}</span>
          <i18n-t v-if="emailAsyncValidationError" keypath="hint.forgotPassword.message" tag="span">
            <Button
              :type="link"
              data-testid="forgot-password-button"
              @click.prevent.stop="forgotPassword()"
            >
              {{ t('hint.forgotPassword.callToAction') }}
            </Button>
          </i18n-t>
        </template>
      </EmailUnavailableStep>
    </div>
  </Modal>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue';
import { useI18n } from 'vue-i18n';

import { Button, ButtonType } from '@caff/cds-button';
import { Modal } from '@caff/cds-modal';
import { ModalBanner } from '@caff/cds-modal-banner';
import { SuccessStep } from '@caff/cds-modal-flow';

import {
  EmailUnavailableStep,
  ErrorStep,
  PasswordStep,
  SignupModalStep,
  UsernameAndEmailStep,
  UsernameUnavailableStep,
  allSignupModalSteps,
} from './steps';

import {
  usernameMinLength,
  usernameMaxLength,
  getUsernameSyncError,
  getEmailSyncError,
} from './validation';

export default defineComponent({
  name: 'SignupModal',
  components: {
    Button,
    EmailUnavailableStep,
    ErrorStep,
    Modal,
    ModalBanner,
    PasswordStep,
    SuccessStep,
    UsernameAndEmailStep,
    UsernameUnavailableStep,
  },
  props: {
    currentStep: {
      type: String as PropType<SignupModalStep>,
      required: true,
      validator: (value: string): boolean => {
        if (value in allSignupModalSteps) {
          return true;
        }

        console.warn(
          `[SignupModal] Invalid value «${value}» for currentStep, expected a value in SignupModalStep enum`,
        );
        return false;
      },
    },
    email: {
      type: String,
      required: true,
    },
    isEmailAsyncValidationLoading: {
      type: Boolean,
      required: true,
    },
    emailAsyncValidationError: {
      type: null as unknown as PropType<string | null>,
      required: false,
    },
    username: {
      type: String,
      required: true,
    },
    isUsernameAsyncValidationLoading: {
      type: Boolean,
      required: true,
    },
    usernameAsyncValidationError: {
      type: null as unknown as PropType<string | null>,
      required: false,
    },
    password: {
      type: String,
      required: true,
    },
    repeatPassword: {
      type: String,
      required: true,
    },
    isCreatingAccount: {
      type: Boolean,
      required: true,
    },
  },
  emits: [
    'username-input',
    'email-input',
    'password-input',
    'repeat-password-input',
    'dismiss',
    'go-to-username-and-email-step',
    'go-to-password-step',
    'create-account',
    'forgot-password',
  ],
  setup(props, { emit }) {
    const { t } = useI18n();

    const progress = computed(() => {
      switch (props.currentStep) {
        case SignupModalStep.usernameAndEmail:
          return 0;
        case SignupModalStep.password:
        case SignupModalStep.error:
          return 0.5;
        case SignupModalStep.usernameUnavailable:
        case SignupModalStep.emailUnavailable:
          return 0.8;
        case SignupModalStep.success:
          return 1;
      }

      return 0;
    });

    const passwordError = computed((): string | null => {
      return null;
    });

    const handleUsernameInput = (newUsername: string) => {
      /**
       * User changed username value.
       *
       * @event username-input
       * @type {string}
       */
      emit('username-input', newUsername);
    };

    const handleEmailInput = (newEmail: string) => {
      /**
       * User changed email value.
       *
       * @event email-input
       * @type {string}
       */
      emit('email-input', newEmail);
    };

    const handlePasswordInput = (newPassword: string) => {
      /**
       * User changed password value.
       *
       * @event password-input
       * @type {string}
       */
      emit('password-input', newPassword);
    };

    const handleRepeatPaswordInput = (newRepeatPassword: string) => {
      /**
       * User changed repeat password value.
       *
       * @event repeat-password-input
       * @type {string}
       */
      emit('repeat-password-input', newRepeatPassword);
    };

    const dismiss = () => {
      /**
       * User wants to dismiss this Signup flow.
       *
       * @event dismiss
       * @type {void}
       */
      emit('dismiss');
    };

    const goToUsernameAndEmailStep = () => {
      /**
       * User wants to move to and email step.
       *
       * @event go-to-username-and-email-step
       * @type {void}
       */
      emit('go-to-username-and-email-step');
    };

    const goToPasswordStep = () => {
      /**
       * User wants to move to password step.
       *
       * @event go-to-password-step
       * @type {void}
       */
      emit('go-to-password-step');
    };

    const createAccount = () => {
      /**
       * User wants to create an account with current data.
       *
       * @event create-account
       * @type {void}
       */
      emit('create-account');
    };

    const forgotPassword = () => {
      /**
       * User forgot their password.
       *
       * @event forgot-password
       * @type {void}
       */
      emit('forgot-password');
    };

    const usernameError = computed((): string | null => {
      const syncError = getUsernameSyncError(props.username);

      if (syncError) {
        return t(syncError.localizableErrorMessage, syncError.localizableErrorMessageParams);
      }

      return props.usernameAsyncValidationError || null;
    });

    const emailError = computed((): string | null => {
      const syncError = getEmailSyncError(props.email);

      if (syncError) {
        return t(syncError.localizableErrorMessage, syncError.localizableErrorMessageParams);
      }

      return props.emailAsyncValidationError || null;
    });

    const areUsernameAndEmailValid = computed(
      (): boolean =>
        !!props.username &&
        !usernameError.value &&
        !props.isUsernameAsyncValidationLoading &&
        !props.usernameAsyncValidationError &&
        !!props.email &&
        !emailError.value &&
        !props.isEmailAsyncValidationLoading &&
        !props.emailAsyncValidationError,
    );

    const repeatPasswordError = computed((): string | null => {
      if (!props.password || !props.repeatPassword) {
        return null;
      }

      if (props.password !== props.repeatPassword) {
        return t('validationError.repeatPassword.notMatching');
      }

      return null;
    });

    const arePasswordsValid = computed(
      (): boolean =>
        !!props.password &&
        !passwordError.value &&
        !!props.repeatPassword &&
        !repeatPasswordError.value,
    );

    return {
      t,
      link: ButtonType.link,
      usernameMinLength,
      usernameMaxLength,
      usernameAndEmailStep: SignupModalStep.usernameAndEmail,
      passwordStep: SignupModalStep.password,
      successStep: SignupModalStep.success,
      errorStep: SignupModalStep.error,
      usernameUnavailableStep: SignupModalStep.usernameUnavailable,
      emailUnavailableStep: SignupModalStep.emailUnavailable,
      progress,
      passwordError,
      handleUsernameInput,
      handleEmailInput,
      handlePasswordInput,
      handleRepeatPaswordInput,
      dismiss,
      goToUsernameAndEmailStep,
      goToPasswordStep,
      createAccount,
      forgotPassword,
      usernameError,
      emailError,
      areUsernameAndEmailValid,
      repeatPasswordError,
      arePasswordsValid,
    };
  },
  computed: {},
});
</script>

<i18n lang="json" locale="es">
{
  "hint": {
    "forgotPassword": {
      "callToAction": "recuperar tu cuenta",
      "message": "¿Quieres {0}?"
    }
  },
  "validationError": {
    "username": {
      "minLength": "¡El nombre de usuario debe tener al menos {minLength} caracteres!",
      "maxLength": "¡El nombre de usuario debe tener como máximo {maxLength} caracteres!",
      "symbols": "¡El nombre de usuario sólo puede tener letras y números!"
    },
    "email": {
      "format": "¡Ese correo electrónico no es válido!"
    },
    "repeatPassword": {
      "notMatching": "¡Las contraseñas no coinciden!"
    }
  },
  "success": {
    "body": "En estos momentos tu cuenta está pendiente de activación. En breve recibirás un correo informándote de que ha sido activada.",
    "close": "Ir a la portada",
    "title": "¡Cuenta creada con éxito!"
  }
}
</i18n>
