import type { IconDefinition, IconName, IconPrefix } from '@fortawesome/fontawesome-common-types';

export const prefix = 'caff' as IconPrefix;
export const iconName = 'logout' as IconName;
export const width = 14;
export const height = 14;
export const ligatures = [];
export const unicode = 'f836';
export const svgPathData =
  'm3 0c1.1045695 0 2 .8954305 2 2v1.826l-.01341043.00052142c-1.6729541.09402637-2.98658957 1.48649534-2.98658957 3.17350069l.00535428.17183561.01796419.19785541.01720475.12188073.04091715.18847041c.35075383 1.43191619 1.51950202 2.42146113 2.91943673 2.49421733l-.0008771 1.8257184c0 1.1045695-.8954305 2-2 2h-1c-1.1045695 0-2-.8954305-2-2v-10c0-1.1045695.8954305-2 2-2zm6.34287089 2.69529305.10350556.07787246 4.13545875 3.32199653.1010191.09609349.0720979.08485314.0677481.09754856.0344601.05939439.0531491.11116528.0305184.08227137.0252228.08698879.0239519.12525628.0098243.1072363.0001731.0994005-.0159683.15853478-.0228994.10177508-.0179729.06099499-.0430203.11289418-.0266294.05631194-.041797.07667589-.0398784.0608708-.0914221.11613216-.0783163.07976946-4.175714 3.35750128c-.50209377.422564-1.24873391.3457626-1.65676998-.1672246-.34202316-.4299951-.33939604-1.0352369.00005178-1.47286873l.09187452-.10571767.07075215-.0651268 1.66072307-1.23706678-4.44613075-.00024331c-.52368512 0-.96601551-.34770195-1.11498488-.84044772l-.03100796-.12609092-.01720476-.12188073-.00368509-.09014133c0-.60318967.45077603-1.10708261 1.03887405-1.1715875l.12800864-.0069732 4.44519736.00062996-1.65983865-1.23802465c-.42167284-.35488144-.53284137-.94695959-.30107927-1.42402972l.06510368-.11666771.07334611-.10297456c.38083366-.47878809 1.0566291-.57760985 1.55325897-.24510168z';

export const definition = {
  prefix,
  iconName,
  icon: [width, height, ligatures, unicode, svgPathData],
} as IconDefinition;

export const faLogout = definition;
