import type { IconDefinition, IconName, IconPrefix } from '@fortawesome/fontawesome-common-types';

export const prefix = 'caff' as IconPrefix;
export const iconName = 'entrance' as IconName;
export const width = 14;
export const height = 14;
export const ligatures = [];
export const unicode = 'f819';
export const svgPathData =
  'm3 0c1.1045695 0 2 .8954305 2 2v1.061l-1.8343593 1.47493432-.12592314.11012742-.10101904.09609349-.14567556.15408715-.07209796.08485314-.11857306.15415365-.06774804.09754856-.08722542.13717479-.03446014.05939439-.07445733.14099767-.09664818.2087646c-.05974013.14428706-.10857564.29244056-.14706436.44494837l-.02520915.1137596-.02395189.12525628-.02725244.19317663-.00982432.10723631-.00833757.17898038-.00017613.0994005.01006887.20391772.02810228.27900135.04947708.21989813.05074442.18726453.04954362.14688326.04302022.11289418.06087412.1428237.07861669.15855345.04179706.0766759.08308907.13876177.03987836.0608708.10147078.14110565.09142208.11613216.14433033.16404499.16064583.16362655 1.96692222 1.58165861v1.064c0 1.1045695-.8954305 2-2 2h-1c-1.1045695 0-2-.8954305-2-2v-10c0-1.1045695.8954305-2 2-2zm5.65712911 2.69529305c.49662987-.33250817 1.17242531-.23368641 1.55325899.24510168l.0733461.10297456.0651037.11666771c.2317621.47707013.1205935 1.06914828-.3010793 1.42402972l-1.65983865 1.23802465 4.44519735-.00062996.1280086.0069732c.5880981.06450489 1.0388741.56839783 1.0388741 1.1715875l-.0036851.09014133-.0172047.12188073-.031008.12609092c-.1489694.49274577-.5912998.84044772-1.1149849.84044772l-4.44613074.00024331 1.66072304 1.23706678.0707522.0651268.0918745.10571767c.3394478.43763183.3420749 1.04287363.0000518 1.47286873-.40803609.5129872-1.15467623.5897886-1.65677.1672246l-4.17571402-3.35750128-.07831633-.07976946-.09142207-.11613216-.03987836-.0608708-.04179706-.07667589-.0266294-.05631194-.04302021-.11289418-.01797292-.06099499-.02289939-.10177508-.01596834-.15853478.0001731-.0994005.00982431-.1072363.02395189-.12525628.02522276-.08698879.03051846-.08227137.05314906-.11116528.03446014-.05939439.06774804-.09754856.07209796-.08485314.10101904-.09609349 4.13545879-3.32199653z';

export const definition = {
  prefix,
  iconName,
  icon: [width, height, ligatures, unicode, svgPathData],
} as IconDefinition;

export const faEntrance = definition;
