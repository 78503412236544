import type { Ref } from 'vue';
import { ref } from 'vue';

import sample from 'lodash.sample';

import type { CategorySlug } from '@caff/category-api-model';
import { yieldMainThread } from '@caff/isomorphic-promise';
import type { UseQueryReturnValue } from '@caff/frontend-use-query';
import { useQuery } from '@caff/frontend-use-query';

import { useCategories } from './simple';

export const useFeaturedCategorySlug = (): Omit<
  UseQueryReturnValue<CategorySlug | null, Error>,
  'data'
> & { featuredCategorySlug: Ref<CategorySlug | null> } => {
  const { sortedCategories, hasNextPage, executeNextPage, isExecuting } = useCategories();
  const { data, ...rest } = useQuery<CategorySlug | null, Error>({
    queryKey: ref('featuredCategorySlug'),
    queryFn: async () => {
      while (isExecuting.value) {
        await yieldMainThread();
      }

      while (hasNextPage.value) {
        await executeNextPage();
      }

      return sample(sortedCategories.value.map(({ slug }) => slug)) ?? null;
    },
  });

  return { ...rest, featuredCategorySlug: data };
};
