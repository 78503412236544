<template>
  <div>
    <H2 center>{{ title }}</H2>
    <P center>{{ body }}</P>

    <Footer>
      <template #trailing>
        <Button :type="primary" @click.stop.prevent="dismiss()">
          {{ close }}
        </Button>
      </template>
    </Footer>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import { Button, ButtonType } from '@caff/cds-button';

import Footer from './Footer.vue';
import H2 from './H2.vue';
import P from './P.vue';

export default defineComponent({
  name: 'SuccessStep',
  components: { Button, Footer, H2, P },
  props: {
    title: {
      type: String,
      required: true,
    },
    body: {
      type: String,
      required: true,
    },
    close: {
      type: String,
      required: true,
    },
  },
  emits: ['dismiss'],
  setup(props, { emit }) {
    const dismiss = () => {
      /**
       * User dismissed the step.
       *
       * @event dismiss
       * @type {void}
       */
      emit('dismiss');
    };

    return { primary: ButtonType.primary, dismiss };
  },
});
</script>

<style lang="scss" scoped>
@import '@caff/cds-scss-core';

@include t-responsive--starting-at($responsive-breakpoint-tablet-medium) {
  h2,
  p {
    margin-left: auto;
    margin-right: auto;
    max-width: get-size-in-rem(310px);
  }
}
</style>
