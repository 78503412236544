<template>
  <MainNavbarItemsGroup>
    <!-- @slot Use this slot to render content before the user button -->
    <slot name="leadingAccessoryItems" />

    <MainNavbarUserButton />

    <!-- @slot Use this slot to render content after the user button -->
    <slot name="trailingAccessoryItems" />
  </MainNavbarItemsGroup>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import MainNavbarItemsGroup from './MainNavbarItemsGroup.vue';
import MainNavbarUserButton from './MainNavbarUserButton.vue';

export default defineComponent({
  name: 'MainNavbarLoggedInMenu',
  components: {
    MainNavbarItemsGroup,
    MainNavbarUserButton,
  },
});
</script>
