import { computed, Ref } from 'vue';

import {
  S3ConfigForImageKind,
  qaS3ConfigForImageKind,
  productionS3ConfigForImageKind,
} from '@caff/image-isomorphic-model';

import { useIsProduction } from './useEnvironment';

export const useS3ConfigForImageKind = (): Ref<S3ConfigForImageKind> =>
  computed(() => {
    const isProduction = useIsProduction();

    return isProduction.value ? productionS3ConfigForImageKind : qaS3ConfigForImageKind;
  });
