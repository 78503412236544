import { parseNoAttributedHTMLTagToken } from './noAttributedHTMLTagParser';
import { MarkdownNodeHTMLTag, MarkdownNodeType, ParseTokenFunction } from './types';

export const parseOrderedListToken: ParseTokenFunction = (params) =>
  parseNoAttributedHTMLTagToken({
    sourceText: params.sourceText,
    tokens: params.tokens,
    startingNodeIndex: params.startingNodeIndex,
    startingCharacterAbsolutePosition: params.startingCharacterAbsolutePosition,
    mentions: params.mentions,
    markdownNodeType: MarkdownNodeType.orderedList,
    markdownNodeHTMLTag: MarkdownNodeHTMLTag.ol,
    markdownToken: {
      prefixLength: 0,
      suffixLength: 2,
    },
    tokenTypeParsers: params.tokenTypeParsers,
  });
