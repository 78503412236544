export const enum ScreenOrientation {
  portrait = 'portrait',
  landscape = 'landscape',
}

export const getScreenOrientationType = (): ScreenOrientation =>
  typeof screen !== 'undefined' &&
  screen.orientation &&
  screen.orientation.type.startsWith('portrait')
    ? ScreenOrientation.portrait
    : ScreenOrientation.landscape;
