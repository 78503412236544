import { defineStore } from 'pinia';
import { ref } from 'vue';

import { isServer } from '@caff/isomorphic-is-server';

import { dateLocalizations } from '../../i18n/es';

export const useDateStore = defineStore('date', () => {
  const currentDate = ref(new Date().getTime());

  if (!isServer()) {
    setInterval(() => {
      currentDate.value = new Date().getTime();
    }, 60 * 1000);
  }

  return {
    currentDate,
    locale: dateLocalizations,
  };
});
