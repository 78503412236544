import { PropType } from 'vue';

import { S3ConfigForImageKind, ImageSizeForLocation } from '@caff/image-isomorphic-model';

export const s3BucketConfigForImageKindVueComponentProperty = {
  type: Object as PropType<S3ConfigForImageKind>,
  required: true,
};

const wellKnownImageHeights = new Set(
  Object.values(ImageSizeForLocation).map(({ height }) => height),
);
const wellKnownImageWidths = new Set(Object.values(ImageSizeForLocation).map(({ width }) => width));

export const imageHeightVueComponentProperty = {
  type: null as unknown as PropType<number | null>,
  required: false,
  validator(value: number): boolean {
    if (value === undefined || value === null) {
      return true;
    }

    if (!wellKnownImageHeights.has(value)) {
      console.warn(
        `Using an image of height ${value}px but this height does not correspond with any well-known height`,
      );
      return false;
    }

    return true;
  },
};

export const imageWidthVueComponentProperty = {
  type: Number,
  required: true,
  validator(value: number): boolean {
    if (!wellKnownImageWidths.has(value)) {
      console.warn(
        `Using an image of width ${value}px but this width does not correspond with any well-known width`,
      );
      return false;
    }

    return true;
  },
};
