<template>
  <ClientOnly>
    <footer v-if="hasChildren" class="c-infinite-scroll-footer">
      <ActivityIndicator v-if="isLoading" />
      <FontAwesomeIcon v-else-if="end" :icon="faCaffLogo" fixed-width aria-hidden />
    </footer>
  </ClientOnly>
</template>

<script lang="ts">
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { computed, defineComponent } from 'vue';
import { ClientOnly } from '@caff/client-only-frontend-component';

import { ActivityIndicator } from '@caff/cds-activity-indicator';
import { faCaffLogo } from '@caff/cds-icons';

export default defineComponent({
  name: 'InfiniteScrollFooter',
  components: {
    ActivityIndicator,
    ClientOnly,
    FontAwesomeIcon,
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },

    end: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const hasChildren = computed(() => props.isLoading || props.end);

    return { faCaffLogo, hasChildren };
  },
});
</script>

<style lang="scss" scoped>
@import '@caff/cds-scss-core';

.c-infinite-scroll-footer {
  @extend %t-padding-v-m;
  @extend %t-padding-h-0;

  align-items: center;
  color: var(--colors-box-background);
  display: flex;
  flex-direction: column;
  font-size: get-size-in-rem(26px);
  justify-content: center;
}

.c-bordered-box {
  .c-infinite-scroll-footer {
    color: var(--colors-background);
  }
}
</style>
