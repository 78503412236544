import { StateTree } from 'pinia';

export { useBrowserPreferencesStore } from './browserPreferences';
export { useDateStore } from './date';
export { useExternalUrlsStore } from './external-url';
export { useFrontpageStore } from './frontpage';
export { ModalType, useModalStore } from './modal';
export { useToastrStore } from './toastr';

const getStoreWithTransformedHtmlValues = <T extends Record<string, StateTree>>({
  store,
}: {
  store: T;
  getTransformedHtmlValue: (value: string) => string;
}): T => ({
  ...store,
});

export const serializeStore = (store: Record<string, StateTree>): Record<string, StateTree> =>
  getStoreWithTransformedHtmlValues({
    store,
    getTransformedHtmlValue: (value) => btoa(encodeURIComponent(value)),
  });

export const unserializeStore = (store: Record<string, StateTree>): Record<string, StateTree> =>
  getStoreWithTransformedHtmlValues({
    store,
    getTransformedHtmlValue: (value) => decodeURIComponent(atob(value)),
  });
