import { ref, watch } from 'vue';

import { UseMutationReturnValue } from '@caff/frontend-use-query';

import { passwordHasher } from '../../utils/passwordHasher';
import { useAxiosMutation } from '../useAxiosQuery';
import { useLogin } from '../useLogin';

export const useOnCurrentlyLoggedInUserChange = (
  callback: Parameters<typeof watch>[1],
  options?: Parameters<typeof watch>[2],
): ReturnType<typeof watch> => {
  const { data } = useLogin();

  return watch([data], (...params) => callback(...params), options);
};

export const useSendChangePasswordEmail = (): Omit<
  UseMutationReturnValue<void, void, Error>,
  'mutate'
> & {
  sendChangePasswordEmail: UseMutationReturnValue<void, void, Error>['mutate'];
} => {
  const { mutate: sendChangePasswordEmail, ...rest } = useAxiosMutation<void, void, Error>({
    mutationKey: ref('sendChangePasswordEmail'),
    async mutationFn({ axiosInstance }) {
      await axiosInstance.post('/account/send-change-password-email');
    },
  });

  return {
    ...rest,
    sendChangePasswordEmail,
  };
};

export const useSavePassword = (): Omit<
  UseMutationReturnValue<
    {
      newPassword: string;
      changePasswordToken: string;
    },
    void,
    Error
  >,
  'mutate'
> & {
  savePassword: UseMutationReturnValue<
    {
      newPassword: string;
      changePasswordToken: string;
    },
    void,
    Error
  >['mutate'];
} => {
  const { mutate: savePassword, ...rest } = useAxiosMutation<
    {
      changePasswordToken: string;
      newPassword: string;
    },
    void,
    Error
  >({
    mutationKey: ref('savePassword'),
    async mutationFn({ axiosInstance, param: { changePasswordToken, newPassword } }) {
      const hashedPassword = await passwordHasher.getPasswordHash(newPassword);

      await axiosInstance.post('/account/password', {
        changePasswordToken,
        newPassword: hashedPassword,
      });
    },
  });

  return {
    ...rest,
    savePassword,
  };
};
