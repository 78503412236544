import { isServer } from '@caff/isomorphic-is-server';
import { counterFactory } from '@caff/isomorphic-number';

import {
  X_AXIS_RELATIVE_POSITION,
  Y_AXIS_RELATIVE_POSITION,
  Offset,
  RelativePosition,
  Translation,
} from './axis';
import { getDOMElementOffset } from './dom';

export const removePopupFromDOM = (popupElement?: Element): void => {
  if (popupElement && popupElement.parentNode) {
    popupElement.parentNode.removeChild(popupElement);
  }
};

export interface PopupPosition {
  containerOffset: Offset;
  popupTranslation: Translation;
  popupPositionRelativeToButton: RelativePosition;
  maxHeight: number;
}

export const reattachPopupToDocumentBodyFactory = (): ((params: {
  containerElement: Element;
  opened: boolean;
  popupElement: Element;
  removalAnimationDurationInMs: number;
  beforeEnqueuingPopupElementRemovalFromDOM: () => void;
  afterPopupElementAddedToDOM: () => void;
}) => void) => {
  const reattachPopupToDocumentBodyCounter = counterFactory();
  let lastReattachPopupToDocumentBodyId = reattachPopupToDocumentBodyCounter();

  return ({
    containerElement,
    opened,
    popupElement,
    removalAnimationDurationInMs,
    beforeEnqueuingPopupElementRemovalFromDOM,
    afterPopupElementAddedToDOM,
  }): void => {
    if (isServer()) {
      return;
    }

    // In Histoire this component is created twice and there's a leaked component
    // which is never destroyed. If we don't add this check we end up adding the
    // popup content more than once to the page. This is technically correct but
    // it's weird (normally if the popup container is no in the DOM Vue would have
    // called the beforeDestroy hook and cleaned it up already).
    if (!containerElement.parentNode) {
      return removePopupFromDOM(popupElement);
    }

    const id = reattachPopupToDocumentBodyCounter();
    lastReattachPopupToDocumentBodyId = id;

    // We only want the popup in the DOM when it's visible.
    if (!opened) {
      beforeEnqueuingPopupElementRemovalFromDOM();

      setTimeout(() => {
        if (lastReattachPopupToDocumentBodyId !== id) {
          return;
        }

        removePopupFromDOM(popupElement);
      }, removalAnimationDurationInMs);

      return;
    }

    document.body.appendChild(popupElement);
    afterPopupElementAddedToDOM();
  };
};

export const getPopupPosition = ({
  containerElement,
  popupElement,
}: {
  containerElement: Element;
  popupElement: Element;
}): PopupPosition => {
  const containerOffset = getDOMElementOffset(containerElement);

  const containerRect = containerElement.getBoundingClientRect();
  const popupRect = popupElement.getBoundingClientRect();

  const popupComputedStyle = getComputedStyle(popupElement);

  // Spacing user defined via CSS in --horizontal-spacing-to-toggle-button
  // and --vertical-spacing-to-toggle-button CSS variables if not supported
  // (aka, IE11) we'll use 0
  const horizontalSpacingToToggleButton =
    parseInt(popupComputedStyle.getPropertyValue('--hspace-to-toggle-button'), 10) || 0;
  const verticalSpacingToToggleButton =
    parseInt(popupComputedStyle.getPropertyValue('--vspace-to-toggle-button'), 10) || 0;

  // Translation required in the x-axis to position the popup so its
  // content is displayed towards right/left, assuming popup is properly
  // positioned in top left corner anchor of the container
  const towardsRightTranslationX = horizontalSpacingToToggleButton;
  const towardsLeftTranslationX =
    containerRect.width - popupRect.width - horizontalSpacingToToggleButton;

  // Translation required in the y-axis to position the popup so it's
  // content is displayed towards the bottom/top of the page, assuming popup
  // is properly positioned in top left corner anchor of the container
  const belowTranslationY = containerRect.height + verticalSpacingToToggleButton;
  const aboveTranslationY = 0 - popupRect.height - verticalSpacingToToggleButton;

  // We'll choose proper transform based on whether the content will fit
  // the screen or not.

  const fitsTowardsRight =
    containerRect.left + towardsRightTranslationX + popupRect.width <
    document.documentElement.clientWidth;

  const translationX = fitsTowardsRight ? towardsRightTranslationX : towardsLeftTranslationX;

  const maxHeightWhenPositionedBelow =
    document.documentElement.clientHeight - (containerRect.top + belowTranslationY);
  const fitsBelow = maxHeightWhenPositionedBelow >= popupRect.height;
  const maxHeightWhenPositionedAbove = containerRect.top - verticalSpacingToToggleButton;
  const fitsAbove = maxHeightWhenPositionedAbove >= popupRect.height;

  const shouldBePositionedBelow =
    fitsBelow || (!fitsAbove && maxHeightWhenPositionedBelow >= maxHeightWhenPositionedAbove);

  const translationY = shouldBePositionedBelow ? belowTranslationY : aboveTranslationY;

  return {
    containerOffset,
    popupTranslation: {
      x: translationX,
      y: translationY,
    },
    popupPositionRelativeToButton: {
      x: fitsTowardsRight ? X_AXIS_RELATIVE_POSITION.left : X_AXIS_RELATIVE_POSITION.right,
      y: shouldBePositionedBelow ? Y_AXIS_RELATIVE_POSITION.below : Y_AXIS_RELATIVE_POSITION.above,
    },
    maxHeight: shouldBePositionedBelow
      ? maxHeightWhenPositionedBelow
      : maxHeightWhenPositionedAbove,
  };
};
