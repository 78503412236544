import { HashingAlgorithm } from './HashingAlgorithm';

/**
 * `PasswordHasher` is a single implementation for frontend and backend of the
 * hashing methods used to provide a safe login to Caff.
 *
 * It includes methods to compute password hashes as well as methods to get
 * the answer to login challenges.
 *
 * This class can be used in both, frontend and backend, to ensure the same
 * algorithm is used to answer and check answers.
 */
export class PasswordHasher {
  private hashingAlgorithmImplementation: HashingAlgorithm;

  /**
   * Creates a new instance of PasswordHasher configured to use given hashing
   * algorithm to compute hashes.
   *
   * @param hashingAlgorithmImplementation Implementation of hashing algorithm
   * to be used. Note that this parameter is used to allow providing efficient
   * implementations for each platform.
   */
  constructor(hashingAlgorithmImplementation: HashingAlgorithm) {
    this.hashingAlgorithmImplementation = hashingAlgorithmImplementation;
  }

  /**
   * Returns the hash of given password.
   *
   * @param password Password to be hashed.
   * @returns Promise that will be resolved with hashed password.
   */
  public async getPasswordHash(password: string): Promise<string> {
    const { hashHex } = await this.hashingAlgorithmImplementation({
      pass: password,
      salt: 'caff-password-salt',
    });
    return hashHex;
  }

  /**
   * Returns the answer to the login challenge for a user with given username or
   * email and password.
   *
   * @param param
   * @param param.usernameOrEmail Username or email of user trying to log in.
   * @param param.hashedPassword Hash of password of user trying to log in. It
   * can be computed using `PasswordHasher.getPasswordHash` method.
   */
  public async getLoginChallengeAnswer({
    usernameOrEmail,
    hashedPassword,
  }: {
    usernameOrEmail: string;
    hashedPassword: string;
  }): Promise<string> {
    const { hashHex } = await this.hashingAlgorithmImplementation({
      pass: `${hashedPassword}${usernameOrEmail}`,
      salt: 'caff-login-challenge-salt',
    });
    return hashHex;
  }
}
