import { watchEffect, WatchStopHandle } from 'vue';
import throttle from 'lodash.throttle';

import { isServer } from '@caff/isomorphic-is-server';
import { counterFactory } from '@caff/isomorphic-number';

export type OnScrollAnywhereCallback = (event: Event) => void;

const callbacks: { [key: number]: OnScrollAnywhereCallback } = {};

const getNextCallbackId = counterFactory();

const runCallbacks = throttle(
  (event: Event) => {
    for (const callback of Object.values(callbacks)) {
      callback(event);
    }
  },
  10,
  {
    leading: true,
    trailing: true,
  },
);

const isEmpty = <T extends Record<string, unknown>>(object: T): boolean =>
  Object.keys(object).length === 0;

const addGlobalListenersIfNeeded = () => {
  if (!isEmpty(callbacks)) {
    return;
  }

  window.addEventListener('scroll', runCallbacks, true);
};

const removeGlobalListenersIfNeeded = () => {
  if (!isEmpty(callbacks)) {
    return;
  }

  window.removeEventListener('scroll', runCallbacks);
};

export const onScrollAnywhere = (callback: (event: Event) => void): WatchStopHandle =>
  watchEffect((onCleanup) => {
    if (isServer()) {
      return;
    }

    addGlobalListenersIfNeeded();

    const idCallback = getNextCallbackId();
    callbacks[idCallback] = callback;

    onCleanup(() => {
      delete callbacks[idCallback];
      removeGlobalListenersIfNeeded();
    });
  });
