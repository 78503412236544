<template>
  <blockquote ref="twit" :data-theme="theme" data-dnt="true" class="twitter-tweet">
    <a :href="url">
      {{ url }}
    </a>
  </blockquote>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted } from 'vue';

import { isServer } from '@caff/isomorphic-is-server';
import { loadScript } from '@caff/frontend-script-loader';

export default defineComponent({
  name: 'MarkdownTwitterPreviewNode',
  props: {
    darkMode: {
      type: Boolean,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    loadScript('https://platform.twitter.com/widgets.js').catch((error) => {
      console.error(error);
    });

    onMounted(() => {
      if (isServer()) {
        return;
      }

      const windowWithTwitter = window as unknown as {
        twttr?: { widgets: { load: () => void } };
      };

      if (!windowWithTwitter.twttr) {
        return;
      }

      windowWithTwitter.twttr.widgets.load();
    });

    const theme = computed(() => (props.darkMode ? 'dark' : null));

    return {
      theme,
    };
  },
});
</script>
