export class Deferred<T> implements Promise<T> {
  get [Symbol.toStringTag](): 'Promise' {
    return 'Promise' as const;
  }

  public get resolve(): (value: T | PromiseLike<T>) => void {
    return this._resolve;
  }

  public get reject(): (reason?: unknown) => void {
    return this._reject;
  }

  private readonly _promise: Promise<T>;
  private _resolve: (value: T | PromiseLike<T>) => void;
  private _reject: (reason?: unknown) => void;
  private _isSettled: boolean;

  constructor() {
    this._resolve = (): never => {
      /* istanbul ignore next */

      throw new Error('._resolve was called before Deferred was built');
    };
    this._reject = (): never => {
      /* istanbul ignore next */

      throw new Error('._reject was called before Deferred was built');
    };

    this._promise = new Promise<T>((resolve, reject) => {
      this._resolve = resolve;
      this._reject = reject;
    });

    this._isSettled = false;
    this._promise.then(() => (this._isSettled = true)).catch(() => (this._isSettled = true));
  }

  then<TResult1 = T, TResult2 = never>(
    onfulfilled?: ((value: T) => TResult1 | PromiseLike<TResult1>) | undefined | null,
    onrejected?: ((reason: unknown) => TResult2 | PromiseLike<TResult2>) | undefined | null,
  ): Promise<TResult1 | TResult2> {
    return this._promise.then(onfulfilled, onrejected);
  }

  catch<TResult = never>(
    onrejected?: ((reason: unknown) => TResult | PromiseLike<TResult>) | undefined | null,
  ): Promise<T | TResult> {
    return this._promise.catch(onrejected);
  }

  finally(onfinally?: (() => void) | undefined | null): Promise<T> {
    return this._promise.finally(onfinally);
  }

  get isSettled(): boolean {
    return this._isSettled;
  }
}
