import { computed } from 'vue';
import type { Ref } from 'vue';

import { ColorSchema } from '@caff/color-schema-api-model';

import { RawLoggedInUser } from '../models/User';
import { UseAxiosQueryResult } from './useAxiosQuery';
import { useCurrentlyLoggedInUser } from './useSession';

export const usePreferredColorSchema = (): Omit<UseAxiosQueryResult<RawLoggedInUser>, 'data'> & {
  preferredColorSchema: Ref<ColorSchema>;
} => {
  const { currentlyLoggedInUser, ...rest } = useCurrentlyLoggedInUser();

  const preferredColorSchema = computed(
    () => currentlyLoggedInUser.value?.preferredColorSchema.name ?? ColorSchema.auto,
  );

  return {
    ...rest,
    preferredColorSchema,
  };
};
