import type { IconDefinition, IconName, IconPrefix } from '@fortawesome/fontawesome-common-types';

export const prefix = 'caff' as IconPrefix;
export const iconName = 'user' as IconName;
export const width = 14;
export const height = 14;
export const ligatures = [];
export const unicode = 'f855';
export const svgPathData =
  'm6.94429114 0c2.27449595 0 4.11833886 1.85276456 4.11833886 4.13826547 0 .54344932-.10425 1.06243176-.2937529 1.53785626-.1263354.31694967-.3942327.74188524-.80369203 1.27480673.21574263-.01503759.62827003.27340131 1.23758193.86531669.913968.88787307 1.504857 2.17829295 1.772669 3.87125955.016351.103362.024564.207848.024564.3124953 0 1.1045695-.89543 2-2 2h-7.99970977c-.104646 0-.209131-.0082132-.312493-.0245639-1.09099997-.1725852-1.83552195-1.1969232-1.66294296-2.287924.261901-1.6556086.83277798-2.92624058 1.71263196-3.81189603.58656932-.59043697.99367998-.88602716 1.22133197-.88677057-.41159132-.51702044-.68471665-.93838974-.81937598-1.26410789-.20198899-.48857723-.31349099-1.02444033-.31349099-1.58647214 0-2.28550091 1.84384296-4.13826547 4.11833991-4.13826547z';

export const definition = {
  prefix,
  iconName,
  icon: [width, height, ligatures, unicode, svgPathData],
} as IconDefinition;

export const faUser = definition;
