<template>
  <DropdownMenuEntry v-if="isActionAllowed" @click="askForConfirmation($event)">
    <FontAwesomeIcon :icon="faTrashBin" class="u-margin-r-xs" aria-hidden fixed-width />

    {{ t('menuEntryLabel') }}

    <ModalTransitionAnimation>
      <ActionConfirmationModal
        v-if="isAskingForConfirmation"
        :title="t('confirmationModal.title')"
        :description="t('confirmationModal.description')"
        :cancelButtonLabel="t('confirmationModal.cancelButtonLabel')"
        :confirmButtonLabel="t('confirmationModal.confirmButtonLabel')"
        :loading="isExecuting"
        @close-modal="closeModal()"
        @confirm-action="confirmAction()"
      >
        <ThreadActionPreview :threadSlug="threadSlug" />
      </ActionConfirmationModal>
    </ModalTransitionAnimation>
  </DropdownMenuEntry>
</template>

<script lang="ts">
export const isAllowed = (currentUser: LoggedInUser | null): boolean =>
  !!currentUser && currentUser.canDeleteThreads;
</script>

<script lang="ts" setup>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import { DropdownMenuEntry } from '@caff/cds-dropdown-menu';
import { faTrashBin } from '@caff/cds-icons';
import { ModalTransitionAnimation } from '@caff/cds-modal';

import ActionConfirmationModal from '../common/ActionConfirmationModal.vue';
import {
  AskingForConfirmationEmits,
  useCurrentlyLoggedInUser,
  useDeleteThread,
  useIsAskingForConfirmation,
  useThread,
} from '../../composition';
import { LoggedInUser } from '../../models/User';
import { useToastrStore } from '../../store';
import { getRetryableFunction } from '../../utils/retryable';
import type { MenuEntryProps } from './menuEntry.mixin';
import ThreadActionPreview from './ThreadActionPreview.vue';

const props = defineProps<MenuEntryProps>();
const emit = defineEmits<AskingForConfirmationEmits>();

const { t } = useI18n();

const threadSlug = computed(() => props.threadSlug);

const { isExecuting, deleteThread } = useDeleteThread(threadSlug);

const { currentlyLoggedInUser } = useCurrentlyLoggedInUser();
const isActionAllowed = computed(() => isAllowed(currentlyLoggedInUser.value));

const { thread } = useThread(threadSlug);

const { isAskingForConfirmation, askForConfirmation, closeModal, confirmAction } =
  useIsAskingForConfirmation({
    emit,
    async performAction() {
      await getRetryableFunction(async () => {
        // All data of thread should be available, we want this to fail if it
        // isn't! However, note that the data will no longer be available after
        // deleting the thread, so we cache the username here for future reference
        // in the success toastr
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const username = thread.value!.authorCanonicalUsername;

        await deleteThread();

        const toastrStore = useToastrStore();
        toastrStore.showSuccessToastr({
          message: t('actionSuccessful', {
            username,
          }),
        });
      });
    },
  });
</script>

<i18n lang="json" locale="es">
{
  "menuEntryLabel": "Borrar hilo",
  "confirmationModal": {
    "title": "¿Quieres borrar este hilo?",
    "description": "El hilo dejará de aparecer y su contenido se borrará de la base de datos.",
    "cancelButtonLabel": "Cancelar",
    "confirmButtonLabel": "Borrar hilo"
  },
  "actionSuccessful": "El hilo de {'@'}{username} ya se ha borrado"
}
</i18n>
