export const enum OnScreenKeyboardStatus {
  visible = 'visible',
  hidden = 'hidden',
}

const getOnScreenKeyboardStatusFromScreenSize = (dH: number) => {
  if (dH > 0.1 * screen.availHeight) {
    return OnScreenKeyboardStatus.hidden;
  }

  if (dH < -0.1 * screen.availHeight) {
    return OnScreenKeyboardStatus.visible;
  }

  return null;
};

const getOnScreenKeyboardStatusKnowingLayoutHeight = ({
  dH,
  height,
  nonOSKLayoutHeight,
}: {
  dH: number;
  height: number;
  nonOSKLayoutHeight: number;
}): OnScreenKeyboardStatus | null => {
  if (height < 0.9 * nonOSKLayoutHeight && dH < 0) {
    return OnScreenKeyboardStatus.visible;
  }

  if (height === nonOSKLayoutHeight && dH > 0) {
    return OnScreenKeyboardStatus.hidden;
  }

  return null;
};

export const getOnScreenKeyboardStatus = ({
  dH,
  height,
  nonOSKLayoutHeight,
}: {
  dH: number;
  height: number;
  nonOSKLayoutHeight?: number;
}): OnScreenKeyboardStatus | null => {
  return !nonOSKLayoutHeight
    ? getOnScreenKeyboardStatusFromScreenSize(dH)
    : getOnScreenKeyboardStatusKnowingLayoutHeight({ dH, height, nonOSKLayoutHeight });
};
