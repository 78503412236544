import { ImageSize } from './ImageSize';
import { ScreenDensity } from './ScreenDensity';

const SUFFIX_FOR_SCREEN_DENSITY: Record<ScreenDensity, string> = {
  [ScreenDensity.x1]: '',
  [ScreenDensity.x2]: '@2x',
  [ScreenDensity.x3]: '@3x',
};

export class ScreenDensityVariation {
  originalSize: ImageSize;
  screenDensity: ScreenDensity;

  constructor(originalSize: ImageSize, screenDensity: ScreenDensity) {
    this.originalSize = originalSize;
    this.screenDensity = screenDensity;
  }

  get suffix(): string {
    return SUFFIX_FOR_SCREEN_DENSITY[this.screenDensity];
  }

  get urlSegmentForSize(): string {
    return this.originalSize.height
      ? `${this.originalSize.width}x${this.originalSize.height}`
      : `w-${this.originalSize.width}`;
  }

  get size(): ImageSize {
    switch (this.screenDensity) {
      case ScreenDensity.x1:
        return this.originalSize;
      case ScreenDensity.x2:
        return {
          width: this.originalSize.width * 2,
          height: this.originalSize.height ? this.originalSize.height * 2 : null,
        };
      case ScreenDensity.x3:
        return {
          width: this.originalSize.width * 3,
          height: this.originalSize.height ? this.originalSize.height * 3 : null,
        };
    }
  }
}
