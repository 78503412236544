import { useEventListener } from '@vueuse/core';
import { ref, Ref } from 'vue';

import { isServer } from '@caff/isomorphic-is-server';

import { OnScreenKeyboardStatus } from '../status';

export const useOnScreenKeyboardStatusiOS = (): Ref<OnScreenKeyboardStatus> => {
  const status = ref(OnScreenKeyboardStatus.hidden);

  const isSupported = !isServer() && 'visualViewport' in window;

  if (!isSupported) {
    console.warn('On-Screen-Keyboard detection not supported on this version of iOS');

    return status;
  }

  useEventListener(
    visualViewport,
    'resize',
    (event: Event) => {
      if (isServer()) {
        return;
      }

      const target = event.target as unknown as { height: number };
      const relativeDifferenceBetweenInnerHeightAndViewportHeight =
        (window.innerHeight - target.height) / window.innerHeight;

      // Account for the predictive text bar, showing on iPad with an external keyboard
      const newStatus =
        relativeDifferenceBetweenInnerHeightAndViewportHeight > 0.1
          ? OnScreenKeyboardStatus.visible
          : OnScreenKeyboardStatus.hidden;

      status.value = newStatus;
    },
    { passive: true },
  );

  return status;
};
