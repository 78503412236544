import type { ValueOf } from 'type-fest';

export const isEmpty = (collection: Record<string | number | symbol, unknown>): boolean =>
  Object.keys(collection).length === 0;

export const mapValues = <Key extends string | number | symbol, OriginalValue, NewValue>(
  inputMap: Record<Key, OriginalValue>,
  transformFn: (value: OriginalValue) => NewValue,
): Record<Key, NewValue> => {
  const result = {} as Record<Key, NewValue>;

  for (const key in inputMap) {
    result[key] = transformFn(inputMap[key]);
  }

  return result;
};

export const omit = <
  Key extends string | number | symbol,
  KeyToOmit extends keyof InputMap,
  InputMap extends Record<Key, unknown>,
>(
  inputMap: InputMap,
  keyToOmit: KeyToOmit,
): Omit<InputMap, KeyToOmit> => {
  const result = {} as Omit<InputMap, KeyToOmit>;

  for (const key of Object.keys(inputMap)) {
    const typedKey = key as keyof InputMap & keyof typeof result;

    if (key !== keyToOmit) {
      result[typedKey] = inputMap[typedKey];
    }
  }

  return result;
};

export const pickBy = <Key extends string | number | symbol, InputMap extends Record<Key, unknown>>(
  inputMap: InputMap,
  filterFn: (value: ValueOf<InputMap>) => boolean,
): { [K in keyof InputMap]: InputMap[K] } => {
  const result = {} as { [K in keyof InputMap]: InputMap[K] };

  for (const key in inputMap) {
    if (filterFn(inputMap[key])) {
      result[key] = inputMap[key];
    }
  }

  return result;
};

export const reduceToObject = <Item, Key extends string | symbol | number, MappedItem = Item>(
  collectionToReduce: Array<Item>,
  {
    getKey,
    getValue,
  }: {
    getKey: (item: Item) => Key;
    getValue: (item: Item) => MappedItem;
  },
): Record<Key, MappedItem> => {
  const result = {} as Record<Key, MappedItem>;

  for (const item of collectionToReduce) {
    result[getKey(item)] = getValue(item);
  }

  return result;
};

export const sortAlphabeticallyByKey = <
  Key extends number | string | symbol,
  Item,
  Collection extends Array<Item & Record<Key, string>>,
>(
  collection: Collection,
  key: keyof Item & Key,
): Collection =>
  ([...collection] as Collection).sort((lhs, rhs) => {
    const lhsValue = lhs[key];
    const rhsValue = rhs[key];

    if (rhsValue > lhsValue) {
      return -1;
    }

    if (lhsValue > rhsValue) {
      return 1;
    }

    return 0;
  });

export const uniqueValues = <Collection extends Array<string>>(
  list: Collection,
): Array<Collection[number]> => [...new Set(list)];

export const zip = (...arrays: Array<Array<unknown>>): Array<Array<unknown>> =>
  (arrays[0] ?? []).map((_, index) => arrays.map((singleArray) => singleArray[index]));
