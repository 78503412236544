<template>
  <Modal
    ref="el"
    :class="{
      'c-new-thread-composer': true,
      'c-new-thread-composer--on-screen-keyboard-visible': isOnScreenKeyboardVisible,
    }"
    @click-outside="close()"
  >
    <form ref="form" class="c-new-thread-composer__form" @submit.prevent.stop="publish()">
      <SingleLineTextInput
        v-model="title"
        :placeholder="t('fields.title.placeholder')"
        class="c-new-thread-composer__title"
      />

      <TextComposer
        v-model="content"
        :loading="isRunningNewThreadRequest"
        :isExternalDataMissing="isDataMissing"
        class="c-new-thread-composer__text-composer"
        showAuthorBadge
        @submit="publish()"
        @cancel="close()"
      >
        <template #actions="{ cancel }">
          <div class="c-new-thread-composer__category-picker">
            <SingleCategoryPicker
              v-model="pickedCategorySlug"
              :disabled="!!forcedCategorySlug"
              @open="disableClosingModal()"
              @close="enableClosingModal()"
            />
          </div>
          <SecondaryButton class="u-margin-r-xs" @click.prevent.stop="cancel()">
            {{ t('actions.cancel') }}
          </SecondaryButton>
        </template>
      </TextComposer>

      <ActivityIndicator v-if="isRunningNewThreadRequest" covering />
    </form>
  </Modal>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import { CategorySlug } from '@caff/category-api-model';
import { ActivityIndicator } from '@caff/cds-activity-indicator';
import { SecondaryButton } from '@caff/cds-button';
import { Modal } from '@caff/cds-modal';
import { useIsOnScreenKeyboardVisible } from '@caff/cds-on-screen-keyboard';

import { useCreateNewThread, useFullscreenModalCssVars } from '../../composition';
import type { Thread } from '../../models/Thread';
import { useToastrStore } from '../../store';
import { getRetryableFunction } from '../../utils/retryable';
import { SingleLineTextInput } from '../form';
import SingleCategoryPicker from './SingleCategoryPicker.vue';
import TextComposer from './TextComposer.vue';

const props = withDefaults(
  defineProps<{
    forcedCategorySlug?: CategorySlug | null;
  }>(),
  {
    forcedCategorySlug: null,
  },
);

const emit = defineEmits<{
  close: [];
  threadPosted: [Thread];
}>();

const { t } = useI18n();

const el = ref<HTMLElement | null>(null);
onMounted(() => {
  useFullscreenModalCssVars(el);
});
const form = ref<Element | null>(null);

const pickedCategorySlug = ref<CategorySlug | null>(props.forcedCategorySlug ?? null);
const title = ref('');
const content = ref('');

const { createNewThread, isExecuting: isRunningNewThreadRequest } = useCreateNewThread({
  categorySlug: pickedCategorySlug,
});

const isDataMissing = computed(() => !(pickedCategorySlug.value && title.value && content.value));

const isModalClosable = ref(true);

const close = () => {
  if (!isModalClosable.value) {
    return;
  }

  emit('close');
};

const disableClosingModal = () => {
  isModalClosable.value = false;
};
const enableClosingModal = () => {
  isModalClosable.value = true;
};

const isOnScreenKeyboardVisible = useIsOnScreenKeyboardVisible();
watch(isOnScreenKeyboardVisible, () => {
  form.value?.scrollIntoView({
    block: 'end',
    inline: 'nearest',
  });
});

const publish = async () => {
  await getRetryableFunction(async () => {
    if (isDataMissing.value || isRunningNewThreadRequest.value) {
      return;
    }

    const newThread = await createNewThread({
      title: title.value,
      content: content.value,
    });

    if (!newThread) {
      return;
    }

    title.value = '';
    content.value = '';

    close();

    emit('threadPosted', newThread);

    const toastrStore = useToastrStore();
    toastrStore.showNewThreadSuccessfullyCreatedToastr({ threadSlug: newThread.slug });
  });
};
</script>

<i18n lang="json" locale="es">
{
  "actions": {
    "cancel": "Cancelar"
  },
  "fields": {
    "title": {
      "placeholder": "¿Qué está pasando?"
    }
  },
  "actionSuccessful": {
    "message": "¡Tu hilo se ha publicado!",
    "actions": {
      "goToThread": "Ir al hilo"
    }
  }
}
</i18n>

<style lang="scss" scoped>
@import '@caff/cds-scss-core';

.c-new-thread-composer {
  @include t-responsive--smaller-than($responsive-breakpoint-tablet-medium) {
    position: fixed;

    &:deep(.c-modal__content) {
      @include t-z-index(modal);

      bottom: 0;
      height: calc(var(--visual-viewport-height, 100vh));
      left: 0;
      position: absolute;
      top: calc(var(--visual-viewport-offset-top));
      right: 0;
    }

    &.c-new-thread-composer--on-screen-keyboard-visible:deep(.c-modal__content) {
      --border-radius: 0;
    }

    .c-new-thread-composer__form {
      // 2 * modal-vspace-to-edges (modal vertical space to top and bottom of the screen)
      --editor-extra-height: calc(2 * var(--modal-vspace-to-edges));

      align-items: stretch;
      bottom: 0;
      display: flex;
      flex-direction: column;
      justify-content: stretch;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
    }

    .c-new-thread-composer__title {
      flex-grow: 0;
      flex-shrink: 0;
    }

    .c-new-thread-composer__text-composer {
      --text-composer-textarea-height: 1fr;

      flex-grow: 1;
      flex-shrink: 1;
    }
  }
}

.c-new-thread-composer__form {
  // 2 * modal-vspace-to-edges (modal vertical space to top and bottom of the screen) + m (to space from modal to input) + line-height (line height of the input)
  --editor-extra-height: calc(
    2 * var(--modal-vspace-to-edges) + var(--spacing-m) + #{map-get(
        map-get($font-configs, $font-header-small),
        'line-height'
      )}
  );

  @include t-width-main-column;

  width: calc(var(--main-column-width));

  @include t-responsive--smaller-than($responsive-breakpoint-tablet-medium) {
    width: 100%;
  }
}

.c-new-thread-composer__title {
  @extend %t-padding-t-m;
  @extend %t-padding-h-m;
}

.c-new-thread-composer__category-picker {
  margin-right: auto;
}
</style>
