<template>
  <component :is="tag" class="c-user-meta-section">
    <UserAvatar
      v-if="user"
      :s3BucketConfigForImageKind="s3ConfigForImageKind"
      :to="user.route"
      :user="user"
    />
    <!-- TODO: Add redacted state -->

    <Pill v-if="user" :to="user.route" class="c-user-meta-section__display-name" tag="router-link">
      {{ user.displayName }}
    </Pill>
    <!-- TODO: Add redacted state -->

    <template v-if="showAuthorBadge">
      <div
        v-if="user"
        :title="t('authorBadge.title', { username: user.username })"
        class="c-user-meta-section__author-badge"
      >
        <div class="c-user-meta-section__author-badge-background" />

        <FontAwesomeIcon :icon="faPencil" fixed-width />
      </div>
      <!-- TODO: Add redacted state -->
    </template>

    <span v-if="isUsernameRendered" class="c-user-meta-section__username">
      <UserProfileLink
        v-if="user"
        :username="username"
        interactive
        class="c-user-meta-section__username-link"
      >
        @{{ user.username }}
      </UserProfileLink>
      <!-- TODO: Add redacted state -->
    </span>
  </component>
</template>

<script lang="ts" setup>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import { faPencil } from '@caff/cds-icons';
import { Pill } from '@caff/cds-pill';
import { UserAvatar } from '@caff/user-avatar-frontend-component';
import { Username } from '@caff/user-api-model';
import { areDisplayNameAndUsernameSimilar } from '@caff/user-isomorphic-model';

import { useS3ConfigForImageKind, useUser } from '../../composition';
import UserProfileLink from './UserProfileLink.vue';

const props = withDefaults(
  defineProps<{
    tag?: string;
    showAuthorBadge?: boolean;
    username: Username;
  }>(),
  {
    tag: 'div',
    showAuthorBadge: false,
  },
);

const { t } = useI18n();

const s3ConfigForImageKind = useS3ConfigForImageKind();

const { user } = useUser(computed(() => props.username));
const isUsernameRendered = computed(() =>
  user.value ? !areDisplayNameAndUsernameSimilar(user.value) : null,
);
</script>

<i18n lang="json" locale="es">
{
  "authorBadge": {
    "title": "{'@'}{username} empezó este hilo"
  }
}
</i18n>

<style lang="scss" scoped>
@import '@caff/cds-scss-core';

.c-user-meta-section {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  overflow: hidden;

  & > * + * {
    @extend %t-margin-l-xs;
  }

  & > * {
    flex-grow: 0;
    flex-shrink: 0;
    overflow: hidden;
  }
}

.c-user-meta-section__username,
.c-user-meta-section__published-date {
  @include t-font($font-body-soft);
}

.c-user-meta-section__display-name {
  flex-shrink: 1;
  min-width: 0;
}

.c-user-meta-section__author-badge {
  --border-radius: 6px;

  align-items: center;
  border-radius: var(--border-radius);
  color: var(--colors-red-500);
  display: flex;
  height: calc(var(--spacing-m) + var(--spacing-xxs));
  font-size: var(--spacing-s);
  justify-content: center;
  position: relative;
  width: calc(var(--spacing-m) + var(--spacing-xxs));
}

.c-user-meta-section__author-badge-background {
  background: var(--colors-red-500);
  bottom: 0;
  left: 0;
  opacity: 0.13;
  position: absolute;
  right: 0;
  top: 0;
}

.c-user-meta-section__username {
  display: inline;
  flex-shrink: 10000;
  min-width: 0;
  text-overflow: ellipsis;
}

.c-user-meta-section__username-link {
  display: inline;
}
</style>
