<template>
  <component :is="tag" class="c-responsive-container">
    <slot />
  </component>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ResponsiveContainer',
  props: {
    tag: {
      type: String,
      default: 'div',
    },
  },
});
</script>

<style lang="scss" scoped>
@import '@caff/cds-scss-core';

.c-responsive-container {
  @extend %t-padding-h-m;
  @extend %t-responsive-container;

  width: calc(100vw - 2 * var(--spacing-m));
}
</style>
