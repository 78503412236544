import { JSONSchema7 } from 'json-schema';

import { ExternalUrlServiceDbEnum } from '@caff/db-types';
import { UUIDv4, OptionalUUIDv4JsonSchema } from '@caff/uuidv4-api-model';

export const enum ExternalUrlService {
  generic = ExternalUrlServiceDbEnum.generic,
  twitch = ExternalUrlServiceDbEnum.twitch,
  twitter = ExternalUrlServiceDbEnum.twitter,
  youtube = ExternalUrlServiceDbEnum.youtube,
}

export interface ExternalUrl {
  url: string;
  title: string | null;
  idThumbnail: UUIDv4 | null;
  summary: string | null;
  website: string | null;
  embedHtml: string | null;
  service: ExternalUrlService;
}

export const ExternalUrlJsonSchema: JSONSchema7 = {
  description: 'An external URL describes links to 3rd party websites and how to embed them',
  type: 'object',
  required: ['url', 'embedHtml', 'idThumbnail', 'service', 'summary', 'title', 'website'],
  additionalProperties: false,
  properties: {
    url: {
      type: 'string',
      format: 'uri',
    },
    title: { anyOf: [{ type: 'null' }, { type: 'string' }] },
    idThumbnail: OptionalUUIDv4JsonSchema,
    summary: { anyOf: [{ type: 'null' }, { type: 'string' }] },
    website: { anyOf: [{ type: 'null' }, { type: 'string' }] },
    embedHtml: { anyOf: [{ type: 'null' }, { type: 'string' }] },
    service: {
      type: 'string',
      enum: [
        ExternalUrlService.generic,
        ExternalUrlService.twitch,
        ExternalUrlService.twitter,
        ExternalUrlService.youtube,
      ],
    },
  },
};
