import { EXTENSION_FOR_IMAGE_FORMAT, ImageFormat } from './ImageFormat';
import { S3Config } from './S3Config';
import { ScreenDensityVariation } from './ScreenDensityVariation';

export class ImageKeyPath {
  readonly idImage: string;
  readonly s3Config: S3Config;

  constructor({ idImage, s3Config }: { idImage: string; s3Config: S3Config }) {
    this.idImage = idImage;
    this.s3Config = s3Config;
  }

  getS3Key(
    params:
      | 'original'
      | {
          imageFormat: ImageFormat;
          screenDensityVariation: ScreenDensityVariation;
        },
  ): string {
    if (params === 'original') {
      return `${this.s3Config.prefix}/original/${this.idImage}`;
    }

    return [
      this.s3Config.prefix,
      params.screenDensityVariation.urlSegmentForSize,
      `${this.idImage}${params.screenDensityVariation.suffix}.${
        EXTENSION_FOR_IMAGE_FORMAT[params.imageFormat]
      }`,
    ].join('/');
  }

  getS3Url(
    params:
      | 'original'
      | {
          imageFormat: ImageFormat;
          screenDensityVariation: ScreenDensityVariation;
        },
  ): URL {
    return new URL(this.getS3Key(params), this.s3Config.bucketUrl);
  }
}
