<template>
  <Toastr :variant="ToastrVariant.success">
    {{ message }}

    <template #actions>
      <ToastrAction :icon="faCheck" @click="dismiss()" />
    </template>
  </Toastr>
</template>

<script lang="ts" setup>
import { faCheck } from '@caff/cds-icons';

import Toastr from './Toastr.vue';
import ToastrAction from './ToastrAction.vue';
import { ToastrVariant } from './Toastr.constants';

defineProps<{ message: string }>();

const emit = defineEmits<{
  dismiss: [];
}>();

const dismiss = () => emit('dismiss');
</script>
