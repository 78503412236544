export const enum PostOrder {
  newestFirst = 'newestFirst',
  polemicFirst = 'polemicFirst',
}

export const ALL_POST_ORDERS = [PostOrder.newestFirst, PostOrder.polemicFirst];
const UNIQUE_POST_ORDERS = new Set(ALL_POST_ORDERS);

export const postOrderValidatorFactory = ({
  required,
}: {
  required: boolean;
}): {
  type: typeof String;
  required?: boolean;
  validator: (value: string) => boolean;
} => ({
  type: String,
  required,
  validator(value: string) {
    if (!UNIQUE_POST_ORDERS.has(value as PostOrder)) {
      console.error(
        `Invalid PostOrder: «${value}». Expected one of: ${[...ALL_POST_ORDERS].join(', ')}`,
      );
      return false;
    }

    return true;
  },
});
