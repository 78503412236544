export type { Post } from './post';
export { postJsonSchemaFactory, postJsonSchemaDefinitionsFactory } from './post';
export { ALL_POST_ORDERS, PostOrder, postOrderValidatorFactory } from './postOrder';
export type { ShallowPostWithReplies } from './shallowPostWithReplies';
export {
  shallowPostWithRepliesJsonSchemaDefinitionsFactory,
  shallowPostWithRepliesJsonSchemaFactory,
} from './shallowPostWithReplies';
export type { ShallowPostWithParents } from './shallowPostWithParents';
export { ShallowPostWithParentsJsonSchema } from './shallowPostWithParents';
export type { ShallowPost, PostId } from './shallowPost';
export { ShallowPostJsonSchema } from './shallowPost';

/**
 * Maximum amount of levels to be displayed in the UI in a threaded chain of
 * messages.
 */
export const MAXIMUM_VISIBLE_THREAD_DEPTH = 5;
