import { S3Config } from './S3Config';
import {
  ImageKind,
  S3ConfigForImageKind,
  REQUIRED_SIZES_FOR_IMAGE_KIND,
  SUPPORTED_FORMATS_FOR_IMAGE_KIND,
} from './ImageKind';
import { ALL_SCREEN_DENSITIES, ScreenDensity } from './ScreenDensity';
import { ScreenDensityVariation } from './ScreenDensityVariation';
import { ImageFormat } from './ImageFormat';
import { ImageSize } from './ImageSize';
import { SrcsetEntry } from './SrcsetEntry';
import { SrcsetSource } from './SrcsetSource';
import { ImageKeyPath } from './ImageKeyPath';

export interface ImageMetadata {
  idImage: string;
  kind: ImageKind;
}

export class Image {
  idImage: string;
  kind: ImageKind;
  s3BucketConfig: S3Config;

  constructor(imageMetadata: ImageMetadata, s3BucketConfigForImageKind: S3ConfigForImageKind) {
    this.idImage = imageMetadata.idImage;
    this.kind = imageMetadata.kind;
    this.s3BucketConfig = s3BucketConfigForImageKind[imageMetadata.kind];
  }

  get supportedFormats(): Array<ImageFormat> {
    return SUPPORTED_FORMATS_FOR_IMAGE_KIND[this.kind];
  }

  get requiredVariations(): Array<ScreenDensityVariation> {
    const requiredSizes = REQUIRED_SIZES_FOR_IMAGE_KIND[this.kind];

    const screenDensityVariations = requiredSizes.map((size) =>
      ALL_SCREEN_DENSITIES.map((screenDensity) => new ScreenDensityVariation(size, screenDensity)),
    );

    return ([] as Array<ScreenDensityVariation>).concat(...screenDensityVariations);
  }

  get keyPath(): ImageKeyPath {
    return new ImageKeyPath({
      idImage: this.idImage,
      s3Config: this.s3BucketConfig,
    });
  }

  getUrlForSize({
    imageSize,
    imageFormat,
  }: {
    imageSize: ImageSize;
    imageFormat: ImageFormat;
  }): URL {
    return this.keyPath.getS3Url({
      screenDensityVariation: new ScreenDensityVariation(imageSize, ScreenDensity.x1),
      imageFormat,
    });
  }

  getSrcsetSourcesForSize(imageSize: ImageSize): {
    allSrcsetSources: Array<SrcsetSource>;
    baseSrcsetSource: SrcsetSource;
  } {
    const allScreenDensities = [
      {
        screenDensity: ScreenDensity.x1,
      },
      {
        screenDensity: ScreenDensity.x2,
      },
      {
        screenDensity: ScreenDensity.x3,
      },
    ];

    const allSrcsetSources = this.supportedFormats.map((imageFormat) => {
      const srcsetEntries = allScreenDensities.map(({ screenDensity }) => {
        const screenDensityVariation = new ScreenDensityVariation(imageSize, screenDensity);

        return new SrcsetEntry({
          format: imageFormat,
          url: this.keyPath.getS3Url({
            imageFormat,
            screenDensityVariation,
          }),
          width: screenDensityVariation.size.width,
        });
      });

      return new SrcsetSource({
        imageFormat,
        srcsetEntries,
      });
    });

    const baseImageFormat = ImageFormat.jpeg;

    const baseImageSrcsetEntries = allScreenDensities.map(({ screenDensity }) => {
      const screenDensityVariation = new ScreenDensityVariation(imageSize, screenDensity);

      return new SrcsetEntry({
        format: baseImageFormat,
        url: this.keyPath.getS3Url({
          imageFormat: baseImageFormat,
          screenDensityVariation,
        }),
        width: screenDensityVariation.size.width,
      });
    });

    const baseSrcsetSource = new SrcsetSource({
      imageFormat: baseImageFormat,
      srcsetEntries: baseImageSrcsetEntries,
    });

    return {
      allSrcsetSources,
      baseSrcsetSource,
    };
  }
}
