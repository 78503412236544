import { ref } from 'vue';

import { PreferredColorSchema } from '@caff/color-schema-api-model';
import { UseMutationReturnValue } from '@caff/frontend-use-query';
import { pickBy } from '@caff/isomorphic-collection';

import { useAxiosMutation } from '../useAxiosQuery';
import { useCurrentlyLoggedInUser } from './useCurrentlyLoggedInUser';

export const useUpdateCurrentlyLoggedInUserInfo = (): Omit<
  UseMutationReturnValue<
    {
      displayName?: string;
      preferredColorSchema?: PreferredColorSchema;
    },
    void,
    Error
  >,
  'mutate'
> & {
  updateCurrentlyLoggedInUserInfo: UseMutationReturnValue<
    {
      displayName?: string;
      preferredColorSchema?: PreferredColorSchema;
    },
    void,
    Error
  >['mutate'];
} => {
  const { invalidate } = useCurrentlyLoggedInUser();

  const { mutate: updateCurrentlyLoggedInUserInfo, ...rest } = useAxiosMutation<
    {
      displayName?: string;
      preferredColorSchema?: PreferredColorSchema;
    },
    void,
    Error
  >({
    mutationKey: ref('updateCurrentlyLoggedInUserInfo'),
    async mutationFn({ axiosInstance, param: { displayName, preferredColorSchema } }) {
      const payload = pickBy(
        {
          displayName,
          preferredColorSchema,
        },
        (value) => !!value,
      );

      await axiosInstance.patch('/account/settings', payload);

      await invalidate();
    },
  });

  return {
    ...rest,
    updateCurrentlyLoggedInUserInfo,
  };
};
