import {
  MarkdownImageBlockNode,
  MarkdownNodeHTMLTag,
  MarkdownNodeType,
  ParseTokenFunction,
} from './types';

export const parseImageToken: ParseTokenFunction = (params) => {
  const token = params.tokens[params.startingNodeIndex];
  const src = token.attrGet('src') as string;

  const node: MarkdownImageBlockNode = {
    type: MarkdownNodeType.image,
    tag: MarkdownNodeHTMLTag.img,
    childNodes: [],
    src,
    alt: token.attrGet('alt') || token.content,
    title: token.attrGet('title'),
    sourceRange: {
      absoluteStart: params.startingCharacterAbsolutePosition,
      length: token.content.length + src.length + 5, // +5 to account for !, [] and ()
    },
  };

  return {
    nodes: [node],
    nodesAdvanced: 1,
    charactersAdvanced: params.sourceText.length,
  };
};
