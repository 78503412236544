<template>
  <ThreadFeedItem
    v-if="thread && author && publishedDate && category"
    :s3BucketConfigForImageKind="s3ConfigForImageKind"
    :author="author"
    :authorRoute="authorRoute"
    :category="category"
    :categoryRoute="categoryRoute"
    :idThumbnail="thread.idThumbnail"
    :title="thread.title"
    :showAuthor="showAuthor"
    :showCategory="showCategory"
    :showSummary="showSummary"
    :summary="thread.content"
    :publishedDate="publishedDate"
    :threadRoute="threadRoute"
    class="c-regular-thread-feed-item"
  >
    <template #before>
      <!-- @slot Use this slot to render content before the thread but inside the box -->
      <slot name="before" />
    </template>

    <template v-if="showAuthor" #authorUsername="{ user }">
      <UserProfileLink :username="user.username" :interactive="interactive" />
    </template>

    <template #relativeDate>
      <ThreadLink :threadSlug="threadSlug" :interactive="interactive">
        {{ relativePublishedDate }}
      </ThreadLink>
    </template>

    <template #actions v-if="showActions">
      <ThreadActionsBar :threadSlug="threadSlug" :interactive="interactive" />
    </template>

    <template #after>
      <!-- @slot Use this slot to render content after the thread but inside the box -->
      <slot name="after" />
    </template>
  </ThreadFeedItem>
  <!-- TODO: Add redacted state -->
</template>

<script lang="ts" setup>
import { computed } from 'vue';

import { ThreadSlug } from '@caff/shallow-thread-api-model';
import { ThreadFeedItem } from '@caff/thread-feed-item-frontend-component';

import {
  useCategory,
  useRelativeDate,
  useS3ConfigForImageKind,
  useThread,
  useUser,
} from '../../composition';
import { InteractiveProps, interactivePropsDefaults } from '../../mixins/interactive.mixin';
import { getCategoryFrontpageRoute, getThreadRoute, getUserProfileRoute } from '../../router';
import { ThreadActionsBar, ThreadLink } from '../thread';
import { UserProfileLink } from '../user';

const props = withDefaults(
  defineProps<
    InteractiveProps & {
      showActions?: boolean;
      showAuthor?: boolean;
      showCategory?: boolean;
      showSummary?: boolean;
      threadSlug: ThreadSlug;
    }
  >(),
  {
    ...interactivePropsDefaults,
    showActions: true,
    showAuthor: true,
    showCategory: true,
    showSummary: true,
  },
);

const s3ConfigForImageKind = useS3ConfigForImageKind();

const threadSlug = computed(() => props.threadSlug);

const { thread } = useThread(threadSlug);
const { category } = useCategory(computed(() => thread.value?.categorySlug ?? null));
const { user: author } = useUser(computed(() => thread.value?.authorCanonicalUsername ?? null));

const publishedDate = computed(() =>
  thread.value ? new Date(thread.value.publishedTimestamp * 1000) : null,
);

const relativePublishedDate = computed(() =>
  publishedDate.value ? useRelativeDate(publishedDate.value).value : null,
);

const authorRoute = computed(() =>
  props.interactive && thread.value
    ? getUserProfileRoute(thread.value.authorCanonicalUsername)
    : null,
);
const categoryRoute = computed(() =>
  props.interactive && thread.value ? getCategoryFrontpageRoute(thread.value.categorySlug) : null,
);
const threadRoute = computed(() => (props.interactive ? getThreadRoute(props.threadSlug) : null));
</script>

<style lang="scss" scoped>
@import '@caff/cds-scss-core';

.c-regular-thread-feed-item {
  &:not(:last-child) {
    @extend %t-margin-b-m;
  }
}
</style>
