<template>
  <DropdownMenu @open="onOpen" @close="onClose">
    <template #toggleButton="{ togglePopover, opened }">
      <SecondaryButton
        :active="opened"
        :loading="isLoading"
        :disabled="isDropdownMenuToggleButtonDisabled"
        @click.prevent.stop="togglePopover()"
      >
        <div v-if="selectedCategory" class="c-single-category-picker__entry">
          <Avatar
            :key="selectedCategory.slug"
            :name="selectedCategory.name"
            :rgbColor="selectedCategory.tintColor"
            :image="avatar"
            class="c-single-category-picker__avatar c-single-category-picker__avatar--small"
            rounded
          />

          {{ selectedCategory.name }}
        </div>
        <template v-else>
          <FontAwesomeIcon :icon="faUsersMany" fixed-width />

          {{ t('dropdown.toggle.noCategory') }}
        </template>
      </SecondaryButton>
    </template>

    <template #default="{ closePopover }">
      <DropdownMenuEntry
        v-for="{ category, avatar } of categories"
        :key="category.slug"
        @click="chooseCategory(category.slug, closePopover)"
      >
        <div class="c-single-category-picker__entry">
          <Avatar
            :key="category.slug"
            :name="category.name"
            :rgbColor="category.tintColor"
            :image="avatar"
            class="c-single-category-picker__avatar"
            rounded
          />

          {{ category.name }}
        </div>
      </DropdownMenuEntry>
    </template>
  </DropdownMenu>
</template>

<script lang="ts" setup>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import { CategorySlug } from '@caff/category-api-model';
import { getAvatar } from '@caff/category-isomorphic-model';
import { Avatar } from '@caff/cds-avatar';
import { SecondaryButton } from '@caff/cds-button';
import { DropdownMenu, DropdownMenuEntry } from '@caff/cds-dropdown-menu';
import { faUsersMany } from '@caff/cds-icons';

import { useCategory, useCategories, useS3ConfigForImageKind } from '../../composition';

const props = defineProps<{
  modelValue: CategorySlug | null;
  disabled: boolean;
}>();

const emit = defineEmits<{
  'update:modelValue': [CategorySlug];
  open: [];
  close: [];
}>();

const { t } = useI18n();

const s3ConfigForImageKind = useS3ConfigForImageKind();
const { sortedCategories, isLoading } = useCategories();

const isDropdownMenuToggleButtonDisabled = computed(() => props.disabled || isLoading.value);

const categories = computed(() =>
  sortedCategories.value.map((category) => ({
    category,
    avatar: getAvatar({ category, s3ConfigForImageKind: s3ConfigForImageKind.value }),
  })),
);

const { category: selectedCategory } = useCategory(
  computed(() => (props.modelValue ? props.modelValue : null)),
);
const avatar = computed(() =>
  selectedCategory.value
    ? getAvatar({
        category: selectedCategory.value,
        s3ConfigForImageKind: s3ConfigForImageKind.value,
      })
    : null,
);

const chooseCategory = (categorySlug: CategorySlug, callback: () => void) => {
  if (props.disabled) {
    return;
  }

  emit('update:modelValue', categorySlug);

  callback();
};

const onOpen = () => {
  emit('open');
};

const onClose = () => {
  emit('close');
};
</script>

<i18n lang="json" locale="es">
{
  "dropdown": {
    "toggle": {
      "noCategory": "Elige una comunidad"
    }
  }
}
</i18n>

<style lang="scss" scoped>
@import '@caff/cds-scss-core';

.c-single-category-picker__entry {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: stretch;
}

.c-single-category-picker__avatar {
  @extend %t-margin-r-xs;

  flex-grow: 0;
  flex-shrink: 0;
  height: var(--spacing-m);
  width: var(--spacing-m);
}

.c-single-category-picker__avatar--small {
  height: var(--spacing-s);
  width: var(--spacing-s);
}
</style>
