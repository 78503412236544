<template>
  <svg
    height="100"
    viewBox=" 0 0 362 100"
    width="362"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <symbol id="symbol-0" height="100" viewBox=" 0 0 362 100" width="362">
      <path
        d="M12.3361188,32 C9.25606264,32 7.7665659,31.8566437 6.34972753,31.6416093 C4.93288916,31.4265748 3.95200106,30.7097934 3.29807566,29.6704603 C2.64415026,28.6311272 1.65011766,27.2618346 0.864020004,24.4737949 C0.0779223461,21.6857551 -0.484803512,19.3288508 0.609715682,17.592693 C1.55427459,16.0944065 3.55233558,15.8007394 12.3361188,15.8007394 C21.0716801,15.8020143 23.0631513,16.0976445 24.0056689,17.592693 C25.1001881,19.3288508 24.5374623,21.6857551 23.7513646,24.4737949 C22.965267,27.2618346 21.9712344,28.6311272 21.317309,29.6704603 C20.6633836,30.7097934 19.6824955,31.4265748 18.2656571,31.6416093 C16.8575641,31.8553164 15.3777069,31.9982276 12.3361188,32 L12.3361188,32 Z M5.83929614,9.3951843 C5.41784626,7.86388023 5.99949407,6.93608711 6.44825686,6.29218765 C7.23992527,5.15627608 8.45281884,4.48104905 8.71011101,4.13732887 C9.27123147,3.38772029 9.54289487,2.23003769 9.91043733,1.46530397 C10.4234692,0.397855276 11.4285507,0 12.259286,0 C13.7805889,0 16.1868621,1.42581411 17.5659446,3.79255146 C19.1047431,6.43338824 18.7153682,8.56062433 17.9139222,9.99854476 C17.5255389,10.6953656 15.7511996,12.4874153 12.259286,12.4874153 C8.76737243,12.4874153 6.26074602,10.9264884 5.83929614,9.3951843 Z"
      />
    </symbol>
    <symbol id="symbol-1" height="100" viewBox=" 0 0 362 100" width="362">
      <path
        d="M24.1857628,20 L23.4684616,29.8800816 C23.3815611,31.0749632 22.3868058,32 21.1887683,32 L10.8112317,32 C9.61319424,32 8.61843888,31.0749632 8.5315384,29.8800816 L7.81090565,20 L11.7617824,20.00226 C12.4405013,21.6763944 14.0823613,22.8571429 16,22.8571429 C17.9176387,22.8571429 19.5594987,21.6763944 20.2382176,20.00226 L24.1857628,20 Z M22.1913801,0 C23.02496,0 23.7924415,0.453789698 24.1941593,1.18418571 L26.708445,5.75561429 C27.3168024,6.86171871 26.9132988,8.25156465 25.8071944,8.85992208 C25.5473927,9.00281296 25.2630372,9.09338029 24.970181,9.12750065 L24.9645655,9.30865302 L24.9645655,9.30865302 L24.4349056,16.5714632 L20.2391471,16.5714632 C19.5609988,14.8961132 17.9185147,13.7142857 16,13.7142857 C14.0814853,13.7142857 12.4390012,14.8961132 11.7608529,16.5714632 L7.56176279,16.5714632 L7.03543451,9.30865302 L7.03147708,9.12685714 C5.89099751,8.99544494 5.00861993,8.02933906 5.00861993,6.85714286 C5.00861993,6.47199321 5.10594416,6.09308853 5.291555,5.75561429 L7.80584071,1.18418571 C8.20755852,0.453789698 8.97503998,0 9.80861993,0 L22.1913801,0 Z"
      />
    </symbol>
    <symbol id="symbol-2" height="100" viewBox=" 0 0 362 100" width="362">
      <path
        d="M15.8726655,0 C21.0715133,0 25.2860115,4.23489042 25.2860115,9.4588925 C25.2860115,10.7010624 25.0477258,11.887308 24.6145761,12.9739925 C24.3258096,13.6984489 23.7134729,14.6697302 22.7775659,15.8878365 C23.270692,15.8534649 24.2136116,16.5127538 25.6063247,17.8657032 C27.6953943,19.8951274 29.0459977,22.8446585 29.6581394,26.7142965 C29.6955131,26.9505525 29.7142857,27.1893776 29.7142857,27.4285714 C29.7142857,29.9533017 27.6675886,32 25.1428572,32 L6.85780623,32 C6.61861538,32 6.37979253,31.981227 6.14353653,31.9438539 C3.64982231,31.5493735 1.94805777,29.2080295 2.34252405,26.7143134 C2.94115489,22.9300651 4.24601658,20.0257635 6.25711139,18.0014082 C7.59784126,16.651838 8.52837991,15.9762032 9.04872733,15.974504 C8.10794716,14.792743 7.48366069,13.8296132 7.17586794,13.0851145 C6.71417881,11.9683666 6.4593171,10.7435366 6.4593171,9.4588925 C6.4593171,4.23489042 10.6738153,0 15.8726655,0 Z"
      />
    </symbol>

    <use
      :class="{
        highlighted: 0 < firstNonHighlightedPathIndex,
      }"
      opacity="0.8"
      transform="rotate(6 117.08774186 1053.7105433)"
      xlink:href="#symbol-0"
    />
    <use
      :class="{
        highlighted: 1 < firstNonHighlightedPathIndex,
      }"
      transform="rotate(16 146.46057092 936.97032682)"
      xlink:href="#symbol-0"
    />
    <use
      :class="{
        highlighted: 2 < firstNonHighlightedPathIndex,
      }"
      transform="rotate(-13 376.1874045 -1000.76316678)"
      xlink:href="#symbol-0"
    />
    <use
      :class="{
        highlighted: 3 < firstNonHighlightedPathIndex,
      }"
      opacity="0.7"
      transform="rotate(20 -19.69468439 442.8310241)"
      xlink:href="#symbol-0"
    />
    <use
      :class="{
        highlighted: 4 < firstNonHighlightedPathIndex,
      }"
      opacity="0.8"
      transform="rotate(-7 311.46893863 58.54751841)"
      xlink:href="#symbol-0"
    />
    <use
      :class="{
        highlighted: 5 < firstNonHighlightedPathIndex,
      }"
      transform="rotate(-13 275.08495233 -135.59746442)"
      xlink:href="#symbol-1"
    />
    <use
      :class="{
        highlighted: 6 < firstNonHighlightedPathIndex,
      }"
      opacity="0.7"
      transform="rotate(-7 535.15511733 -2247.67541477)"
      xlink:href="#symbol-1"
    />
    <use
      :class="{
        highlighted: 7 < firstNonHighlightedPathIndex,
      }"
      transform="rotate(20 170.24133347 947.05758718)"
      xlink:href="#symbol-1"
    />
    <use
      :class="{
        highlighted: 8 < firstNonHighlightedPathIndex,
      }"
      transform="rotate(10 16.5854713 262.09210387)"
      xlink:href="#symbol-2"
    />
    <use
      :class="{
        highlighted: 9 < firstNonHighlightedPathIndex,
      }"
      transform="rotate(-13 140.38582847 -777.74751008)"
      xlink:href="#symbol-2"
    />
    <use
      :class="{
        highlighted: 10 < firstNonHighlightedPathIndex,
      }"
      opacity="0.7"
      transform="rotate(7 -484.93126866 1570.7487204)"
      xlink:href="#symbol-2"
    />
    <use
      :class="{
        highlighted: 11 < firstNonHighlightedPathIndex,
      }"
      transform="rotate(-8 473.99510643 -611.16977997)"
      xlink:href="#symbol-2"
    />
    <use
      :class="{
        highlighted: 12 < firstNonHighlightedPathIndex,
      }"
      opacity="0.8"
      transform="rotate(5 -422.42188134 3807.0372536)"
      xlink:href="#symbol-2"
    />
  </svg>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';

export default defineComponent({
  name: 'Chill',
  props: {
    progress: {
      type: Number,
      default: 0,
      validator: (value: number): boolean => {
        if (value < 0 || value > 1) {
          console.warn(`[Chill] progress must be a value in 0-1 range, but found ${value}`);
          return false;
        }

        return true;
      },
    },
  },
  setup(props) {
    const firstNonHighlightedPathIndex = computed(() => Math.round(props.progress * 13));

    return { firstNonHighlightedPathIndex };
  },
});
</script>

<style lang="scss" scope>
use {
  fill: var(--fill-color);

  &.highlighted {
    fill: var(--fill-color--highlighted);
  }
}
</style>
