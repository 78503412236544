import type { ValueOf } from 'type-fest';
import { HistoryState, useRouter } from 'vue-router';
import { readonly, ref, Ref } from 'vue';

export type Route = ValueOf<HistoryState>;

export const usePreviousRoute = (): Ref<Route> => {
  const router = useRouter();
  return readonly(ref(router.options.history.state.back));
};
