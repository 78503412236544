<template>
  <TextLink v-if="user" :to="user.route" :interactive="interactive">
    <!-- @slot Use this slot to customize link's content -->
    <slot>@{{ user.username }}</slot>
  </TextLink>
  <!-- TODO: Add redacted state when user is not available -->
</template>

<script lang="ts" setup>
import { computed } from 'vue';

import { Username } from '@caff/user-api-model';

import { useUser } from '../../composition';
import { InteractiveProps, interactivePropsDefaults } from '../../mixins/interactive.mixin';
import { TextLink } from '../common';

const props = withDefaults(defineProps<InteractiveProps & { username: Username }>(), {
  ...interactivePropsDefaults,
});

const { user } = useUser(computed(() => props.username));
</script>
