import type { Ref, UnwrapRef } from 'vue';
import { ref } from 'vue';

import type { QueryKey } from '@caff/frontend-use-query';

import type { User as RawUser, Username } from '@caff/user-api-model';

import { getCanonicalUsername } from '../models/User';

export const getKeyForUser = (username: Ref<Username | null>): UnwrapRef<QueryKey> =>
  username.value ? `user/${getCanonicalUsername(username.value)}` : null;

export const setUser = ({
  user,
  setQueryData,
}: {
  user: RawUser;
  setQueryData: <T>(key: UnwrapRef<QueryKey>, data: T) => Promise<void>;
}): Promise<void> => setQueryData(getKeyForUser(ref(user.username)), user);
