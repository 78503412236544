<template>
  <Toastr :variant="ToastrVariant.success">
    {{ t('message', { username }) }}

    <template #actions>
      <ToastrAction @click="goToPost()">
        {{ t('goToPost') }}
      </ToastrAction>
      <ToastrAction :icon="faCheck" @click="dismiss()" />
    </template>
  </Toastr>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';

import { faCheck } from '@caff/cds-icons';
import { PostId } from '@caff/post-api-model';

import { usePost } from '../../composition';
import { getPostRoute } from '../../router';
import Toastr from './Toastr.vue';
import ToastrAction from './ToastrAction.vue';
import { ToastrVariant } from './Toastr.constants';

const props = defineProps<{
  idChildPost: PostId;
  idParentPost: PostId;
}>();

const emit = defineEmits<{
  dismiss: [];
}>();

const dismiss = () => emit('dismiss');

const router = useRouter();
const goToPost = async () => {
  await router.push(getPostRoute(props.idChildPost));
};

const { post } = usePost(computed(() => props.idParentPost));
const username = computed(() => post.value?.authorCanonicalUsername);

const { t } = useI18n();
</script>

<i18n lang="json" locale="es">
{
  "message": "¡Tu respuesta a {'@'}{username} se ha publicado!",
  "goToPost": "Ir a la respuesta"
}
</i18n>
