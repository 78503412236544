import PasswordStep from './PasswordStep.vue';
import SuccessStep from './SuccessStep.vue';

export { PasswordStep };
export { SuccessStep };

export const enum ResetPasswordModalStep {
  password = 'password',
  success = 'success',
}

export const allResetPasswordModalSteps: Record<ResetPasswordModalStep, boolean> = {
  [ResetPasswordModalStep.password]: true,
  [ResetPasswordModalStep.success]: true,
};
