import {
  debouncedWatch,
  useDocumentVisibility,
  useEventListener,
  useWindowSize,
} from '@vueuse/core';
import { computed, ref, Ref, watch, watchEffect, ToRefs } from 'vue';

import { isServer } from '@caff/isomorphic-is-server';
import { asyncWait } from '@caff/isomorphic-time';

import {
  INPUT_ELEMENT_FOCUS_JUMP_DELAY,
  LAYOUT_RESIZE_TO_LAYOUT_HEIGHT_FIX_DELAY,
} from '../constants';
import { ScreenOrientation, getScreenOrientationType } from '../orientation';
import { OnScreenKeyboardStatus, getOnScreenKeyboardStatus } from '../status';

interface ScreenOrientationAndHeight {
  screenOrientation: ScreenOrientation;
  height: number;
}

const getStaticScreenOrientationAndHeight = (
  reactiveScreenOrientationAndHeight: ToRefs<ScreenOrientationAndHeight>,
): ScreenOrientationAndHeight => ({
  height: reactiveScreenOrientationAndHeight.height.value,
  screenOrientation: reactiveScreenOrientationAndHeight.screenOrientation.value,
});

const isSameScreenOrientationAndHeight = (
  lhs: ScreenOrientationAndHeight,
  rhs: ScreenOrientationAndHeight,
): boolean => lhs.screenOrientation === rhs.screenOrientation && lhs.height === rhs.height;

const useIsSomeElementFocused = (): Ref<boolean> => {
  const isSomeElementFocused = ref(
    (document.activeElement && document.activeElement !== document.body) ?? false,
  );

  useEventListener(
    document.documentElement,
    'focusin',
    () => {
      isSomeElementFocused.value = true;
    },
    { passive: true },
  );

  useEventListener(
    document.documentElement,
    'focusout',
    async () => {
      await asyncWait(INPUT_ELEMENT_FOCUS_JUMP_DELAY);
      isSomeElementFocused.value = false;
    },
    { passive: true },
  );

  return isSomeElementFocused;
};

const useScreenOrientation = (): Ref<ScreenOrientation> => {
  const screenOrientation = ref(getScreenOrientationType());

  if (typeof screen && screen.orientation) {
    // The `change` event hits very early, **before** `window.innerHeight` is updated (e.g. on `resize`)
    useEventListener(
      screen.orientation,
      'change',
      () => {
        screenOrientation.value = getScreenOrientationType();
      },
      { passive: true },
    );
  }

  return screenOrientation;
};

const useScreenOrientationWhenOSKIsHidden = ({
  isSomeElementFocused,
  screenOrientation,
  windowSize,
}: {
  isSomeElementFocused: Ref<boolean>;
  screenOrientation: Ref<ScreenOrientation>;
  windowSize: { width: Ref<number>; height: Ref<number> };
}): Ref<ScreenOrientationAndHeight> => {
  // Assumes initially hidden OSK
  const initialLayoutHeight = windowSize.height.value;
  // Assumes initially hidden OSK
  const approximateBrowserToolbarHeight = screen.availHeight - windowSize.height.value;

  const reactiveLayoutHeightOnOSKFreeOrientationChange = computed<ScreenOrientationAndHeight>(
    () => {
      const isOSKHidden =
        !isSomeElementFocused.value || windowSize.height.value === initialLayoutHeight;

      return {
        screenOrientation: screenOrientation.value,
        height: isOSKHidden
          ? windowSize.height.value
          : screen.availHeight - approximateBrowserToolbarHeight,
      };
    },
  );

  return reactiveLayoutHeightOnOSKFreeOrientationChange;
};

const useLayoutHeightOnUnfocus = ({
  isSomeElementFocused,
  screenOrientation,
  windowSize,
}: {
  isSomeElementFocused: Ref<boolean>;
  screenOrientation: Ref<ScreenOrientation>;
  windowSize: { width: Ref<number>; height: Ref<number> };
}): ToRefs<ScreenOrientationAndHeight> => {
  const reactiveLayoutHeightOnUnfocus: ToRefs<ScreenOrientationAndHeight> = {
    height: ref(windowSize.height.value),
    screenOrientation: ref(screenOrientation.value),
  };

  watchEffect(() => {
    const newLayoutHeightOnUnfocus = {
      screenOrientation: screenOrientation.value,
      height: windowSize.height.value,
    };

    if (isSomeElementFocused.value) {
      return;
    }

    reactiveLayoutHeightOnUnfocus.height.value = newLayoutHeightOnUnfocus.height;
    reactiveLayoutHeightOnUnfocus.screenOrientation.value =
      newLayoutHeightOnUnfocus.screenOrientation;
  });

  return reactiveLayoutHeightOnUnfocus;
};

const useLayoutHeight = ({
  isSomeElementFocused,
  screenOrientation,
  windowSize,
}: {
  isSomeElementFocused: Ref<boolean>;
  screenOrientation: Ref<ScreenOrientation>;
  windowSize: { width: Ref<number>; height: Ref<number> };
}): ToRefs<Record<ScreenOrientation, number>> => {
  const reactiveLayoutHeightOnUnfocus = useLayoutHeightOnUnfocus({
    isSomeElementFocused,
    screenOrientation,
    windowSize,
  });
  const documentVisibility = useDocumentVisibility();
  const screenOrientationWhenOSKIsHidden = useScreenOrientationWhenOSKIsHidden({
    isSomeElementFocused,
    screenOrientation,
    windowSize,
  });

  const layoutHeight: ToRefs<Record<ScreenOrientation, number>> = {
    [ScreenOrientation.landscape]: ref<number>(Number.MAX_SAFE_INTEGER),
    [ScreenOrientation.portrait]: ref<number>(Number.MAX_SAFE_INTEGER),
  };

  watch(
    [
      documentVisibility,
      reactiveLayoutHeightOnUnfocus.height,
      reactiveLayoutHeightOnUnfocus.screenOrientation,
      screenOrientationWhenOSKIsHidden,
    ],
    async (newValues, [, oldHeight, oldScreenOrientation]) => {
      // `visibilitychange` comes 1 sec after `focusout`!
      await asyncWait(1000);

      if (documentVisibility.value === 'hidden') {
        return;
      }

      const staticLayoutHeightOnUnfocus = getStaticScreenOrientationAndHeight(
        reactiveLayoutHeightOnUnfocus,
      );

      const changedLayoutHeight = isSameScreenOrientationAndHeight(staticLayoutHeightOnUnfocus, {
        height: oldHeight as number,
        screenOrientation: oldScreenOrientation as ScreenOrientation,
      })
        ? screenOrientationWhenOSKIsHidden.value
        : staticLayoutHeightOnUnfocus;

      layoutHeight[screenOrientation.value].value = windowSize.height.value;
      layoutHeight[changedLayoutHeight.screenOrientation].value = changedLayoutHeight.height;
    },
  );

  return layoutHeight;
};

const useLayoutHeightOnVerticalResize = ({
  windowSize,
}: {
  windowSize: { width: Ref<number>; height: Ref<number> };
}): Ref<{
  dH: number;
  height: number;
  isJustHeightResize: boolean;
  width: number;
}> => {
  const layoutHeightOnVerticalResize = ref({
    dH: 0,
    height: windowSize.height.value,
    isJustHeightResize: false,
    width: windowSize.width.value,
  });

  debouncedWatch([windowSize.height, windowSize.width], () => {
    layoutHeightOnVerticalResize.value = {
      ...layoutHeightOnVerticalResize.value,
      dH: windowSize.height.value - layoutHeightOnVerticalResize.value.height,
      isJustHeightResize: layoutHeightOnVerticalResize.value.width === windowSize.width.value,
    };
  });

  return layoutHeightOnVerticalResize;
};

export const useOnScreenKeyboardStatusAndroid = (): Ref<OnScreenKeyboardStatus> => {
  const status = ref(OnScreenKeyboardStatus.hidden);

  if (isServer()) {
    return status;
  }

  const windowSize = useWindowSize();
  const isSomeElementFocused = useIsSomeElementFocused();
  const screenOrientation = useScreenOrientation();

  // Difficulties: the exact layout height in the perpendicular orientation is
  // only to determine on orientation change,
  //
  // Orientation change can happen:
  // - entirely unfocused,
  // - focused but w/o OSK, or
  // - with OSK.
  //
  // Thus on arriving in the new orientation, until complete unfocus, it is
  // uncertain what the current `window.innerHeight` value means

  // Solution?: Assume initially hidden OSK (even if any input has the `autofocus`
  // attribute), and initialize other dimension with `screen.availWidth` so
  // there can always be made a decision on the keyboard.

  const layoutHeight = useLayoutHeight({
    isSomeElementFocused,
    screenOrientation,
    windowSize,
  });

  const layoutHeightOnVerticalResize = useLayoutHeightOnVerticalResize({ windowSize });

  watch([screenOrientation, layoutHeightOnVerticalResize, isSomeElementFocused], async () => {
    await asyncWait(LAYOUT_RESIZE_TO_LAYOUT_HEIGHT_FIX_DELAY);

    const { dH, height } = layoutHeightOnVerticalResize.value;
    const nonOSKLayoutHeight = layoutHeight[screenOrientation.value].value;

    const newStatus = isSomeElementFocused.value
      ? OnScreenKeyboardStatus.hidden
      : getOnScreenKeyboardStatus({
          dH,
          height,
          nonOSKLayoutHeight,
        });

    if (newStatus) {
      status.value = newStatus;
    }
  });

  return status;
};
