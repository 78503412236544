import { PropType } from 'vue';

export const CDSColor = {
  amber: 'amber',
  blue: 'blue',
  green: 'green',
  orange: 'orange',
  pink: 'pink',
  red: 'red',
  violet: 'violet',
};

type ValueOf<T> = T[keyof T];

export const cdsColorVueComponentProperty = {
  type: String as PropType<ValueOf<typeof CDSColor>>,
  validator: (value: string): boolean => {
    if (value in CDSColor) {
      return true;
    }

    console.warn(`Unknown CDS color: ${value}`);

    return false;
  },
};

export const rgbColorVueComponentProperty = {
  type: String,
  validator: (value: string): boolean => {
    if (/^[0-9]{1,3}, [0-9]{1,3}, [0-9]{1,3}$/.test(value)) {
      return true;
    }

    console.warn(`Invalid RGB color: ${value}. Color must be in format: «255, 255, 255»`);

    return false;
  },
};
