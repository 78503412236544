export const enum ExternalUrlServiceDbEnum {
  generic = 'generic',
  twitch = 'twitch',
  twitter = 'twitter',
  youtube = 'youtube',
}

export interface ExternalUrlRow {
  url: string;
  title: string | null;
  id_thumbnail: string | null;
  summary: string | null;
  website: string | null;
  embed_html: string | null;
  service: ExternalUrlServiceDbEnum;
}
