<template>
  <Toastr :variant="ToastrVariant.error">
    {{ t('message') }}

    <template #actions>
      <ToastrAction :icon="faCross" @click="dismiss" />
    </template>
  </Toastr>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n';

import { faCross } from '@caff/cds-icons';

import Toastr from './Toastr.vue';
import ToastrAction from './ToastrAction.vue';
import { ToastrVariant } from './Toastr.constants';

const emit = defineEmits<{
  dismiss: [];
}>();

const dismiss = () => emit('dismiss');

const { t } = useI18n();
</script>

<i18n lang="json" locale="es">
{
  "message": "¡La sesión ha caducado!"
}
</i18n>
