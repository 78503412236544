import { defineStore } from 'pinia';

import { Signal } from '@caff/frontend-use-query';
import { isServer } from '@caff/isomorphic-is-server';
import { PostId } from '@caff/post-api-model';
import { ThreadSlug } from '@caff/shallow-thread-api-model';

import { DEFAULT_TOASTR_TIMEOUT_IN_SECONDS } from '../../config';
import {
  Toastr,
  ToastrType,
  GenericSuccessToastr,
  NewThreadSuccessfullyCreated,
  NewPostReplySuccessfullyCreated,
  GenericRetryableErrorToastr,
} from '../../models/Toastr';

type Store = ReturnType<typeof useToastrStore>;
type StorePatchCallback = Parameters<Store['$patch']>[0];
export type ToastrState = Parameters<StorePatchCallback>[0];

const dismissToastr = (idToastr: number, toastrStore: ReturnType<typeof useToastrStore>) => {
  toastrStore.toastrs = toastrStore.toastrs.filter((toastr) => toastr.id !== idToastr);
};

export interface NewToastr {
  toastr: Toastr;
  dismiss: () => void;
}

const showToastr = (
  toastr: Omit<Toastr, 'id'>,
  toastrStore: ReturnType<typeof useToastrStore>,
): NewToastr => {
  const nextId = (toastrStore.toastrs.at(-1)?.id ?? 0) + 1;

  const newToastr = {
    ...toastr,
    id: nextId,
  } as Toastr;

  const isDismissedSignal = new Signal();
  const dismiss = () => {
    if (isDismissedSignal.isCancelled) {
      return;
    }

    isDismissedSignal.cancel();
    dismissToastr(newToastr.id, toastrStore);
  };

  if (!isServer()) {
    // We don't want to store new toastrs in the store in the server because
    // genericRetryableErrorToastr have a retry function as one of their
    // props and we cannot serialize that into a JSON object for hydratation,
    // meaning that we cannot serialize a store that tried to display a
    // genericRetryableErrorToastr. Since we cannot really control when this
    // kind of toastrs are displayed (they are used as generic error handlers)
    // we might face unexpected unserializable states when the server throws
    // some client error when attempting to render the page.
    toastrStore.toastrs.push(newToastr);

    if (newToastr.timeoutInMilliseconds) {
      setTimeout(() => {
        dismiss();
      }, newToastr.timeoutInMilliseconds);
    }
  }

  return { toastr: newToastr, dismiss };
};

export const useToastrStore = defineStore('toastr', {
  state: () => ({
    toastrs: [] as Array<Toastr>,
  }),
  actions: {
    showGenericRetryableErrorToastr<T>({
      retry,
      timeoutInMilliseconds,
    }: {
      retry: () => Promise<T>;
      timeoutInMilliseconds: number | null;
    }): NewToastr {
      return showToastr(
        {
          type: ToastrType.genericRetryableError,
          retry,
          timeoutInMilliseconds,
        } as GenericRetryableErrorToastr<T>,
        this,
      );
    },

    showSuccessToastr({
      message,
      timeoutInMilliseconds = DEFAULT_TOASTR_TIMEOUT_IN_SECONDS * 1000,
    }: {
      message: string;
      timeoutInMilliseconds?: number | null;
    }): NewToastr {
      return showToastr(
        {
          type: ToastrType.genericSuccess,
          message,
          timeoutInMilliseconds,
        } as GenericSuccessToastr,
        this,
      );
    },

    showUploadingToastr(): NewToastr {
      return showToastr(
        {
          type: ToastrType.avatarUploading,
          timeoutInMilliseconds: null,
        },
        this,
      );
    },

    showProcessingToastr(): NewToastr {
      return showToastr(
        {
          type: ToastrType.avatarProcessing,
          timeoutInMilliseconds: null,
        },
        this,
      );
    },

    showAvatarSuccessfullyProcessedToastr({
      timeoutInMilliseconds = DEFAULT_TOASTR_TIMEOUT_IN_SECONDS * 1000,
    }: {
      timeoutInMilliseconds?: number | null;
    } = {}): NewToastr {
      return showToastr(
        {
          type: ToastrType.avatarSuccessfullyProcessed,
          timeoutInMilliseconds,
        },
        this,
      );
    },

    showNewThreadSuccessfullyCreatedToastr({
      threadSlug,
      timeoutInMilliseconds = DEFAULT_TOASTR_TIMEOUT_IN_SECONDS * 1000,
    }: {
      threadSlug: ThreadSlug;
      timeoutInMilliseconds?: number | null;
    }): NewToastr {
      return showToastr(
        {
          type: ToastrType.newThreadSuccessfullyCreated,
          threadSlug,
          timeoutInMilliseconds,
        } as NewThreadSuccessfullyCreated,
        this,
      );
    },

    showNewPostReplySuccessfullyCreatedToastr({
      idChildPost,
      idParentPost,
      timeoutInMilliseconds = DEFAULT_TOASTR_TIMEOUT_IN_SECONDS * 1000,
    }: {
      idChildPost: PostId;
      idParentPost: PostId;
      timeoutInMilliseconds?: number | null;
    }): NewToastr {
      return showToastr(
        {
          type: ToastrType.newPostReplySuccessfullyCreated,
          idChildPost,
          idParentPost,
          timeoutInMilliseconds,
        } as NewPostReplySuccessfullyCreated,
        this,
      );
    },

    showSessionInvalidToastr({
      // 1 minute because this toastr appears unexpectedtly when page loads
      timeoutInMilliseconds = 60 * 1000,
    }: {
      timeoutInMilliseconds?: number | null;
    } = {}): NewToastr {
      return showToastr(
        {
          type: ToastrType.invalidSession,
          timeoutInMilliseconds,
        },
        this,
      );
    },

    dismiss(idToastr: Toastr['id']) {
      dismissToastr(idToastr, this);
    },
  },
});
