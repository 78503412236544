<template>
  <ResponsiveContainer tag="header" class="c-main-navbar-header-banner">
    <template v-if="logoUrl">
      <ResponsiveImage
        v-if="bannerImage"
        :height="bannerHeight"
        :image="bannerImage"
        :width="bannerWidth"
        class="c-main-navbar-header-banner__banner-image"
      />

      <img :src="logoUrl" class="c-main-navbar-header-banner__logo" />
    </template>
    <div v-else class="c-main-navbar-header-banner__loading-container">
      <ActivityIndicator class="u-margin-r-xs" />
      {{ t('title.loading') }}
    </div>
  </ResponsiveContainer>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue';
import { useI18n } from 'vue-i18n';

import { CategorySlug } from '@caff/category-api-model';
import { ActivityIndicator } from '@caff/cds-activity-indicator';
import { ResponsiveContainer } from '@caff/cds-responsive-container';
import { ResponsiveImage } from '@caff/cds-responsive-image';
import { ImageLocation } from '@caff/image-isomorphic-model';

import {
  useCategoryBannerImage,
  useCategoryLogoUrl,
  useImageSizeForLocation,
} from '../../composition';

export default defineComponent({
  name: 'MainNavbarHeaderBanner',
  components: {
    ActivityIndicator,
    ResponsiveContainer,
    ResponsiveImage,
  },
  props: {
    categorySlug: {
      type: null as unknown as PropType<CategorySlug | null>,
      required: false,
    },
  },
  setup(props) {
    const { t } = useI18n();

    const logoUrl = useCategoryLogoUrl(computed(() => props.categorySlug ?? null));
    const bannerImage = useCategoryBannerImage(computed(() => props.categorySlug ?? null));
    const { height: bannerHeight, width: bannerWidth } = useImageSizeForLocation(
      ImageLocation.headerBanner,
    );

    return {
      t,
      logoUrl,
      bannerHeight,
      bannerImage,
      bannerWidth,
    };
  },
});
</script>

<i18n lang="json" locale="es">
{
  "title": {
    "loading": "Cargando comunidad…"
  }
}
</i18n>

<style lang="scss" scoped>
@import '@caff/cds-scss-core';

// We increase the priority of this selector a little bit, otherwise some of the
// attributes might get overriden by the styles of `ResponsiveContainer`. This
// happens when coming from Home to a page using this component.
header.c-main-navbar-header-banner {
  --border-radius: 8px;

  background-color: var(--colors-box-background);
  border-bottom-left-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius);
  box-shadow: 0 1px 2px 0 var(--colors-box-shadow);
  height: get-size-in-rem(116px);
  overflow: hidden;
  padding: 0;
  position: relative;

  @include t-responsive--smaller-than($responsive-breakpoint-tablet-medium) {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    width: 100vw;
  }
}

.c-main-navbar-header-banner__banner-image {
  display: block;
  height: 100%;
  width: 100%;
}

.c-main-navbar-header-banner__logo {
  left: 0;
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
}

.c-main-navbar-header-banner__loading-container {
  @include t-font($font-header-small);

  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  margin: 0;
  width: 100%;
}
</style>
