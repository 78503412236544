import { EXTENSION_FOR_IMAGE_FORMAT, ImageFormat } from './ImageFormat';
import { ScreenDensityVariation } from './ScreenDensityVariation';

export class SrcsetEntry {
  readonly url: URL;
  readonly width: number;
  readonly format: ImageFormat;

  constructor({ format, url, width }: { format: ImageFormat; url: URL; width: number }) {
    this.format = format;
    this.url = url;
    this.width = width;
  }

  get srcsetEntryString(): string {
    return `${this.url.toString()} ${this.width}w`;
  }

  static from({
    baseUrl,
    imagePathWithoutExtension,
    formats,
    screenDensityVariations,
  }: {
    baseUrl: string;
    imagePathWithoutExtension: string;
    formats: Array<ImageFormat>;
    screenDensityVariations: Array<ScreenDensityVariation>;
  }): Array<SrcsetEntry> {
    const srcsetEntries = ([] as Array<SrcsetEntry>).concat(
      ...formats.map((imageFormat) =>
        screenDensityVariations.map(
          ({ size, suffix }) =>
            new SrcsetEntry({
              format: imageFormat,
              url: new URL(
                `${imagePathWithoutExtension}${suffix}.${EXTENSION_FOR_IMAGE_FORMAT[imageFormat]}`,
                baseUrl,
              ),
              width: size.width,
            }),
        ),
      ),
    );

    return srcsetEntries;
  }
}
