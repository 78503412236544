<template>
  <SidebarBannerWidget>
    <template #header>
      <ModalBanner class="c-user-profile-sidebar-widget__banner">
        <template #default="{ classes }">
          <Chill :class="classes" />
        </template>
      </ModalBanner>
    </template>

    <template v-if="user">
      <div class="c-user-profile-sidebar-widget__row">
        <UserAvatar
          :s3BucketConfigForImageKind="s3ConfigForImageKind"
          :to="userProfileRoute"
          :user="user"
          class="c-user-profile-sidebar-widget__avatar"
          large
        />

        <Pill :to="userProfileRoute" tag="router-link" large>
          {{ user.displayName }}
        </Pill>
      </div>
      <div class="c-user-profile-sidebar-widget__row">
        <UserProfileLink :username="username" :interactive="interactive">
          @{{ user.username }}
        </UserProfileLink>
      </div>
    </template>
    <template v-else>
      <div class="c-user-profile-sidebar-widget__row">
        <Avatar
          :cdsColor="AvatarColor.amber"
          name=""
          class="c-user-profile-sidebar-widget__avatar"
          large
          rounded
        />

        <Pill large />
      </div>
      <div class="c-user-profile-sidebar-widget__row">
        <div class="c-user-profile-sidebar-widget__username-placeholder">&nbsp;</div>
      </div>
    </template>

    <template #footer>
      <template v-if="user">
        <UserAdvancedOptionsMenu v-if="interactive" :username="username">
          <template #default="{ opened, togglePopover }">
            <Button :active="opened" :type="ButtonType.secondary" @click="togglePopover()">
              <FontAwesomeIcon :icon="faMoreMenu" fixedWidth aria-hidden />

              {{ t('actions.showUserOptions') }}
            </Button>
          </template>
        </UserAdvancedOptionsMenu>
      </template>
      <template v-else>
        <div class="c-user-profile-sidebar-widget__footer-placeholder" />
      </template>
    </template>
  </SidebarBannerWidget>
</template>

<script lang="ts" setup>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import { Avatar, AvatarColor } from '@caff/cds-avatar';
import { Button, ButtonType } from '@caff/cds-button';
import { faMoreMenu } from '@caff/cds-icons';
import { Chill, ModalBanner } from '@caff/cds-modal-banner';
import { Pill } from '@caff/cds-pill';
import { SidebarBannerWidget } from '@caff/cds-sidebar-banner-widget';
import { Username } from '@caff/user-api-model';
import { UserAvatar } from '@caff/user-avatar-frontend-component';

import { useS3ConfigForImageKind, useUser } from '../../composition';
import type { InteractiveProps } from '../../mixins/interactive.mixin';
import { interactivePropsDefaults } from '../../mixins/interactive.mixin';
import { getUserProfileRoute } from '../../router';
import { UserProfileLink } from '../user';
import { UserAdvancedOptionsMenu } from '../user-advanced-options-menu';

const props = withDefaults(
  defineProps<
    InteractiveProps & {
      username: Username;
    }
  >(),
  {
    ...interactivePropsDefaults,
  },
);

const { t } = useI18n();

const s3ConfigForImageKind = useS3ConfigForImageKind();
const { user } = useUser(computed(() => props.username));
const userProfileRoute = computed(() =>
  props.interactive ? getUserProfileRoute(props.username) : null,
);
</script>

<i18n lang="json" locale="es">
{
  "actions": {
    "showUserOptions": "Opciones de usuario"
  }
}
</i18n>

<style lang="scss" scoped>
@import '@caff/cds-scss-core';

.c-user-profile-sidebar-widget__banner {
  background: var(--colors-blue-500);

  &:deep(svg) {
    --fill-color: var(--colors-readable-over-color-500);

    height: get-size-in-rem(100px);
  }
}

.c-user-profile-sidebar-widget__row {
  align-items: center;
  display: flex;
  flex-direction: row;

  & + & {
    @extend %t-margin-t-s;
  }
}

.c-user-profile-sidebar-widget__avatar {
  @extend %t-margin-r-xs;

  flex-grow: 0;
  flex-shrink: 0;
}

.c-user-profile-sidebar-widget__username-placeholder {
  @include t-font($font-body-action);

  display: inline-flex;
}

.c-user-profile-sidebar-widget__footer-placeholder {
  @extend %t-height-l;
}
</style>
