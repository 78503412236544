<template>
  <Button v-if="previousRoute" :type="buttonType" @click="goBack()">
    <FontAwesomeIcon :icon="faArrowLeft" fixed-width aria-hidden />

    <!-- @slot Use this slot to customize button's label -->
    <slot>
      {{ t('action.goBack') }}
    </slot>
  </Button>
</template>

<script lang="ts">
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';

import { Button, ButtonType } from '@caff/cds-button';
import { faArrowLeft } from '@caff/cds-icons';

import { usePreviousRoute } from '../../composition';

export default defineComponent({
  name: 'GoBackButton',
  components: {
    Button,
    FontAwesomeIcon,
  },
  setup() {
    const { t } = useI18n();

    const previousRoute = usePreviousRoute();
    const router = useRouter();
    const goBack = () => {
      router.back();
    };

    return {
      t,
      faArrowLeft,
      buttonType: ButtonType.secondary,
      goBack,
      previousRoute,
    };
  },
});
</script>

<i18n lang="json" locale="es">
{
  "action": {
    "goBack": "Volver"
  }
}
</i18n>
