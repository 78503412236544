import type { IconDefinition, IconName, IconPrefix } from '@fortawesome/fontawesome-common-types';

export const prefix = 'caff' as IconPrefix;
export const iconName = 'users-many' as IconName;
export const width = 14;
export const height = 14;
export const ligatures = [];
export const unicode = 'f856';
export const svgPathData =
  'm8.91320456 2c1.91057664 0 3.45940464 1.58808391 3.45940464 3.54708469 0 .4658137-.08757.91065579-.2467524 1.31816251l-.0416023.0925522c-.0086268.01743487-.0181002.0358745-.0284201.05531888l-.1157368.2021278-.1614511.25638451-.2071654.31064121c-.0383371.05629493-.078579.11485056-.1207256.17566688.3518312.15622932.6983541.40346266 1.0395688.74170002.7677331.76103406 1.2640799 1.8671082 1.4890419 3.3182225.0137349.088596.0206338.1781554.0206338.2678531 0 .9467738-.7521612 1.7142857-1.68 1.7142857h-6.71975621c-.08790264 0-.17567004-.0070399-.26249412-.0210548-.91643997-.1479301-1.54183843-1.0259341-1.39687208-1.9610777.21999684-1.4190931.6995335-2.5082062 1.43861084-3.26733944.32847882-.33739255.67045177-.59075557 1.02591886-.76008906l-.23281036-.33154862-.18717597-.27968224-.14154159-.22781586-.0959072-.17594948-.03084071-.06852485c-.16967075-.41878048-.26333243-.87809171-.26333243-1.35983326 0-1.95900078 1.54882809-3.54708469 3.45940553-3.54708469zm-5.16830114-2c.65847542 0 1.25965861.25151414 1.71715575.6659589.11426927.10351605.21957464.21719658.31456993.33966126-1.4075352 1.00676253-2.32283007 2.66915704-2.32283007 4.54146453l.00855559.31222383c.02846459.51838458.1276854 1.02601285.29360974 1.51067577l.05003564.13601571-.06518944.07131886c-.37231495.42217726-.6885351.89718883-.95075028 1.42230729l-1.52987744.00037385c-.06592698 0-.13175253-.00527991-.19687059-.01579108-.68732998-.11094763-1.15637882-.76945063-1.04765406-1.47080828.16499763-1.06431982.52465013-1.88115466 1.07895813-2.4505046.24635912-.25304441.50283883-.44306668.76943915-.57006679l-.10242825-.14465857-.17236873-.25239273c-.02511837-.03796275-.04843187-.07387414-.06994049-.10773417l-.10739321-.17854393c-.02857823-.05130922-.04993696-.09441301-.06407619-.12931139-.12725307-.31408536-.19749933-.65856878-.19749933-1.01987494 0-1.46925059 1.16162107-2.66031352 2.59455415-2.66031352z';

export const definition = {
  prefix,
  iconName,
  icon: [width, height, ligatures, unicode, svgPathData],
} as IconDefinition;

export const faUsersMany = definition;
