<template>
  <span v-if="!areLinksHidden && user">
    <!-- @slot Use this slot to customize how links to user mentions are rendered -->
    <slot :text="`@${userUsername}`" :user="user" />
  </span>
  <span v-else>@{{ username }}</span>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue';

import { Username } from '@caff/user-api-model';

import { useAreLinksHidden } from './composition';
import { nodeVueProp, usersByLowercaseUsernameVueProp, featuresVueProp } from './props';

export default defineComponent({
  name: 'MarkdownUserMentionNode',
  props: {
    features: featuresVueProp,
    node: nodeVueProp,
    username: {
      type: String as PropType<Username>,
      required: true,
    },
    usersByLowercaseUsername: usersByLowercaseUsernameVueProp,
  },
  setup(props) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const areLinksHidden = useAreLinksHidden(props.features!);

    const user = computed(
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      () => props.usersByLowercaseUsername![props.username.toLowerCase()] ?? null,
    );
    const userUsername = computed(() => user.value?.username ?? null);

    return {
      areLinksHidden,
      user,
      userUsername,
    };
  },
});
</script>
