import formatDistance from 'date-fns/formatDistance/index.js';
import { computed, MaybeRef, Ref, unref } from 'vue';

import { useDateStore } from '../store';

export const useRelativeDate = <T extends Date | null>(
  absoluteDate: MaybeRef<T>,
): Ref<T extends Date ? string : null> =>
  computed((): T extends Date ? string : null => {
    const date = unref(absoluteDate);

    if (date === null) {
      return null as T extends Date ? string : null;
    }

    const dateStore = useDateStore();

    return formatDistance(date, dateStore.currentDate, {
      locale: dateStore.locale,
    }) as T extends Date ? string : null;
  });
