import { ImageFormat, MimeTypeForImageFormat } from './ImageFormat';
import { SrcsetEntry } from './SrcsetEntry';

export class SrcsetSource {
  readonly imageFormat: ImageFormat;
  readonly srcsetEntries: Array<SrcsetEntry>;

  constructor({
    imageFormat,
    srcsetEntries,
  }: {
    imageFormat: ImageFormat;
    srcsetEntries: Array<SrcsetEntry>;
  }) {
    this.imageFormat = imageFormat;
    this.srcsetEntries = srcsetEntries;
  }

  get type(): string {
    return MimeTypeForImageFormat[this.imageFormat];
  }

  get srcsetEntryString(): string {
    return this.srcsetEntries.map((srcsetEntry) => srcsetEntry.srcsetEntryString).join(', ');
  }
}
