/**
 * Possible status in which a user can be.
 *
 * - `activated`: Account is active and can be used.
 * - `deactivated`: Account is deactivated and can't be used to log in or
 * perform any action.
 */
export const enum UserModerationStatus {
  activated = 'activated',
  deactivated = 'deactivated',
}

export const ALL_USER_MODERATION_STATUS = [
  UserModerationStatus.activated,
  UserModerationStatus.deactivated,
];
