<template>
  <Button :to="to" :type="buttonType" class="c-dropdown-menu-entry" @click="handleClick($event)">
    <FontAwesomeIcon v-if="icon" :icon="icon" class="c-dropdown-menu-entry__icon" fixed-width />

    <slot />
  </Button>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import { Button, ButtonType } from '@caff/cds-button';

export default defineComponent({
  name: 'DropdownMenuEntry',
  components: {
    Button,
    FontAwesomeIcon,
  },
  props: {
    icon: {
      type: [Object, Array, String],
      required: false,
    },
    to: {
      type: [String, Object],
      required: false,
    },
  },
  emits: ['click'],
  setup(props, { emit }) {
    const buttonType = ButtonType.tertiary;

    const handleClick = ($event: MouseEvent) => {
      /**
       * User clicked this menu entry.
       *
       * @event click
       * @type {MouseEvent}
       */
      emit('click', $event);
    };

    return {
      buttonType,
      handleClick,
    };
  },
});
</script>

<style lang="scss" scoped>
@import '@caff/cds-scss-core';

.c-dropdown-menu-entry {
  display: block;
  margin: var(--spacing-xxs) var(--spacing-xs);

  &:first-child {
    margin-top: var(--spacing-xs);
  }

  &:last-child {
    margin-bottom: var(--spacing-xs);
  }
}

.c-dropdown-menu-entry__icon {
  @extend %t-margin-r-xs;
}
</style>
