import { RouteRecordRaw } from 'vue-router';

type HomeView = typeof import('../views/Home.vue');
const Home = (): Promise<HomeView> => import('../views/Home.vue');

type ForgotPasswordView = typeof import('../views/ForgotPassword.vue');
const ForgotPassword = (): Promise<ForgotPasswordView> => import('../views/ForgotPassword.vue');

type ChangePasswordView = typeof import('../views/ChangePassword.vue');
const ChangePassword = (): Promise<ChangePasswordView> => import('../views/ChangePassword.vue');

type RegisterView = typeof import('../views/Register.vue');
const Register = (): Promise<RegisterView> => import('../views/Register.vue');

export const loginRoute = {
  path: '/login',
  name: 'login',
  component: Home,
} satisfies RouteRecordRaw;

export const forgotPasswordRoute = {
  path: '/password/forgot',
  name: 'forgot-password',
  component: ForgotPassword,
} satisfies RouteRecordRaw;

export const changePasswordRoute = {
  path: '/change-password/:token',
  name: 'change-password',
  component: ChangePassword,
  props: true,
} satisfies RouteRecordRaw;

export const registerRoute = {
  path: '/register',
  name: 'register',
  component: Register,
} satisfies RouteRecordRaw;
