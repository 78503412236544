import { RouteLocationNormalized, RouterOptions } from 'vue-router';

import { isServer } from '@caff/isomorphic-is-server';

import { getPaddedOffsetToCompensateStickyHeader, getScrollForElement } from '../utils/scroll';
import {
  userManagerShowingOnlyActivatedRoute,
  userManagerShowingOnlyDeactivatedRoute,
} from './admin';
import { notificationsRoute, settingsRoute } from './account';
import { homeRoute, discoverRoute, categoryFrontpageRoute } from './home';
export { discoverRoute };
export { getCategoryFrontpageRoute } from './home';
import { loginRoute, registerRoute, forgotPasswordRoute, changePasswordRoute } from './login';
export { forgotPasswordRoute };
import { RouterMetaField } from './meta-fields';
import { postRoute } from './post';
export { getPostRoute } from './post';
import { threadRoute } from './thread';
export { getThreadRoute } from './thread';
import { userProfileRoute } from './user';
export { getUserProfileRoute } from './user';

export {
  homeRoute,
  loginRoute,
  notificationsRoute,
  settingsRoute,
  userManagerShowingOnlyActivatedRoute,
  userManagerShowingOnlyDeactivatedRoute,
  userProfileRoute,
};

// TODO: Maybe dynamically add routes?
export const routes = [
  homeRoute,
  discoverRoute,
  categoryFrontpageRoute,

  notificationsRoute,
  settingsRoute,

  threadRoute,
  postRoute,
  userProfileRoute,
  userManagerShowingOnlyActivatedRoute,
  userManagerShowingOnlyDeactivatedRoute,

  loginRoute,
  registerRoute,
  forgotPasswordRoute,
  changePasswordRoute,
];

type ScrollBehaviorResult = ReturnType<Exclude<RouterOptions['scrollBehavior'], undefined>>;

const getPositionForRouteHash = (route: RouteLocationNormalized): ScrollBehaviorResult => {
  if (!route.hash) {
    return false;
  }

  const targetElement = isServer() ? null : document.querySelector(route.hash);
  if (!targetElement) {
    return false;
  }

  const scrollForElement = getScrollForElement(targetElement);
  return {
    top: scrollForElement.y,
    left: scrollForElement.x,
  };
};

const getPositionForRouteMeta = (route: RouteLocationNormalized): ScrollBehaviorResult => {
  const getSelectorToScrollTo = route.meta?.[RouterMetaField.getSelectorToScrollTo];
  if (!getSelectorToScrollTo) {
    return false;
  }

  const paramsForwardedToGetSelectorToScrollTo: Array<string> =
    route.meta[RouterMetaField.paramsForwardedToGetSelectorToScrollTo] || [];

  const forwardedParams = paramsForwardedToGetSelectorToScrollTo.map(
    (paramName) => route.params[paramName],
  );
  const selectorToScrollTo = getSelectorToScrollTo(...forwardedParams);

  return {
    el: selectorToScrollTo,
    top: getPaddedOffsetToCompensateStickyHeader(),
    left: 0,
  };
};

export const scrollBehavior: NonNullable<RouterOptions['scrollBehavior']> = (
  to,
  from,
  savedPosition,
) => {
  if (savedPosition) {
    return savedPosition;
  }

  const positionForRouteHash = getPositionForRouteHash(to);
  if (positionForRouteHash) {
    return positionForRouteHash;
  }

  const positionForRouteMeta = getPositionForRouteMeta(to);
  if (positionForRouteMeta) {
    return positionForRouteMeta;
  }

  return { top: 0, left: 0 };
};
