import { computed, Ref } from 'vue';

import { useApiBaseUrl } from './useNetwork';

export const useIsProduction = (): Ref<boolean> =>
  computed(() => {
    const apiUrl = useApiBaseUrl();

    return apiUrl.value?.host === 'api.caff.co';
  });
