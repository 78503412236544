<template>
  <section
    v-if="currentlyLoggedInUser"
    ref="el"
    :class="{
      'c-text-composer': true,
      'c-text-composer--focused': isFocused,
      'c-text-composer--on-screen-keyboard-visible': isOnScreenKeyboardVisible,
    }"
  >
    <div class="c-text-composer__leading-border" />

    <UserMetaSection
      :showAuthorBadge="showAuthorBadge"
      :username="currentlyLoggedInUser.username"
      tag="header"
      class="c-text-composer__meta"
    />

    <GrowingTextarea
      :modelValue="modelValue"
      :placeholder="
        replyingToUsername
          ? t('placeholder.replyingToUser', {
              username: replyingToUsername,
            })
          : t('placeholder.notReplying')
      "
      :disabled="loading"
      :autofocus="autofocus"
      class="c-text-composer__textarea"
      @autofocus="onAutofocus()"
      @blur="onBlur()"
      @focus="onFocus()"
      @update:modelValue="input($event)"
    />

    <footer class="c-text-composer__actions">
      <input
        :value="t('actions.publish')"
        :disabled="isSubmitDisabled"
        type="submit"
        class="c-text-composer__hidden-input"
      />

      <!-- @slot Use this slot to render additional actions next to the Publish button -->
      <slot name="actions" :cancel="cancel" :submit="submit" />

      <PrimaryButton :disabled="isSubmitDisabled" :loading="loading" @click.prevent.stop="submit()">
        {{ t('actions.publish') }}
      </PrimaryButton>
    </footer>
  </section>
</template>

<script lang="ts" setup>
import { computed, nextTick, onMounted, ref, watchEffect } from 'vue';
import { useI18n } from 'vue-i18n';

import { PrimaryButton } from '@caff/cds-button';
import { isTouchable, useIsOnScreenKeyboardVisible } from '@caff/cds-on-screen-keyboard';
import { waitOneFrame } from '@caff/isomorphic-time';
import { Username } from '@caff/user-api-model';

import { useCssVar, useCurrentlyLoggedInUser, useVisualViewportCssVars } from '../../composition';
import { GrowingTextarea } from '../form';
import { UserMetaSection } from '../user';

const props = withDefaults(
  defineProps<{
    autofocus?: boolean;
    modelValue?: string;
    loading: boolean;
    isExternalDataMissing?: boolean;
    replyingToUsername?: Username;
    showAuthorBadge?: boolean;
  }>(),
  {
    autofocus: false,
    showAuthorBadge: false,
  },
);

const emit = defineEmits<{
  cancel: [];
  submit: [string?];
  focus: [];
  blur: [];
  'update:modelValue': [string];
}>();

const { t } = useI18n();

const { currentlyLoggedInUser } = useCurrentlyLoggedInUser();

const isSubmitDisabled = computed(
  () => props.isExternalDataMissing || props.loading || !props.modelValue,
);

const cancel = () => {
  /**
   * User wants to dismiss this text editor.
   *
   * @event cancel
   */
  emit('cancel');
};

const submit = () => {
  /**
   * User wants to submit the content of this text editor.
   *
   * @event cancel
   * @type {String}
   */
  emit('submit', props.modelValue);
};

const isFocused = ref(false);

const reapplyWindowScroll = async () => {
  const originalWindowScroll = window.scrollY;

  window.scrollTo(window.scrollX, 0);
  await waitOneFrame();
  window.scrollTo(window.scrollX, originalWindowScroll);
};

const onFocus = async () => {
  // We must wait for this frame to finish before considering it as focused
  // in order to properly position the textarea in iOS.
  //
  // Why? Because iOS scrolls the content when the on-screen-keyboard appears.
  // It also scrolls whenever an input field is focused and the
  // on-screen-keyboard is visible. It does that to ensure that the input is
  // visible even when the keyboard is covering part of the website.
  //
  // Why is this a problem? Because when we focus on this input we immediately
  // add a CSS class that makes the textarea absolutely positioned, covering
  // anything in the screen. iOS is not aware of this when it computes the
  // scroll distance required to ensure field's visibility.
  //
  // Why is this a problem? In NewThreadComposer we display an input field
  // for the title before the text composer for the body. When the user is
  // focused on that field and then focuses on the text composer then iOS
  // wrongly infers that it must move the content up a little bit to account
  // for the space that the title input was taking previously.
  //
  // What happens in other use cases of TextComposer? If there is no focused
  // field before focusing on the text composer then iOS doesn't think it
  // needs to scroll to compensate anything because we always put TextComposer
  // in modals that are absolutely positioned and the TextComposer is in the
  // upper part of the screen.
  await waitOneFrame();

  isFocused.value = true;

  /**
   * User focused on this text editor.
   *
   * @event focus
   */
  emit('focus');

  // When interacting with the composer used to reply to a thread or to the
  // main post (when user is in single post view) we have to reapply window
  // scroll to fix issues with iOS automatically forcing scroll to ensure
  // textarea is not covered by the on-screen-keyboard.
  //
  // However, if we reapply the window scroll when iOS has not applied a
  // forced scroll then we end up buggying it ourselves. We can
  // detect when iOS forces the scroll checking that the visualViewport
  // offsetTop property is not 0.
  //
  // Note that Firefox does not support visualViewport so we have to detect
  // it before using it.
  //
  // If the visualViewport and the window scroll match then there's no need
  // for the fix and applying it will but the position of the textarea.

  if (
    typeof visualViewport === 'undefined' ||
    visualViewport?.offsetTop === 0 ||
    window.scrollY === visualViewport?.offsetTop
  ) {
    return;
  }

  await reapplyWindowScroll();
};

const onAutofocus = async () => {
  ensureEditorIsVisible();

  if (isTouchable()) {
    // When interacting with the composer used to reply to a reply (any
    // reply but the main post in single post view) we are in a special case
    // because we are adding a new element to the DOM and immediately
    // focusing on it.
    //
    // When that happens iOS really messes up the scroll and we have to fix
    // it but we can only detect this scenario by hooking into the autofocus
    // event.
    //
    // This event is triggered at the same time as the focus event so we
    // must introduce here a larger delay to not collide with the fixes
    // being applied by the focus handler. We need to skip 3 frames to
    // prevent collisions.
    await waitOneFrame();
    await waitOneFrame();
    await waitOneFrame();
    await reapplyWindowScroll();
  }
};

const onBlur = async () => {
  // We leave a blank frame so iOS handles any other event handler that
  // triggered this blur. Otherwise when we update `isFocused` Safari in iOS
  // just ignores the rest of the events dispatched.
  //
  // You can reproduce issues when replying to a post (all but the main post
  // in post view) and clicking the "Cancel" button.
  await waitOneFrame();
  isFocused.value = false;

  /**
   * User stopped focusing on this text editor.
   *
   * @event blur
   */
  emit('blur');
};

const el = ref<HTMLElement | null>(null);
const bottomSpace = useCssVar('--editor-bottom-spacing', el);
const bottomSpaceInPx = computed(() => {
  if (bottomSpace.value.endsWith('rem')) {
    const remValue = parseFloat(bottomSpace.value.substring(0, bottomSpace.value.length - 3));
    const oneRemSize = parseFloat(getComputedStyle(document.documentElement).fontSize);
    return remValue * oneRemSize;
  }

  return parseInt(bottomSpace.value, 10);
});

onMounted(() => {
  useVisualViewportCssVars(el);
});

const isOnScreenKeyboardVisible = useIsOnScreenKeyboardVisible();

const moveEditorTopToScreenTop = () => {
  el.value?.scrollIntoView({
    block: 'start',
    inline: 'nearest',
  });
};

watchEffect(async () => {
  if (!isFocused.value || !isOnScreenKeyboardVisible.value) {
    return;
  }

  await waitOneFrame();
  moveEditorTopToScreenTop();
});

const ensureEditorIsNotOutOfBottomBound = () => {
  const editorPositionRelativeToViewport = el.value?.getBoundingClientRect() ?? { bottom: 0 };
  const viewportHeight =
    typeof visualViewport !== 'undefined' && !!visualViewport
      ? visualViewport.height
      : window.innerHeight;
  const editorHeightOutOfBounds =
    editorPositionRelativeToViewport.bottom + bottomSpaceInPx.value - viewportHeight;

  if (editorHeightOutOfBounds > 0) {
    window.scrollTo(window.scrollX, window.scrollY + editorHeightOutOfBounds);
  }
};

const ensureEditorIsVisible = async () => {
  await nextTick();

  if (isOnScreenKeyboardVisible.value) {
    moveEditorTopToScreenTop();
  } else {
    ensureEditorIsNotOutOfBottomBound();
  }
};

const input = (newValue: string) => {
  emit('update:modelValue', newValue);

  ensureEditorIsVisible();
};
</script>

<i18n lang="json" locale="es">
{
  "placeholder": {
    "notReplying": "Escribe un mensaje",
    "replyingToUser": "Responde a {username}"
  },
  "actions": {
    "publish": "Publicar"
  }
}
</i18n>

<style lang="scss" scoped>
@import '@caff/cds-scss-core';

.c-text-composer {
  // (2 * 10px + l) (main navbar) + m (editor box top paddding) + l (user meta) + s (margin bottom from user meta) + s (margin top to actions) + l (actions) + m (editor box bottom paddding) + additional height coming from parent container (used by new thread composer to account for modal max height)
  --editor-height: calc(
    #{get-size-in-rem(2 * 10px)} + 3 * var(--spacing-l) + 2 * var(--spacing-s) + 2 * var(
        --spacing-m
      ) + var(--editor-extra-height, 0px)
  );

  display: grid;
  grid-template-columns:
    [leading-edge] var(--leading-spacing, var(--spacing-m)) [start] 3px [after-leading-border] calc(
      var(--spacing-m)
    )
    [before-body] auto [end] var(--trailing-spacing, var(--spacing-m)) [trailing-edge];
  grid-template-rows:
    [top-edge] var(--spacing-m) [start] auto [after-line1] var(--spacing-s) [before-line2] var(
      --text-composer-textarea-height,
      auto
    )
    [after-line2] var(--spacing-s)
    [before-line3] auto [end] var(--bottom-edge-spacing, var(--spacing-m)) [bottom-edge];
}

.c-text-composer--focused.c-text-composer--on-screen-keyboard-visible {
  // (when on screen keyboard is visible put the editor over the main navbar) + m (editor box top paddding) + l (user meta) + s (margin bottom from user meta) + s (margin top to actions) + l (actions)
  --editor-height: calc(2 * var(--spacing-l) + 2 * var(--spacing-s) + 1 * var(--spacing-m));

  @extend %t-z-index-modal;

  background-color: var(--colors-box-background);
  bottom: 0;
  left: 0;
  grid-template-columns:
    [leading-edge] var(--spacing-m) [start] 3px [after-leading-border] calc(var(--spacing-m))
    [before-body] auto [end] var(--spacing-m) [trailing-edge];
  grid-template-rows:
    [top-edge] var(--spacing-s) [start] auto [after-line1] var(--spacing-s) [before-line2] 1fr [after-line2] var(
      --spacing-s
    )
    [before-line3] auto [end] var(--spacing-s) [bottom-edge];
  height: calc(var(--visual-viewport-height, 100vh));
  position: absolute;
  top: calc(var(--visual-viewport-offset-top));
  right: 0;
}

.c-text-composer__leading-border {
  border-radius: calc(3px / 2);
  background-color: var(--colors-orange-500);
  grid-column-end: after-leading-border;
  grid-column-start: start;
  grid-row-end: end;
  grid-row-start: start;
}

.c-text-composer__meta {
  grid-column-end: end;
  grid-column-start: before-body;
  grid-row-end: after-line1;
  grid-row-start: start;
}

.c-text-composer__textarea {
  grid-column-end: end;
  grid-column-start: before-body;
  grid-row-end: after-line2;
  grid-row-start: before-line2;
  margin: 0;
}

.c-text-composer__actions {
  align-items: center;
  display: flex;
  flex-direction: row;
  grid-column-end: end;
  grid-column-start: before-body;
  grid-row-end: end;
  grid-row-start: before-line3;
  justify-content: flex-end;
}

.c-text-composer__hidden-input {
  display: none;
}
</style>
