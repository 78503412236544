import type { IconDefinition, IconName, IconPrefix } from '@fortawesome/fontawesome-common-types';

export const prefix = 'caff' as IconPrefix;
export const iconName = 'letter' as IconName;
export const width = 14;
export const height = 14;
export const ligatures = [];
export const unicode = 'f831';
export const svgPathData =
  'm14 6.593v4.407c0 1.1045695-.8954305 2-2 2h-10c-1.1045695 0-2-.8954305-2-2v-4.407l4.72156648 2.77397453c1.40668359.82592087 3.15018345.82592087 4.55686704 0zm-1.3478425-5.593c.7443928 0 1.3478425.60344965 1.3478425 1.34784252 0 .83498734-.4422589 1.60751062-1.1623073 2.03028021l-4.82505558 2.83298551c-.62519271.36707595-1.40008153.36707595-2.02527424 0l-4.82505558-2.83298551c-.72004842-.42276959-1.1623073-1.19529287-1.1623073-2.03028021 0-.74439287.60344965-1.34784252 1.34784252-1.34784252z';

export const definition = {
  prefix,
  iconName,
  icon: [width, height, ligatures, unicode, svgPathData],
} as IconDefinition;

export const faLetter = definition;
