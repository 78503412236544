import { RouteRecordRaw } from 'vue-router';

import { Username } from '@caff/user-api-model';

type UserView = typeof import('../views/User.vue');
const User = (): Promise<UserView> => import('../views/User.vue');

export const userProfileRoute = {
  path: '/u/:username',
  name: 'user-profile',
  component: User,
  props: true,
} satisfies RouteRecordRaw;

export const getUserProfileRoute = (
  username: Username,
): typeof userProfileRoute & { params: { username: Username } } => ({
  ...userProfileRoute,
  params: { username },
});
