<template>
  <div class="c-display-options-controls">
    <Button :type="buttonType" :active="isSortingByHot" class="u-margin-r-xs" @click="sortByHot">
      <FontAwesomeIcon :icon="faBolt" fixed-width />

      {{ t('actions.sortBy.hot') }}
    </Button>
    <Button :type="buttonType" :active="isSortingByRecent" @click="sortByRecent">
      <FontAwesomeIcon :icon="faBadgeStar" fixed-width />

      {{ t('actions.sortBy.recent') }}
    </Button>
  </div>
</template>

<script lang="ts">
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { computed, defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';

import { Button, ButtonType } from '@caff/cds-button';
import { faBolt, faBadgeStar } from '@caff/cds-icons';
import { ThreadOrder, threadOrderValidatorFactory } from '@caff/shallow-thread-api-model';

export default defineComponent({
  name: 'DisplayOptionsBar',
  components: {
    Button,
    FontAwesomeIcon,
  },
  props: {
    padded: {
      type: Boolean,
      default: false,
    },

    threadOrder: threadOrderValidatorFactory({ required: true }),
  },
  emits: ['sort-by'],
  setup(props, { emit }) {
    const { t } = useI18n();

    const isSortingByHot = computed(() => props.threadOrder === ThreadOrder.polemicFirst);
    const isSortingByRecent = computed(() => props.threadOrder === ThreadOrder.newestFirst);

    const emitSortBy = (threadOrder: ThreadOrder) => {
      /**
       * User wants to sort the threads by a specific criteria.
       *
       * @event sort-by
       * @type {ThreadOrder}
       */
      emit('sort-by', threadOrder);
    };

    const sortByHot = () => emitSortBy(ThreadOrder.polemicFirst);
    const sortByRecent = () => emitSortBy(ThreadOrder.newestFirst);

    return {
      t,
      faBolt,
      faBadgeStar,
      buttonType: ButtonType.secondary,
      isSortingByHot,
      isSortingByRecent,
      sortByHot,
      sortByRecent,
    };
  },
});
</script>

<i18n lang="json" locale="es">
{
  "actions": {
    "sortBy": {
      "hot": "Tendencias",
      "recent": "Nuevo"
    }
  }
}
</i18n>

<style lang="scss" scoped>
@import '@caff/cds-scss-core';

.c-display-options-controls {
  display: flex;
  flex-direction: row;
}
</style>
