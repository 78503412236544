import { JSONSchema7 } from 'json-schema';

export type UUIDv4 = string;

const UUIDv4Pattern = '^[a-z0-9\\-]{36}$';

export const UUIDv4JsonSchema: JSONSchema7 = {
  description: 'A UUIDv4',
  type: 'string',
  pattern: UUIDv4Pattern,
};

export const OptionalUUIDv4JsonSchema: JSONSchema7 = {
  description: 'An optional UUIDv4',
  type: ['string', 'null'],
  pattern: UUIDv4Pattern,
};
