import { RouteRecordRaw } from 'vue-router';

import { CategorySlug } from '@caff/category-api-model';

type HomeView = typeof import('../views/Home.vue');
const Home = (): Promise<HomeView> => import('../views/Home.vue');
type CategoryView = typeof import('../views/Category.vue');
const Category = (): Promise<CategoryView> => import('../views/Category.vue');
type DiscoverView = typeof import('../views/Discover.vue');
const Discover = (): Promise<DiscoverView> => import('../views/Discover.vue');

export const homeRoute = {
  path: '/',
  name: 'home',
  component: Home,
} satisfies RouteRecordRaw;

export const discoverRoute = {
  path: '/discover',
  name: 'discover',
  component: Discover,
} satisfies RouteRecordRaw;

export const categoryFrontpageRoute = {
  path: '/c/:categorySlug',
  name: 'category-frontpage',
  component: Category,
  props: true,
} satisfies RouteRecordRaw;

export const getCategoryFrontpageRoute = (
  categorySlug: CategorySlug,
): typeof categoryFrontpageRoute & { params: { categorySlug: CategorySlug } } => ({
  ...categoryFrontpageRoute,
  params: { categorySlug },
});
