import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import type { ValueOf } from 'type-fest';
import { computed, defineComponent } from 'vue';
import type { Prop, PropType, ComputedRef } from 'vue';
import type { RouteLocationRaw } from 'vue-router';

import { faSpinnerThird } from '@caff/cds-icons';

export const ButtonType = {
  primary: 'primary',
  secondary: 'secondary',
  tertiary: 'tertiary',
  link: 'link',
} as const;

export const buttonTypeProp = {
  type: String as PropType<ValueOf<typeof ButtonType>>,
  default: ButtonType.primary,
  validator: (value: string): boolean => {
    if (value in ButtonType) {
      return true;
    }

    console.warn(`Unknown Button type: ${value}`);

    return false;
  },
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const buttonFactory = <AdditionalConstants extends Record<string, unknown>>({
  name,
  type,
  additionalProps,
  additionalConstants,
}: {
  name: string;
  type?: ValueOf<typeof ButtonType>;
  additionalProps?: Record<string, Prop<unknown>>;
  additionalConstants?: AdditionalConstants;
}) => {
  const props = {
    hover: {
      type: Boolean,
      default: false,
    } as Prop<boolean>,
    active: {
      type: Boolean,
      default: false,
    } as Prop<boolean>,
    disabled: {
      type: Boolean,
      default: false,
    } as Prop<boolean>,
    loading: {
      type: Boolean,
      default: false,
    } as Prop<boolean>,
    focus: {
      type: Boolean,
      default: false,
    } as Prop<boolean>,
    to: {
      type: null as unknown as PropType<RouteLocationRaw | null>,
      required: false,
    } as Prop<RouteLocationRaw | null>,
    ...additionalProps,
  };

  const constants = type ? { type } : {};

  return defineComponent({
    name,
    components: {
      FontAwesomeIcon,
    },
    props,
    emits: ['click'],
    setup(
      props,
      { emit },
    ): typeof constants &
      AdditionalConstants & {
        faSpinnerThird: typeof faSpinnerThird;
        tag: ComputedRef<string>;
        handleClick: (event: MouseEvent) => void;
      } {
      const tag = computed(() => (props.to ? 'router-link' : 'button'));

      const handleClick = (event: MouseEvent) => {
        if (props.disabled || props.loading) {
          return;
        }

        /**
         * User clicked this Button.
         *
         * @event click
         * @type {MouseEvent}
         */
        emit('click', event);
      };

      return {
        ...constants,
        faSpinnerThird,
        tag,
        handleClick,
        ...additionalConstants,
      };
    },
  });
};
