<template>
  <div
    v-if="currentlyLoggedInUser"
    :class="{
      'c-update-user-avatar': true,
      'c-update-user-avatar--loading': loading,
      'c-update-user-avatar--focused': isFocused,
    }"
    @dragenter="focus()"
    @dragleave="unfocus()"
    @dragover.prevent="focus()"
    @dragexit="unfocus()"
    @drop.prevent="handleDrop($event)"
    @click="openPickDialog($event)"
  >
    <UserAvatar
      :s3BucketConfigForImageKind="s3BucketConfigForImageKind"
      :user="currentlyLoggedInUser"
      large
      class="c-update-user-avatar__avatar"
    />

    <form class="c-update-user-avatar__overlay">
      <input
        ref="input"
        :disabled="loading"
        type="file"
        class="c-update-user-avatar__file-input"
        @change="handleFilePick($event)"
      />

      <FontAwesomeIcon
        :icon="icon"
        :spin="loading"
        class="c-update-user-avatar__icon"
        fixed-width
      />
    </form>
  </div>
  <!-- TODO: Add redacted state -->
</template>

<script lang="ts" setup>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { computed, ref } from 'vue';

import { faCameraPlus, faSpinnerThird } from '@caff/cds-icons';
import { UserAvatar } from '@caff/user-avatar-frontend-component';
import { S3ConfigForImageKind } from '@caff/image-isomorphic-model';
import { useCurrentlyLoggedInUser } from '../../composition';

const props = defineProps<{
  s3BucketConfigForImageKind: S3ConfigForImageKind;
  loading: boolean;
}>();

const emit = defineEmits<{
  openPickDialog: [MouseEvent];
  pickFile: [File];
}>();

const isFocused = ref(false);
const isResettingInputFileField = ref(false);
const input = ref<HTMLInputElement | null>(null);

const { currentlyLoggedInUser } = useCurrentlyLoggedInUser();

const icon = computed(() => (props.loading ? faSpinnerThird : faCameraPlus));

const handleDrop = ($event: DragEvent) => {
  if (props.loading) {
    return;
  }

  const file = $event.dataTransfer?.files?.[0];

  if (!file) {
    return;
  }

  pickFile(file);
};

const handleFilePick = ($event: Event) => {
  if (props.loading || isResettingInputFileField.value) {
    return;
  }

  isResettingInputFileField.value = true;

  // For now we are going to allow only one file at a time
  const file: File | undefined = ($event.target as { files?: Array<File> } | undefined)?.files?.[0];

  if (file) {
    pickFile(file);
  }

  if (input.value) {
    input.value.value = null as unknown as string;
  }
  isResettingInputFileField.value = false;
};

const openPickDialog = ($event: MouseEvent) => {
  if (props.loading) {
    return;
  }

  focus();

  /**
   * User clicks the avatar.
   */
  emit('openPickDialog', $event);

  input.value?.click();
};

const focus = () => {
  if (props.loading) {
    return;
  }

  isFocused.value = true;
};

const unfocus = () => {
  if (props.loading) {
    return;
  }

  isFocused.value = false;
};

const pickFile = (file: File) => {
  if (props.loading) {
    return;
  }

  isFocused.value = false;

  /**
   * User chooses a new avatar.
   */
  emit('pickFile', file);
};
</script>

<style lang="scss" scoped>
@import '@caff/cds-scss-core';

.c-update-user-avatar {
  cursor: pointer;
  position: relative;

  &:hover {
    .c-update-user-avatar__overlay {
      background: rgba(255, 255, 255, 0.2);
    }
  }
}

.c-update-user-avatar--loading {
  cursor: progress;

  &,
  &:hover,
  &.c-update-user-avatar--focused {
    .c-update-user-avatar__overlay {
      background: none;
    }
  }
}

.c-update-user-avatar--focused {
  .c-update-user-avatar__overlay {
    border-color: #fff;
    background: rgba(255, 255, 255, 0.2);
  }
}

.c-update-user-avatar__avatar {
  opacity: 0.5;
}

.c-update-user-avatar__file-input {
  display: none;
}

.c-update-user-avatar__overlay {
  align-items: center;
  border: 1px dashed transparent;
  border-radius: var(--spacing-l);
  bottom: var(--spacing-xxs);
  display: flex;
  justify-content: center;
  left: var(--spacing-xxs);
  position: absolute;
  right: var(--spacing-xxs);
  top: var(--spacing-xxs);
}

.c-update-user-avatar__icon {
  color: var(--colors-readable-over-color-500);
}
</style>
