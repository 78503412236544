<template>
  <DropdownMenuEntry v-if="isActionAllowed" @click="askForConfirmation($event)">
    <FontAwesomeIcon :icon="faSpeakerOn" class="u-margin-r-xs" aria-hidden fixed-width />

    {{ t('menuEntryLabel') }}

    <ModalTransitionAnimation>
      <ActionConfirmationModal
        v-if="isAskingForConfirmation"
        :title="t('confirmationModal.title')"
        :description="t('confirmationModal.description')"
        :cancelButtonLabel="t('confirmationModal.cancelButtonLabel')"
        :confirmButtonLabel="t('confirmationModal.confirmButtonLabel')"
        :loading="isExecuting"
        @close-modal="closeModal()"
        @confirm-action="confirmAction()"
      >
        <ThreadActionPreview :threadSlug="threadSlug" />
      </ActionConfirmationModal>
    </ModalTransitionAnimation>
  </DropdownMenuEntry>
</template>

<script lang="ts">
export const isAllowed = (
  currentUser: LoggedInUser | null,
  thread: Pick<ShallowThread, 'notificationFrequency'>,
): boolean =>
  !!currentUser && thread.notificationFrequency.name !== NotificationFrequencyType.immediate;
</script>

<script lang="ts" setup>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import { DropdownMenuEntry } from '@caff/cds-dropdown-menu';
import { faSpeakerOn } from '@caff/cds-icons';
import { ModalTransitionAnimation } from '@caff/cds-modal';
import { NotificationFrequencyType } from '@caff/notification-frequency-api-model';
import { ShallowThread } from '@caff/shallow-thread-api-model';

import {
  AskingForConfirmationEmits,
  useCurrentlyLoggedInUser,
  useEnableNotificationsInThread,
  useIsAskingForConfirmation,
  useThread,
} from '../../composition';
import { LoggedInUser } from '../../models/User';
import { useToastrStore } from '../../store';
import { getRetryableFunction } from '../../utils/retryable';
import { MenuEntryProps } from './menuEntry.mixin';

import ActionConfirmationModal from '../common/ActionConfirmationModal.vue';
import ThreadActionPreview from './ThreadActionPreview.vue';

const props = defineProps<MenuEntryProps>();
const emit = defineEmits<AskingForConfirmationEmits>();

const { t } = useI18n();

const threadSlug = computed(() => props.threadSlug);

const { isExecuting, enableNotificationsInThread } = useEnableNotificationsInThread(threadSlug);

const { thread } = useThread(threadSlug);

const { currentlyLoggedInUser } = useCurrentlyLoggedInUser();
const isActionAllowed = computed(() =>
  thread.value ? isAllowed(currentlyLoggedInUser.value, thread.value) : null,
);

const { isAskingForConfirmation, askForConfirmation, closeModal, confirmAction } =
  useIsAskingForConfirmation({
    emit,
    async performAction() {
      await getRetryableFunction(async () => {
        await enableNotificationsInThread();

        const toastrStore = useToastrStore();
        toastrStore.showSuccessToastr({
          message: t('actionSuccessful', {
            // All data of post should be available, we want this to fail if it isn't!
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            username: thread.value!.authorCanonicalUsername,
          }),
        });
      });
    },
  });
</script>

<i18n lang="json" locale="es">
{
  "menuEntryLabel": "Activar notificaciones del hilo",
  "confirmationModal": {
    "title": "¿Quieres volver a recibir notificaciones de las respuestas de este hilo?",
    "description": "Si reactivas las notificaciones en el hilo volverás a recibir notificaciones de menciones y nuevas respuestas tanto en este hilo como en sus respuestas. Puedes silenciarlo de nuevo en cualquier momento.",
    "cancelButtonLabel": "Cancelar",
    "confirmButtonLabel": "Reactivar notificaciones del hilo"
  },
  "actionSuccessful": "Recibirás notificaciones cuando alguien te mencione en las respuestas al hilo de {'@'}{username}"
}
</i18n>
