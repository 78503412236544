<template>
  <Toastr :variant="ToastrVariant.progress">
    {{ t('message') }}
  </Toastr>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n';

import Toastr from './Toastr.vue';
import { ToastrVariant } from './Toastr.constants';

const { t } = useI18n();
</script>

<i18n lang="json" locale="es">
{
  "message": "Estamos procesando tu nuevo avatar, estará listo enseguida…"
}
</i18n>
