export const enum X_AXIS_RELATIVE_POSITION {
  none = 'none',
  left = 'left',
  right = 'right',
}

export const enum Y_AXIS_RELATIVE_POSITION {
  none = 'none',
  above = 'above',
  below = 'below',
}

export interface Offset {
  top: number;
  left: number;
}

export interface RelativePosition {
  x: X_AXIS_RELATIVE_POSITION;
  y: Y_AXIS_RELATIVE_POSITION;
}

export interface Translation {
  x: number;
  y: number;
}
