<template>
  <div :class="{ 'c-activity-indicator--covering': covering }" class="c-activity-indicator">
    <FontAwesomeIcon :icon="faSpinnerThird" fixed-width spin />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import { faSpinnerThird } from '@caff/cds-icons';

export default defineComponent({
  name: 'ActivityIndicator',
  components: {
    FontAwesomeIcon,
  },
  props: {
    covering: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return { faSpinnerThird };
  },
});
</script>

<style lang="scss" scoped>
@import '@caff/cds-scss-core';

.c-activity-indicator {
  color: var(--colors-accent);
  cursor: progress;
}

.c-activity-indicator--covering {
  align-items: center;
  background: var(--colors-box-shadow);
  bottom: 0;
  display: flex;
  font-size: get-size-in-rem(50px);
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: calc(var(--z-index, 0) + 1);
}
</style>
