import { defineStore } from 'pinia';

import { ExternalUrl as RawExternalUrl } from '@caff/external-url-api-model';
import { ShallowPostWithReplies as RawShallowPostWithReplies } from '@caff/post-api-model';
import { ShallowThread } from '@caff/shallow-thread-api-model';
import { Thread as RawThread } from '@caff/thread-api-model';

type Store = ReturnType<typeof useExternalUrlsStore>;
type StorePatchCallback = Parameters<Store['$patch']>[0];
export type ExternalUrlState = Parameters<StorePatchCallback>[0];

const getExternalUrlsFromReply = (post: RawShallowPostWithReplies): Array<RawExternalUrl> =>
  post.replies
    ? [
        ...post.externalUrls,
        ...post.replies.reduce(
          (accum, reply) => [...accum, ...getExternalUrlsFromReply(reply)],
          [] as Array<RawExternalUrl>,
        ),
      ]
    : [...post.externalUrls];

const getExternalUrlsFromThread = (thread: RawThread | ShallowThread): Array<RawExternalUrl> =>
  'replies' in thread && (thread as RawThread).replies
    ? [
        ...thread.externalUrls,
        ...(thread as RawThread).replies.reduce(
          (accum, post) => [...accum, ...getExternalUrlsFromReply(post)],
          [] as Array<RawExternalUrl>,
        ),
      ]
    : [...thread.externalUrls];

const upsertExternalUrls = (externalUrls: Array<RawExternalUrl>) => {
  const externalUrlsStore = useExternalUrlsStore();

  externalUrlsStore.$patch((state) => {
    for (const metadata of externalUrls) {
      state.metadataByUrl[metadata.url] = metadata;
    }
  });
};

export const useExternalUrlsStore = defineStore('externalUrls', {
  state: () => ({
    metadataByUrl: {} as Record<string, RawExternalUrl>,
  }),
  actions: {
    upsertExternalUrlsFromThreads(threads: Array<RawThread | ShallowThread>) {
      const rawMetadata = threads.reduce(
        (accum, rawThread) => [...accum, ...getExternalUrlsFromThread(rawThread)],
        [] as Array<RawExternalUrl>,
      );

      upsertExternalUrls(rawMetadata);
    },

    upsertExternalUrlsFromPosts(posts: Array<RawShallowPostWithReplies>) {
      const rawMetadata = posts.reduce(
        (accum, rawPost) => [...accum, ...getExternalUrlsFromReply(rawPost)],
        [] as Array<RawExternalUrl>,
      );

      upsertExternalUrls(rawMetadata);
    },
  },
});
