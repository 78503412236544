<template>
  <span class="c-external-url-thumbnail">
    <ResponsiveImage :alt="alt ?? ''" :height="height" :image="image" :width="width" />
  </span>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue';

import { ResponsiveImage } from '@caff/cds-responsive-image';
import {
  imageHeightVueComponentProperty,
  imageWidthVueComponentProperty,
  s3BucketConfigForImageKindVueComponentProperty as s3BucketConfigForImageKind,
} from '@caff/image-frontend-model';
import { Image, ImageKind } from '@caff/image-isomorphic-model';

export default defineComponent({
  name: 'ExternalUrlThumbnail',
  components: {
    ResponsiveImage,
  },
  props: {
    alt: {
      type: null as unknown as PropType<string | null>,
      required: false,
    },
    height: imageHeightVueComponentProperty,
    idThumbnail: {
      type: String,
      required: true,
    },
    s3BucketConfigForImageKind,
    width: imageWidthVueComponentProperty,
  },
  setup(props) {
    const image = computed(
      () =>
        new Image(
          {
            idImage: props.idThumbnail,
            kind: ImageKind.external_url_thumbnail,
          },
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          props.s3BucketConfigForImageKind!,
        ),
    );

    return {
      image,
    };
  },
});
</script>

<style lang="scss" scoped>
@import '@caff/cds-scss-core';

.c-external-url-thumbnail {
  align-items: center;
  display: flex;
  justify-content: center;
  overflow: hidden;
}
</style>
