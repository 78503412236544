import { Username } from '@caff/user-api-model';

import { SourceRange } from './SourceRange';

export const enum MarkdownNodeType {
  paragraph = 'paragraph',
  link = 'link',
  image = 'image',
  strong = 'strong',
  emphasis = 'emphasis',
  strikethrough = 'strikethrough',
  heading = 'heading',
  orderedList = 'orderedList',
  unorderedList = 'unorderedList',
  listItem = 'listItem',
  blockquote = 'blockquote',
  code = 'code',
  plainText = 'plainText',
  userMention = 'userMention',
}

export const enum MarkdownNodeHTMLTag {
  p = 'p',
  a = 'a',
  img = 'img',
  strong = 'strong',
  em = 'em',
  del = 'del',
  h1 = 'h1',
  h2 = 'h2',
  h3 = 'h3',
  h4 = 'h4',
  h5 = 'h5',
  h6 = 'h6',
  ul = 'ul',
  ol = 'ol',
  li = 'li',
  blockquote = 'blockquote',
}

export interface MarkdownBlockNode {
  type: MarkdownNodeType;
  tag: string;
  childNodes: Array<MarkdownNode>;
  sourceRange: SourceRange;
}

export interface MarkdownInlineNode {
  type: MarkdownNodeType.plainText;
  content: string;
  sourceRange: SourceRange;
}

export interface MarkdownUserMentionNode {
  type: MarkdownNodeType.userMention;
  username: Username;
  sourceRange: SourceRange;
}

export interface MarkdownCodeBlockNode {
  type: MarkdownNodeType.code;
  content: string;
  sourceRange: SourceRange;
}

export type MarkdownNode =
  | MarkdownBlockNode
  | MarkdownInlineNode
  | MarkdownCodeBlockNode
  | MarkdownUserMentionNode;

export interface MarkdownBlockNodeWithTitle extends MarkdownBlockNode {
  title: string | null;
}

export interface MarkdownLinkBlockNode extends MarkdownBlockNodeWithTitle {
  href: string;
  title: string | null;
}

export interface MarkdownImageBlockNode extends MarkdownBlockNodeWithTitle {
  src: string;
  alt: string;
  title: string | null;
}
