import type { Ref } from 'vue';
import { computed } from 'vue';

import type { CategorySlug } from '@caff/category-api-model';
import type { PostId, PostOrder, ShallowPostWithParents } from '@caff/post-api-model';
import type {
  ThreadOrder,
  ThreadSlug,
  ShallowThread as RawShallowThread,
} from '@caff/shallow-thread-api-model';
import type { Username } from '@caff/user-api-model';

import { getCanonicalUsername } from '../models/User';
import { useFrontpageStore } from '../store';
import { setPost } from './post';
import { setThread } from './thread';
import type { UseAxiosFlatPaginatedQueryResult } from './useAxiosQuery';
import { useAxiosFlatPaginatedQuery } from './useAxiosQuery';
import { UseReachContentEndEmitHandlerProps, useReachContentEnd } from './useScroll';
import {
  useKeyInfixForCurrentlyLoggedInUser,
  useOnCurrentlyLoggedInUserChange,
} from './useSession';
import { useUser } from './useUser';

export const useFrontpage = ({
  emit,
}: {
  emit: UseReachContentEndEmitHandlerProps['emit'];
}): {
  contentContainer: Ref<Element | { $el: Element } | null>;
  sortBy: (threadOrder: ThreadOrder) => void;
} => {
  const { contentContainer } = useReachContentEnd({ emit });

  const frontpageStore = useFrontpageStore();

  const sortBy = (threadOrder: ThreadOrder) => frontpageStore.sortBy({ threadOrder });

  return {
    contentContainer,
    sortBy,
  };
};

export const useFrontpageThreadOrder = (): Ref<ThreadOrder> =>
  computed(() => {
    const frontpageStore = useFrontpageStore();

    return frontpageStore.threadOrder;
  });

export const useFrontpagePostOrder = (): Ref<PostOrder> =>
  computed(() => {
    const frontpageStore = useFrontpageStore();

    return frontpageStore.postOrder;
  });

export const useCategoryFrontpageThreadSlugs = ({
  categorySlug,
  threadOrder,
}: {
  categorySlug: Ref<CategorySlug | null>;
  threadOrder: Ref<ThreadOrder>;
}): UseAxiosFlatPaginatedQueryResult<Array<ThreadSlug>> & {
  threadSlugs: Ref<Array<ThreadSlug> | null>;
} => {
  const keyInfixForCurrentlyLoggedInUser = useKeyInfixForCurrentlyLoggedInUser();

  const { data, invalidate, ...rest } = useAxiosFlatPaginatedQuery<
    Array<ThreadSlug>,
    number,
    Error
  >({
    isEnabled: computed(() => !!categorySlug.value),
    getQueryKey: (cursor = 1) =>
      computed(() =>
        categorySlug.value
          ? `${keyInfixForCurrentlyLoggedInUser.value}/frontpage/category/${categorySlug.value}/${threadOrder.value}/${cursor}`
          : null,
      ),
    async queryPageFn({ axiosInstance, cursor = 1, setQueryData }) {
      const {
        data: shallowThreads,
      }: {
        data: Array<RawShallowThread>;
      } = await axiosInstance.get(
        `/frontpage/${categorySlug.value}/${cursor}?order=${threadOrder.value}`,
      );

      await Promise.all(
        shallowThreads.map((rawThread) =>
          setThread({
            setQueryData,
            thread: rawThread,
          }),
        ),
      );

      return {
        data: shallowThreads.map(({ slug }) => slug),
        nextCursor: shallowThreads.length > 0 ? cursor + 1 : null,
      };
    },
    keepPreviousData: {
      onReexecute: true,
    },
  });

  useOnCurrentlyLoggedInUserChange(async () => {
    await invalidate();
  });

  return { ...rest, invalidate, threadSlugs: data };
};

export const useGeneralFrontpageThreadSlugs = ({
  threadOrder,
}: {
  threadOrder: Ref<ThreadOrder>;
}): UseAxiosFlatPaginatedQueryResult<Array<ThreadSlug>> & {
  threadSlugs: Ref<Array<ThreadSlug> | null>;
} => {
  const keyInfixForCurrentlyLoggedInUser = useKeyInfixForCurrentlyLoggedInUser();

  const { data, invalidate, error, ...rest } = useAxiosFlatPaginatedQuery<
    Array<ThreadSlug>,
    number,
    Error
  >({
    getQueryKey: (cursor = 1) =>
      computed(
        () =>
          `${keyInfixForCurrentlyLoggedInUser.value}/frontpage/general/${threadOrder.value}/${cursor}`,
      ),
    async queryPageFn({ axiosInstance, cursor = 1, setQueryData }) {
      const {
        data: shallowThreads,
      }: {
        data: Array<RawShallowThread>;
      } = await axiosInstance.get(`/frontpage/${cursor}?order=${threadOrder.value}`);

      await Promise.all(
        shallowThreads.map((rawThread) =>
          setThread({
            setQueryData,
            thread: rawThread,
          }),
        ),
      );

      return {
        data: shallowThreads.map(({ slug }) => slug),
        nextCursor: shallowThreads.length > 0 ? cursor + 1 : null,
      };
    },
    keepPreviousData: {
      onReexecute: true,
    },
  });

  useOnCurrentlyLoggedInUserChange(async () => {
    await invalidate();
  });

  return { ...rest, invalidate, threadSlugs: data, error };
};

export const useAuthorFrontpageThreadSlugs = ({
  username,
  threadOrder,
}: {
  username: Ref<Username | null>;
  threadOrder: Ref<ThreadOrder>;
}): UseAxiosFlatPaginatedQueryResult<Array<ThreadSlug>> & {
  threadSlugs: Ref<Array<ThreadSlug> | null>;
} => {
  const keyInfixForCurrentlyLoggedInUser = useKeyInfixForCurrentlyLoggedInUser();

  const canonicalUsername = computed(() =>
    username.value ? getCanonicalUsername(username.value) : null,
  );

  const { user } = useUser(username);

  const { data, invalidate, ...rest } = useAxiosFlatPaginatedQuery<
    Array<ThreadSlug>,
    number,
    Error
  >({
    isEnabled: computed(() => !!canonicalUsername.value && !!user.value?.username),
    getQueryKey: (cursor = 1) =>
      computed(() =>
        canonicalUsername.value
          ? `${keyInfixForCurrentlyLoggedInUser.value}/frontpage/author/${canonicalUsername.value}/threads/${threadOrder.value}/${cursor}`
          : null,
      ),
    async queryPageFn({ axiosInstance, cursor = 1, setQueryData }) {
      const {
        data: shallowThreads,
      }: {
        data: Array<RawShallowThread>;
      } = await axiosInstance.get(
        `/user/${user.value?.username}/threads/${cursor}?order=${threadOrder.value}`,
      );

      await Promise.all(
        shallowThreads.map((rawThread) =>
          setThread({
            setQueryData,
            thread: rawThread,
          }),
        ),
      );

      return {
        data: shallowThreads.map(({ slug }) => slug),
        nextCursor: shallowThreads.length > 0 ? cursor + 1 : null,
      };
    },
    keepPreviousData: {
      onReexecute: true,
    },
  });

  useOnCurrentlyLoggedInUserChange(async () => {
    await invalidate();
  });

  return { ...rest, invalidate, threadSlugs: data };
};

export const useAuthorFrontpagePostIds = ({
  username,
  postOrder,
}: {
  username: Ref<Username | null>;
  postOrder: Ref<PostOrder>;
}): UseAxiosFlatPaginatedQueryResult<Array<PostId>> & {
  postIds: Ref<Array<PostId> | null>;
} => {
  const keyInfixForCurrentlyLoggedInUser = useKeyInfixForCurrentlyLoggedInUser();

  const canonicalUsername = computed(() =>
    username.value ? getCanonicalUsername(username.value) : null,
  );

  const { user } = useUser(username);

  const { data, invalidate, ...rest } = useAxiosFlatPaginatedQuery<
    Array<ThreadSlug>,
    number,
    Error
  >({
    isEnabled: computed(() => !!canonicalUsername.value && !!user.value?.username),
    getQueryKey: (cursor = 1) =>
      computed(() =>
        canonicalUsername.value
          ? `${keyInfixForCurrentlyLoggedInUser.value}/frontpage/author/${canonicalUsername.value}/posts/${postOrder.value}/${cursor}`
          : null,
      ),
    async queryPageFn({ axiosInstance, cursor = 1, setQueryData }) {
      const {
        data: shallowPostsWithParents,
      }: {
        data: Array<ShallowPostWithParents>;
      } = await axiosInstance.get(
        `/user/${user.value?.username}/posts/${cursor}?order=${postOrder.value}`,
      );

      await Promise.all(
        shallowPostsWithParents.map((rawShallowPost) =>
          setPost({
            setQueryData,
            post: rawShallowPost,
          }),
        ),
      );

      return {
        data: shallowPostsWithParents.map(({ uuid }) => uuid),
        nextCursor: shallowPostsWithParents.length > 0 ? cursor + 1 : null,
      };
    },
    keepPreviousData: {
      onReexecute: true,
    },
  });

  useOnCurrentlyLoggedInUserChange(async () => {
    await invalidate();
  });

  return { ...rest, invalidate, postIds: data };
};
