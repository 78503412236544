<template>
  <ClientOnly v-if="isTwitterEmbed">
    <MarkdownTwitterPreviewNode :darkMode="darkMode" :url="url" />
  </ClientOnly>
  <ClientOnly v-else-if="isYoutubeEmbed">
    <MarkdownYoutubePreviewNode :embedHtml="embedHtml!" />
  </ClientOnly>
  <div v-else-if="isHtmlEmbed" v-html="embedHtml" />
  <a v-else :href="url" class="c-markdown-external-url-preview__link" target="_blank">
    <ExternalUrlThumbnail
      v-if="isImageEmbed"
      :height="thumbnailHeight"
      :idThumbnail="idThumbnail!"
      :s3BucketConfigForImageKind="s3BucketConfigForImageKind"
      :width="thumbnailWidth"
      class="c-markdown-external-url-preview__thumbnail"
    />
    <span v-else class="c-markdown-external-url-preview">
      <ExternalUrlThumbnail
        v-if="idThumbnail"
        :height="thumbnailHeight"
        :idThumbnail="idThumbnail"
        :s3BucketConfigForImageKind="s3BucketConfigForImageKind"
        :width="thumbnailWidth"
        alt=""
        class="c-markdown-external-url-preview__thumbnail"
      />

      <span
        v-if="title"
        class="c-markdown-external-url-preview__title"
        role="heading"
        aria-level="3"
      >
        {{ title }}
      </span>

      <span v-if="summary" class="c-markdown-external-url-preview__summary">
        {{ summary }}
      </span>

      <span v-if="website" class="c-markdown-external-url-preview__website">
        {{ website }}
      </span>
    </span>
  </a>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue';
import { ClientOnly } from '@caff/client-only-frontend-component';

import { ExternalUrl, ExternalUrlService } from '@caff/external-url-api-model';
import { ExternalUrlThumbnail } from '@caff/external-url-thumbnail-frontend-component';
import { s3BucketConfigForImageKindVueComponentProperty as s3BucketConfigForImageKind } from '@caff/image-frontend-model';
import { ImageLocation, ImageSizeForLocation } from '@caff/image-isomorphic-model';

import { darkModeVueProp, featuresVueProp, nodeVueProp } from './props';

import MarkdownTwitterPreviewNode from './MarkdownTwitterPreviewNode.vue';
import MarkdownYoutubePreviewNode from './MarkdownYoutubePreviewNode.vue';

export default defineComponent({
  name: 'MarkdownLinkPreviewNode',
  components: {
    ClientOnly,
    ExternalUrlThumbnail,
    MarkdownTwitterPreviewNode,
    MarkdownYoutubePreviewNode,
  },
  props: {
    darkMode: darkModeVueProp,
    externalUrl: {
      type: Object as PropType<ExternalUrl>,
      required: true,
    },
    features: featuresVueProp,
    node: nodeVueProp,
    s3BucketConfigForImageKind,
  },
  setup(props) {
    const url = computed(() => props.externalUrl.url);
    const idThumbnail = computed(() => props.externalUrl.idThumbnail || null);
    const title = computed(() => props.externalUrl.title || null);
    const summary = computed(() => props.externalUrl.summary || null);
    const website = computed(() => props.externalUrl.website || null);
    const embedHtml = computed(() => props.externalUrl.embedHtml || null);
    const service = computed(() => props.externalUrl.service);

    const isGenericEmbed = computed(
      () => !embedHtml.value || service.value === ExternalUrlService.generic,
    );

    const isImageEmbed = computed(
      () => isGenericEmbed.value && !!idThumbnail.value && !title.value && !summary.value,
    );

    const isHtmlEmbed = computed(
      () => !!(embedHtml.value && service.value !== ExternalUrlService.generic),
    );

    const isTwitterEmbed = computed(
      () => !!(embedHtml.value && service.value === ExternalUrlService.twitter),
    );

    const isYoutubeEmbed = computed(
      () => !!(embedHtml.value && service.value === ExternalUrlService.youtube),
    );

    const thumbnailLocation = computed(() =>
      isImageEmbed.value ? ImageLocation.fullBodyEmbedThumbnail : ImageLocation.frontpageThumbnail,
    );

    const thumbnailHeight = computed(() => ImageSizeForLocation[thumbnailLocation.value].height);
    const thumbnailWidth = computed(() => ImageSizeForLocation[thumbnailLocation.value].width);

    return {
      embedHtml,
      isHtmlEmbed,
      isImageEmbed,
      isTwitterEmbed,
      isYoutubeEmbed,
      idThumbnail,
      title,
      summary,
      thumbnailHeight,
      thumbnailWidth,
      url,
      website,
    };
  },
});
</script>

<style lang="scss" scoped>
@import '@caff/cds-scss-core';

$border-radius: 8px;
$transition: all 300ms ease-in-out;

.c-markdown-external-url-preview__link {
  text-decoration: none;

  @include t-focus-visible {
    outline: none;

    .c-markdown-external-url-preview {
      &::before {
        opacity: 1;
      }
    }
  }
}

.c-markdown-external-url-preview {
  --focus-ring-size: 3px;
  --padding: var(--spacing-xs);

  @include t-responsive--starting-at($responsive-breakpoint-tablet-medium) {
    --padding: var(--spacing-m);
  }

  --border-radius: #{$border-radius};

  background-color: var(--colors-background);
  border-radius: var(--border-radius);
  display: grid;
  grid-template-columns: [start] fit-content(#{get-size-in-rem(120px)}) [separator] 1fr [end];
  grid-template-rows: [start] auto [line2] auto [line3] auto [line4] auto [end];
  padding: var(--padding);
  position: relative;
  transition: $transition;
  transition-property: background-color, box-shadow, opacity, transform;

  &:hover {
    background-color: var(--colors-background-hover);
  }

  &::before {
    @include t-focus-ring(
      (
        'border-radius': var(--border-radius),
        'focus-ring-size': var(--focus-ring-size),
        'transition': $transition,
        'color': var(--colors-background-hover),
      )
    );
  }

  .c-markdown-external-url-preview__thumbnail {
    @extend %t-margin-r-m;

    grid-column-end: separator;
    grid-column-start: start;
    grid-row-end: line4;
    grid-row-start: start;
    width: calc(100% - var(--spacing-m));
  }
}

.c-markdown-external-url-preview__thumbnail {
  --border-radius: #{$border-radius};

  border-radius: var(--border-radius);
}

.c-markdown-external-url-preview__title {
  --line-height: 22px;

  @include t-font($font-display-name);
  @include t-text-max-lines(6, var(--line-height));

  grid-column-end: end;
  grid-column-start: separator;
  grid-row-end: line2;
  grid-row-start: start;
  margin: 0;
}

.c-markdown-external-url-preview__summary {
  @include t-font($font-body-soft);
  @include t-text-max-lines(2, var(--line-height));

  grid-column-end: end;
  grid-column-start: separator;
  grid-row-end: line3;
  grid-row-start: line2;
  margin: 0;
}

.c-markdown-external-url-preview__website {
  @include t-font($font-body-soft);

  color: var(--colors-orange-500);
  grid-column-end: end;
  grid-column-start: separator;
  grid-row-end: line4;
  grid-row-start: line3;
}
</style>
