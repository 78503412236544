import type { IconDefinition, IconName, IconPrefix } from '@fortawesome/fontawesome-common-types';

export const prefix = 'caff' as IconPrefix;
export const iconName = 'globe-world' as IconName;
export const width = 14;
export const height = 14;
export const ligatures = [];
export const unicode = 'f825';
export const svgPathData =
  'm11.0058161 1.26369877c1.2119153.84508451 2.1460364 2.08243884 2.6207287 3.48209416.5001313 1.45816509.496061 3.08270142-.0106844 4.53832261-.4731659 1.37421626-1.3889709 2.59020186-2.5764648 3.42765466-1.22819626.8812079-2.75148526 1.3365665-4.26154596 1.2841621-1.47037583-.0422288-2.92090921-.5688166-4.07736742-1.4769899-1.15391432-.8969802-2.01782371-2.1623174-2.4238306-3.56706057-.43602494-1.49428851-.35563761-3.13510582.23403906-4.57648115.60951911-1.51158705 1.76699489-2.79371406 3.20786144-3.55586734.94938453-.50827178 2.01833249-.78962743 3.09491216-.8165928 1.48411289-.04375513 2.97992774.40193664 4.19235182 1.26075823zm-5.77278666 9.87613513.01593461-1.80687543-1.74817001-1.74918757-.96326524.00007471c.20775248 1.60359847 1.2596839 2.94235909 2.69550064 3.55598829zm4.09807185-5.89041181-.58223576.00108116-1.16561626 1.16561627v1.1671426l1.16663382 1.16663383 1.16714261.00101756.5555492 1.11131512c.6418579-.77783782 1.0274251-1.7750062 1.0274251-2.86222863 0-.52260414-.0890859-1.02440116-.2529001-1.49103341l-.1629316.32307284-.7790372-.38968822c-.1299275-.06459629-.2604015-.1279112-.39217564-.1882865zm-2.33110129-2.74942209c-2.09960319 0-3.86335986 1.43792992-4.36021332 3.38273308l.56890372-.33968101 1.45721113-.87764647.00050878-1.16561626h1.11321186c.0340883-.00585098.0604177.00864927.0838852.02919128l.55310807.55438002 1.16663383-.58408008.00059634-.96175176c-.19111334-.02475948-.38598826-.0375288-.58384561-.0375288zm2.18408584.56462871-.43471153.43617819.00050878 1.16612504 1.16816017-.5835713.69169004.22990434c-.380155-.51061038-.86627193-.93752486-1.42564746-1.24863627z';

export const definition = {
  prefix,
  iconName,
  icon: [width, height, ligatures, unicode, svgPathData],
} as IconDefinition;

export const faGlobeWorld = definition;
