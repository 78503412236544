<template>
  <div class="c-modal-banner">
    <!-- @slot Use this slot to customize the image rendered inside the banner -->
    <slot classes="c-modal-banner__image">
      <Happy :progress="progress" class="c-modal-banner__image" />
    </slot>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import { Happy } from './images';

export default defineComponent({
  name: 'ModalBanner',
  components: {
    Happy,
  },
  props: {
    progress: {
      type: Number,
      default: 0,
      validator: (value: number): boolean => {
        if (value < 0 || value > 1) {
          console.warn(`[ModalBanner] progress must be a value in 0-1 range, but found ${value}`);
          return false;
        }

        return true;
      },
    },
  },
});
</script>

<style lang="scss" scope>
@import '@caff/cds-scss-core';

.c-modal-banner {
  --fill-color: var(--colors-blue-500);
  --fill-color--highlighted: var(--colors-red-500);
}

.c-modal-banner__image {
  height: auto;
  width: 100%;
}
</style>
