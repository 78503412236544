<template>
  <DropdownMenu v-if="currentlyLoggedInUser" popup-class="c-main-navbar-user-button__popup">
    <template #toggleButton="{ togglePopover }">
      <button class="c-main-navbar-user-button" @click="togglePopover()">
        <UserAvatar
          :s3BucketConfigForImageKind="s3BucketConfigForImageKind"
          :user="currentlyLoggedInUser"
        />
      </button>
    </template>

    <DropdownMenuEntry :to="currentlyLoggedInUser.route">
      <FontAwesomeIcon :icon="faUser" class="u-margin-r-xs" aria-hidden fixed-width />

      {{ t('entry.myProfile') }}
    </DropdownMenuEntry>

    <DropdownMenuEntry :to="notificationsRoute">
      <FontAwesomeIcon :icon="faBell" class="u-margin-r-xs" aria-hidden fixed-width />

      {{ t('entry.notifications') }}
    </DropdownMenuEntry>

    <DropdownMenuEntry :to="settingsRoute">
      <FontAwesomeIcon :icon="faGear" class="u-margin-r-xs" aria-hidden fixed-width />

      {{ t('entry.settings') }}
    </DropdownMenuEntry>

    <DropdownMenuEntrySeparator />

    <template v-if="currentlyLoggedInUser.canManageUsers">
      <DropdownMenuEntry :to="userManagerShowingOnlyActivatedRoute">
        <FontAwesomeIcon :icon="faUsersMany" class="u-margin-r-xs" aria-hidden fixed-width />

        {{ t('entry.manageUsers') }}
      </DropdownMenuEntry>

      <DropdownMenuEntrySeparator />
    </template>

    <DropdownMenuEntry @click="logout()">
      <FontAwesomeIcon :icon="faLogout" class="u-margin-r-xs" aria-hidden fixed-width />

      {{ t('entry.logout') }}
    </DropdownMenuEntry>
  </DropdownMenu>
</template>

<script lang="ts" setup>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { useI18n } from 'vue-i18n';

import {
  DropdownMenu,
  DropdownMenuEntry,
  DropdownMenuEntrySeparator,
} from '@caff/cds-dropdown-menu';
import { faUser, faBell, faGear, faUsersMany, faLogout } from '@caff/cds-icons';
import { UserAvatar } from '@caff/user-avatar-frontend-component';

import { useS3ConfigForImageKind, useCurrentlyLoggedInUser, useLogout } from '../../composition';
import {
  notificationsRoute,
  settingsRoute,
  userManagerShowingOnlyActivatedRoute,
} from '../../router';

const { t } = useI18n();

const s3BucketConfigForImageKind = useS3ConfigForImageKind();
const { currentlyLoggedInUser } = useCurrentlyLoggedInUser();
const { logout } = useLogout();
</script>

<i18n lang="json" locale="es">
{
  "entry": {
    "myProfile": "Mi perfil",
    "notifications": "Notificaciones",
    "settings": "Cuenta de Caff",
    "manageUsers": "Administrar usuarios",
    "logout": "Cerrar sesión"
  }
}
</i18n>

<style lang="scss" scoped>
.c-main-navbar-user-button {
  background: transparent;
  border: 0;
  cursor: pointer;
  padding: 0;
}
</style>

<style lang="scss">
.c-main-navbar-user-button__popup.c-main-navbar-user-button__popup.c-main-navbar-user-button__popup {
  --vspace-to-toggle-button: 10;
}
</style>
