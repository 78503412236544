import { parseASTListRecursive } from './ASTList';
import {
  MarkdownLinkBlockNode,
  MarkdownNodeHTMLTag,
  MarkdownNodeType,
  ParseTokenFunction,
} from './types';

export const parseLinkToken: ParseTokenFunction = (params) => {
  const token = params.tokens[params.startingNodeIndex];

  const isLinkifiedUrl = params.sourceText[params.startingCharacterAbsolutePosition] !== '[';

  const childrenStartingCharacterAbsolutePosition = isLinkifiedUrl
    ? params.startingCharacterAbsolutePosition
    : params.startingCharacterAbsolutePosition + 1;

  const childrenMetadata = parseASTListRecursive({
    sourceText: params.sourceText,
    tokens: params.tokens,
    startingNodeIndex: params.startingNodeIndex + 1,
    // +1 due to the `[` symbol used to open the link tag
    startingCharacterAbsolutePosition: childrenStartingCharacterAbsolutePosition,
    mentions: params.mentions,
    tokenTypeParsers: params.tokenTypeParsers,
  });

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const href = token.attrGet('href')!;
  const title = token.attrGet('title');

  const sourceRangeLength = isLinkifiedUrl
    ? childrenMetadata.parsedCharactersLength
    : // +4 due to `[`, `]`, `(` and `)`
      1 + childrenMetadata.parsedCharactersLength + 1 + 1 + href.length + 1;

  const node: MarkdownLinkBlockNode = {
    type: MarkdownNodeType.link,
    tag: MarkdownNodeHTMLTag.a,
    childNodes: childrenMetadata.nodes,
    href,
    title,
    sourceRange: {
      absoluteStart: params.startingCharacterAbsolutePosition,
      length: sourceRangeLength,
    },
  };

  return {
    nodes: [node],
    nodesAdvanced: 1 + childrenMetadata.parsedNodesLength + 1,
    charactersAdvanced: node.sourceRange.length,
  };
};
