<template>
  <component
    :is="tag"
    :class="{
      'c-content-with-sidebar-grid': true,
      'c-content-with-sidebar-grid--sidebar-before-content': sidebarBeforeContent,
    }"
  >
    <div class="c-content-with-sidebar-grid__main">
      <!-- @slot Use this slot to render content in the main, wider, column -->
      <slot name="main" />
    </div>
    <div class="c-content-with-sidebar-grid__sidebar">
      <!-- @slot Use this slot to render content in the secondary, narrow, column -->
      <slot name="sidebar" />
    </div>
  </component>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ContentWithSidebarGrid',
  props: {
    tag: {
      type: String,
      default: 'div',
    },

    sidebarBeforeContent: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<style lang="scss" scoped>
@import '@caff/cds-scss-core';

.c-content-with-sidebar-grid {
  @extend %t-responsive-container;
  @extend %t-padding-v-m;
  @include t-width-main-column;
  @include t-width-sidebar-column;

  display: grid;
  grid-template-columns:
    [start] var(--main-column-width) [before-gap] var(--spacing-m) [after-gap] var(
      --sidebar-column-width
    )
    [end];
  grid-template-rows: [start] auto [end];

  .c-content-with-sidebar-grid__main {
    grid-column-end: before-gap;
    grid-column-start: start;
    grid-row-end: end;
    grid-row-start: start;
  }

  .c-content-with-sidebar-grid__sidebar {
    grid-column-end: end;
    grid-column-start: after-gap;
    grid-row-end: end;
    grid-row-start: start;
  }

  @include t-responsive--smaller-than($responsive-breakpoint-tablet-medium) {
    grid-template-columns: [start] auto [end];
    grid-template-rows: [start] auto [before-gap] var(--spacing-m) [after-gap] auto [end];

    .c-content-with-sidebar-grid__main {
      grid-column-end: end;
      grid-column-start: start;
      grid-row-end: before-gap;
      grid-row-start: start;
    }

    .c-content-with-sidebar-grid__sidebar {
      grid-column-end: end;
      grid-column-start: start;
      grid-row-end: end;
      grid-row-start: after-gap;
    }
  }

  &.c-content-with-sidebar-grid--sidebar-before-content {
    @include t-responsive--smaller-than($responsive-breakpoint-tablet-medium) {
      .c-content-with-sidebar-grid__main {
        grid-column-end: end;
        grid-column-start: start;
        grid-row-end: end;
        grid-row-start: after-gap;
      }

      .c-content-with-sidebar-grid__sidebar {
        grid-column-end: end;
        grid-column-start: start;
        grid-row-end: before-gap;
        grid-row-start: start;
      }
    }
  }
}
</style>
