<template>
  <div v-html="cookieLessEmbedHtml" class="c-markdown-youtube-preview" />
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';

export default defineComponent({
  name: 'MarkdownYoutubePreviewNode',
  props: {
    embedHtml: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const cookieLessEmbedHtml = computed(() =>
      props.embedHtml
        .replace('https://www.youtube.com/embed', 'https://www.youtube-nocookie.com/embed')
        .replace(/\s+height="\d+"\s+/, ' ')
        .replace(/\s+width="\d+"\s+/, ' '),
    );

    return { cookieLessEmbedHtml };
  },
});
</script>

<style lang="scss" scoped>
.c-markdown-youtube-preview {
  aspect-ratio: 16 / 9;
  width: 100%;

  iframe {
    height: 100%;
    width: 100%;
  }
}
</style>
