<template>
  <component
    :is="tag"
    :class="{
      'c-avatar--rounded': rounded,
      'c-avatar--colored': colorStyles,
      'c-avatar--large': large,
    }"
    :style="colorStyles"
    :to="to"
    class="c-avatar"
  >
    <ResponsiveImage v-if="image" :alt="alt" :height="height" :image="image" :width="width" />
    <div v-else-if="initials" class="c-avatar__initials">
      {{ initials }}
    </div>
  </component>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue';
import type { RouteLocationRaw } from 'vue-router';

import { cdsColorVueComponentProperty, rgbColorVueComponentProperty } from '@caff/cds-color';
import { Image, ImageLocation, ImageSizeForLocation } from '@caff/image-isomorphic-model';
import { ResponsiveImage } from '@caff/cds-responsive-image';

import { getInitialsFromName } from './initials';

export default defineComponent({
  name: 'Avatar',
  components: {
    ResponsiveImage,
  },
  props: {
    alt: {
      type: String,
      default: '',
    },
    image: {
      type: null as unknown as PropType<Image | null>,
      required: false,
    },
    large: {
      type: Boolean,
      default: false,
    },
    cdsColor: {
      ...cdsColorVueComponentProperty,
      required: false,
    },
    /**
     * @note Overrides `cdsColor`.
     */
    rgbColor: {
      ...rgbColorVueComponentProperty,
      required: false,
    },
    name: {
      type: String,
      required: true,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    to: {
      type: null as unknown as PropType<RouteLocationRaw | null>,
      required: false,
    },
  },
  setup(props) {
    const tag = computed(() => (props.to ? 'router-link' : 'span'));
    const initials = computed(() => getInitialsFromName(props.name));

    const colorStyles = computed((): Record<string, string> | null => {
      if (props.rgbColor) {
        return {
          '--color': `rgb(${props.rgbColor})`,
        };
      }

      if (props.cdsColor) {
        return {
          '--color': `var(--colors-${props.cdsColor}-500)`,
        };
      }

      return null;
    });

    const size = computed(() =>
      props.large
        ? ImageSizeForLocation[ImageLocation.largeAvatar]
        : ImageSizeForLocation[ImageLocation.smallAvatar],
    );

    const height = computed(() => size.value.height);
    const width = computed(() => size.value.width);

    return {
      tag,
      initials,
      colorStyles,
      height,
      width,
    };
  },
});
</script>

<style lang="scss" scoped>
@import '@caff/cds-scss-core';

.c-avatar {
  --border-radius: 6px;

  @include t-font($font-body-action);
  @extend %t-height-l;
  @extend %t-width-l;

  align-items: center;
  border-radius: var(--border-radius);
  display: flex;
  justify-content: center;
  overflow: hidden;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

.c-avatar--large {
  @extend %t-height-xl;
  @extend %t-width-xl;
}

.c-avatar--rounded {
  border-radius: 50%;
}

.c-avatar--colored {
  background-color: var(--color);
}

.c-avatar__initials {
  color: var(--colors-readable-over-color-500);
}
</style>
