import type { IconDefinition, IconName, IconPrefix } from '@fortawesome/fontawesome-common-types';

export const prefix = 'caff' as IconPrefix;
export const iconName = 'bell' as IconName;
export const width = 14;
export const height = 14;
export const ligatures = [];
export const unicode = 'f807';
export const svgPathData =
  'm11.7948387 1.06545283c.9317487.69583555 1.6387667 1.69143229 1.9727966 2.80606777.3592806 1.19615899.3022863 2.51496395-.173508 3.67108264-.2586387.63162677-.6395627 1.21239154-1.1117497 1.70477906-.5944722.61034409-1.2102272 1.2001269-1.8007315 1.8137175-.2488992.6889818-.465694 1.3898675-.70846085 2.0813744-.11182427.2972361-.33402992.5533498-.61900145.6961963-.35531261.1836082-.79792029.1868547-1.15395435.0032465-.13808494-.0670946-.25422346-.1649661-.36376507-.2706292l-7.43002596-7.4300952c-.30877928-.29002166-.46425109-.73551513-.38669554-1.15359362.07070179-.4429684.39427074-.83363191.81776008-.98297142.66337043-.2276165 1.32926594-.44765781 1.99371855-.67130635.06024081-.01695401.11146355-.04869767.15366819-.09450955l1.58682249-1.58610104c.85130736-.84481434 1.97315732-1.41475741 3.16029822-1.58970828 1.41547884-.21931987 2.91608839.1385178 4.06282829 1.00245049zm-10.43935342 8.49938267 3.05821348 3.0582135c-.49888055.3473767-1.13988613.4855338-1.73507984.353509-.72325053-.1489788-1.34766283-.694032-1.59403696-1.3898675-.24709556-.6655348-.1385178-1.4428938.27090332-2.021855z';

export const definition = {
  prefix,
  iconName,
  icon: [width, height, ligatures, unicode, svgPathData],
} as IconDefinition;

export const faBell = definition;
