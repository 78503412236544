<template>
  <div class="c-main-navbar-items-group">
    <slot />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'MainNavbarItemsGroup',
});
</script>

<style lang="scss" scoped>
.c-main-navbar-items-group {
  align-items: center;
  display: flex;
  flex-direction: row;

  &:deep(> *) {
    flex-grow: 0;
    flex-shrink: 1;
    height: var(--spacing-l);

    &:not(:last-child) {
      margin-right: var(--spacing-xs);
    }
  }
}
</style>
