import { parseASTListRecursive } from './ASTList';
import { ParseTokenFunction } from './types';

export const parseInlineToken: ParseTokenFunction = (params) => {
  const token = params.tokens[params.startingNodeIndex];

  const childrenMetadata = parseASTListRecursive({
    sourceText: params.sourceText,
    tokens: token.children || [],
    startingNodeIndex: 0,
    startingCharacterAbsolutePosition: params.startingCharacterAbsolutePosition,
    mentions: params.mentions,
    tokenTypeParsers: params.tokenTypeParsers,
  });

  return {
    nodes: childrenMetadata.nodes,
    nodesAdvanced: 1,
    charactersAdvanced: childrenMetadata.parsedCharactersLength,
  };
};
