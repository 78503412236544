import type { AxiosError } from 'axios';
import { ref } from 'vue';

import { QueryClient, UseMutationReturnValue } from '@caff/frontend-use-query';

import type { RawLoggedInUser } from '../models/User';
import { useToastrStore } from '../store';
import { passwordHasher } from '../utils/passwordHasher';
import { useAxiosMutation } from './useAxiosQuery';
import { useOpenSocket } from './useSocket';

export const enum LoginError {
  wrongLoginData = 'wrongLoginData',
  accountNotReadyYet = 'accountNotReadyYet',
  unexpected = 'unexpected',
}

interface UseLoginParams {
  usernameOrEmail: string;
  password: string;
  rememberLogin: boolean;
}

const loginMutationQueryKey = ref('login');

export const useLogin = (): Omit<
  UseMutationReturnValue<UseLoginParams, RawLoggedInUser | null, LoginError>,
  'mutate'
> & {
  login: UseMutationReturnValue<UseLoginParams, RawLoggedInUser | null, LoginError>['mutate'];
} => {
  const toastrStore = useToastrStore();
  const openSocket = useOpenSocket();

  const { mutate: login, ...rest } = useAxiosMutation<
    UseLoginParams,
    RawLoggedInUser | null,
    LoginError
  >({
    mutationKey: loginMutationQueryKey,
    async mutationFn({ axiosInstance, param: { password, rememberLogin, usernameOrEmail } }) {
      const hashedPassword = await passwordHasher.getPasswordHash(password);
      const challengeAnswer = await passwordHasher.getLoginChallengeAnswer({
        usernameOrEmail,
        hashedPassword,
      });

      try {
        const { data: rawLoggedInUserData }: { data: RawLoggedInUser } = await axiosInstance.post(
          '/session',
          {
            usernameOrEmail,
            secret: challengeAnswer,
            isLongTerm: rememberLogin,
          },
        );

        openSocket();

        return rawLoggedInUserData;
      } catch (error) {
        const axiosError = error as AxiosError;

        const loginError = axiosError.response
          ? axiosError.response.status === 512
            ? LoginError.accountNotReadyYet
            : LoginError.wrongLoginData
          : LoginError.unexpected;

        if (loginError === LoginError.unexpected) {
          throw error;
        }

        toastrStore.showSessionInvalidToastr();

        return null;
      }
    },
  });

  return {
    ...rest,
    login,
  };
};

export const useLogout = (): Omit<UseMutationReturnValue<void, void, Error>, 'mutate'> & {
  logout: UseMutationReturnValue<void, void, Error>['mutate'];
} => {
  const client = QueryClient.useDefaultClient();

  const { mutate: logout, ...rest } = useAxiosMutation<void, void, Error>({
    queryClient: client,
    mutationKey: ref('logout'),
    async mutationFn({ axiosInstance }) {
      try {
        await axiosInstance.delete('/session');
      } finally {
        client.invalidateQueries([loginMutationQueryKey.value]);
      }
    },
  });

  return {
    ...rest,
    logout,
  };
};

interface UseRegisterParams {
  email: string;
  username: string;
  password: string;
}

export const useRegister = (): Omit<
  UseMutationReturnValue<UseRegisterParams, void, Error>,
  'mutate'
> & {
  register: UseMutationReturnValue<UseRegisterParams, void, Error>['mutate'];
} => {
  const { mutate: register, ...rest } = useAxiosMutation<UseRegisterParams, void, Error>({
    mutationKey: ref('register'),
    async mutationFn({ axiosInstance, param: { email, username, password } }) {
      const hashedPassword = await passwordHasher.getPasswordHash(password);

      await axiosInstance.post('/user', {
        email,
        username,
        password: hashedPassword,
      });
    },
  });

  return { ...rest, register };
};

interface UseSendPasswordRecoveryEmail {
  email: string;
}

export const useSendPasswordRecoveryEmail = (): Omit<
  UseMutationReturnValue<UseSendPasswordRecoveryEmail, void, Error>,
  'mutate'
> & {
  sendPasswordRecoveryEmail: UseMutationReturnValue<
    UseSendPasswordRecoveryEmail,
    void,
    Error
  >['mutate'];
} => {
  const { mutate: sendPasswordRecoveryEmail, ...rest } = useAxiosMutation<
    UseSendPasswordRecoveryEmail,
    void,
    Error
  >({
    mutationKey: ref('sendPasswordRecoveryEmail'),
    async mutationFn({ axiosInstance, param: { email } }) {
      await axiosInstance.post('/user/send-reset-password-email', {
        email,
      });
    },
  });

  return { ...rest, sendPasswordRecoveryEmail };
};
