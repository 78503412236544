import { UserPermissionsDbEnum } from '@caff/db-types';

export const enum UserPermissions {
  delete_post = UserPermissionsDbEnum.delete_post,
  delete_thread = UserPermissionsDbEnum.delete_thread,
  permanently_delete_post = UserPermissionsDbEnum.permanently_delete_post,
  permanently_delete_thread = UserPermissionsDbEnum.permanently_delete_thread,
  activate_user = UserPermissionsDbEnum.activate_user,
  deactivate_user = UserPermissionsDbEnum.deactivate_user,
  override_avatar = UserPermissionsDbEnum.override_avatar,
  override_user_info = UserPermissionsDbEnum.override_user_info,
}

const userPermissionByDbEnum: Record<UserPermissionsDbEnum, UserPermissions> = {
  [UserPermissionsDbEnum.delete_post]: UserPermissions.delete_post,
  [UserPermissionsDbEnum.delete_thread]: UserPermissions.delete_thread,
  [UserPermissionsDbEnum.permanently_delete_post]: UserPermissions.permanently_delete_post,
  [UserPermissionsDbEnum.permanently_delete_thread]: UserPermissions.permanently_delete_thread,
  [UserPermissionsDbEnum.activate_user]: UserPermissions.activate_user,
  [UserPermissionsDbEnum.deactivate_user]: UserPermissions.deactivate_user,
  [UserPermissionsDbEnum.override_avatar]: UserPermissions.override_avatar,
  [UserPermissionsDbEnum.override_user_info]: UserPermissions.override_user_info,
};

export const getUserPermissionsByDbEnum = (
  dbEnumEntries: Array<UserPermissionsDbEnum>,
): Array<UserPermissions> =>
  dbEnumEntries.map((dbEnumValue) => userPermissionByDbEnum[dbEnumValue]);

export type UserPermissionsLookupTable = Partial<Record<UserPermissions, UserPermissions>>;
