<template>
  <svg
    height="93"
    viewBox=" 0 0 461 93"
    width="461"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <symbol id="symbol-0" height="93" viewBox=" 0 0 461 93" width="461">
      <path
        d="m23.880768 19.7477898-.7082556 9.7554888c-.0858047 1.1798135-1.0680157 2.0931851-2.2509453 2.0931851h-10.2466705c-1.18292964 0-2.16514062-.9133716-2.25094524-2.0931851l-.71154521-9.7554888 3.90105425.0022316c.6701598 1.6530226 2.2913152 2.8188813 4.1847715 2.8188813 1.8934562 0 3.5146116-1.1658587 4.1847714-2.8188813zm-1.9692325-19.7477898c.8230681 0 1.5808712.44806718 1.9775231 1.16925253l2.4825793 4.51378053c.6006857 1.09215588.2022705 2.46447516-.8898854 3.06516089-.2565254.14108895-.5372951.23051419-.8264582.26420427l-.0055447.17886795-.5229806 7.17122243h-4.1428478c-.6695966-1.6542229-2.2913681-2.821147-4.1856893-2.821147-1.8943213 0-3.5160928 1.1669241-4.1856894 2.821147h-4.14613739l-.51969102-7.17122243-.00390752-.17950334c-1.12609754-.12975503-1.99734788-1.08367782-1.99734788-2.24109203 0-.38029272.09609692-.75441922.27936711-1.08763774l2.48257929-4.51378053c.39665194-.72118535 1.15445507-1.16925253 1.97752315-1.16925253z"
      />
    </symbol>
    <symbol id="symbol-1" height="93" viewBox=" 0 0 461 93" width="461">
      <path
        d="m15.6725031 0c5.1332877 0 9.294639 4.1814863 9.294639 9.33961105 0 1.22650545-.2352809 2.39779185-.6629683 3.47077285-.285125.7153206-.8897399 1.6743536-1.8138446 2.8770989.4869075-.0339382 1.4179364.6170367 2.7930867 1.9529248 2.0627254 2.0038321 3.396297 4.9161681 4.0007193 8.737008.0369024.2332767.0554382.4690901.0554382.7052676 0 2.4928921-2.0208872 4.5137805-4.5137804 4.5137805h-18.0544672c-.23617453 0-.4719857-.0185363-.70526239-.055438-2.46226722-.3895059-4.14257164-2.7013243-3.75307978-5.1635934.5910818-3.736527 1.87948852-6.6042039 3.8652224-8.6030311 1.32382259-1.3325514 2.24262667-1.999666 2.75641224-2.0013438-.92891645-1.1668584-1.54533035-2.1178427-1.84924168-2.8529528-.455867-1.1026652-.70751477-2.3120494-.70751477-3.58049355 0-5.15812475 4.16135118-9.33961105 9.29464128-9.33961105z"
      />
    </symbol>
    <symbol id="symbol-2" height="93" viewBox=" 0 0 461 93" width="461">
      <path
        d="m15.7609105 0c8.3263991 0 15.1759557 6.46788339 15.7258152 14.7112925l.0350953-.0011094-.0001001 1.0909183.0283582 1.6263045-.186087-.088121c-.6688349 3.2919335-3.3864488 5.7767171-6.671899 5.7767171-1.5902758 0-3.0917851-.5880471-4.3017942-1.6440022-1.2918801 1.0535981-2.914887 1.6440022-4.6293884 1.6440022-4.0621046 0-7.35509158-3.2929868-7.35509158-7.3550915 0-4.0621048 3.29298698-7.35509158 7.35509158-7.35509158 3.926701 0 7.1347045 3.07711328 7.3442084 6.95153828l.0108831.4035533c0 1.2055193.7690353 2.1014547 1.5760911 2.1014547.7449746 0 1.457553-.7634006 1.5627723-1.8290204l.0133187-.2724343-.0000168-.018997c-.0102533-5.79427315-4.7105836-10.48827667-10.5072568-10.48827667-5.80300685 0-10.50727367 4.70426675-10.50727367 10.50727367 0 5.4486028 4.14721044 9.9285729 9.45717707 10.4554563l-.0006308-.0228254 1.0507274.0746427h1.0507273l.0021335.2682358c.8755468.4302232 1.4689101 1.3307539 1.4689101 2.3585827 0 1.2303813-.8502734 2.278348-2.0685892 2.56092l-.2665252.0486235-1.2373839.0919176.0003705-.1090024c-7.89444882-.5178074-14.16255376-6.8209415-14.67648634-14.6811765l-.02569217-.5262761-.00837509-.5190982c0-8.71010853 7.05358757-15.7609105 15.7609105-15.7609105zm0 13.6594558c-1.1606013 0-2.1014547.9408534-2.1014547 2.1014547s.9408534 2.1014547 2.1014547 2.1014547c1.00159 0 1.8492259-.70434 2.0513984-1.6158581l.0368115-.2319081.0132448-.2536885c0-1.1606013-.9408535-2.1014547-2.1014547-2.1014547z"
      />
    </symbol>
    <symbol id="symbol-3" height="93" viewBox=" 0 0 461 93" width="461">
      <path
        d="m11.8438111 32c-3.08005615 0-4.5695529-.1433563-5.98639126-.3583907-1.41683837-.2150345-2.39772647-.9318159-3.05165187-1.971149s-1.647958-2.4086257-2.43405566-5.1966654c-.78609766-2.7880398-1.34882351-5.1449441-.25430432-6.8811019.94455891-1.4982865 2.9426199-1.7919536 11.72640311-1.7919536 8.7355613.0012749 10.7270325.2969051 11.6695501 1.7919536 1.0945192 1.7361578.5317934 4.0930621-.2543043 6.8811019-.7860976 2.7880397-1.7801302 4.1573323-2.4340556 5.1966654s-1.6348135 1.7561145-3.0516519 1.971149c-1.408093.2137071-2.8879502.3566183-5.9295383.3583907zm-6.49682265-22.6048157c-.42144988-1.53130407.16019793-2.45909719.60896072-3.10299665.79166841-1.13591157 2.00456198-1.8111386 2.26185415-2.15485878.56112046-.74960858.83278385-1.90729118 1.20032632-2.6720249.51303182-1.06744869 1.51811336-1.46530397 2.34884866-1.46530397 1.5213029 0 3.9275761 1.42581411 5.3066586 3.79255146 1.5387985 2.64083678 1.1494236 4.76807287.3479776 6.2059933-.3883833.69682084-2.1627226 2.48887054-5.6546362 2.48887054-3.49191356 0-5.99853998-1.5609269-6.41998985-3.092231z"
      />
    </symbol>

    <use
      :class="{
        highlighted: 0 < firstNonHighlightedPathIndex,
      }"
      opacity="0.75"
      transform="matrix(.9961947 -.08715574 .08715574 .9961947 .046657 55.743446)"
      xlink:href="#symbol-0"
    />
    <use
      :class="{
        highlighted: 1 < firstNonHighlightedPathIndex,
      }"
      transform="matrix(.9781476 .20791169 -.20791169 .9781476 42.185524 1.67286)"
      xlink:href="#symbol-1"
    />
    <use
      :class="{
        highlighted: 2 < firstNonHighlightedPathIndex,
      }"
      transform="matrix(.89100652 -.4539905 .4539905 .89100652 57.131252 63.200573)"
      xlink:href="#symbol-2"
    />
    <use
      :class="{
        highlighted: 3 < firstNonHighlightedPathIndex,
      }"
      opacity="0.75"
      transform="matrix(.93358043 .35836795 -.35836795 .93358043 101.547751 0)"
      xlink:href="#symbol-0"
    />
    <use
      :class="{
        highlighted: 4 < firstNonHighlightedPathIndex,
      }"
      transform="matrix(.99862953 .05233596 -.05233596 .99862953 124.502238 53.49855)"
      xlink:href="#symbol-3"
    />
    <use
      :class="{
        highlighted: 5 < firstNonHighlightedPathIndex,
      }"
      transform="matrix(.98480775 -.17364818 .17364818 .98480775 148.614736 7.595613)"
      xlink:href="#symbol-1"
    />
    <use
      :class="{
        highlighted: 6 < firstNonHighlightedPathIndex,
      }"
      transform="matrix(.8480481 .52991926 -.52991926 .8480481 187.891135 48.335206)"
      xlink:href="#symbol-0"
    />
    <use
      :class="{
        highlighted: 7 < firstNonHighlightedPathIndex,
      }"
      transform="matrix(.91354546 -.40673664 .40673664 .91354546 205.727161 10.674612)"
      xlink:href="#symbol-3"
    />
    <use
      :class="{
        highlighted: 8 < firstNonHighlightedPathIndex,
      }"
      opacity="0.75"
      transform="matrix(.9781476 .20791169 -.20791169 .9781476 241.96679 51.367014)"
      xlink:href="#symbol-2"
    />
    <use
      :class="{
        highlighted: 9 < firstNonHighlightedPathIndex,
      }"
      opacity="0.75"
      transform="matrix(.99939083 -.0348995 .0348995 .99939083 262.151346 5.173242)"
      xlink:href="#symbol-0"
    />
    <use
      :class="{
        highlighted: 10 < firstNonHighlightedPathIndex,
      }"
      transform="matrix(.78801075 .61566148 -.61566148 .78801075 312.630491 47.929114)"
      xlink:href="#symbol-1"
    />
    <use
      :class="{
        highlighted: 11 < firstNonHighlightedPathIndex,
      }"
      transform="matrix(.98768834 -.15643447 .15643447 .98768834 332.265967 6.4847)"
      xlink:href="#symbol-3"
    />
    <use
      :class="{
        highlighted: 12 < firstNonHighlightedPathIndex,
      }"
      opacity="0.75"
      transform="matrix(.8480481 -.52991926 .52991926 .8480481 354.802005 65.078781)"
      xlink:href="#symbol-0"
    />
    <use
      :class="{
        highlighted: 13 < firstNonHighlightedPathIndex,
      }"
      transform="matrix(.9612617 .27563736 -.27563736 .9612617 398.982455 .869682)"
      xlink:href="#symbol-2"
    />
    <use
      :class="{
        highlighted: 14 < firstNonHighlightedPathIndex,
      }"
      transform="matrix(.76604444 -.64278761 .64278761 .76604444 415.532546 68.157414)"
      xlink:href="#symbol-1"
    />
  </svg>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';

export default defineComponent({
  name: 'Happy',
  props: {
    progress: {
      type: Number,
      default: 0,
      validator: (value: number): boolean => {
        if (value < 0 || value > 1) {
          console.warn(`[Happy] progress must be a value in 0-1 range, but found ${value}`);
          return false;
        }

        return true;
      },
    },
  },
  setup(props) {
    const firstNonHighlightedPathIndex = computed(() => Math.round(props.progress * 15));

    return { firstNonHighlightedPathIndex };
  },
});
</script>

<style lang="scss" scope>
use {
  fill: var(--fill-color);

  &.highlighted {
    fill: var(--fill-color--highlighted);
  }
}
</style>
