<template>
  <BorderedBox
    v-if="currentlyLoggedInUser"
    class="c-new-thread-composer-toggle-module"
    padded
    rounded
    @click="handleClick($event)"
  >
    <UserAvatar :s3BucketConfigForImageKind="s3ConfigForImageKind" :user="currentlyLoggedInUser" />

    <div class="c-new-thread-composer-toggle-module__input-placeholder">
      {{ t('newThreadCallToAction') }}
    </div>
  </BorderedBox>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n';

import { BorderedBox } from '@caff/cds-bordered-box';
import { UserAvatar } from '@caff/user-avatar-frontend-component';

import { useS3ConfigForImageKind, useCurrentlyLoggedInUser } from '../../composition';

const emit = defineEmits<{
  click: [MouseEvent];
}>();

const { t } = useI18n();

const s3ConfigForImageKind = useS3ConfigForImageKind();
const { currentlyLoggedInUser } = useCurrentlyLoggedInUser();

const handleClick = (event: MouseEvent) => {
  emit('click', event);
};
</script>

<i18n lang="json" locale="es">
{
  "newThreadCallToAction": "¿Qué está pasando?"
}
</i18n>

<style lang="scss" scoped>
@import '@caff/cds-scss-core';

.c-new-thread-composer-toggle-module {
  --border-radius: 0;

  @extend %t-margin-b-m;

  cursor: pointer;
  display: flex;
  flex-direction: row;
  flex-grow: 0;
  flex-shrink: 0;

  @include t-responsive--starting-at($responsive-breakpoint-tablet-medium) {
    --border-radius: 6px;
  }
}

.c-new-thread-composer-toggle-module__input-placeholder {
  @extend %t-margin-l-s;
  @include t-font($font-header-small-soft);

  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
  justify-content: center;
  user-select: none;
}
</style>
