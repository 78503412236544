<template>
  <Modal>
    <div class="u-padding-m">
      <ModalBanner :progress="progress" />

      <PasswordStep
        v-if="currentStep === passwordStep"
        :password="password"
        :repeatPassword="repeatPassword"
        :isLoading="isLoading"
        :isSubmitDisabled="isResetPasswordDisabled"
        @password-input="handlePasswordInput($event)"
        @repeat-password-input="handleRepeatPaswordInput($event)"
        @dismiss="dismiss()"
        @submit="saveNewPassword()"
      >
        <template v-if="passwordResetError" #passwordError>
          <span class="u-color-red-500" data-testid="password-error">
            {{ passwordResetError }}
          </span>
        </template>
        <template v-if="repeatPasswordError" #repeatPasswordError>
          <span class="u-color-red-500" data-testid="repeat-password-error">
            {{ repeatPasswordError }}
          </span>
        </template>
      </PasswordStep>
      <SuccessStep v-else-if="currentStep === successStep" @dismiss="dismiss()" />
    </div>
  </Modal>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import { Modal } from '@caff/cds-modal';
import { ModalBanner } from '@caff/cds-modal-banner';

import {
  PasswordStep,
  ResetPasswordModalStep,
  SuccessStep,
  allResetPasswordModalSteps,
} from './steps';

export default defineComponent({
  name: 'ResetPasswordModal',
  components: {
    PasswordStep,
    Modal,
    ModalBanner,
    SuccessStep,
  },
  props: {
    currentStep: {
      type: String as PropType<ResetPasswordModalStep>,
      required: true,
      validator: (value: string): boolean => {
        if (value in allResetPasswordModalSteps) {
          return true;
        }

        console.warn(
          `[ResetPasswordModal] Invalid value «${value}» for currentStep, expected a value in ResetPasswordModalStep enum`,
        );
        return false;
      },
    },
    password: {
      type: String,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
    passwordResetError: {
      type: null as unknown as PropType<string | null>,
      required: false,
    },
  },
  emits: ['password-input', 'dismiss', 'save-new-password'],
  setup(props, { emit }) {
    const { t } = useI18n();

    const progress = computed(() => {
      switch (props.currentStep) {
        case ResetPasswordModalStep.password:
          return 0.5;
        case ResetPasswordModalStep.success:
          return 1;
      }

      return 0;
    });

    const handlePasswordInput = (newPassword: string) => {
      /**
       * User changed password value.
       *
       * @event password-input
       * @type {string}
       */
      emit('password-input', newPassword);
    };

    const repeatPassword = ref('');

    const handleRepeatPaswordInput = (newRepeatPassword: string) => {
      repeatPassword.value = newRepeatPassword;
    };

    const dismiss = () => {
      /**
       * User wants to dismiss this Reset Password flow.
       *
       * @event dismiss
       * @type {void}
       */
      emit('dismiss');
    };

    const saveNewPassword = () => {
      /**
       * User wants to save a new password.
       *
       * @event save-new-password
       * @type {{ password: string }}
       */
      emit('save-new-password', { password: props.password });
    };

    const repeatPasswordError = computed((): string | null =>
      props.password && repeatPassword.value && props.password !== repeatPassword.value
        ? t('validationError.password.notMatching')
        : null,
    );

    const error = computed(
      (): string | null => repeatPasswordError.value || props.passwordResetError || null,
    );

    const isResetPasswordDisabled = computed(
      (): boolean => !props.password || !repeatPassword.value || !!error.value || props.isLoading,
    );

    return {
      t,
      dismiss,
      handlePasswordInput,
      handleRepeatPaswordInput,
      progress,
      repeatPassword,
      passwordStep: ResetPasswordModalStep.password,
      successStep: ResetPasswordModalStep.success,
      saveNewPassword,
      repeatPasswordError,
      error,
      isResetPasswordDisabled,
    };
  },
});
</script>

<i18n lang="json" locale="es">
{
  "validationError": {
    "password": {
      "notMatching": "¡Las contraseñas no coinciden!"
    }
  }
}
</i18n>
