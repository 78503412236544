<template>
  <div
    ref="containerElement"
    :class="{
      'c-popover': true,
      'c-popover--is-open': opened,
    }"
  >
    <!-- @slot Use this slot to customize the button used to toggle the popup -->
    <slot name="toggleButton" />

    <ClientOnly>
      <div v-if="opened" ref="popupElement" :class="allPopupClasses" :style="popupStyle">
        <!-- @slot Use this slot to customize the popup content -->
        <slot name="popoverContent" />
      </div>
    </ClientOnly>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref, watch, onBeforeUnmount, onUpdated, nextTick } from 'vue';
import { ClientOnly } from '@caff/client-only-frontend-component';

import { onScrollAnywhere } from '@caff/cds-scroll-anywhere';

import { onClickOutside } from './composition';
import { isDOMElementPartOfModal } from './dom';
import {
  removePopupFromDOM as _removePopupFromDOM,
  reattachPopupToDocumentBodyFactory,
  getPopupPosition as _getPopupPosition,
} from './popup';

import {
  Offset,
  RelativePosition,
  Translation,
  X_AXIS_RELATIVE_POSITION,
  Y_AXIS_RELATIVE_POSITION,
} from './axis';

enum AnimationType {
  enter = 'enter',
  leave = 'leave',
}

interface Animation {
  type: AnimationType;
  frame: number;
}

const createEnterAnimation = () => ({
  type: AnimationType.enter,
  frame: 0,
});

const createLeaveAnimation = () => ({
  type: AnimationType.leave,
  frame: 0,
});

export default defineComponent({
  name: 'Popover',
  components: {
    ClientOnly,
  },
  props: {
    opened: {
      type: Boolean,
      required: true,
    },

    /**
     * Additional CSS classes to be applied to the popup itself.
     */
    popupClass: {
      type: [String, Array, Object],
      default: '',
    },
  },
  emits: ['click-outside'],
  setup(props, { emit }) {
    const containerElement = ref(null as unknown as HTMLElement);
    const popupElement = ref(null as unknown as HTMLElement);

    /**
     * Max height available for the popup content.
     */
    const popupMaxHeight = ref(Number.MAX_SAFE_INTEGER as number);
    /**
     * Offset of the container (button's parent) relative to the document root.
     */
    const containerOffset = ref({
      top: 0,
      left: 0,
    } as Offset);
    /**
     * Translation to properly position the popup relative to the top left
     * anchor of the container.
     *
     * Initial value is far away of the canvas to avoid issues with SSR.
     */
    const popupTranslation = ref({
      x: -Number.MAX_SAFE_INTEGER,
      y: -Number.MAX_SAFE_INTEGER,
    } as Translation);
    /**
     * Position of the popup with respect to the toggle button which can be
     * `left`/`right` on the x-axis and `above`/`below` on the y-axis.
     */
    const popupPositionRelativeToButton = ref({
      x: X_AXIS_RELATIVE_POSITION.none,
      y: Y_AXIS_RELATIVE_POSITION.none,
    } as RelativePosition);
    /**
     * Current animation.
     * Animation could be enter or leave and includes information of current frame.
     */
    const currentAnimation = ref<Animation>({
      ...(props.opened ? createEnterAnimation() : createLeaveAnimation()),
      frame: 1,
    });

    const popupStyle = computed((): { [key: string]: string } => ({
      '--popup-transform-translate-x': `${containerOffset.value.left + popupTranslation.value.x}px`,
      '--popup-transform-translate-y': `${containerOffset.value.top + popupTranslation.value.y}px`,
      '--popup-max-height': `${popupMaxHeight.value}px`,
    }));

    const popupPositionCSSModifier = computed((): string => {
      const X_AXIS_RELATIVE_POSITION_MODIFIERS = {
        [X_AXIS_RELATIVE_POSITION.none]: null,
        [X_AXIS_RELATIVE_POSITION.left]: 'left',
        [X_AXIS_RELATIVE_POSITION.right]: 'right',
      };

      const Y_AXIS_RELATIVE_POSITION_MODIFIERS = {
        [Y_AXIS_RELATIVE_POSITION.none]: null,
        [Y_AXIS_RELATIVE_POSITION.below]: 'below',
        [Y_AXIS_RELATIVE_POSITION.above]: 'above',
      };

      const modifiers = [
        X_AXIS_RELATIVE_POSITION_MODIFIERS[popupPositionRelativeToButton.value.x],
        Y_AXIS_RELATIVE_POSITION_MODIFIERS[popupPositionRelativeToButton.value.y],
      ].filter((v) => !!v);

      const cssModifier = modifiers.length ? `--${modifiers.join('-')}-anchored` : '';

      return cssModifier;
    });

    const allPopupClasses = computed(
      (): Array<Record<string, boolean> | string | Array<unknown>> => {
        const currentAnimationType = currentAnimation.value && currentAnimation.value.type;
        const isEnterAnimation = currentAnimationType === AnimationType.enter;
        const isLeaveAnimation = currentAnimationType === AnimationType.leave;
        const currentFrame = currentAnimation.value ? currentAnimation.value.frame : -1;

        const isFirstFrame = currentFrame === 0;
        const isAfterFirstFrame = currentFrame > 0;

        const baseClasses = {
          'c-popover__popup': true,
          [`c-popover__popup${popupPositionCSSModifier.value}`]: true,
          'c-popover__popup--v-enter': isEnterAnimation && isFirstFrame,
          'c-popover__popup--v-enter-active': isEnterAnimation,
          'c-popover__popup--v-enter-to': isEnterAnimation && isAfterFirstFrame,
          'c-popover__popup--v-leave': isLeaveAnimation && isFirstFrame,
          'c-popover__popup--v-leave-active': isLeaveAnimation,
          'c-popover__popup--v-leave-to': isLeaveAnimation && isAfterFirstFrame,
        };

        return [baseClasses, props.popupClass];
      },
    );

    const _reattachPopupToDocumentBody = reattachPopupToDocumentBodyFactory();
    const removePopupFromDOM = () => _removePopupFromDOM(popupElement.value);
    const reattachPopupToDocumentBody = () =>
      _reattachPopupToDocumentBody({
        containerElement: containerElement.value,
        opened: props.opened,
        popupElement: popupElement.value,
        removalAnimationDurationInMs: 250,
        beforeEnqueuingPopupElementRemovalFromDOM: () => {
          if (currentAnimation.value.type !== AnimationType.leave) {
            currentAnimation.value = createLeaveAnimation();
          }
        },
        afterPopupElementAddedToDOM: () => {
          if (currentAnimation.value.type !== AnimationType.enter) {
            currentAnimation.value = createEnterAnimation();
          }
        },
      });

    const repositionPopup = () => {
      if (!containerElement.value || !popupElement.value) {
        return removePopupFromDOM();
      }

      const popupPosition = _getPopupPosition({
        containerElement: containerElement.value,
        popupElement: popupElement.value,
      });

      popupMaxHeight.value = popupPosition.maxHeight;
      containerOffset.value.top = popupPosition.containerOffset.top;
      containerOffset.value.left = popupPosition.containerOffset.left;
      popupTranslation.value.x = popupPosition.popupTranslation.x;
      popupTranslation.value.y = popupPosition.popupTranslation.y;
      popupPositionRelativeToButton.value.x = popupPosition.popupPositionRelativeToButton.x;
      popupPositionRelativeToButton.value.y = popupPosition.popupPositionRelativeToButton.y;
    };

    onScrollAnywhere(() => repositionPopup());

    onClickOutside(containerElement, ($event) => {
      const target = $event.target as HTMLElement;
      const isClickOnPopoverButton =
        target === containerElement.value || containerElement.value?.contains(target);
      const isClickOnPopoverContent =
        target === popupElement.value || popupElement.value?.contains(target);
      const isClickOnModal = isDOMElementPartOfModal(target);
      const isClickOnModalRenderingThisPopover = target.contains(containerElement.value);

      // click-outside will be wrongly received here if the user clicked on the
      // popup, as it's not attached in the DOM to the container element but to
      // the document body
      // click-outside will be wrongly received here also when the user clicks
      // on any modal since they are detached in the DOM from the component that
      // renders them, so interacting with a modal rendered by a popover will be
      // detected as clicking outside the popover. To prevent this situation we
      // ignore clicks on `dialog` (the backdrop of the modals) unless we click
      // on the backdrop of a modal that is rendering this specific popover
      if (
        isClickOnPopoverButton ||
        isClickOnPopoverContent ||
        (isClickOnModal && !isClickOnModalRenderingThisPopover)
      ) {
        return;
      }

      /**
       * User clicked outside toggle button or popup of this menu.
       *
       * @event click-outside
       * @type {PointerEvent}
       */
      emit('click-outside', $event);
    });

    onBeforeUnmount(() => {
      removePopupFromDOM();
    });

    onUpdated(() => {
      // We have to reposition the popup because this hook is the only way we have
      // to detect changes on the content of the popup, and those changes might
      // require repositioning the popup if it fitted in a position with the old
      // content but it no longer fits there wit the new one.
      repositionPopup();
    });

    watch(
      // The initial reattachment + repositioning happens when `popupElement`
      // gets bound to the actual HTML element. This happens in a different
      // render iteration than the initial mount since we are using `ClientOnly`
      // to prevent issues in SSR
      () => props.opened && popupElement.value,
      () => {
        if (!popupElement.value) {
          return;
        }

        reattachPopupToDocumentBody();
        repositionPopup();
      },
    );

    watch(currentAnimation, (newAnimation, oldAnimation) => {
      // If next animation is no animation we have to do nothing
      if (!newAnimation) {
        return;
      }

      // If the animation type did not change then what changed was the frame
      if (oldAnimation && oldAnimation.type === newAnimation.type) {
        return;
      }

      nextTick(() => {
        // This is just to ensure type-safety but it will never happen in real use cases
        if (!currentAnimation.value) {
          return;
        }

        currentAnimation.value.frame = 1;
      });
    });

    return {
      containerElement,
      popupElement,
      popupStyle,
      allPopupClasses,
      removePopupFromDOM,
      repositionPopup,
    };
  },
});
</script>

<style lang="scss" scoped>
@import '@caff/cds-scss-core';

.c-popover {
  display: inline-flex;
  user-select: none;
}

.c-popover__popup {
  @extend %t-z-index-popover;
  @include t-box-shadow($box-shadow-popup-base);

  // Use this variable to customize the space between toggle button popup
  --hspace-to-toggle-button: 0;
  --vspace-to-toggle-button: 5;
  --border-radius: 8px;
  --border: 1px solid var(--colors-popup-border);

  background: var(--colors-box-background);
  border: var(--border);
  border-radius: var(--border-radius);
  display: flex;
  flex-direction: column;
  left: 0;
  max-height: calc(var(--popup-max-height));
  overflow-y: auto;
  pointer-events: initial;
  position: absolute;
  top: 0;
  transform: translate(var(--popup-transform-translate-x), var(--popup-transform-translate-y));

  @mixin c-popover__popup--hidden {
    opacity: 0;
  }
  @mixin c-popover__popup--visible {
    opacity: 1;
  }
  @mixin c-popover__popup--do-not-animate {
    transition: none;
  }
  @mixin c-popover__popup--animate {
    transition: all 0.25s ease-in-out;
    transition-property: opacity;
  }

  @mixin c-popover__popup--translated-out-of-canvas {
    transform: translateY(100vh);
  }
  @mixin c-popover__popup--displayed-in-canvas {
    transform: none;
  }
  @mixin c-popover__popup--animate-translation-and-opacity {
    transition-property: opacity, transform;
  }

  &.c-popover__popup--v-enter {
    @include c-popover__popup--hidden;
    @include c-popover__popup--do-not-animate;
  }
  &.c-popover__popup--v-enter-to {
    @include c-popover__popup--visible;
    @include c-popover__popup--animate;
  }

  &.c-popover__popup--v-leave {
    @include c-popover__popup--visible;
    @include c-popover__popup--do-not-animate;
  }
  &.c-popover__popup--v-leave-to {
    @include c-popover__popup--hidden;
    @include c-popover__popup--animate;
  }

  @include t-responsive--smaller-than($responsive-breakpoint-tablet-medium) {
    @include t-box-shadow($box-shadow-popup-with-backdrop);

    border: 0;
    border-radius: var(--border-radius) var(--border-radius) 0 0;
    border-top: var(--border);
    bottom: 0;
    max-height: calc(100vh - var(--spacing-xxl));
    overflow-y: auto;
    right: 0;
    top: unset;
    transform: none;

    &.c-popover__popup--v-enter {
      @include c-popover__popup--hidden;
      @include c-popover__popup--do-not-animate;
      @include c-popover__popup--translated-out-of-canvas;
    }
    &.c-popover__popup--v-enter-to {
      @include c-popover__popup--visible;
      @include c-popover__popup--animate;
      @include c-popover__popup--displayed-in-canvas;
      @include c-popover__popup--animate-translation-and-opacity;
    }

    &.c-popover__popup--v-leave {
      @include c-popover__popup--visible;
      @include c-popover__popup--do-not-animate;
      @include c-popover__popup--displayed-in-canvas;
    }
    &.c-popover__popup--v-leave-to {
      @include c-popover__popup--hidden;
      @include c-popover__popup--animate;
      @include c-popover__popup--translated-out-of-canvas;
      @include c-popover__popup--animate-translation-and-opacity;
    }
  }
}
</style>
