export const getInitialsFromName = (name: string): string => {
  const trimmedName = name.trim();

  if (trimmedName === '') {
    return '';
  }

  const words = trimmedName.split(' ');
  const firstWord = words[0];
  const firstLetter = firstWord[0];

  const initials = words.length === 1 ? firstLetter : `${firstLetter}${words[words.length - 1][0]}`;

  return initials.toUpperCase();
};
