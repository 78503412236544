<template>
  <Popover
    :opened="isLoginPopupOpened"
    popup-class="c-main-navbar-login-button__popup"
    @click-outside="hideLoginPopup()"
  >
    <template #toggleButton>
      <SecondaryButton :active="isLoginPopupOpened" @click="toggleLoginPopup()">
        <FontAwesomeIcon :icon="faEntrance" fixed-width />

        <span class="u-responsive--display-none--smaller-than-tablet-medium">
          {{ t('header.login.button') }}
        </span>
      </SecondaryButton>
    </template>

    <template #popoverContent>
      <LoginPopupContent
        :loginError="loginError"
        :loading="isRunningLoginRequest"
        @login="doLogin($event)"
        @showPasswordRecoveryForm="showPasswordRecoveryModal()"
        @showRegisterForm="showRegisterModal()"
      />
    </template>
  </Popover>
</template>

<script lang="ts" setup>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';

import { SecondaryButton } from '@caff/cds-button';
import { faEntrance } from '@caff/cds-icons';
import { Popover } from '@caff/cds-popover';

import { loginRoute } from '../../router';
import { useModalStore } from '../../store';
import { useLogin } from '../../composition';
import LoginPopupContent from '../login/LoginPopupContent.vue';

const { t } = useI18n();

const currentRoute = useRoute();
const isLoginPopupOpened = ref(currentRoute.name === loginRoute.name);

const toggleLoginPopup = () => {
  isLoginPopupOpened.value = !isLoginPopupOpened.value;
};

const hideLoginPopup = () => {
  isLoginPopupOpened.value = false;
};

const { isExecuting: isRunningLoginRequest, error: loginError, login } = useLogin();

const doLogin = ({
  usernameOrEmail,
  password,
  rememberLogin,
}: {
  usernameOrEmail: string;
  password: string;
  rememberLogin: boolean;
}) =>
  login({
    usernameOrEmail,
    password,
    rememberLogin,
  });

const showPasswordRecoveryModal = () => {
  hideLoginPopup();
  const modalStore = useModalStore();
  modalStore.showForgotPasswordModal();
};

const showRegisterModal = () => {
  hideLoginPopup();
  const modalStore = useModalStore();
  modalStore.showRegisterModal();
};
</script>

<i18n lang="json" locale="es">
{
  "header": {
    "login": {
      "button": "Iniciar sesión"
    }
  }
}
</i18n>

<style lang="scss">
@import '@caff/cds-scss-core';

.c-main-navbar-login-button__popup {
  &.c-main-navbar-login-button__popup.c-main-navbar-login-button__popup {
    --vspace-to-toggle-button: 10;
  }

  @include t-responsive--starting-at($responsive-breakpoint-tablet-medium) {
    width: 19em;
  }
}
</style>
