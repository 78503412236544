<template>
  <TextLink :to="route" :interactive="interactive">
    <!-- @slot Use this slot to customize link's content -->
    <slot />
  </TextLink>
</template>

<script lang="ts" setup>
import { computed } from 'vue';

import { ThreadSlug } from '@caff/shallow-thread-api-model';

import { InteractiveProps, interactivePropsDefaults } from '../../mixins/interactive.mixin';
import { getThreadRoute } from '../../router';
import { TextLink } from '../common';

const props = withDefaults(defineProps<InteractiveProps & { threadSlug: ThreadSlug }>(), {
  ...interactivePropsDefaults,
});

const route = computed(() => getThreadRoute(props.threadSlug));
</script>
