<template>
  <RouterView />
</template>

<script lang="ts" setup>
import { useHead } from '@vueuse/head';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { RouterView } from 'vue-router';

import { ColorSchema } from '@caff/color-schema-api-model';

import { usePreferredColorSchema } from '../composition';

const classByPreferredColorSchema: Record<ColorSchema, string> = {
  [ColorSchema.light]: 'light-scheme',
  [ColorSchema.dark]: 'dark-scheme',
  [ColorSchema.auto]: '',
};

const { preferredColorSchema } = usePreferredColorSchema();
const preferredColorSchemaClass = computed(
  () => classByPreferredColorSchema[preferredColorSchema.value],
);

const { t, locale } = useI18n();
useHead({
  title: t('title'),
  bodyAttrs: {
    class: computed(() => preferredColorSchemaClass.value),
  },
  htmlAttrs: {
    lang: computed(() => locale.value),
  },
});
</script>

<i18n lang="json" locale="es">
{
  "title": "Caff | Cargando…"
}
</i18n>

<style lang="scss">
@import '../styles/main.scss';
</style>
