import type { IconDefinition, IconName, IconPrefix } from '@fortawesome/fontawesome-common-types';

export const prefix = 'caff' as IconPrefix;
export const iconName = 'trash-bin' as IconName;
export const width = 14;
export const height = 14;
export const ligatures = [];
export const unicode = 'f852';
export const svgPathData =
  'm8.13011004 0c.9061014 0 1.69900139.60916787 1.93246986 1.4846747l.5374201 2.0153253h.9c.8284271 0 1.5.67157288 1.5 1.5 0 .52124774-.2658722.9803977-.6694013 1.2492347l-.6581814 5.9403835c-.0977592 1.0264713-.9598749 1.8103818-1.99099093 1.8103818h-5.36285274c-1.03111604 0-1.89323177-.7839105-1.99099095-1.8103818l-.65818134-5.9403835c-.40352916-.268837-.66940134-.72798696-.66940134-1.2492347 0-.82842712.67157288-1.5 1.5-1.5h.9l.53742008-2.0153253c.23346849-.87550683 1.02636848-1.4846747 1.93246988-1.4846747zm-3.13011004 7c-.51283584 0-.93550716.38604019-.99327227.88337887l-.00672773.11662113v2c0 .5522847.44771525 1 1 1 .51283584 0 .93550716-.3860402.99327227-.8833789l.00672773-.1166211v-2c0-.55228475-.44771525-1-1-1zm4 0c-.51283584 0-.93550716.38604019-.99327227.88337887l-.00672773.11662113v2c0 .5522847.44771525 1 1 1 .51283584 0 .93550716-.3860402.99327227-.8833789l.00672773-.1166211v-2c0-.55228475-.44771525-1-1-1zm-1.36989-5h-1.26022c-.20593214 0-.38847512.12586085-.463815.31319l-.019303.057979-.24466094 1.128831h2.71399473l-.24287779-1.128831c-.05306102-.19897882-.22170776-.34292971-.422124-.367458z';

export const definition = {
  prefix,
  iconName,
  icon: [width, height, ligatures, unicode, svgPathData],
} as IconDefinition;

export const faTrashBin = definition;
