import { useEventListener, useCssVar } from '@vueuse/core';
import { ref, Ref, watch } from 'vue';

import { useOnScreenKeyboardStatus } from '@caff/cds-on-screen-keyboard';

export const useVisualViewportCssVars = (
  el: Ref<HTMLElement | SVGElement | null>,
): {
  height: Ref<string>;
  offsetTop: Ref<string>;
} => {
  if (typeof visualViewport === 'undefined') {
    return {
      height: ref('initial'),
      offsetTop: ref('initial'),
    };
  }

  const visualViewportHeightCssVar = useCssVar('--visual-viewport-height', el);
  const visualViewportOffsetTopCssVar = useCssVar('--visual-viewport-offset-top', el);

  const refreshViewportHeight = () => {
    if (!visualViewport) {
      return;
    }

    visualViewportHeightCssVar.value = `${visualViewport.height}px`;
    visualViewportOffsetTopCssVar.value = `${visualViewport.offsetTop}px`;
  };

  refreshViewportHeight();

  const onScreenKeyboardStatus = useOnScreenKeyboardStatus();
  watch(onScreenKeyboardStatus, () => refreshViewportHeight());

  for (const event of ['resize', 'scroll']) {
    useEventListener(
      visualViewport,
      event,
      () => {
        refreshViewportHeight();
      },
      { passive: true },
    );
  }

  return {
    height: visualViewportHeightCssVar,
    offsetTop: visualViewportOffsetTopCssVar,
  };
};
