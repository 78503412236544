/**
 * Returns a counter function that will return a greater number on each call.
 */
export const counterFactory = (): (() => number) => {
  let value = 0;
  return (): number => value++;
};

/**
 * Returns given `optionalValue` if it's a positive integer, otherwise returns
 * `defaultValue`.
 *
 * @param optionalValue Value to be checked.
 * @param defaultValue Positive integer to be returned if `optionalValue` is not
 * a positive integer.
 * @return Positive integer, which will be `defaultValue` or `optionalValue`
 * isn't so.
 */
export const getPositiveInteger = (
  optionalValue: string | number | undefined,
  defaultValue: number,
): number => {
  const integerValue = parseInt(`${optionalValue}`, 10);
  return integerValue > 0 ? integerValue : defaultValue;
};
