<template>
  <BorderedBox class="c-sidebar-banner-widget" rounded>
    <header class="c-sidebar-banner-widget__header">
      <!-- @slot Use this slot to render content in the header -->
      <slot name="header" />
    </header>

    <div class="c-sidebar-banner-widget__body">
      <!-- @slot Use this slot to render content in the body. When the slot is empty an activity indicator will be rendered -->
      <slot>
        <ActivityIndicator class="c-sidebar-banner-widget__activity-indicator" />
      </slot>
    </div>

    <footer v-if="hasFooter" class="c-sidebar-banner-widget__footer">
      <!-- @slot Use this slot to render content in the footer -->
      <slot name="footer" />
    </footer>
  </BorderedBox>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import { ActivityIndicator } from '@caff/cds-activity-indicator';
import { BorderedBox } from '@caff/cds-bordered-box';

export default defineComponent({
  name: 'SidebarBannerWidget',
  components: {
    ActivityIndicator,
    BorderedBox,
  },
  setup(props, { slots }) {
    const hasFooter = !!slots.footer;

    return {
      hasFooter,
    };
  },
});
</script>

<style lang="scss" scoped>
@import '@caff/cds-scss-core';

.c-sidebar-banner-widget {
  --border-radius: 0;

  display: flex;
  flex-direction: column;
  margin-bottom: var(--spacing-m);
  overflow: hidden;

  @include t-responsive--starting-at($responsive-breakpoint-tablet-medium) {
    --border-radius: 6px;
  }
}

.c-sidebar-banner-widget__header {
  background: var(--colors-box-shadow);
  display: block;
  height: get-size-in-rem(100px);
}

.c-sidebar-banner-widget__body {
  @extend %t-padding-m;

  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.c-sidebar-banner-widget__activity-indicator {
  align-items: center;
  display: flex;
  flex-grow: 1;
  justify-content: center;
}

.c-sidebar-banner-widget__footer {
  @extend %t-padding-m;

  align-items: center;
  border-top: 1px solid var(--colors-background);
  display: flex;
  flex-direction: row;
}
</style>
