<template>
  <Button :to="discoverRoute" :active="isActive" :type="buttonType">
    <FontAwesomeIcon :icon="faGlobeWorld" fixed-width />
  </Button>
</template>

<script lang="ts">
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { computed, defineComponent } from 'vue';
import { useRouter } from 'vue-router';

import { Button, ButtonType } from '@caff/cds-button';
import { faGlobeWorld } from '@caff/cds-icons';

import { discoverRoute } from '../../router';

export default defineComponent({
  name: 'MainNavbarDiscoverButton',
  components: {
    Button,
    FontAwesomeIcon,
  },
  setup() {
    const router = useRouter();

    const isActive = computed(() => router.currentRoute.value.name === discoverRoute.name);

    return {
      faGlobeWorld,
      discoverRoute,
      buttonType: ButtonType.secondary,
      isActive,
    };
  },
});
</script>
