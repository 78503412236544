import { isServer } from '@caff/isomorphic-is-server';

export const isEndReached = (contentContainer?: Element): boolean => {
  if (!contentContainer) {
    return true;
  }

  const boundingClientRect = contentContainer.getBoundingClientRect();
  const viewportHeight = isServer()
    ? 1080 // Just some default height when running in SSR
    : document.documentElement.clientHeight;

  const yPositionOfLastContentPixel = boundingClientRect.top + boundingClientRect.height;
  const yAxisMarginBeforeLoadingMoreContent = 100;

  return yPositionOfLastContentPixel <= viewportHeight + yAxisMarginBeforeLoadingMoreContent;
};

interface ScrollPadding {
  top?: number;
  left?: number;
}

export const getOffsetToCompensateStickyHeader = (): number => {
  const headerNavBarElement = document.querySelector('.c-top-navbar');

  if (!headerNavBarElement) {
    console.warn('Could not find header nav bar');
    return 0;
  }

  return headerNavBarElement.getBoundingClientRect().height;
};

export const getPaddedOffsetToCompensateStickyHeader = (): number =>
  getOffsetToCompensateStickyHeader() + 34; // 48 to account for "go back" button - 14 to account for its margin

export const getScrollForElement = (
  element: Element,
  options?: { padding?: ScrollPadding },
): { x: number; y: number } => {
  const topOffsetRelativeToViewport = element.getBoundingClientRect().top;
  const viewportOffsetRelativeToDocument = window.scrollY;
  const paddedOffsetToCompensateStickyHeader = getPaddedOffsetToCompensateStickyHeader();

  const padding: { top: number; left: number } = {
    top: options?.padding?.top ?? 0,
    left: options?.padding?.left ?? 0,
  };

  return {
    x: padding.left,
    y:
      topOffsetRelativeToViewport +
      viewportOffsetRelativeToDocument -
      paddedOffsetToCompensateStickyHeader -
      padding.top,
  };
};

export const scrollToElement = (element: Element, options?: { padding?: ScrollPadding }): void => {
  const requiredScroll = getScrollForElement(element, options);
  window.scroll(requiredScroll.x, requiredScroll.y);
};
