import { RouteRecordRaw } from 'vue-router';

import { PostId } from '@caff/post-api-model';

import { RouterMetaField } from './meta-fields';

type PostView = typeof import('../views/Post.vue');
const Post = (): Promise<PostView> => import('../views/Post.vue');

export const postRoute = {
  path: '/p/:idPost',
  name: 'single-post',
  component: Post,
  props: true,
  meta: {
    [RouterMetaField.paramsForwardedToGetSelectorToScrollTo]: ['idPost'],
    [RouterMetaField.getSelectorToScrollTo]: (idPost): string => `#post-${idPost}`,
  },
} satisfies RouteRecordRaw;

export const getPostRoute = (
  idPost: PostId,
): typeof postRoute & { params: { idPost: PostId } } => ({
  ...postRoute,
  params: { idPost },
});
