import { createHead } from '@vueuse/head';
import axios from 'axios';
import { createPinia } from 'pinia';
import viteSSR from 'vite-ssr';
import { createI18n } from 'vue-i18n';

import { createUseQuery } from '@caff/frontend-use-query';
import {
  BrowserSideContext,
  ServerSideContext,
  SerializedServerSideContext,
  ServerSideRenderingSharedContext,
} from '@caff/server-side-rendering-context-isomorphic-model';

import { setAxiosInstance } from './axios';
import { routes, scrollBehavior } from './router';
import { serializeStore, unserializeStore } from './store';
import { getApiBaseUrl } from './utils/network';
import { initSentry } from './utils/sentry';
import App from './views/App.vue';

const getSerializedStateForBrowser = (state: ServerSideContext): string => {
  const stateFromServerToBrowser: SerializedServerSideContext = {
    pinia: serializeStore(state.pinia),
    requestedUrl: state.requestedUrl,
    useQuerySerializedData: state.useQuerySerializedData,
    userPreferredLanguages: state.userPreferredLanguages,
  };

  return JSON.stringify(stateFromServerToBrowser);
};

const getUnserializeStateFromServer = (state: SerializedServerSideContext): BrowserSideContext => {
  return state
    ? {
        ...state,
        pinia: unserializeStore(state.pinia),
      }
    : {
        pinia: {},
        requestedUrl: '',
        useQuerySerializedData: {
          pages: [],
          queries: [],
        },
        userPreferredLanguages:
          // We currently only support Spanish. When frontend cannot load
          // translations for requested language it falls back to Spanish
          // but it also logs a warning for each string that it tried to
          // translate and could not. So to keep the logs clear we will
          // force Spanish here for everybody until we have proper
          // translations to other languages.
          ['es', ...navigator.languages],
      };
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default () =>
  viteSSR(
    App,
    {
      routes,
      routerOptions: {
        scrollBehavior,
      },
      // In SSR this is called after app has been rendered, so we have access
      // to the resulting store data
      // In Client-Side Rendering this is called before the app is rendered
      // Note that in dev mode, when frontend is running without SSR, this is
      // called with an `undefined` state.
      transformState: (state: ServerSideContext | SerializedServerSideContext) =>
        import.meta.env.SSR
          ? getSerializedStateForBrowser(state as ServerSideContext)
          : getUnserializeStateFromServer(state as SerializedServerSideContext),
    },
    ({ app, initialState: untypedInitialState, router }) => {
      const initialState = untypedInitialState as ServerSideContext | BrowserSideContext;

      initSentry({
        app,
        initialState,
        router,
      });

      const useQueryPlugin = createUseQuery({
        serializedData: initialState.useQuerySerializedData,
      });
      app.use(useQueryPlugin);

      const head = createHead();
      app.use(head);

      const axiosInstance = import.meta.env.SSR
        ? (initialState as ServerSideContext).axiosInstance
        : axios.create({
            baseURL: getApiBaseUrl(initialState as BrowserSideContext).toString(),
            // This property is required to use http-only signed cookies which are persisted
            // for larger amount of times on all browsers (and safer)
            withCredentials: true,
          });

      setAxiosInstance(axiosInstance);

      const pinia = createPinia();

      if (import.meta.env.SSR) {
        // Note that `pinia.state.value` is a reference, so we cannot clone that
        (initialState as SerializedServerSideContext).pinia = pinia.state.value;
      } else {
        pinia.state.value = (initialState as BrowserSideContext).pinia;
      }

      app.use(pinia);

      const sharedState = initialState as ServerSideRenderingSharedContext;
      const i18n = createI18n({
        locale: sharedState.userPreferredLanguages[0],
        fallbackLocale: 'es',
        allowComposition: true,
        legacy: false,
      });
      app.use(i18n);

      return { head };
    },
  );
