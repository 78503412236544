<template>
  <article
    :class="{
      'c-toastr': true,
      [`c-toastr--${variant}`]: true,
    }"
  >
    <div class="c-toastr__body">
      <FontAwesomeIcon
        v-if="isProgressIndicatorVisible"
        :icon="faSpinnerThird"
        class="u-margin-r-xs"
        aria-busy="true"
        fixedWidth
        spin
      />
      <FontAwesomeIcon v-else-if="icon" :icon="icon" class="u-margin-r-xs" fixedWidth aria-hidden />

      <slot name="default" />
    </div>

    <section v-if="hasActions" class="c-toastr__actions">
      <slot name="actions" />
    </section>
  </article>
</template>

<script lang="ts" setup>
import type { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { computed } from 'vue';

import { faSpinnerThird } from '@caff/cds-icons';

import { ToastrVariant } from './Toastr.constants';

const props = defineProps<{
  icon?: IconDefinition;
  variant: ToastrVariant;
}>();

const slots = defineSlots<{
  default(): unknown;
  actions(): unknown;
}>();

const hasActions = computed(() => !!slots.actions);
const isProgressIndicatorVisible = computed(() => props.variant === ToastrVariant.progress);
</script>

<style lang="scss" scoped>
@import '@caff/cds-scss-core';

.c-toastr {
  @include t-font($font-body);
  @extend %t-margin-b-xs;

  --border-radius: 8px;

  align-items: center;
  border-radius: var(--border-radius);
  color: var(--colors-readable-over-color-500);
  display: flex;
  flex-direction: row;
  flex-grow: 0;
  max-width: calc(100vw - 2 * var(--spacing-xs));
  min-height: var(--spacing-l);
  pointer-events: all;
  word-break: break-word;
}

.c-toastr--error {
  background: var(--colors-red-500);
}

.c-toastr--progress {
  background: var(--colors-orange-500);
}

.c-toastr--success {
  background: var(--colors-green-500);
}

.c-toastr__body {
  flex-grow: 1;
  flex-shrink: 1;
  padding: var(--spacing-xs) var(--spacing-s);
}

.c-toastr__actions {
  flex-shrink: 0;
}
</style>
