import type { Ref } from 'vue';
import { computed, ref } from 'vue';

import { CategorySlug } from '@caff/category-api-model';
import { UseMutationReturnValue } from '@caff/frontend-use-query';
import type {
  PostId,
  ShallowPost as RawShallowPost,
  ShallowPostWithReplies as RawShallowPostWithReplies,
} from '@caff/post-api-model';
import type { ThreadSlug } from '@caff/shallow-thread-api-model';
import type { Thread as RawThread } from '@caff/thread-api-model';

import type { Post } from '../../models/Post';
import { getPostFromShallowReply } from '../../models/Post';
import type { Thread } from '../../models/Thread';
import { getThreadFromShallowThread } from '../../models/Thread';
import { setReply } from '../post';
import { getKeyForThread, setThread, setThreadReplies } from '../thread';
import type { UseAxiosFlatPaginatedQueryResult, UseAxiosQueryResult } from '../useAxiosQuery';
import { useAxiosFlatPaginatedQuery, useAxiosQuery, useAxiosMutation } from '../useAxiosQuery';
import {
  useAuthorFrontpagePostIds,
  useAuthorFrontpageThreadSlugs,
  useCategoryFrontpageThreadSlugs,
  useFrontpagePostOrder,
  useFrontpageThreadOrder,
  useGeneralFrontpageThreadSlugs,
} from '../useFrontpage';
import { usePostRepliesIds } from '../usePost/usePostRepliesIds';
import { useCurrentlyLoggedInUser, useOnCurrentlyLoggedInUserChange } from '../useSession';

export const useCreateNewThread = ({
  categorySlug,
}: {
  categorySlug: Ref<CategorySlug | null>;
}): Omit<
  UseMutationReturnValue<
    {
      title: string;
      content: string;
    },
    Thread | null,
    Error
  >,
  'data' | 'mutate'
> & {
  thread: Ref<Thread | null>;
  createNewThread: UseMutationReturnValue<
    {
      title: string;
      content: string;
    },
    Thread | null,
    Error
  >['mutate'];
} => {
  const threadOrder = useFrontpageThreadOrder();
  const { currentlyLoggedInUser } = useCurrentlyLoggedInUser();
  const { reexecute: reexecuteAuthorFrontpage } = useAuthorFrontpageThreadSlugs({
    username: computed(() =>
      currentlyLoggedInUser.value ? currentlyLoggedInUser.value.username : null,
    ),
    threadOrder,
  });
  const { reexecute: reexecuteCategoryFrontpage } = useCategoryFrontpageThreadSlugs({
    categorySlug,
    threadOrder,
  });
  const { reexecute: reexecuteGeneralFrontpage } = useGeneralFrontpageThreadSlugs({
    threadOrder,
  });

  const {
    data: thread,
    mutate: createNewThread,
    ...rest
  } = useAxiosMutation<
    {
      title: string;
      content: string;
    },
    Thread | null,
    Error
  >({
    mutationKey: ref('create/thread'),
    async mutationFn({ axiosInstance, param: { content, title } }) {
      if (!categorySlug.value || !title || !content) {
        return null;
      }

      const {
        data: rawThread,
      }: {
        data: RawThread;
      } = await axiosInstance.post('/thread', {
        category: categorySlug.value,
        content,
        title,
      });

      await Promise.allSettled([
        reexecuteAuthorFrontpage(),
        reexecuteCategoryFrontpage(),
        reexecuteGeneralFrontpage(),
      ]);

      return getThreadFromShallowThread(rawThread);
    },
  });

  return {
    ...rest,
    createNewThread,
    thread,
  };
};

export const useThreadRepliesIds = (
  threadSlug: Ref<ThreadSlug | null>,
): UseAxiosFlatPaginatedQueryResult<Array<PostId>> & {
  threadRepliesIds: Ref<Array<PostId>>;
} => {
  const { data, invalidate, ...rest } = useAxiosFlatPaginatedQuery<
    Array<ThreadSlug>,
    number,
    Error
  >({
    getQueryKey: (cursor = 1) => computed(() => `thread/${threadSlug.value}/replies/${cursor}`),
    async queryPageFn({ axiosInstance, cursor = 1, setQueryData }) {
      const rootThreadSlug = threadSlug.value;

      if (!rootThreadSlug) {
        return {
          data: [],
          nextCursor: null,
        };
      }

      const {
        data: shallowPostsWithReplies,
      }: {
        data: Array<RawShallowPostWithReplies>;
      } = await axiosInstance.get(`/thread/${rootThreadSlug}/replies/${cursor}`);

      await Promise.all(
        shallowPostsWithReplies.map((rawPost) =>
          setReply({
            parentId: null,
            reply: rawPost,
            rootThreadSlug: rootThreadSlug,
            setQueryData,
          }),
        ),
      );

      return {
        data: shallowPostsWithReplies.map(({ uuid }) => uuid),
        nextCursor: shallowPostsWithReplies.length > 0 ? cursor + 1 : null,
      };
    },
  });

  useOnCurrentlyLoggedInUserChange(async () => {
    await invalidate();
  });

  const threadRepliesIds = computed(() => data.value ?? []);

  return { ...rest, invalidate, threadRepliesIds };
};

export const useCreateNewPostReply = ({
  idParent,
}: {
  idParent: Ref<PostId>;
}): Omit<
  UseMutationReturnValue<
    {
      content: string;
    },
    Post | null,
    Error
  >,
  'data' | 'mutate'
> & {
  post: Ref<Post | null>;
  createNewPostReply: UseMutationReturnValue<
    {
      content: string;
    },
    Post | null,
    Error
  >['mutate'];
} => {
  const postOrder = useFrontpagePostOrder();
  const { currentlyLoggedInUser } = useCurrentlyLoggedInUser();
  const { reexecute: reexecuteAuthorFrontpage } = useAuthorFrontpagePostIds({
    username: computed(() =>
      currentlyLoggedInUser.value ? currentlyLoggedInUser.value.username : null,
    ),
    postOrder,
  });
  const { reexecute: reexecutePostRepliesIds } = usePostRepliesIds(idParent);

  const {
    data: post,
    mutate: createNewPostReply,
    ...rest
  } = useAxiosMutation<
    {
      content: string;
    },
    Post | null,
    Error
  >({
    mutationKey: ref('create/postReply'),
    async mutationFn({ axiosInstance, param: { content } }) {
      if (!idParent.value || !content) {
        return null;
      }

      const {
        data: rawShallowPost,
      }: {
        data: RawShallowPost;
      } = await axiosInstance.post(`/post/${idParent.value}`, {
        content,
      });

      await Promise.allSettled([reexecuteAuthorFrontpage(), reexecutePostRepliesIds()]);

      return getPostFromShallowReply(rawShallowPost);
    },
  });

  return {
    ...rest,
    createNewPostReply,
    post,
  };
};

export const useThread = (
  threadSlug: Ref<ThreadSlug | null>,
): UseAxiosQueryResult<Thread> & {
  thread: Ref<Thread | null>;
} => {
  const {
    data: thread,
    invalidate,
    ...rest
  } = useAxiosQuery<Thread | null, Error>({
    queryKey: computed(() => getKeyForThread(threadSlug)),
    async queryFn({ axiosInstance, setQueryData }) {
      if (!threadSlug.value) {
        return null;
      }

      const {
        data: rawThread,
      }: {
        data: RawThread;
      } = await axiosInstance.get(`/thread/${threadSlug.value}`);

      await Promise.all([
        setThread({
          thread: rawThread,
          setQueryData,
        }),
        setThreadReplies({
          thread: rawThread,
          setQueryData,
        }),
      ]);

      return getThreadFromShallowThread(rawThread);
    },
  });

  useOnCurrentlyLoggedInUserChange(async () => {
    await invalidate();
  });

  const populatedThread = computed<Thread | null>(() => {
    if (!thread.value) {
      return null;
    }

    return {
      authorCanonicalUsername: thread.value.authorCanonicalUsername,
      categorySlug: thread.value.categorySlug,
      content: thread.value.content,
      externalUrls: thread.value.externalUrls,
      idThumbnail: thread.value.idThumbnail,
      mentions: thread.value.mentions,
      notificationFrequency: thread.value.notificationFrequency,
      polemicScore: thread.value.polemicScore,
      publishedTimestamp: thread.value.publishedTimestamp,
      repliesCount: thread.value.repliesCount,
      slug: thread.value.slug,
      title: thread.value.title,
      reactions: thread.value.reactions,
    };
  });

  return {
    ...rest,
    invalidate,
    thread: populatedThread,
  };
};
