<template>
  <ResetPasswordModal
    :currentStep="currentStep"
    :password="password"
    :isLoading="isExecuting"
    :passwordResetError="passwordResetError && t(passwordResetError)"
    @dismiss="dismissWizard()"
    @password-input="onPasswordInput($event)"
    @save-new-password="saveNewPassword()"
  />
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';

import {
  ResetPasswordModal,
  ResetPasswordModalStep,
} from '@caff/reset-password-modal-frontend-component';

import { homeRoute } from '../../router';
import { useModalStore } from '../../store';
import { useSavePassword } from '../../composition';

const { t } = useI18n();

const currentStep = ref(ResetPasswordModalStep.password);
const password = ref('');
const passwordResetError = ref<string | null>(null);

const modalStore = useModalStore();

const onPasswordInput = (newPassword: string) => {
  password.value = newPassword;
};

const { savePassword, isExecuting } = useSavePassword();

const saveNewPassword = async () => {
  if (!modalStore.changePasswordToken) {
    return;
  }

  if (isExecuting.value) {
    return;
  }

  passwordResetError.value = null;

  try {
    await savePassword({
      changePasswordToken: modalStore.changePasswordToken,
      newPassword: password.value,
    });

    currentStep.value = ResetPasswordModalStep.success;
  } catch (error) {
    passwordResetError.value = 'error.passwordReset.serverError';
    console.error(error);
  }
};

const router = useRouter();

const dismissWizard = async () => {
  if (isExecuting.value) {
    return;
  }

  modalStore.dismissCurrentModal();

  await router.push(homeRoute);
};
</script>

<i18n lang="json" locale="es">
{
  "error": {
    "passwordReset": {
      "serverError": "Ha sucedido un error inesperado."
    }
  }
}
</i18n>
