import type { Socket as SocketIOClientSocket } from 'socket.io-client';
import { computed, ref } from 'vue';

import { isServer } from '@caff/isomorphic-is-server';
import {
  ServerToClientSocketMessage,
  SocketMessageAction,
  SocketMessageEvent,
} from '@caff/socket-message-api-model';

import { captureException } from '../utils/sentry';

const getWebsocketUrl = (): string | null => {
  if (isServer()) {
    return null;
  }

  const frontendUrl = new URL(window.location.href);
  const webSocketUrl = new URL('https://ws.caff.co');
  webSocketUrl.port = frontendUrl.port;
  webSocketUrl.host = `ws.${frontendUrl.host}`;
  webSocketUrl.protocol = frontendUrl.protocol;

  return webSocketUrl.toString();
};

const addPersistentMessageHandlersToSocketInstance = async (socket: SocketIOClientSocket) => {
  // Event handlers are loaded asynchronously because a sync import would cause
  // a circular dependency loop since the newNotification handler imports useNotifications
  // to invalidate old data, useNotifications requires the currently logged in
  // user, which in turn requires importing the helper that invalidates the
  // currently logged in user when it changes, which in turn requires useLogin,
  // which depends on openSocket to start the real-time connection as soon as
  // the user logs in, which needs addPersistentMessageHandlersToSocketInstance
  // helper to add the basic event handlers, leading to the cycle
  const { eventHandlers } = await import('./socket');

  for (const { eventName, handlerComposable } of eventHandlers) {
    const handler = handlerComposable();

    socket.on(eventName, async (message) => {
      try {
        await handler(message);
      } catch (error) {
        console.error(error);
        // TODO: Do something about the error?
        captureException(error);
      }
    });
  }
};

const addSingleTemporaryMessageHandlerToSocketInstance = ({
  eventName,
  handler,
  socket,
}: {
  eventName: SocketMessageEvent;
  handler: (param: { message: ServerToClientSocketMessage; removeHandler: () => void }) => void;
  socket: SocketIOClientSocket;
}): (() => void) => {
  const boundHandler = (message: ServerToClientSocketMessage) =>
    handler({
      message,
      removeHandler,
    });

  const removeHandler = () => {
    socket.off(eventName, boundHandler);

    removeTemporarySocketHandler({ eventName, handler });
  };

  socket.on(eventName, boundHandler);

  return removeHandler;
};

type TemporaryEventHandler = (param: {
  message: ServerToClientSocketMessage;
  removeHandler: () => void;
}) => void;

const temporarySocketEventHandlers = new Map<SocketMessageEvent, Set<TemporaryEventHandler>>();

const removeTemporarySocketHandler = ({
  eventName,
  handler,
}: {
  eventName: SocketMessageEvent;
  handler: TemporaryEventHandler;
}) => {
  const temporaryHandlers = temporarySocketEventHandlers.get(eventName);

  temporaryHandlers?.delete(handler);
};

const addPendingTemporaryMessageHandlersToSocketInstance = (socket: SocketIOClientSocket) => {
  for (const [eventName, handlers] of temporarySocketEventHandlers.entries()) {
    for (const singleHandler of handlers) {
      addSingleTemporaryMessageHandlerToSocketInstance({
        eventName,
        handler: singleHandler,
        socket,
      });
    }
  }
};

const addLifecycleHandlersToSocketInstance = (socket: SocketIOClientSocket) => {
  socket.on('disconnect', () => {
    if ('close' in socketActions.value) {
      socketActions.value.close();
    } else {
      const error = new Error('Received a `disconnect` message on an already closed Socket');
      console.error(error);
      captureException(error);
    }
  });

  socket.on('connect', () => {
    socket.send({
      action: SocketMessageAction.authenticate,
      payload: {},
    });
  });
};

interface SocketActions {
  close: () => void;
  logout: () => void;
  underlyingSocket: SocketIOClientSocket;
}

const openSocket = async (): Promise<SocketActions | null> => {
  const webSocketUrl = getWebsocketUrl();

  if (!webSocketUrl) {
    return null;
  }

  const socketIOClient = await import('socket.io-client');

  const socket = socketIOClient.io(webSocketUrl, {
    withCredentials: true,
  });

  const close = () => {
    socket.close();

    socketActions.value = { open: openSocket, underlyingSocket: null };
  };

  const logout = () => {
    socket.send({
      action: SocketMessageAction.logout,
      payload: {},
    });

    close();
  };

  const actions = {
    underlyingSocket: socket,
    close,
    logout,
  };

  socketActions.value = actions;

  addPersistentMessageHandlersToSocketInstance(socket);
  addPendingTemporaryMessageHandlersToSocketInstance(socket);
  addLifecycleHandlersToSocketInstance(socket);

  return actions;
};

const socketActions = ref<
  { underlyingSocket: null; open: () => Promise<SocketActions | null> } | SocketActions
>({ underlyingSocket: null, open: openSocket });

export const useOpenSocket = (): (() => Promise<SocketActions | null>) => {
  const openSocket = computed(() =>
    socketActions.value.underlyingSocket
      ? () =>
          Promise.reject<null>(
            new Error('Tried to open a Socket when there was an already open Socket'),
          )
      : socketActions.value.open,
  );

  return async () => openSocket.value();
};

export const useAddTemporaryMessageHandler = ({
  eventName,
}: {
  eventName: SocketMessageEvent;
}): ((
  handler: (params: { message: ServerToClientSocketMessage; removeHandler: () => void }) => void,
) => () => void) => {
  return (handler): (() => void) => {
    const socket = socketActions.value.underlyingSocket;

    if (socket) {
      return addSingleTemporaryMessageHandlerToSocketInstance({
        eventName,
        handler,
        socket: socket as SocketIOClientSocket,
      });
    } else {
      const temporaryHandlers =
        temporarySocketEventHandlers.get(eventName) ?? new Set<TemporaryEventHandler>();

      temporaryHandlers.add(handler);

      temporarySocketEventHandlers.set(eventName, temporaryHandlers);

      return () => removeTemporarySocketHandler({ eventName, handler });
    }
  };
};
