import {
  PasswordHasher,
  HashingAlgorithmConfig,
  HashingAlgorithmResult,
} from '@caff/isomorphic-hash';

// We don't care about code coverage of this function as it is called by
// PasswordHasher class, and we will test that class calling the method passed
// to its constructor in a different unit test, so we can safely assume that
// this method is going to be called.
/* istanbul ignore next */
const getArgon2Hash = async ({
  pass,
  salt,
}: HashingAlgorithmConfig): Promise<HashingAlgorithmResult> => {
  const { default: argon2 } = await import('argon2-wasm-esm');

  return argon2.hash({
    pass,
    salt,
    type: argon2.ArgonType.argon2i,
    mem: 4096,
    time: 4,
    hashLen: 32,
  });
};

export const passwordHasher = new PasswordHasher(getArgon2Hash);
