<template>
  <BorderedBox tag="aside" class="c-sidebar-list-widget" rounded>
    <h2 class="c-sidebar-list-widget__title">
      <!-- @slot Use this slot to render content as title -->
      <slot name="title" />
    </h2>

    <!-- @slot Use this slot to render content in the body -->
    <slot />
  </BorderedBox>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import { BorderedBox } from '@caff/cds-bordered-box';

export default defineComponent({
  name: 'SidebarListWidget',
  components: {
    BorderedBox,
  },
});
</script>

<style lang="scss" scoped>
@import '@caff/cds-scss-core';

.c-sidebar-list-widget {
  --border-radius: 0;

  display: flex;
  flex-direction: column;
  overflow: hidden;

  &:not(:last-child) {
    margin-bottom: var(--spacing-m);
  }

  @include t-responsive--starting-at($responsive-breakpoint-tablet-medium) {
    --border-radius: 6px;
  }
}

.c-sidebar-list-widget__title {
  @include t-font($font-header-big);
  @extend %t-margin-b-s;
  @extend %t-margin-h-m;
  @extend %t-margin-t-m;
}
</style>
