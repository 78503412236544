<template>
  <form @submit.stop.prevent="submitForm()">
    <H2>{{ t('title') }}</H2>
    <P>
      <slot name="emailError">
        {{ t('email.visibleLabel') }}
      </slot>
    </P>
    <Input
      :icon="faLetter"
      :label="t('email.accessibilityLabel')"
      :loading="isEmailAsyncValidationLoading"
      :placeholder="t('email.placeholder')"
      :value="email"
      autocomplete="email"
      class="u-width-100"
      name="email"
      required
      type="email"
      @input="handleEmailInput($event)"
    />

    <Footer>
      <template #leading>
        <Button
          :disabled="isCreatingAccount"
          :type="secondary"
          data-testid="cancel-button"
          @click.stop.prevent="dismiss()"
        >
          {{ t('action.cancel') }}
        </Button>
      </template>

      <template #trailing>
        <Button
          :disabled="isSubmitDisabled"
          :loading="isCreatingAccount"
          :type="primary"
          data-testid="next-button"
          @click.stop.prevent="submitForm()"
        >
          {{ t('action.next') }}
        </Button>
        <Button
          :disabled="isCreatingAccount"
          :type="secondary"
          class="u-margin-r-xs"
          data-testid="back-button"
          @click.stop.prevent="goBack()"
        >
          <FontAwesomeIcon :icon="faArrowLeft" fixed-width />

          {{ t('action.back') }}
        </Button>
      </template>
    </Footer>
  </form>
</template>

<script lang="ts">
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';

import { Button, ButtonType } from '@caff/cds-button';
import { faArrowLeft, faLetter } from '@caff/cds-icons';
import { Input } from '@caff/cds-input';
import { Footer, H2, P } from '@caff/cds-modal-flow';

export default defineComponent({
  name: 'EmailUnavailableStep',
  components: { Button, FontAwesomeIcon, Footer, H2, Input, P },
  props: {
    email: {
      type: String,
      required: true,
    },
    emailError: {
      type: String,
      required: false,
    },
    isEmailAsyncValidationLoading: {
      type: Boolean,
      required: true,
    },
    isSubmitDisabled: {
      type: Boolean,
      required: true,
    },
    isCreatingAccount: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['email-input', 'dismiss', 'go-back', 'submit'],
  setup(props, { emit }) {
    const { t } = useI18n();

    const handleEmailInput = (value: string): void => {
      /**
       * User changed the value of the email input.
       *
       * @event email-input
       * @type {String}
       */
      emit('email-input', value);
    };

    const dismiss = (): void => {
      /**
       * User dismissed the form.
       *
       * @event dismiss
       * @type {void}
       */
      emit('dismiss');
    };

    const goBack = (): void => {
      /**
       * User went to previous step of the form.
       *
       * @event go-back
       * @type {void}
       */
      emit('go-back');
    };

    const submitForm = (): void => {
      if (props.isSubmitDisabled) {
        return;
      }

      /**
       * User submitted the form.
       *
       * @event submit
       * @type {void}
       */
      emit('submit');
    };

    return {
      t,
      faArrowLeft: faArrowLeft,
      faLetter: faLetter,
      primary: ButtonType.primary,
      secondary: ButtonType.secondary,
      handleEmailInput,
      dismiss,
      goBack,
      submitForm,
    };
  },
});
</script>

<i18n lang="json" locale="es">
{
  "action": {
    "back": "Atrás",
    "cancel": "Cancelar",
    "next": "Continuar"
  },
  "email": {
    "accessibilityLabel": "Correo electrónico",
    "placeholder": "Correo electrónico",
    "visibleLabel": "El correo electrónico elegido no está disponible, utiliza uno distinto para crear con éxito tu cuenta."
  },
  "title": "¡Correo no disponible!"
}
</i18n>
