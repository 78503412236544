import type { IconDefinition, IconName, IconPrefix } from '@fortawesome/fontawesome-common-types';

export const prefix = 'caff' as IconPrefix;
export const iconName = 'check' as IconName;
export const width = 14;
export const height = 14;
export const ligatures = [];
export const unicode = 'f816';
export const svgPathData =
  'm5.50002558 8.467-3.04602359-3.04595559-.089492-.08174713c-.58841389-.48074972-1.42068851-.44102518-1.94345041.08173672s-.56248643 1.35503652-.09826877 1.92419972l.08088847.0904522 4.38906559 4.38938128c.39062035.3904282 1.02378531.3902724 1.41421352-.0003479l7.37202281-7.37074289.0817471-.089492c.4807497-.58841389.4410252-1.42068851-.0817367-1.94345041-.5613787-.56137867-1.4715533-.56137867-2.032932 0z';

export const definition = {
  prefix,
  iconName,
  icon: [width, height, ligatures, unicode, svgPathData],
} as IconDefinition;

export const faCheck = definition;
