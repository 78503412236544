import type { IconDefinition, IconName, IconPrefix } from '@fortawesome/fontawesome-common-types';

export const prefix = 'caff' as IconPrefix;
export const iconName = 'speaker-on' as IconName;
export const width = 14;
export const height = 14;
export const ligatures = [];
export const unicode = 'f847';
export const svgPathData =
  'm4.78988699 2.42781975c.80936372-.90784158 2.11613757-.27611883 2.20529752 1.00601376l.00481549.13981966v7.85269363c0 1.3274563-1.27886476 2.0362583-2.12157024 1.2372515l-.08854277-.091418-1.95488699-2.1928593h-1.5225c-.6578686 0-1.20269049-.57916742-1.29779264-1.33463901l-.01247931-.14361863-.00222805-.09228108v-2.61756456c0-.81636196.52052707-1.48724644 1.18609749-1.56334924l.12640251-.00718949h1.5220625zm5.92376051 1.63004317c.4497611-.54974868 1.2518339-.61461527 1.7796286-.1412488.9963709.89362134 1.5060035 2.12152608 1.5060035 3.59008864 0 1.46856255-.5096326 2.69646734-1.5060035 3.59008864-.5277947.4733665-1.3298675.4084999-1.7796286-.1412488-.4093571-.5003624-.385246-1.23048048.038115-1.70079315l.2108115-.20712114c.3611815-.37917851.5348776-.86932865.5348776-1.54092555 0-.67159691-.1736961-1.16174704-.5348776-1.54092555l-.2108115-.20712115c-.423361-.47031267-.4474721-1.20043078-.038115-1.70079314z';

export const definition = {
  prefix,
  iconName,
  icon: [width, height, ligatures, unicode, svgPathData],
} as IconDefinition;

export const faSpeakerOn = definition;
