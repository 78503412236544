import { RouteRecordRaw } from 'vue-router';

type NotificationsView = typeof import('../views/Notifications.vue');
const Notifications = (): Promise<NotificationsView> => import('../views/Notifications.vue');
type SettingsView = typeof import('../views/Settings.vue');
const Settings = (): Promise<SettingsView> => import('../views/Settings.vue');

export const notificationsRoute = {
  path: '/account/notifications',
  name: 'notifications',
  component: Notifications,
} satisfies RouteRecordRaw;

export const settingsRoute = {
  path: '/account/settings',
  name: 'settings',
  component: Settings,
} satisfies RouteRecordRaw;
