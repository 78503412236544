import { onMounted, onUpdated, ref, Ref } from 'vue';

import { onScrollAnywhere } from '@caff/cds-scroll-anywhere';

import { isEndReached } from '../utils/scroll';

export interface UseReachContentEndSyncHandlerProps {
  onReachContentEnd: () => void;
}

export interface UseReachContentEndEmitHandlerProps {
  emit: (evt: 'reach-content-end') => void;
}

export type UseReachContentEndProps =
  | UseReachContentEndSyncHandlerProps
  | UseReachContentEndEmitHandlerProps;

export const useReachContentEnd = (
  props: UseReachContentEndProps,
): {
  contentContainer: Ref<Element | { $el: Element } | null>;
} => {
  const contentContainer = ref<Element | { $el: Element } | null>(null);

  const checkContentScroll = () => {
    if (!contentContainer.value) {
      return;
    }

    const element =
      '$el' in contentContainer.value ? contentContainer.value.$el : contentContainer.value;

    if (isEndReached(element)) {
      if ('emit' in props) {
        props.emit('reach-content-end');
      }

      if ('onReachContentEnd' in props) {
        props.onReachContentEnd();
      }
    }
  };

  onMounted(() => checkContentScroll());
  onUpdated(() => checkContentScroll());
  onScrollAnywhere(() => checkContentScroll());

  return {
    contentContainer,
  };
};
