import { computed, Ref } from 'vue';

import { OnScreenKeyboardStatus } from '../status';
import { useOnScreenKeyboardStatus } from './status';

export const useIsOnScreenKeyboardVisible = (): Ref<boolean> => {
  const onScreenKeyboardStatus = useOnScreenKeyboardStatus();

  return computed(() => onScreenKeyboardStatus.value === OnScreenKeyboardStatus.visible);
};
