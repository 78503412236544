<template>
  <MarkdownExternalUrlPreviewNode
    v-if="isEmbeddable"
    :darkMode="darkMode"
    :externalUrl="externalUrl"
    :features="features"
    :node="node"
    :s3BucketConfigForImageKind="s3BucketConfigForImageKind"
  />
  <MarkdownPlainLinkNode v-else :href="href" :features="features" :node="node">
    <slot />
  </MarkdownPlainLinkNode>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';

import { s3BucketConfigForImageKindVueComponentProperty as s3BucketConfigForImageKind } from '@caff/image-frontend-model';

import { MarkdownLinkBlockNode, MarkdownFeature } from './parsers';
import {
  darkModeVueProp,
  externalUrlMetadataByUrlVueProp,
  featuresVueProp,
  nodeVueProp,
} from './props';

import MarkdownExternalUrlPreviewNode from './MarkdownExternalUrlPreviewNode.vue';
import MarkdownPlainLinkNode from './MarkdownPlainLinkNode.vue';

export default defineComponent({
  name: 'MarkdownLinkNode',
  components: {
    MarkdownExternalUrlPreviewNode,
    MarkdownPlainLinkNode,
  },
  props: {
    darkMode: darkModeVueProp,
    externalUrlMetadataByUrl: externalUrlMetadataByUrlVueProp,
    features: featuresVueProp,
    node: nodeVueProp,
    s3BucketConfigForImageKind,
  },
  setup(props) {
    const areLinksPreviewsEnabled = computed(
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      () => !!props.features![MarkdownFeature.embedLinksPreviews],
    );

    const href = computed(() => (props.node as MarkdownLinkBlockNode).href);

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const externalUrl = computed(() => props.externalUrlMetadataByUrl![href.value] || null);

    const isEmbeddable = computed(() => !!(areLinksPreviewsEnabled.value && externalUrl.value));

    return {
      isEmbeddable,
      externalUrl,
      href,
    };
  },
});
</script>
