import { Ref, computed } from 'vue';

import { useModalStore } from '../store';

export const useIsForgotPasswordModalOpened = (): Ref<boolean> =>
  computed(() => {
    const modalStore = useModalStore();

    return modalStore.isForgotPasswordOpened;
  });

export const useIsChangePasswordModalOpened = (): Ref<boolean> =>
  computed(() => {
    const modalStore = useModalStore();

    return modalStore.isChangePasswordOpened;
  });

export const useIsRegisterModalOpened = (): Ref<boolean> =>
  computed(() => {
    const modalStore = useModalStore();

    return modalStore.isRegisterOpened;
  });
