import type { IconDefinition, IconName, IconPrefix } from '@fortawesome/fontawesome-common-types';

export const prefix = 'caff' as IconPrefix;
export const iconName = 'gear' as IconName;
export const width = 14;
export const height = 14;
export const ligatures = [];
export const unicode = 'f823';
export const svgPathData =
  'm8.51159188 0c.55655276 0 1.00772792.44771525 1.00772792 1l.000151.51979178c.3567155.15826371.6951508.34981673 1.0112942.57067819l.445883-.25553348c.144959-.08305034.3020683-.12630035.4584776-.13316679l.0780868-.00041707c.3116382.01033301.6119102.16392245.7980152.4334342l.0420023.06617506 1.5115919 2.59807622c.2650251.45551678.1278571 1.03193858-.3021676 1.32434514l-.0666864.04168026-.4245334.2432461c.0192011.19465217.0290286.39203242.0290286.59169039 0 .19948747-.0098107.3967011-.0289794.59119171l.4244842.24374478c.4819888.27614238.6471304.88773278.368854 1.3660254l-1.5115919 2.59807621c-.1722663.2960859-.4740765.4716808-.7933698.4969883l-.0739425.0031588c-.1730184.0010824-.3486067-.0420742-.5092696-.1341217l-.445883-.2555335c-.3161434.2208615-.6545787.4124145-1.0112942.5706782l-.000151.5197918c0 .5522847-.45117516 1-1.00772792 1h-3.02318376c-.55655276 0-1.00772792-.4477153-1.00772792-1l-.00025116-.5691201c-.26806863-.1253138-.52535712-.2695964-.77010795-.431104l-.18118486-.124333-.5057842.2896206c-.14495905.0830503-.30206828.1263004-.45847762.1331668l-.07808686.0004171c-.31163819-.010333-.6119101-.1639225-.79801511-.4334342l-.04200235-.0661751-1.51159188-2.59807621c-.26502513-.45551678-.1278571-1.03193858.30216756-1.32434514l.06668646-.04168026.52720565-.30217321c-.01556331-.17552764-.02350996-.35322456-.02350996-.53276328 0-.17936822.00793156-.35689813.02346564-.5322632l-.52716133-.30267329c-.48198883-.27614238-.6471304-.88773278-.36885402-1.3660254l1.51159188-2.59807622c.17226633-.29608591.47407647-.47168077.79336983-.4969883l.07394243-.00315882c.17301847-.00108236.34860673.04207426.50926968.13412172l.5057842.28962066c.29796521-.21251375.61620703-.39879486.95129281-.55543702l.00025116-.56912013c0-.55228475.45117516-1 1.00772792-1zm-1.45749644 4.5c-1.3913819 0-2.5193198 1.11928813-2.5193198 2.5s1.1279379 2.5 2.5193198 2.5c1.39138191 0 2.5193198-1.11928813 2.5193198-2.5s-1.12793789-2.5-2.5193198-2.5z';

export const definition = {
  prefix,
  iconName,
  icon: [width, height, ligatures, unicode, svgPathData],
} as IconDefinition;

export const faGear = definition;
