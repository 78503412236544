import { computed, Ref, useSSRContext } from 'vue';

import { ServerSideRenderingSharedContext } from '@caff/server-side-rendering-context-isomorphic-model';
import { isServer } from '@caff/isomorphic-is-server';

import { getApiBaseUrl } from '../utils/network';

export const useApiBaseUrl = (): Ref<URL | null> =>
  computed(() => {
    if (!isServer()) {
      return getApiBaseUrl({ requestedUrl: '' });
    }

    const ssrContext = useSSRContext<{ initialState: ServerSideRenderingSharedContext }>();
    return ssrContext?.initialState ? getApiBaseUrl(ssrContext.initialState) : null;
  });
