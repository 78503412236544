<template>
  <DropdownMenu v-if="isAtLeastOneActionAllowed" :popupClass="popupClass">
    <template #toggleButton="passedProps">
      <slot v-bind="passedProps" />
    </template>

    <template #default="{ closePopover }">
      <MuteThreadMenuEntry :threadSlug="threadSlug" @actionConfirmed="closePopover()" />
      <UnmuteThreadMenuEntry :threadSlug="threadSlug" @actionConfirmed="closePopover()" />
      <template v-if="author">
        <MuteUserMenuEntry :username="author.username" @actionConfirmed="closePopover()" />
        <UnmuteUserMenuEntry :username="author.username" @actionConfirmed="closePopover()" />
      </template>
      <DropdownMenuEntrySeparator v-if="isModerationActionsSeparatorRequired" />
      <HideThreadMenuEntry :threadSlug="threadSlug" @actionConfirmed="closePopover()" />
      <DeleteThreadMenuEntry :threadSlug="threadSlug" @actionConfirmed="closePopover()" />
    </template>
  </DropdownMenu>
</template>

<script lang="ts" setup>
import { computed } from 'vue';

import { DropdownMenu, DropdownMenuEntrySeparator } from '@caff/cds-dropdown-menu';
import { ThreadSlug } from '@caff/shallow-thread-api-model';

import { useThread, useCurrentlyLoggedInUser, useUser } from '../../composition';
import MuteUserMenuEntry, {
  isAllowed as isDisablingNotificationsFromUserAllowed,
} from '../user-advanced-options-menu/MuteUserMenuEntry.vue';
import UnmuteUserMenuEntry, {
  isAllowed as isEnablingNotificationsFromUserAllowed,
} from '../user-advanced-options-menu/UnmuteUserMenuEntry.vue';
import MuteThreadMenuEntry, {
  isAllowed as isDisablingNotificationsInThreadAllowed,
} from './MuteThreadMenuEntry.vue';
import UnmuteThreadMenuEntry, {
  isAllowed as isEnablingNotificationsInThreadAllowed,
} from './UnmuteThreadMenuEntry.vue';
import HideThreadMenuEntry, { isAllowed as isHidingThreadAllowed } from './HideThreadMenuEntry.vue';
import DeleteThreadMenuEntry, {
  isAllowed as isDeletingThreadAllowed,
} from './DeleteThreadMenuEntry.vue';

const props = defineProps<{
  threadSlug: ThreadSlug;
  popupClass?: string | Array<string> | Record<string, boolean>;
}>();

const { currentlyLoggedInUser } = useCurrentlyLoggedInUser();
const { thread } = useThread(computed(() => props.threadSlug));
const { user: author } = useUser(computed(() => thread.value?.authorCanonicalUsername ?? null));

const isAtLeastOneRegularActionAllowed = computed(
  () =>
    (thread.value &&
      (isDisablingNotificationsInThreadAllowed(currentlyLoggedInUser.value, thread.value) ||
        isEnablingNotificationsInThreadAllowed(currentlyLoggedInUser.value, thread.value))) ||
    (author.value &&
      (isDisablingNotificationsFromUserAllowed(currentlyLoggedInUser.value, author.value) ||
        isEnablingNotificationsFromUserAllowed(currentlyLoggedInUser.value, author.value))),
);

const isAtLeastOneModerationActionAllowed = computed(
  () =>
    isHidingThreadAllowed(currentlyLoggedInUser.value) ||
    isDeletingThreadAllowed(currentlyLoggedInUser.value),
);

const isModerationActionsSeparatorRequired = computed(
  () => isAtLeastOneRegularActionAllowed.value && isAtLeastOneModerationActionAllowed.value,
);

const isAtLeastOneActionAllowed = computed(
  () => isAtLeastOneRegularActionAllowed.value || isAtLeastOneModerationActionAllowed.value,
);
</script>
