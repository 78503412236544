import { computed, ref } from 'vue';
import type { Ref } from 'vue';
import type { AxiosError } from 'axios';

import { LoggedInUser } from '../../models/User';
import type { RawLoggedInUser } from '../../models/User';
import { useToastrStore } from '../../store';
import { captureException } from '../../utils/sentry';
import { useAxiosQuery } from '../useAxiosQuery';
import type { UseAxiosQueryResult } from '../useAxiosQuery';
import { setUser } from '../user';
import { useOnCurrentlyLoggedInUserChange } from './simple';

export const useCurrentlyLoggedInUser = (): UseAxiosQueryResult<RawLoggedInUser> & {
  currentlyLoggedInUser: Ref<LoggedInUser | null>;
} => {
  const toastrStore = useToastrStore();
  const { data, invalidate, ...rest } = useAxiosQuery({
    queryKey: ref('currentlyLoggedInUser'),
    async queryFn({ axiosInstance, setQueryData }) {
      try {
        const { data: rawLoggedInUserData }: { data: RawLoggedInUser } = await axiosInstance.get(
          '/account/settings',
        );

        await setUser({ setQueryData, user: rawLoggedInUserData });

        return rawLoggedInUserData;
      } catch (error) {
        const typedError = error as AxiosError;

        if (!typedError.response) {
          captureException(error);
          throw error;
        }

        switch (typedError.response.status) {
          // Session token cookie was not sent so user was not logged in
          case 400:
            return null;

          // Session token cookie was sent but it was invalid (expired session?)
          case 401: {
            toastrStore.showSessionInvalidToastr();
            return null;
          }

          default:
            captureException(error);
            throw error;
        }
      }
    },
  });

  useOnCurrentlyLoggedInUserChange(async () => {
    await invalidate();
  });

  return {
    ...rest,
    currentlyLoggedInUser: computed(() => (data.value ? new LoggedInUser(data.value) : null)),
    invalidate,
  };
};
