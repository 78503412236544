import { isServer } from '@caff/isomorphic-is-server';

export const isDOMElementPartOfModal = (initialElement: HTMLElement): boolean => {
  for (let element: HTMLElement | null = initialElement; element; element = element.parentElement) {
    if (element.tagName.toLowerCase() === 'dialog') {
      return true;
    }
  }

  return false;
};

/**
 * Returns the offset of given element relative to document's body.
 *
 * @param {HTMLElement} initialElement Element whose offset will be returned.
 * @returns {{left: number, top: number}} Offset of given element relative to
 * document body.
 */
export const getDOMElementOffset = (initialElement: Element): { left: number; top: number } => {
  if (isServer()) {
    return { left: 0, top: 0 };
  }

  const boundingRect = initialElement.getBoundingClientRect();

  const body = document.body;
  const docEl = document.documentElement;

  const scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
  const scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;

  const clientTop = docEl.clientTop || body.clientTop || 0;
  const clientLeft = docEl.clientLeft || body.clientLeft || 0;

  const top = boundingRect.top + scrollTop - clientTop;
  const left = boundingRect.left + scrollLeft - clientLeft;

  return {
    top: Math.round(top),
    left: Math.round(left),
  };
};
