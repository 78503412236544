import { computed } from 'vue';
import type { Ref } from 'vue';

import type { RawLoggedInUser } from '../../models/User';
import type { UseAxiosQueryResult } from '../useAxiosQuery';
import { useCurrentlyLoggedInUser } from './useCurrentlyLoggedInUser';

export const useIsLoggedIn = (): UseAxiosQueryResult<RawLoggedInUser> & {
  isLoggedIn: Ref<boolean>;
} => {
  const { currentlyLoggedInUser, ...rest } = useCurrentlyLoggedInUser();

  const isLoggedIn = computed(() => !!currentlyLoggedInUser.value);

  return {
    ...rest,
    isLoggedIn,
  };
};
