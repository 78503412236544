import type { IconDefinition, IconName, IconPrefix } from '@fortawesome/fontawesome-common-types';

export const prefix = 'caff' as IconPrefix;
export const iconName = 'camera-plus' as IconName;
export const width = 14;
export const height = 14;
export const ligatures = [];
export const unicode = 'f812';
export const svgPathData =
  'm8 0c1.59768088 0 2.9036609 1.24891996 2.9949073 2.82372721l.0050927.17627279c1.6568542 0 3 1.34314575 3 3v5c0 1.6568542-1.3431458 3-3 3h-8c-1.65685425 0-3-1.3431458-3-3v-5c0-1.65685425 1.34314575-3 3-3 0-1.65685425 1.34314575-3 3-3zm-1 5c-1.65685425 0-3 1.34314575-3 3s1.34314575 3 3 3 3-1.34314575 3-3-1.34314575-3-3-3z';

export const definition = {
  prefix,
  iconName,
  icon: [width, height, ligatures, unicode, svgPathData],
} as IconDefinition;

export const faCameraPlus = definition;
