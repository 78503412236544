import type { IconDefinition, IconName, IconPrefix } from '@fortawesome/fontawesome-common-types';

export const prefix = 'caff' as IconPrefix;
export const iconName = 'cross' as IconName;
export const width = 14;
export const height = 14;
export const ligatures = [];
export const unicode = 'f817';
export const svgPathData =
  'm7 4.967-3.04602359-3.04595559-.089492-.08174713c-.58841389-.48074972-1.42068851-.44102518-1.94345041.08173672s-.56248644 1.35503652-.09826878 1.92419972l.08088848.0904522 3.0633463 3.06431408-3.04595559 3.0460236-.08174713.089492c-.48074972.5884139-.44102518 1.4206885.08173672 1.9434504l.10917564.0986798c.56461238.460506 1.39746386.4276127 1.92375636-.0986798l3.046034-3.046966 3.0460236 3.0469556.089492.0817471c.5884139.4807497 1.4206885.4410252 1.9434504-.0817367s.5624864-1.3550365.0982688-1.9241997l-.0808885-.0904522-3.0643463-3.0653141 3.0469556-3.04502359.0817471-.089492c.4807497-.58841389.4410252-1.42068851-.0817367-1.94345041-.5613787-.56137867-1.4715533-.56137867-2.032932 0z';

export const definition = {
  prefix,
  iconName,
  icon: [width, height, ligatures, unicode, svgPathData],
} as IconDefinition;

export const faCross = definition;
