import type { Ref } from 'vue';
import { computed, ref } from 'vue';

import { UseMutationReturnValue } from '@caff/frontend-use-query';
import type { ThreadSlug } from '@caff/shallow-thread-api-model';
import { ALL_THREAD_ORDERS } from '@caff/shallow-thread-api-model';

import { useAxiosMutation } from '../useAxiosQuery';
import {
  useAuthorFrontpageThreadSlugs,
  useCategoryFrontpageThreadSlugs,
  useGeneralFrontpageThreadSlugs,
} from '../useFrontpage';
import { useThread } from './simple';

const useInvalidateThread = (threadSlug: Ref<ThreadSlug>) => {
  const { invalidate, thread } = useThread(threadSlug);

  const queriesToInvalidate = ALL_THREAD_ORDERS.flatMap((singleThreadOrder) => {
    const threadOrder = ref(singleThreadOrder);

    return [
      useGeneralFrontpageThreadSlugs({ threadOrder }),
      ...(thread.value
        ? [
            useAuthorFrontpageThreadSlugs({
              threadOrder,
              username: ref(thread.value.authorCanonicalUsername),
            }),
            useCategoryFrontpageThreadSlugs({
              threadOrder,
              categorySlug: ref(thread.value.categorySlug),
            }),
          ]
        : []),
    ];
  });

  return async () => {
    await invalidate();

    for (const singleQuery of queriesToInvalidate) {
      await singleQuery.invalidate();
    }
  };
};

const getRemoveThreadModerationMutationKey = (threadSlug: Ref<ThreadSlug>) =>
  computed(() => `moderation/thread/${threadSlug.value}/remove`);

export const useHideThread = (
  threadSlug: Ref<ThreadSlug>,
): Omit<UseMutationReturnValue<void, void, Error>, 'mutate'> & {
  hideThread: UseMutationReturnValue<void, void, Error>['mutate'];
} => {
  const invalidateThread = useInvalidateThread(threadSlug);

  const { mutate: hideThread, ...rest } = useAxiosMutation({
    async mutationFn({ axiosInstance }) {
      await axiosInstance.post(`/moderation/thread/${threadSlug.value}/delete`);

      await invalidateThread();
    },
    mutationKey: getRemoveThreadModerationMutationKey(threadSlug),
  });

  return {
    ...rest,
    hideThread,
  };
};

export const useDeleteThread = (
  threadSlug: Ref<ThreadSlug>,
): Omit<UseMutationReturnValue<void, void, Error>, 'mutate'> & {
  deleteThread: UseMutationReturnValue<void, void, Error>['mutate'];
} => {
  const invalidateThread = useInvalidateThread(threadSlug);

  const { mutate: deleteThread, ...rest } = useAxiosMutation({
    async mutationFn({ axiosInstance }) {
      await axiosInstance.post(`/moderation/thread/${threadSlug.value}/delete/permanent`);

      await invalidateThread();
    },
    mutationKey: getRemoveThreadModerationMutationKey(threadSlug),
  });

  return {
    ...rest,
    deleteThread,
  };
};
