<template>
  <h2 class="c-modal-h2" :class="{ 'c-modal-h2--center': center }">
    <slot />
  </h2>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'H2',
  props: {
    center: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<style lang="scss" scoped>
@import '@caff/cds-scss-core';

.c-modal-h2 {
  @include t-font($font-header-big);
}

.c-modal-h2--center {
  text-align: center;
}
</style>
