<template>
  <label class="c-single-line-text-input">
    <span class="c-single-line-text-input__label">
      {{ labelOrPlaceholder }}
    </span>

    <input
      ref="inputElement"
      :type="type"
      :value="modelValue"
      :placeholder="placeholder"
      :disabled="disabled"
      :name="name"
      :autocomplete="autocomplete"
      :required="required"
      class="c-single-line-text-input__input"
      @input="onInput($event)"
      @focus="onFocus($event)"
      @blur="onBlur($event)"
    />
  </label>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref } from 'vue';

export default defineComponent({
  name: 'SingleLineTextInput',
  props: {
    modelValue: {
      type: String,
      required: false,
    },

    type: {
      type: String,
      default: 'text',
    },

    name: {
      type: String,
      required: false,
    },

    autocomplete: {
      type: String,
      required: false,
    },

    required: {
      type: Boolean,
      required: false,
    },

    autofocus: {
      type: Boolean,
      required: false,
    },

    placeholder: {
      type: String,
      required: true,
    },

    label: {
      type: String,
      required: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['autofocus', 'update:modelValue', 'blur', 'focus'],
  setup(props, { emit }) {
    const inputElement = ref<HTMLInputElement | null>(null);
    const labelOrPlaceholder = computed(() => props.label || props.placeholder);

    onMounted(() => {
      if (!props.autofocus) {
        return;
      }

      inputElement.value?.focus();

      /**
       * Input was just autofocused.
       *
       * @event autofocus
       */
      emit('autofocus');
    });

    const onInput = ($event: Event) => {
      const target = $event.target as HTMLInputElement;

      /**
       * @event update:modelValue
       * @type {String} New value of this field.
       */
      emit('update:modelValue', target.value);
    };

    const onBlur = ($event: Event) => {
      /**
       * User blurred this input.
       *
       * @event blur
       * @type {Event}
       */
      emit('blur', $event);
    };

    const onFocus = ($event: Event) => {
      /**
       * User focused this input.
       *
       * @event focus
       * @type {Event}
       */
      emit('focus', $event);
    };

    return { labelOrPlaceholder, inputElement, onInput, onBlur, onFocus };
  },
});
</script>

<style lang="scss" scoped>
@import '@caff/cds-scss-core';

.c-single-line-text-input {
  display: flex;
}

.c-single-line-text-input__input {
  @include t-font($font-header-small);

  background: transparent;
  border: 0;
  flex-grow: 1;
  flex-shrink: 1;
  padding: 0;

  &::placeholder {
    @include t-font($font-header-small-soft);
  }
}

.c-single-line-text-input__label {
  @extend %t-accessibility-visually-hidden;
}
</style>
