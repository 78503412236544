import { PropType } from 'vue';

import { ExternalUrl } from '@caff/external-url-api-model';
import { UserMention } from '@caff/mention-api-model';
import { User, Username } from '@caff/user-api-model';

import { MarkdownFeaturesConfig, MarkdownMentions, MarkdownNode } from './parsers';

export const darkModeVueProp = {
  type: Boolean,
  required: true,
} as const;

export const nodeVueProp = {
  type: Object as PropType<MarkdownNode>,
  required: true,
} as const;

export const featuresVueProp = {
  type: Object as PropType<MarkdownFeaturesConfig>,
  required: true,
} as const;

export const mentionsVueProp = {
  type: Object as PropType<MarkdownMentions>,
  required: true,
} as const;

export const externalUrlMetadataByUrlVueProp = {
  type: Object as PropType<Record<string, ExternalUrl>>,
  required: true,
} as const;

export const userMentionsVueProp = {
  type: Array as PropType<Array<UserMention>>,
  required: true,
} as const;

export const usersByLowercaseUsernameVueProp = {
  type: Object as PropType<Record<Username, User | null>>,
  required: true,
} as const;
