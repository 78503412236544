export const enum ThreadOrder {
  newestFirst = 'newestFirst',
  polemicFirst = 'polemicFirst',
}

export const ALL_THREAD_ORDERS = [ThreadOrder.newestFirst, ThreadOrder.polemicFirst];
const UNIQUE_THREAD_ORDERS = new Set(ALL_THREAD_ORDERS);

export const threadOrderValidatorFactory = ({
  required,
}: {
  required: boolean;
}): {
  type: typeof String;
  required?: boolean;
  validator: (value: string) => boolean;
} => ({
  type: String,
  required,
  validator(value: string) {
    if (!UNIQUE_THREAD_ORDERS.has(value as ThreadOrder)) {
      console.error(
        `Invalid ThreadOrder: «${value}». Expected one of: ${[...ALL_THREAD_ORDERS].join(', ')}`,
      );
      return false;
    }

    return true;
  },
});
