import { S3Config } from './S3Config';
import { ImageFormat } from './ImageFormat';
import { ImageSizeForLocation } from './ImageLocation';
import { ImageSize } from './ImageSize';

export const enum ImageKind {
  external_url_thumbnail = 'external_url_thumbnail',
  user_avatar = 'user_avatar',
  category_avatar = 'category_avatar',
  category_banner = 'category_banner',
  category_logo = 'category_logo',
}

export type S3ConfigForImageKind = Record<ImageKind, S3Config>;

export const qaS3ConfigForImageKind: S3ConfigForImageKind = {
  [ImageKind.external_url_thumbnail]: {
    bucketName: 'caff-qa-thumbnails',
    bucketUrl: 'https://caff-qa-thumbnails.ams3.digitaloceanspaces.com',
    prefix: 'thumbnails',
  },
  [ImageKind.user_avatar]: {
    bucketName: 'caff-qa-avatars',
    bucketUrl: 'https://caff-qa-avatars.ams3.digitaloceanspaces.com',
    prefix: 'avatars',
  },
  [ImageKind.category_avatar]: {
    bucketName: 'caff-static',
    bucketUrl: 'https://caff-static.ams3.digitaloceanspaces.com',
    prefix: 'categories/avatar',
  },
  [ImageKind.category_banner]: {
    bucketName: 'caff-static',
    bucketUrl: 'https://caff-static.ams3.digitaloceanspaces.com',
    prefix: 'categories/banner',
  },
  [ImageKind.category_logo]: {
    bucketName: 'caff-static',
    bucketUrl: 'https://caff-static.ams3.digitaloceanspaces.com',
    prefix: 'categories/logo',
  },
};

export const productionS3ConfigForImageKind: S3ConfigForImageKind = {
  [ImageKind.external_url_thumbnail]: {
    bucketName: 'caff-thumbnails',
    bucketUrl: 'https://caff-thumbnails.ams3.digitaloceanspaces.com',
    prefix: 'thumbnails',
  },
  [ImageKind.user_avatar]: {
    bucketName: 'caff-avatars',
    bucketUrl: 'https://caff-avatars.ams3.digitaloceanspaces.com',
    prefix: 'avatars',
  },
  [ImageKind.category_avatar]: {
    bucketName: 'caff-static',
    bucketUrl: 'https://caff-static.ams3.digitaloceanspaces.com',
    prefix: 'categories/avatar',
  },
  [ImageKind.category_banner]: {
    bucketName: 'caff-static',
    bucketUrl: 'https://caff-static.ams3.digitaloceanspaces.com',
    prefix: 'categories/banner',
  },
  [ImageKind.category_logo]: {
    bucketName: 'caff-static',
    bucketUrl: 'https://caff-static.ams3.digitaloceanspaces.com',
    prefix: 'categories/logo',
  },
};

export const REQUIRED_SIZES_FOR_IMAGE_KIND: Record<ImageKind, Array<ImageSize>> = {
  [ImageKind.user_avatar]: [
    ImageSizeForLocation.sidebarAvatar,
    ImageSizeForLocation.largeAvatar,
    ImageSizeForLocation.smallAvatar,
  ],
  [ImageKind.external_url_thumbnail]: [
    ImageSizeForLocation.frontpageThumbnail,
    ImageSizeForLocation.sidebarThumbnail,
    ImageSizeForLocation.fullBodyThumbnail,
    ImageSizeForLocation.fullBodyEmbedThumbnail,
  ],
  [ImageKind.category_avatar]: [ImageSizeForLocation.smallAvatar, ImageSizeForLocation.largeAvatar],
  [ImageKind.category_banner]: [
    ImageSizeForLocation.headerBanner,
    ImageSizeForLocation.discoverBanner,
    ImageSizeForLocation.sidebarBanner,
  ],
  [ImageKind.category_logo]: [],
};

export const SUPPORTED_FORMATS_FOR_IMAGE_KIND: Record<ImageKind, Array<ImageFormat>> = {
  [ImageKind.user_avatar]: [ImageFormat.jpeg, ImageFormat.webp],
  [ImageKind.external_url_thumbnail]: [ImageFormat.jpeg, ImageFormat.webp],
  [ImageKind.category_avatar]: [ImageFormat.avif, ImageFormat.jpeg, ImageFormat.webp],
  [ImageKind.category_banner]: [ImageFormat.avif, ImageFormat.jpeg, ImageFormat.webp],
  [ImageKind.category_logo]: [ImageFormat.svg],
};
