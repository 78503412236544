import { ref } from 'vue';

import { UseMutationReturnValue } from '@caff/frontend-use-query';
import { Deferred } from '@caff/isomorphic-promise';
import { SocketMessageEvent } from '@caff/socket-message-api-model';

import { getCanonicalUsername } from '../models/User';
import { useToastrStore } from '../store';
import { useAxiosMutation } from './useAxiosQuery';
import { useCurrentlyLoggedInUser } from './useSession';
import { useAddTemporaryMessageHandler } from './useSocket';

export const useUpdateCurrentlyLoggedInUserAvatar = (): Omit<
  UseMutationReturnValue<{ avatar: File }, void, Error>,
  'mutate'
> & { updateAvatar: UseMutationReturnValue<{ avatar: File }, void, Error>['mutate'] } => {
  const { invalidate, currentlyLoggedInUser } = useCurrentlyLoggedInUser();

  const addTemporaryMessageHandler = useAddTemporaryMessageHandler({
    eventName: SocketMessageEvent.userUpdated,
  });

  const toastrStore = useToastrStore();

  const { mutate: updateAvatar, ...rest } = useAxiosMutation<{ avatar: File }, void, Error>({
    mutationKey: ref('updateCurrentlyLoggedInUserAvatar'),
    async mutationFn({ axiosInstance, param: { avatar } }) {
      const currentlyLoggedInCanonicalUsername = currentlyLoggedInUser.value?.canonicalUsername;

      if (!currentlyLoggedInCanonicalUsername) {
        return;
      }

      const data = new FormData();
      data.append('avatar', avatar);

      const avatarProcessingFinishedDeferred = new Deferred<void>();
      const removeTemporaryMessageHandler = addTemporaryMessageHandler(
        ({ message, removeHandler }) => {
          if (message.event !== SocketMessageEvent.userUpdated) {
            return;
          }

          const updatedUserCanonicalUsername = getCanonicalUsername(message.username);

          if (updatedUserCanonicalUsername !== currentlyLoggedInCanonicalUsername) {
            return;
          }

          removeHandler();
          avatarProcessingFinishedDeferred.resolve();
        },
      );

      const { dismiss: dismissUploadingToastr } = toastrStore.showUploadingToastr();

      try {
        try {
          await axiosInstance.put('/account/settings/avatar', data, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });
        } finally {
          dismissUploadingToastr();
        }

        const { dismiss: dismissProcessingToastr } = toastrStore.showProcessingToastr();

        await avatarProcessingFinishedDeferred;

        toastrStore.showAvatarSuccessfullyProcessedToastr();

        dismissProcessingToastr();

        await invalidate();
      } finally {
        removeTemporaryMessageHandler();
      }
    },
  });

  return {
    ...rest,
    updateAvatar,
  };
};
