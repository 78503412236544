import type { IconDefinition, IconName, IconPrefix } from '@fortawesome/fontawesome-common-types';

export const prefix = 'caff' as IconPrefix;
export const iconName = 'retry-cw' as IconName;
export const width = 14;
export const height = 14;
export const ligatures = [];
export const unicode = 'f845';
export const svgPathData =
  'm7.152241 6.16593312c-.42167284.35488144-.5328411.94695987-.301079 1.42403l.065104.116668.073346.102974c.38083366.47878809 1.05662913.57761017 1.553259.245102l3.7389642-2.89986904.1010191-.09609349.0720979-.08485313.0677481-.09754857.0344601-.05939439.0531491-.11116528.0305184-.08227137.0322747-.11715725.0169-.09508782.0098243-.1072363.0001731-.0994005-.0159683-.15853478-.0228994-.10177508-.0179729-.06099498-.0430203-.11289419-.0266294-.05631194-.041797-.07667589-.0398784-.0608708-.0914221-.11613216-.0783163-.07976946-3.6757139-2.85750158c-.50209378-.422564-1.24873394-.34576224-1.65677.167225-.34202317.42999513-.33939582 1.03523717.000052 1.472869l.091874.105717.070752.065127.97171.61689288-1.624-.00099988c-2.97234885 0-5.37997181 2.29910448-5.49564863 5.22367844l-.00435137.22048965c0 3.03756609 2.46243389 5.49999999 5.5 5.49999999 1.8731901 0 3.5044293-.959554 4.5208255-2.3667029.6116549-.8468051.2107784-1.67935956-.4676278-1.976827-.5879521-.25780512-1.05644915.06411298-1.46912234.460798l-.18673243.1865555c-.62855872.6455564-1.41757006 1.1961764-2.39734293 1.1961764-1.65685425 0-3-1.3431457-3-2.99999999 0-1.4706923 1.06571864-2.71817946 2.46469824-2.95395437l.09805768-.0076411 2.24224408-.00057274z';

export const definition = {
  prefix,
  iconName,
  icon: [width, height, ligatures, unicode, svgPathData],
} as IconDefinition;

export const faRetryCw = definition;
