<template>
  <ClientOnly>
    <div class="c-toastrs-collection">
      <TransitionGroup name="vertical-list">
        <template v-for="singleToastr of toastrs" :key="singleToastr.id">
          <AvatarSuccessfullyProcessedToastr
            v-if="singleToastr.type === ToastrType.avatarSuccessfullyProcessed"
            :idToastr="singleToastr.id"
            class="c-toastrs-collection__item"
            @dismiss="dismiss(singleToastr.id)"
          />
          <GenericRetryableErrorToastr
            v-else-if="singleToastr.type === ToastrType.genericRetryableError"
            :idToastr="singleToastr.id"
            class="c-toastrs-collection__item"
            @retry="singleToastr.retry()"
            @dismiss="dismiss(singleToastr.id)"
          />
          <GenericSuccessToastr
            v-else-if="singleToastr.type === ToastrType.genericSuccess"
            :idToastr="singleToastr.id"
            :message="singleToastr.message"
            :timeoutInMilliseconds="singleToastr.timeoutInMilliseconds"
            class="c-toastrs-collection__item"
            @dismiss="dismiss(singleToastr.id)"
          />
          <InvalidSessionToastr
            v-else-if="singleToastr.type === ToastrType.invalidSession"
            :idToastr="singleToastr.id"
            class="c-toastrs-collection__item"
            @dismiss="dismiss(singleToastr.id)"
          />
          <NewPostReplySuccessfullyCreatedToastr
            v-else-if="singleToastr.type === ToastrType.newPostReplySuccessfullyCreated"
            :idToastr="singleToastr.id"
            :idChildPost="singleToastr.idChildPost"
            :idParentPost="singleToastr.idParentPost"
            class="c-toastrs-collection__item"
            @dismiss="dismiss(singleToastr.id)"
          />
          <NewThreadSuccessfullyCreatedToastr
            v-else-if="singleToastr.type === ToastrType.newThreadSuccessfullyCreated"
            :idToastr="singleToastr.id"
            :threadSlug="singleToastr.threadSlug"
            class="c-toastrs-collection__item"
            @dismiss="dismiss(singleToastr.id)"
          />
          <ProcessingAvatarToastr
            v-else-if="singleToastr.type === ToastrType.avatarProcessing"
            class="c-toastrs-collection__item"
          />
        </template>
      </TransitionGroup>
    </div>
  </ClientOnly>
</template>

<script lang="ts" setup>
import { ClientOnly } from '@caff/client-only-frontend-component';

import { Toastr, ToastrType } from '../../models/Toastr';
import { useToastrStore } from '../../store';
import AvatarSuccessfullyProcessedToastr from './AvatarSuccessfullyProcessedToastr.vue';
import GenericRetryableErrorToastr from './GenericRetryableErrorToastr.vue';
import InvalidSessionToastr from './InvalidSessionToastr.vue';
import NewPostReplySuccessfullyCreatedToastr from './NewPostReplySuccessfullyCreatedToastr.vue';
import NewThreadSuccessfullyCreatedToastr from './NewThreadSuccessfullyCreatedToastr.vue';
import ProcessingAvatarToastr from './ProcessingAvatarToastr.vue';
import GenericSuccessToastr from './GenericSuccessToastr.vue';

defineProps<{
  toastrs: Array<Toastr>;
}>();

const toastrStore = useToastrStore();
const dismiss = (idToastr: Toastr['id']) => {
  toastrStore.dismiss(idToastr);
};
</script>

<style lang="scss" scoped>
@import '@caff/cds-scss-core';

.c-toastrs-collection {
  @extend %t-z-index-toastr;

  align-items: flex-start;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  left: var(--spacing-xs);
  overflow: hidden;
  pointer-events: none;
  position: fixed;
  right: var(--spacing-xs);
  top: var(--spacing-xs);

  @include t-responsive--starting-at($responsive-breakpoint-tablet-medium) {
    right: initial;
  }
}

.c-toastrs-collection__item {
  transition: all 500ms;
  transition-property: opacity, transform;
}

.vertical-list-enter,
.vertical-list-leave-to {
  opacity: 0;
  transform: translateY(var(--spacing-l));
}

.vertical-list-leave-active {
  position: absolute;
}
</style>
