<template>
  <Button :type="signupButtonType" :active="isRegisterModalOpened" @click="showRegisterModal()">
    <FontAwesomeIcon :icon="faUser" fixed-width />

    <span class="u-responsive--display-none--smaller-than-tablet-medium">
      {{ t('action.signup') }}
    </span>
  </Button>
</template>

<script lang="ts">
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';

import { Button, ButtonType } from '@caff/cds-button';
import { faUser } from '@caff/cds-icons';

import { useIsRegisterModalOpened } from '../../composition';
import { useModalStore } from '../../store';

export default defineComponent({
  name: 'MainNavbarSignupButton',
  components: {
    Button,
    FontAwesomeIcon,
  },
  setup() {
    const { t } = useI18n();

    const isRegisterModalOpened = useIsRegisterModalOpened();

    const modalStore = useModalStore();
    const showRegisterModal = () => modalStore.showRegisterModal();

    return {
      t,
      faUser,
      signupButtonType: ButtonType.primary,
      showRegisterModal,
      isRegisterModalOpened,
    };
  },
});
</script>

<i18n lang="json" locale="es">
{
  "action": {
    "signup": "Crear cuenta"
  }
}
</i18n>
