import { PostId } from '@caff/post-api-model';
import { ThreadSlug } from '@caff/shallow-thread-api-model';

export const enum ToastrType {
  invalidSession = 'invalidSession',
  avatarProcessing = 'avatarProcessing',
  avatarUploading = 'avatarUploading',
  avatarSuccessfullyProcessed = 'avatarSuccessfullyProcessed',
  genericSuccess = 'genericSuccess',
  genericRetryableError = 'genericRetryableError',
  newThreadSuccessfullyCreated = 'newThreadSuccessfullyCreated',
  newPostReplySuccessfullyCreated = 'newPostReplySuccessfullyCreated',
}

export interface BaseToastr {
  id: number;
  type: ToastrType;
  timeoutInMilliseconds: number | null;
}

export interface GenericSuccessToastr extends BaseToastr {
  type: ToastrType.genericSuccess;
  message: string;
}

export interface InvalidSessionToastr extends BaseToastr {
  type: ToastrType.invalidSession;
}

export interface AvatarUploadingToastr extends BaseToastr {
  type: ToastrType.avatarUploading;
}

export interface AvatarProcessingToastr extends BaseToastr {
  type: ToastrType.avatarProcessing;
}

export interface AvatarSuccessfullyProcessedToastr extends BaseToastr {
  type: ToastrType.avatarSuccessfullyProcessed;
}

export interface NewThreadSuccessfullyCreated extends BaseToastr {
  type: ToastrType.newThreadSuccessfullyCreated;
  threadSlug: ThreadSlug;
}

export interface NewPostReplySuccessfullyCreated extends BaseToastr {
  type: ToastrType.newPostReplySuccessfullyCreated;
  idChildPost: PostId;
  idParentPost: PostId;
}

export interface GenericRetryableErrorToastr<T> extends BaseToastr {
  type: ToastrType.genericRetryableError;
  retry: () => Promise<T>;
}

export type Toastr =
  | GenericSuccessToastr
  | InvalidSessionToastr
  | AvatarUploadingToastr
  | AvatarProcessingToastr
  | AvatarSuccessfullyProcessedToastr
  | NewThreadSuccessfullyCreated
  | NewPostReplySuccessfullyCreated
  | GenericRetryableErrorToastr<unknown>;
