<template>
  <BaseLayout>
    <template #title>
      <FontAwesomeIcon :icon="faUsersMany" aria-hidden class="u-margin-r-xs" fixed-width />

      {{ t('title') }}
    </template>

    <ContentWithSidebarGrid>
      <template #main>
        <ThreadsFrontpage
          :threadOrder="threadOrder"
          :threadSlugs="threadSlugs ?? []"
          :isLoading="isLoading"
          :hasLoadedLastPage="hasLoadedLastPage"
          @reach-content-end="executeNextPage()"
        />
      </template>

      <template #sidebar>
        <CategoryTimelineSidebarWidget
          v-if="featuredCategorySlug"
          :categorySlug="featuredCategorySlug"
        />
      </template>
    </ContentWithSidebarGrid>
  </BaseLayout>
</template>

<script lang="ts" setup>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { useHead } from '@vueuse/head';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import { faUsersMany } from '@caff/cds-icons';

import BaseLayout from '../layouts/BaseLayout.vue';
import { ThreadsFrontpage } from '../components/frontpage';
import ContentWithSidebarGrid from '../components/grid/ContentWithSidebarGrid.vue';
import { CategoryTimelineSidebarWidget } from '../components/sidebar';
import {
  useFrontpageThreadOrder,
  useGeneralFrontpageThreadSlugs,
  useFeaturedCategorySlug,
} from '../composition';

const { t } = useI18n();

useHead({
  title: t('title'),
});

const threadOrder = useFrontpageThreadOrder();

const { threadSlugs, isLoading, hasNextPage, executeNextPage } = useGeneralFrontpageThreadSlugs({
  threadOrder,
});

const hasLoadedLastPage = computed(() => !hasNextPage);

const { featuredCategorySlug } = useFeaturedCategorySlug();
</script>

<i18n lang="json" locale="es">
{
  "title": "Portada"
}
</i18n>
