import type { Ref } from 'vue';
import { computed } from 'vue';

import type { CategorySlug } from '@caff/category-api-model';

import { useCategory } from './simple';

export const useCategoryLogoUrl = (categorySlug: Ref<CategorySlug | null>): Ref<string | null> => {
  const { category } = useCategory(categorySlug);
  return computed(() => category.value?.logoURL ?? null);
};
