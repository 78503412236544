export const enum SocketMessageAction {
  authenticate = 'authenticate',
  logout = 'logout',
}

export interface AuthenticateSocketMessage {
  action: SocketMessageAction.authenticate;
  payload: never;
}

export interface LogoutSocketMessage {
  action: SocketMessageAction.logout;
  payload: never;
}

export type ClientToServerSocketMessage = AuthenticateSocketMessage | LogoutSocketMessage;
