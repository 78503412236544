import type { IconDefinition, IconName, IconPrefix } from '@fortawesome/fontawesome-common-types';

export const prefix = 'caff' as IconPrefix;
export const iconName = 'arrow-left' as IconName;
export const width = 14;
export const height = 14;
export const ligatures = [];
export const unicode = 'f801';
export const svgPathData =
  'm.03886773 6.69432-.02289939.10177508-.01596834.15853478.0001731.0994005.00982431.1072363.01690003.09508782.00705186.03016846c.01508821.05978556.03359206.11576449.05574122.16926016l.05314906.11116528.03446014.05939439.06774804.09754856.07209796.08485314.10101904.09609349 5.13545879 4.32199654c.50208832.4225594 1.24872846.345758 1.65676453-.1672292l.07334611-.1029746c.31323759-.4934109.22083665-1.1562425-.23597559-1.5406974l-2.65983865-2.23802467 8.44519735.00062996c.6494102 0 1.1668827-.53228606 1.1668827-1.1785607l-.0036851-.09014133-.0172047-.12188073c-.1068206-.55847177-.5786673-.96653864-1.1459929-.96653864l-8.44613074-.00024331 2.66072307-2.23706678.07075215-.0651268c.42849108-.44125889.46245139-1.11275834.0919263-1.57858639-.40803607-.51298724-1.15467621-.58978865-1.65676998-.16722464l-5.17571402 4.35750131-.07831633.07976946-.09142207.11613216-.03987836.0608708-.04179706.07667589-.0266294.05631194-.04302021.11289418z';

export const definition = {
  prefix,
  iconName,
  icon: [width, height, ligatures, unicode, svgPathData],
} as IconDefinition;

export const faArrowLeft = definition;
