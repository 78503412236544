<template>
  <BorderedBox
    rounded
    tag="article"
    :class="{
      'c-thread-feed-item': true,
      'c-thread-feed-item--without-thumbnail': !idThumbnail,
    }"
  >
    <h2 class="c-thread-feed-item__title">
      <router-link v-if="threadRoute" :to="threadRoute">{{ title }}</router-link>
      <template v-else>{{ title }}</template>
    </h2>

    <div v-if="hasContentBefore" class="c-thread-feed-item__before">
      <!-- @slot Use this slot to render content before the thread but inside the box -->
      <slot name="before" />
    </div>

    <aside v-if="thumbnailImage" class="c-thread-feed-item__thumbnail">
      <router-link v-if="threadRoute" :to="threadRoute">
        <ResponsiveImage
          :s3BucketConfigForImageKind="s3BucketConfigForImageKind"
          :image="thumbnailImage"
          :height="thumbnailHeight"
          :width="thumbnailWidth"
        />
      </router-link>
      <ResponsiveImage
        v-else
        :s3BucketConfigForImageKind="s3BucketConfigForImageKind"
        :image="thumbnailImage"
        :height="thumbnailHeight"
        :width="thumbnailWidth"
      />
    </aside>
    <header class="c-thread-feed-item__meta">
      <CategoryPill
        v-if="showCategory"
        :category="category"
        :s3BucketConfigForImageKind="s3BucketConfigForImageKind"
        :to="categoryRoute"
      />
      <UserAvatar
        v-if="showAuthor"
        :s3BucketConfigForImageKind="s3BucketConfigForImageKind"
        :to="authorRoute"
        :user="author"
      />
      <Pill
        v-if="showAuthor"
        :tag="displayNameTag"
        :to="authorRoute"
        class="c-thread-feed-item__author-display-name"
      >
        {{ author.displayName }}
      </Pill>
      <span v-if="isUsernameRendered" class="c-thread-feed-item__author-username">
        <!-- @slot Use this slot to customize how username is rendered when it differs from display name -->
        <slot name="authorUsername" :to="authorRoute" :user="author" />
      </span>
      <span class="c-thread-feed-item__published-date">
        <!-- @slot Use this slot to customize how relative published date is rendered -->
        <slot name="relativeDate" :date="publishedDate" />
      </span>
    </header>
    <div v-if="showSummary" class="c-thread-feed-item__summary">{{ summary }}</div>
    <footer class="c-thread-feed-item__actions">
      <!-- @slot Use this slot to customize actions available on this thread -->
      <slot name="actions" />
    </footer>

    <div v-if="hasContentAfter" class="c-thread-feed-item__after">
      <!-- @slot Use this slot to render content after the thread but inside the box -->
      <slot name="after" />
    </div>
  </BorderedBox>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue';
import type { RouteLocationRaw } from 'vue-router';

import { Category } from '@caff/category-api-model';
import { CategoryPill } from '@caff/category-pill-frontend-component';
import { BorderedBox } from '@caff/cds-bordered-box';
import { Pill } from '@caff/cds-pill';
import { ResponsiveImage } from '@caff/cds-responsive-image';
import { s3BucketConfigForImageKindVueComponentProperty as s3BucketConfigForImageKind } from '@caff/image-frontend-model';
import {
  Image,
  ImageKind,
  ImageLocation,
  ImageSizeForLocation,
} from '@caff/image-isomorphic-model';
import { User } from '@caff/user-api-model';
import { UserAvatar } from '@caff/user-avatar-frontend-component';
import { areDisplayNameAndUsernameSimilar } from '@caff/user-isomorphic-model';

export default defineComponent({
  name: 'ThreadFeedItem',
  components: { BorderedBox, CategoryPill, Pill, ResponsiveImage, UserAvatar },
  props: {
    s3BucketConfigForImageKind,
    author: {
      type: Object as PropType<User>,
      required: true,
    },
    authorRoute: {
      type: null as unknown as PropType<RouteLocationRaw | null>,
      required: false,
    },
    category: {
      type: Object as PropType<Category>,
      required: true,
    },
    categoryRoute: {
      type: null as unknown as PropType<RouteLocationRaw | null>,
      required: false,
    },
    idThumbnail: {
      type: null as unknown as PropType<string | null>,
      required: false,
    },
    title: {
      type: String,
      required: true,
    },
    summary: {
      type: String,
      required: true,
    },
    publishedDate: {
      type: Date,
      required: true,
    },
    showAuthor: {
      type: Boolean,
      default: true,
    },
    showCategory: {
      type: Boolean,
      default: true,
    },
    showSummary: {
      type: Boolean,
      default: true,
    },
    threadRoute: {
      type: null as unknown as PropType<RouteLocationRaw | null>,
      required: false,
    },
  },
  setup(props, { slots }) {
    const isUsernameRendered = computed(
      () => props.showAuthor && !areDisplayNameAndUsernameSimilar(props.author),
    );

    const thumbnailImage = computed(() =>
      props.idThumbnail
        ? new Image(
            {
              idImage: props.idThumbnail,
              kind: ImageKind.external_url_thumbnail,
            },
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            props.s3BucketConfigForImageKind!,
          )
        : null,
    );

    const { height: thumbnailHeight, width: thumbnailWidth } =
      ImageSizeForLocation[ImageLocation.frontpageThumbnail];

    const displayNameTag = computed(() => (props.authorRoute ? 'router-link' : 'div'));

    const hasContentAfter = !!slots.after;
    const hasContentBefore = !!slots.before;

    return {
      hasContentAfter,
      hasContentBefore,
      isUsernameRendered,
      thumbnailHeight,
      thumbnailImage,
      thumbnailWidth,
      displayNameTag,
    };
  },
});
</script>

<style lang="scss" scoped>
@import '@caff/cds-scss-core';

.c-thread-feed-item {
  @extend %t-padding-m;

  display: grid;
  grid-template-columns: [start] 1fr [end];
  grid-template-rows: [before] auto [start] auto [line2] auto [line3] auto [line4] auto [line5] auto [end] auto [after];

  @include t-responsive--smaller-than($responsive-breakpoint-tablet-medium) {
    --border-radius: 0;
  }

  @include t-responsive--starting-at($responsive-breakpoint-tablet-medium) {
    grid-template-columns:
      [start] 1fr [separator] calc(#{get-size-in-rem(100px)} + var(--spacing-m))
      [end];
    grid-template-rows: [before] auto [start] auto [line2] auto [line3] auto [line4] auto [end] auto [after];

    &#{&}--without-thumbnail {
      grid-template-columns: [start] 1fr [end];
    }
  }
}

.c-thread-feed-item__before {
  grid-column-start: start;
  grid-column-end: end;
  grid-row-end: start;
  grid-row-start: before;
}
.c-thread-feed-item__after {
  grid-column-start: start;
  grid-column-end: end;
  grid-row-end: after;
  grid-row-start: end;
}

.c-thread-feed-item__thumbnail {
  --border-radius: 0;

  @extend %t-margin-b-s;
  @extend %t-margin-t-s;

  border-radius: var(--border-radius);
  grid-column-end: end;
  grid-column-start: start;
  grid-row-end: line4;
  grid-row-start: line3;
  margin-left: calc(-1 * var(--padding));
  margin-right: calc(-1 * var(--padding));
  overflow: hidden;

  @include t-responsive--starting-at($responsive-breakpoint-tablet-medium) {
    --border-radius: 6px;

    grid-column-start: separator;
    grid-row-end: line4;
    grid-row-start: start;
    margin: 0 0 0 var(--spacing-m);
    position: relative;
  }

  .c-responsive-image {
    height: 100%;

    @include t-responsive--starting-at($responsive-breakpoint-tablet-medium) {
      position: absolute;
    }
  }
}

.c-thread-feed-item__meta {
  @extend %t-margin-b-s;

  align-items: center;
  display: flex;
  flex-direction: row;
  grid-column-end: end;
  grid-column-start: start;
  grid-row-end: line2;
  grid-row-start: start;
  justify-content: flex-start;
  overflow: hidden;

  @include t-responsive--starting-at($responsive-breakpoint-tablet-medium) {
    grid-column-end: separator;
  }

  & > * + * {
    @extend %t-margin-l-xs;
  }

  & > * {
    flex-grow: 0;
    flex-shrink: 0;
    overflow: hidden;
  }
}

.c-thread-feed-item__author-username,
.c-thread-feed-item__published-date {
  @include t-font($font-body-soft);
}

.c-thread-feed-item__author-display-name {
  flex-shrink: 1;
  min-width: 0;
}

.c-thread-feed-item__author-username {
  flex-shrink: 10000;
  min-width: 0;
  text-overflow: ellipsis;
}

.c-thread-feed-item__title {
  @include t-text-max-lines(6, var(--line-height));

  &,
  a {
    @include t-font($font-header-small);

    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  grid-column-end: end;
  grid-column-start: start;
  grid-row-end: line3;
  grid-row-start: line2;
  margin: 0;

  @include t-responsive--starting-at($responsive-breakpoint-tablet-medium) {
    grid-column-end: separator;
  }
}

.c-thread-feed-item__summary {
  @include t-font($font-body-soft);
  @include t-text-max-lines(2, var(--line-height));

  grid-column-end: end;
  grid-column-start: start;
  grid-row-end: line5;
  grid-row-start: line4;

  @include t-responsive--starting-at($responsive-breakpoint-tablet-medium) {
    grid-column-end: separator;
    grid-row-end: line4;
    grid-row-start: line3;
  }
}

.c-thread-feed-item__actions {
  grid-column-end: end;
  grid-column-start: start;
  grid-row-end: end;
  grid-row-start: line5;

  @include t-responsive--starting-at($responsive-breakpoint-tablet-medium) {
    grid-row-start: line4;
  }
}
</style>
