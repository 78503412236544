export const removeModalFromDOM = (modalElement?: Element): void => {
  if (modalElement && modalElement.parentNode) {
    modalElement.parentNode.removeChild(modalElement);
  }
};

export const reattachModalToDocumentBody = (modalElement: Element): void => {
  if (typeof document === 'undefined') {
    return;
  }

  // In Histoire this component is created twice and there's a leaked component
  // which is never destroyed. If we don't add this check we end up adding the
  // modal content more than once to the page. This is technically correct but
  // it's weird (normally if the modal container is no in the DOM Vue would have
  // called the beforeDestroy hook and cleaned it up already).
  if (!modalElement.parentNode) {
    return;
  }

  document.body.appendChild(modalElement);
};
