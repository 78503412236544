<template>
  <form @submit.stop.prevent="submitForm()">
    <H2>{{ t('title') }}</H2>
    <P>
      <slot name="emailError">
        {{ t('email.visibleLabel') }}
      </slot>
    </P>
    <Input
      :icon="faLetter"
      :label="t('email.accessibilityLabel')"
      :loading="isLoading"
      :placeholder="t('email.placeholder')"
      :modelValue="email"
      autocomplete="email"
      class="u-width-100"
      name="email"
      required
      type="email"
      @update:modelValue="handleEmailInput($event)"
    />

    <Footer>
      <template #leading>
        <Button
          :disabled="isLoading"
          :type="secondary"
          data-testid="cancel-button"
          @click.stop.prevent="dismiss()"
        >
          {{ t('action.cancel') }}
        </Button>
      </template>

      <template #trailing>
        <Button
          :disabled="isSubmitDisabled"
          :loading="isLoading"
          :type="primary"
          data-testid="next-button"
          @click.stop.prevent="submitForm()"
        >
          {{ t('action.next') }}
        </Button>
      </template>
    </Footer>
  </form>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';

import { Button, ButtonType } from '@caff/cds-button';
import { faLetter } from '@caff/cds-icons';
import { Input } from '@caff/cds-input';
import { Footer, H2, P } from '@caff/cds-modal-flow';

export default defineComponent({
  name: 'EmailStep',
  components: { Button, Footer, H2, Input, P },
  props: {
    email: {
      type: String,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
    isSubmitDisabled: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['email-input', 'dismiss', 'submit'],
  setup(props, { emit }) {
    const { t } = useI18n();

    const handleEmailInput = (value: string) => {
      /**
       * User changed the value of the email input.
       *
       * @event email-input
       * @type {String}
       */
      emit('email-input', value);
    };

    const dismiss = () => {
      /**
       * User dismissed the form.
       *
       * @event dismiss
       * @type {void}
       */
      emit('dismiss');
    };

    const submitForm = () => {
      if (props.isSubmitDisabled) {
        return;
      }

      /**
       * User submitted the form.
       *
       * @event submit
       * @type {void}
       */
      emit('submit');
    };

    return {
      t,
      faLetter,
      primary: ButtonType.primary,
      secondary: ButtonType.secondary,
      handleEmailInput,
      dismiss,
      submitForm,
    };
  },
});
</script>

<i18n lang="json" locale="es">
{
  "action": {
    "cancel": "Cancelar",
    "next": "Solicitar correo"
  },
  "email": {
    "accessibilityLabel": "Correo electrónico",
    "placeholder": "Correo electrónico",
    "visibleLabel": "Introduce el correo electrónico con el que te registraste y recibirás un correo con el que podrás reestablecer tu contraseña."
  },
  "title": "Recupera tu contraseña"
}
</i18n>
