import type { IconDefinition, IconName, IconPrefix } from '@fortawesome/fontawesome-common-types';

export const prefix = 'caff' as IconPrefix;
export const iconName = 'key' as IconName;
export const width = 14;
export const height = 14;
export const ligatures = [];
export const unicode = 'f830';
export const svgPathData =
  'm10 0c2.209139 0 4 1.790861 4 4s-1.790861 4-4 4c-.64911389 0-1.26211502-.15461713-1.80418311-.42903111l-1.17881689 1.17903111 1.36688348 1.3661165c.48815536.4881554.48815536 1.2796116 0 1.767767-.45561168.4556117-1.1754321.4859858-1.66627161.0911223l-.10149535-.0911223-1.36611652-1.3668835-.983.983.36688348.3661165c.48815536.4881554.48815536 1.2796116 0 1.767767-.45561168.4556117-1.1754321.4859858-1.66627161.0911223l-.10149535-.0911223-.36611652-.3668835-.36611652.3668835c-.48815537.4881553-1.27961159.4881553-1.76776696 0-.45561167-.4556117-.48598578-1.1754321-.09112233-1.6662716l.09112233-.1014954 6.06291459-6.06193339c-.27441398-.54206809-.42903111-1.15506922-.42903111-1.80418311 0-2.209139 1.790861-4 4-4zm0 2.5c-.82842712 0-1.5.67157288-1.5 1.5s.67157288 1.5 1.5 1.5c.8284271 0 1.5-.67157288 1.5-1.5s-.6715729-1.5-1.5-1.5z';

export const definition = {
  prefix,
  iconName,
  icon: [width, height, ligatures, unicode, svgPathData],
} as IconDefinition;

export const faKey = definition;
