import type { IconDefinition, IconName, IconPrefix } from '@fortawesome/fontawesome-common-types';

export const prefix = 'caff' as IconPrefix;
export const iconName = 'spinner-third' as IconName;
export const width = 14;
export const height = 14;
export const ligatures = [];
export const unicode = 'f848';
export const svgPathData =
  'm7 0 .14446001.00686658c.76065191.07268474 1.35553999.7134373 1.35553999 1.49313342s-.59488808 1.42044868-1.35553999 1.49313342l-.14446001.00686658c-2.209139 0-4 1.790861-4 4s1.790861 4 4 4c2.14219539 0 3.8910789-1.68396847 3.9951047-3.80035966l.0117619-.34410035c.0726847-.76065191.7134373-1.35553999 1.4931334-1.35553999s1.4204487.59488808 1.4931334 1.35553999l.0068666.14446001c0 3.8659932-3.1340068 7-7 7-3.86599325 0-7-3.1340068-7-7 0-3.86599325 3.13400675-7 7-7z';

export const definition = {
  prefix,
  iconName,
  icon: [width, height, ligatures, unicode, svgPathData],
} as IconDefinition;

export const faSpinnerThird = definition;
