<template>
  <header class="c-top-navbar">
    <ResponsiveContainer class="c-top-navbar__content">
      <div class="c-top-navbar__leading">
        <!-- @slot Use this slot to display items in the leading side of the navbar (left on LTR languages) -->
        <slot name="leading" />
      </div>
      <div class="c-top-navbar__trailing">
        <!-- @slot Use this slot to display items in the trailing side of the navbar (right on LTR languages) -->
        <slot name="trailing" />
      </div>
    </ResponsiveContainer>

    <!-- @slot Use this slot to display items in the bottom of the bar -->
    <slot />
  </header>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import { ResponsiveContainer } from '@caff/cds-responsive-container';

export default defineComponent({
  name: 'TopNavbar',
  components: { ResponsiveContainer },
});
</script>

<style lang="scss" scoped>
@import '@caff/cds-scss-core';

.c-top-navbar {
  @include t-box-shadow($box-shadow-only-bottom);
  @extend %t-z-index-over-elements-with-hover-background-effects;

  background: var(--colors-box-background);
  position: sticky;
  top: 0;
}

.c-top-navbar__content {
  @extend %t-height-l;

  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: get-size-in-rem(10px) 0;
}

.c-top-navbar__leading,
.c-top-navbar__trailing {
  align-items: center;
  display: flex;
  flex-direction: row;
}
</style>
