import EmailUnavailableStep from './EmailUnavailableStep.vue';
import ErrorStep from './ErrorStep.vue';
import PasswordStep from './PasswordStep.vue';
import UsernameAndEmailStep from './UsernameAndEmailStep.vue';
import UsernameUnavailableStep from './UsernameUnavailableStep.vue';

export { EmailUnavailableStep };
export { ErrorStep };
export { PasswordStep };
export { UsernameAndEmailStep };
export { UsernameUnavailableStep };

export const enum SignupModalStep {
  usernameAndEmail = 'usernameAndEmail',
  password = 'password',
  usernameUnavailable = 'usernameUnavailable',
  emailUnavailable = 'emailUnavailable',
  success = 'success',
  error = 'error',
}

export const allSignupModalSteps: Record<SignupModalStep, boolean> = {
  [SignupModalStep.usernameAndEmail]: true,
  [SignupModalStep.password]: true,
  [SignupModalStep.usernameUnavailable]: true,
  [SignupModalStep.emailUnavailable]: true,
  [SignupModalStep.success]: true,
  [SignupModalStep.error]: true,
};
