<template>
  <BorderedBox :padded="padded" rounded class="c-frontpage-box">
    <!-- @slot Use this slot to render any content inside the box-->
    <slot />
  </BorderedBox>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import { BorderedBox } from '@caff/cds-bordered-box';

export default defineComponent({
  name: 'FrontpageBox',
  components: {
    BorderedBox,
  },
  props: {
    padded: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<style lang="scss" scoped>
@import '@caff/cds-scss-core';

.c-frontpage-box {
  @extend %t-margin-b-m;

  display: flex;
  flex-direction: column;

  @include t-responsive--smaller-than($responsive-breakpoint-tablet-medium) {
    --border-radius: 0px;
  }
}
</style>
