<template>
  <Avatar
    :cdsColor="cdsColor"
    :image="image"
    :large="large"
    :name="user.displayName"
    :to="to"
    rounded
  />
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue';
import type { RouteLocationRaw } from 'vue-router';

import { Avatar } from '@caff/cds-avatar';
import { CDSColor } from '@caff/cds-color';
import { s3BucketConfigForImageKindVueComponentProperty as s3BucketConfigForImageKind } from '@caff/image-frontend-model';
import { User } from '@caff/user-api-model';
import { getAvatar } from '@caff/user-isomorphic-model';

const sortedCDSColors = Object.keys(CDSColor).sort();

export default defineComponent({
  name: 'UserAvatar',
  components: { Avatar },
  props: {
    s3BucketConfigForImageKind,
    large: {
      type: Boolean,
      default: false,
    },
    to: {
      type: null as unknown as PropType<RouteLocationRaw | null>,
      required: false,
    },
    user: {
      type: Object as PropType<Pick<User, 'displayName' | 'idAvatar' | 'username'>>,
      required: true,
    },
  },
  setup(props) {
    const cdsColor = computed(() => {
      const hash = props.user.username
        .split('')
        .reduce((hash: number, character: string) => hash + character.charCodeAt(0), 0);

      return sortedCDSColors[hash % sortedCDSColors.length];
    });

    const image = computed(() =>
      getAvatar({
        user: props.user,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        s3ConfigForImageKind: props.s3BucketConfigForImageKind!,
      }),
    );
    return {
      cdsColor,
      image,
    };
  },
});
</script>
