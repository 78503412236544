<template src="./Button.html"></template>

<script lang="ts">
import { buttonFactory, buttonTypeProp } from './Button';

export default buttonFactory({
  // Name must be different than `button`, otherwise Vue 3 enters in an infinite
  // loop, probably a bug in Vue 3
  name: 'CaffButton',
  additionalProps: { type: buttonTypeProp },
});
</script>

<style lang="scss" src="./Button.scss" scoped></style>
