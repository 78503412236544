import { defineStore } from 'pinia';

import { PostOrder } from '@caff/post-api-model';
import { ThreadOrder } from '@caff/shallow-thread-api-model';

type Store = ReturnType<typeof useFrontpageStore>;
type StorePatchCallback = Parameters<Store['$patch']>[0];
export type FrontpageState = Parameters<StorePatchCallback>[0];

export const useFrontpageStore = defineStore('frontpage', {
  state: () => ({
    postOrder: PostOrder.newestFirst,
    threadOrder: ThreadOrder.newestFirst,
  }),

  actions: {
    sortBy({ postOrder, threadOrder }: { postOrder?: PostOrder; threadOrder?: ThreadOrder }) {
      this.postOrder = postOrder ?? this.postOrder;
      this.threadOrder = threadOrder ?? this.threadOrder;
    },
  },
});
