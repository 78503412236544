import type { IconDefinition, IconName, IconPrefix } from '@fortawesome/fontawesome-common-types';

export const prefix = 'caff' as IconPrefix;
export const iconName = 'list-medium' as IconName;
export const width = 15;
export const height = 14;
export const ligatures = [];
export const unicode = 'f833';
export const svgPathData =
  'm12.5 10.5c.8284271 0 1.5.6715729 1.5 1.5s-.6715729 1.5-1.5 1.5h-11c-.82842712 0-1.5-.6715729-1.5-1.5s.67157288-1.5 1.5-1.5zm-9-5c.82842712 0 1.5.67157288 1.5 1.5s-.67157288 1.5-1.5 1.5h-2c-.82842712 0-1.5-.67157288-1.5-1.5s.67157288-1.5 1.5-1.5zm8.5-5c1.1045695 0 2 .8954305 2 2v4c0 1.1045695-.8954305 2-2 2h-3c-1.1045695 0-2-.8954305-2-2v-4c0-1.1045695.8954305-2 2-2zm-8.5 0c.82842712 0 1.5.67157288 1.5 1.5s-.67157288 1.5-1.5 1.5h-2c-.82842712 0-1.5-.67157288-1.5-1.5s.67157288-1.5 1.5-1.5z';

export const definition = {
  prefix,
  iconName,
  icon: [width, height, ligatures, unicode, svgPathData],
} as IconDefinition;

export const faListMedium = definition;
