import { ImageSize } from './ImageSize';

export const enum ImageLocation {
  frontpageThumbnail = 'frontpageThumbnail',
  sidebarThumbnail = 'sidebarThumbnail',
  fullBodyThumbnail = 'fullBodyThumbnail',
  fullBodyEmbedThumbnail = 'fullBodyEmbedThumbnail',
  sidebarAvatar = 'sidebarAvatar',
  largeAvatar = 'largeAvatar',
  smallAvatar = 'smallAvatar',
  headerBanner = 'headerBanner',
  discoverBanner = 'discoverBanner',
  sidebarBanner = 'sidebarBanner',
}

export const ImageSizeForLocation: Record<ImageLocation, ImageSize> = {
  [ImageLocation.frontpageThumbnail]: { width: 120, height: null },
  [ImageLocation.sidebarThumbnail]: { width: 71, height: 44 },
  [ImageLocation.fullBodyThumbnail]: { width: 585, height: null },
  [ImageLocation.fullBodyEmbedThumbnail]: { width: 545, height: null },
  [ImageLocation.sidebarAvatar]: { width: 84, height: 84 },
  [ImageLocation.largeAvatar]: { width: 44, height: 44 },
  [ImageLocation.smallAvatar]: { width: 32, height: 32 },
  [ImageLocation.headerBanner]: { width: 967, height: 116 },
  [ImageLocation.discoverBanner]: { width: 309, height: 100 },
  [ImageLocation.sidebarBanner]: { width: 362, height: 100 },
};
