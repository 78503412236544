<template>
  <Button v-if="isReplying" :type="type" :disabled="!interactive" @click.prevent.stop="cancel()">
    <FontAwesomeIcon :icon="faCross" fixed-width aria-hidden />

    {{ t('actions.cancel') }}
  </Button>
  <Button v-else :type="type" :disabled="!interactive" @click.prevent.stop="reply()">
    <FontAwesomeIcon :icon="faArrowReplyLeft" fixed-width aria-hidden />

    {{ t('actions.reply') }}
  </Button>
</template>

<script lang="ts" setup>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import type { ValueOf } from 'type-fest';
import { useI18n } from 'vue-i18n';

import { faArrowReplyLeft, faCross } from '@caff/cds-icons';
import { Button, ButtonType } from '@caff/cds-button';

import { InteractiveProps, interactivePropsDefaults } from '../../mixins/interactive.mixin';

withDefaults(
  defineProps<
    InteractiveProps & {
      type?: ValueOf<typeof ButtonType>;
      isReplying: boolean;
    }
  >(),
  { ...interactivePropsDefaults, type: ButtonType.secondary },
);

const emit = defineEmits<{
  cancel: [];
  reply: [];
}>();

const { t } = useI18n();

const cancel = () => {
  emit('cancel');
};

const reply = () => {
  emit('reply');
};
</script>

<i18n lang="json" locale="es">
{
  "actions": {
    "cancel": "Cancelar",
    "reply": "Responder"
  }
}
</i18n>
