import { JsonB } from './jsonb';
import { NotificationFrequencyTypeDbEnum } from './notifications';

export const enum UserPermissionsDbEnum {
  delete_post = 'delete-post',
  delete_thread = 'delete-thread',
  permanently_delete_post = 'permanently-delete-post',
  permanently_delete_thread = 'permanently-delete-thread',
  activate_user = 'activate-user',
  deactivate_user = 'deactivate-user',
  override_avatar = 'override-avatar',
  override_user_info = 'override-user-info',
}

/**
 * Possible color schemas to be applied to frontend.
 *
 * - `light`: Original mostly-light color schema.
 * - `dark`: Alternative dark mode.
 * - `auto`: `light` or `dark` automatically chosen depending on browser preferences.
 */
export const enum ColorSchemaDbEnum {
  light = 'light',
  dark = 'dark',
  auto = 'auto',
}

export interface PublicUserInfoRow {
  username: string;
  display_name: string;
  id_avatar: string | null;
  notification_frequency: {
    name: NotificationFrequencyTypeDbEnum;
  };
}

export interface PrivateUserInfoRow extends PublicUserInfoRow {
  email: string;
  permissions: JsonB<Array<UserPermissionsDbEnum>>;
  preferred_color_schema: {
    name: ColorSchemaDbEnum;
  };
}
