<template>
  <form @submit.stop.prevent="submitForm()">
    <H2>{{ t('title') }}</H2>
    <P>
      <slot name="passwordError">
        {{ t('password.visibleLabel') }}
      </slot>
    </P>
    <Input
      :icon="faKey"
      :label="t('password.accessibilityLabel')"
      :placeholder="t('password.placeholder')"
      :modelValue="password"
      name="password"
      autocomplete="new-password"
      class="u-width-100"
      required
      type="password"
      @update:modelValue="handlePasswordInput($event)"
    />
    <P>
      <slot name="repeatPasswordError">
        {{ t('repeatPassword.visibleLabel') }}
      </slot>
    </P>
    <Input
      :icon="faKey"
      :label="t('repeatPassword.accessibilityLabel')"
      :placeholder="t('repeatPassword.placeholder')"
      :modelValue="repeatPassword"
      autocomplete="new-password"
      class="u-width-100"
      name="repeated-password"
      required
      type="password"
      @update:modelValue="handleRepeatPasswordInput($event)"
    />

    <Footer>
      <template #leading>
        <Button
          :disabled="isLoading"
          :type="secondary"
          data-testid="cancel-button"
          @click.stop.prevent="dismiss()"
        >
          {{ t('action.cancel') }}
        </Button>
      </template>

      <template #trailing>
        <Button
          :disabled="isSubmitDisabled"
          :loading="isLoading"
          :type="primary"
          data-testid="next-button"
          @click.stop.prevent="submitForm()"
        >
          {{ t('action.next') }}
        </Button>
      </template>
    </Footer>
  </form>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';

import { Button, ButtonType } from '@caff/cds-button';
import { faKey } from '@caff/cds-icons';
import { Input } from '@caff/cds-input';
import { Footer, H2, P } from '@caff/cds-modal-flow';

export default defineComponent({
  name: 'PasswordStep',
  components: { Button, Footer, H2, Input, P },
  props: {
    password: {
      type: String,
      required: true,
    },
    passwordError: {
      type: String,
      required: false,
    },
    repeatPassword: {
      type: String,
      required: true,
    },
    repeatPasswordError: {
      type: String,
      required: false,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
    isSubmitDisabled: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['password-input', 'repeat-password-input', 'dismiss', 'submit'],
  setup(props, { emit }) {
    const { t } = useI18n();

    const handlePasswordInput = (value: string): void => {
      /**
       * User changed the value of the password input.
       *
       * @event password-input
       * @type {String}
       */
      emit('password-input', value);
    };

    const handleRepeatPasswordInput = (value: string): void => {
      /**
       * User changed the value of the repeat password input.
       *
       * @event repeat-password-input
       * @type {String}
       */
      emit('repeat-password-input', value);
    };

    const dismiss = () => {
      /**
       * User dismissed the form.
       *
       * @event dismiss
       * @type {void}
       */
      emit('dismiss');
    };

    const submitForm = () => {
      if (props.isSubmitDisabled) {
        return;
      }

      /**
       * User submitted the form.
       *
       * @event submit
       * @type {void}
       */
      emit('submit');
    };

    return {
      t,
      faKey,
      primary: ButtonType.primary,
      secondary: ButtonType.secondary,
      handlePasswordInput,
      handleRepeatPasswordInput,
      dismiss,
      submitForm,
    };
  },
});
</script>

<i18n lang="json" locale="es">
{
  "action": {
    "cancel": "Cancelar",
    "next": "Confirmar"
  },
  "password": {
    "accessibilityLabel": "Contraseña",
    "placeholder": "Contraseña",
    "visibleLabel": "Elige una constraseña de acceso"
  },
  "repeatPassword": {
    "accessibilityLabel": "Repite la contraseña",
    "placeholder": "Contraseña",
    "visibleLabel": "Repite la contraseña"
  },
  "title": "Escoge tu nueva contraseña"
}
</i18n>
