<template>
  <SidebarListWidget>
    <template #title>
      <template v-if="category">
        {{ category.name }}
      </template>
    </template>

    <ThreadSidebarTimelineItem
      v-for="threadSlug of featuredThreadSlugs"
      :key="threadSlug"
      :threadSlug="threadSlug"
    />

    <InfiniteScrollFooter :isLoading="isExecuting" :end="!featuredThreadSlugs.length" />
  </SidebarListWidget>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue';

import { CategorySlug } from '@caff/category-api-model';
import { SidebarListWidget } from '@caff/cds-sidebar-list-widget';
import { ThreadOrder } from '@caff/shallow-thread-api-model';

import {
  useCategory,
  useCategoryFrontpageThreadSlugs,
  useOnCurrentlyLoggedInUserChange,
} from '../../composition';
import { MAX_SIDEBAR_TIMELINE_ITEMS } from '../../config';
import InfiniteScrollFooter from '../common/InfiniteScrollFooter.vue';
import ThreadSidebarTimelineItem from './ThreadSidebarTimelineItem.vue';

const props = defineProps<{
  categorySlug: CategorySlug;
}>();

const threadOrder = ref(ThreadOrder.polemicFirst);

const categorySlug = computed(() => props.categorySlug);

const { category } = useCategory(categorySlug);

const { isExecuting, threadSlugs, reexecute } = useCategoryFrontpageThreadSlugs({
  categorySlug,
  threadOrder,
});

const featuredThreadSlugs = computed(
  () => threadSlugs.value?.slice(0, MAX_SIDEBAR_TIMELINE_ITEMS) ?? [],
);

useOnCurrentlyLoggedInUserChange(() => {
  reexecute();
});
</script>
