<template>
  <SignupModal
    :currentStep="currentStep"
    :username="username"
    :email="email"
    :password="password"
    :repeatPassword="repeatPassword"
    :isCreatingAccount="isCreatingAccount"
    :isEmailAsyncValidationLoading="isEmailAsyncValidationLoading"
    :isUsernameAsyncValidationLoading="isUsernameAsyncValidationLoading"
    :emailAsyncValidationError="emailAsyncValidationError && t(emailAsyncValidationError)"
    :usernameAsyncValidationError="usernameAsyncValidationError && t(usernameAsyncValidationError)"
    @dismiss="dismissWizard()"
    @go-to-username-and-email-step="goToUsernameAndEmailStep()"
    @go-to-password-step="goToPasswordStep()"
    @username-input="onUsernameInput($event)"
    @email-input="onEmailInput($event)"
    @password-input="onPasswordInput($event)"
    @repeat-password-input="onRepeatPasswordInput($event)"
    @create-account="createAccount()"
    @forgot-password="showForgotPasswordModal()"
  />
</template>

<script lang="ts" setup>
import { AxiosError } from 'axios';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import { SignupModal, SignupModalStep } from '@caff/signup-modal-frontend-component';

import { useIsEmailAvailable, useIsUsernameAvailable, useRegister } from '../../composition';
import { useModalStore } from '../../store';

const { t } = useI18n();

const currentStep = ref(SignupModalStep.usernameAndEmail);

const username = ref('');
const onUsernameInput = (newUsername: string) => {
  username.value = newUsername;
};
const {
  isExecuting: isUsernameAsyncValidationLoading,
  data: isUsernameAvailable,
  error: usernameAsyncValidationResultError,
} = useIsUsernameAvailable(username);
const usernameAsyncValidationError = computed(() => {
  if (!username.value) {
    return null;
  }

  if (usernameAsyncValidationResultError.value) {
    return 'validationError.username.serverError';
  }

  if (isUsernameAvailable.value === null || isUsernameAvailable.value) {
    return null;
  }

  return 'validationError.username.notAvailable';
});

const email = ref('');
const onEmailInput = (newEmail: string) => {
  email.value = newEmail;
};
const {
  isExecuting: isEmailAsyncValidationLoading,
  data: isEmailAvailable,
  error: emailAsyncValidationResultError,
} = useIsEmailAvailable(email);
const emailAsyncValidationError = computed(() => {
  if (!email.value) {
    return null;
  }

  if (emailAsyncValidationResultError.value) {
    return 'validationError.email.serverError';
  }

  if (isEmailAvailable.value === null || isEmailAvailable.value) {
    return null;
  }

  return 'validationError.email.notAvailable';
});

const password = ref('');
const onPasswordInput = (newPassword: string) => {
  password.value = newPassword;
};

const repeatPassword = ref('');
const onRepeatPasswordInput = (newRepeatPassword: string) => {
  repeatPassword.value = newRepeatPassword;
};

const modalStore = useModalStore();

const dismissWizard = () => {
  if (isCreatingAccount.value) {
    return;
  }

  modalStore.dismissCurrentModal();
};

const showForgotPasswordModal = () => {
  dismissWizard();

  modalStore.showForgotPasswordModal();
};

const goToUsernameAndEmailStep = () => {
  currentStep.value = SignupModalStep.usernameAndEmail;
};
const goToPasswordStep = () => {
  currentStep.value = SignupModalStep.password;
};

const { register, isExecuting: isCreatingAccount } = useRegister();

const createAccount = async () => {
  if (
    isCreatingAccount.value ||
    isUsernameAsyncValidationLoading.value ||
    isEmailAsyncValidationLoading.value
  ) {
    return;
  }

  try {
    await register({
      email: email.value,
      username: username.value,
      password: password.value,
    });
    currentStep.value = SignupModalStep.success;
  } catch (error) {
    const typedError = error as AxiosError;

    if (typedError.response && typedError.response.status === 409) {
      if (!typedError.response.data.isUsernameAvailable) {
        currentStep.value = SignupModalStep.usernameUnavailable;
      } else if (!typedError.response.data.isEmailAvailable) {
        currentStep.value = SignupModalStep.emailUnavailable;
      }
    } else {
      currentStep.value = SignupModalStep.error;
    }
  }
};
</script>

<i18n lang="json" locale="es">
{
  "validationError": {
    "email": {
      "notAvailable": "¡Ese correo electrónico ya está en uso!",
      "serverError": "Error inesperado al comprobar ese correo electrónico."
    },
    "username": {
      "notAvailable": "¡Ese nombre de usuario ya está en uso!",
      "serverError": "Error inesperado al comprobar ese nombre de usuario."
    }
  }
}
</i18n>
