<template>
  <MainNavbar>
    <template #title>
      <h1 class="c-base-layout__title">
        <!-- @slot Use this slot to customize the title section of the main navigation bar -->
        <slot name="title" />
      </h1>
    </template>

    <template #loggedInMenuTrailingAccessoryItems>
      <PrimaryButton @click="showComposer()">
        <FontAwesomeIcon :icon="faPencil" aria-hidden fixed-width />

        <span class="c-base-layout__show-composer-button-text">
          {{ t('action.newThread') }}
        </span>
      </PrimaryButton>
    </template>

    <!-- @slot Use this slot to customize the trailing accessory items of the main navigation bar -->
    <slot name="headerTrailingAccessoryItem" />
  </MainNavbar>

  <!-- @slot Use this slot to render the main content -->
  <slot />

  <!-- TODO: Move this after ModalTransitionAnimation once https://github.com/vuejs/core/issues/5352 is fixed (Vue 3.2.34 or later )-->
  <ToastrsCollection :toastrs="toastrs" />

  <ModalTransitionAnimation>
    <NewThreadComposer
      v-if="isComposerVisible"
      :forcedCategorySlug="forcedCategorySlug"
      @close="hideComposer()"
    />
  </ModalTransitionAnimation>
</template>

<script lang="ts" setup>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import { CategorySlug } from '@caff/category-api-model';
import { PrimaryButton } from '@caff/cds-button';
import { faPencil } from '@caff/cds-icons';
import { ModalTransitionAnimation } from '@caff/cds-modal';

import NewThreadComposer from '../components/composer/NewThreadComposer.vue';
import { MainNavbar } from '../components/main-navbar';
import ToastrsCollection from '../components/toastr/ToastrsCollection.vue';
import { useComposer } from '../composition';
import { useToastrStore } from '../store';

defineProps<{
  forcedCategorySlug?: CategorySlug | null;
}>();

const { t } = useI18n();

const { isComposerVisible, showComposer, hideComposer } = useComposer();

const toastrStore = useToastrStore();

const toastrs = computed(() => toastrStore.toastrs);
</script>

<script lang="ts">
export default {
  inheritAttrs: false,
};
</script>

<i18n lang="json" locale="es">
{
  "action": {
    "newThread": "Nuevo hilo"
  }
}
</i18n>

<style lang="scss" scoped>
@import '@caff/cds-scss-core';

.c-base-layout__title {
  align-items: center;
  display: flex;
  flex-direction: row;
}

.c-base-layout__show-composer-button-text {
  @include t-responsive--smaller-than($responsive-breakpoint-tablet-medium) {
    display: none;
  }
}
</style>
