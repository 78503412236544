<template>
  <CategoryPill
    v-if="category"
    :category="category"
    :s3BucketConfigForImageKind="s3ConfigForImageKind"
    :to="route"
  />
  <!-- TODO: Improve redacted state -->
  <span v-else>
    {{ t('title.loading') }}
  </span>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import { CategorySlug } from '@caff/category-api-model';
import { CategoryPill } from '@caff/category-pill-frontend-component';

import { useCategory, useS3ConfigForImageKind } from '../../composition';
import { getCategoryFrontpageRoute } from '../../router';

const props = defineProps<{
  categorySlug?: CategorySlug | null;
}>();

const { t } = useI18n();

const s3ConfigForImageKind = useS3ConfigForImageKind();

const route = computed(() =>
  props.categorySlug ? getCategoryFrontpageRoute(props.categorySlug) : null,
);
const { category } = useCategory(computed(() => props.categorySlug ?? null));
</script>

<i18n lang="json" locale="es">
{
  "title": {
    "loading": "Cargando comunidad…"
  }
}
</i18n>
