import type { IconDefinition, IconName, IconPrefix } from '@fortawesome/fontawesome-common-types';

export const prefix = 'caff' as IconPrefix;
export const iconName = 'handle' as IconName;
export const width = 14;
export const height = 14;
export const ligatures = [];
export const unicode = 'f826';
export const svgPathData =
  'm6.98346336 0c3.68932394 0 6.72427724 2.86583867 6.96791304 6.51839069l.0155503-.00049155-.0000443.48337233.0125651.7205953-.0824528-.03904533c-.2963524 1.45861479-1.5004933 2.55959146-2.9562354 2.55959146-.7046315 0-1.36993156-.26055633-1.9060715-.72843691-.57241603.46683622-1.2915502.72843691-2.05122444.72843691-1.79986799 0-3.25894957-1.45908146-3.25894957-3.25894954 0-1.79986807 1.45908158-3.25894957 3.25894957-3.25894957 1.7398724 0 3.16129884 1.3634306 3.25412734 3.08014014l.0048222.17880943c0 .5341506.34075.93112845.6983464.93112845.330089 0 .6458236-.33825332.6924449-.8104162l.0059014-.12071225-.0000074-.0084173c-.0045432-2.56737037-2.08720105-4.64722494-4.65563484-4.64722494-2.57124013 0-4.65564224 2.08440208-4.65564224 4.65564224 0 2.41420812 1.83757736 4.39922714 4.19035752 4.63268264l-.0002795-.0101137.46556422.0330733h.46556423l.0009453.1188519c.38794391.1906266.65085579.5896405.65085579 1.0450587 0 .5451666-.37674556 1.0095076-.91656612 1.1347118l-.11809401.0215444-.54826941.0407276.00016414-.0482976c-3.49793204-.229434-6.27525137-3.0222743-6.5029685-6.50504669l-.01138388-.23318639-.0037109-.23000596c0-3.85934072 3.1253569-6.98346336 6.98346336-6.98346336zm0 6.05233492c-.51424797 0-.93112844.41688045-.93112844.93112844 0 .514248.41688047.93112845.93112844.93112845.44379207 0 .819369-.31208431.90894911-.71596664l.01631073-.10275556.00586861-.11240625c0-.51424799-.41688047-.93112844-.93112845-.93112844z';

export const definition = {
  prefix,
  iconName,
  icon: [width, height, ligatures, unicode, svgPathData],
} as IconDefinition;

export const faHandle = definition;
