import type { IconDefinition, IconName, IconPrefix } from '@fortawesome/fontawesome-common-types';

export const prefix = 'caff' as IconPrefix;
export const iconName = 'eye-closed' as IconName;
export const width = 14;
export const height = 14;
export const ligatures = [];
export const unicode = 'f821';
export const svgPathData =
  'm11.9497475 2.29289322c.3905243.39052429.3905243 1.02368927 0 1.41421356l-8.48528141 8.48528142c-.39052429.3905242-1.02368927.3905242-1.41421356 0-.39052429-.3905243-.39052429-1.0236893 0-1.4142136l8.48528137-8.48528138c.3905243-.39052429 1.0236893-.39052429 1.4142136 0zm2.0502525 4.95289322c0 1.58852852-2.5377285 5.00312466-7 5.00000216-.24915168-.0001773-.49230347-.0109685-.72934863-.031466l6.93383893-6.93264563c.5231534.72765397.7955097 1.44370275.7955097 1.96410947zm-7-5c.24739709 0 .4888785.0104862.72433973.03056656l-6.93141334 6.92926654c-.52146669-.72607466-.79292639-1.44028577-.79292639-1.9598331 0-1.58853497 2.53772851-5 7-5z';

export const definition = {
  prefix,
  iconName,
  icon: [width, height, ligatures, unicode, svgPathData],
} as IconDefinition;

export const faEyeClosed = definition;
