<template>
  <main ref="contentContainer">
    <FrontpageBox padded>
      <DisplayOptionsControls :threadOrder="threadOrder" @sort-by="sortBy($event)" />
    </FrontpageBox>

    <NewThreadComposerToggleModule @click="showComposer()" />

    <RegularThreadFeedItem v-for="slug in threadSlugs" :key="slug" :threadSlug="slug" interactive />

    <InfiniteScrollFooter :isLoading="isLoading" :end="hasLoadedLastPage" />
  </main>
</template>

<script lang="ts" setup>
import { useShowNewThreadComposer, useFrontpage } from '../../composition';
import InfiniteScrollFooter from '../common/InfiniteScrollFooter.vue';
import NewThreadComposerToggleModule from '../composer/NewThreadComposerToggleModule.vue';
import DisplayOptionsControls from './DisplayOptionsControls.vue';
import { FrontpageEmits, FrontpageProps } from './frontpage.mixin';
import FrontpageBox from './FrontpageBox.vue';
import RegularThreadFeedItem from './RegularThreadFeedItem.vue';

defineProps<FrontpageProps>();

const emit = defineEmits<FrontpageEmits>();

const { contentContainer, sortBy } = useFrontpage({
  emit,
});

const showComposer = useShowNewThreadComposer();
</script>
