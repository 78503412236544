<template>
  <Toastr :variant="ToastrVariant.error">
    {{ t('message') }}

    <template #actions>
      <ToastrAction :icon="faRetryCw" @click="retry()">{{ t('retry') }}</ToastrAction>
      <ToastrAction :icon="faCheck" @click="dismiss()" />
    </template>
  </Toastr>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n';

import { faCheck, faRetryCw } from '@caff/cds-icons';

import Toastr from './Toastr.vue';
import ToastrAction from './ToastrAction.vue';
import { ToastrVariant } from './Toastr.constants';

const emit = defineEmits<{
  dismiss: [];
  retry: [];
}>();

const dismiss = () => emit('dismiss');
const retry = () => emit('retry');

const { t } = useI18n();
</script>

<i18n lang="json" locale="es">
{
  "message": "Ha habido un error inesperado",
  "retry": "Reintentar"
}
</i18n>
