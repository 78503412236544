<template>
  <div>
    <H2 center>{{ t('title') }}</H2>
    <P center>{{ t('body') }}</P>

    <Footer>
      <template #trailing>
        <Button :type="primary" @click.stop.prevent="dismiss()">
          {{ t('close') }}
        </Button>
      </template>
    </Footer>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';

import { Button, ButtonType } from '@caff/cds-button';
import { Footer, H2, P } from '@caff/cds-modal-flow';

export default defineComponent({
  name: 'SuccessStep',
  components: { Button, Footer, H2, P },
  emits: ['dismiss'],
  setup(props, { emit }) {
    const { t } = useI18n();

    const dismiss = () => {
      /**
       * User dismissed the form.
       *
       * @event dismiss
       * @type {void}
       */
      emit('dismiss');
    };

    return { t, primary: ButtonType.primary, dismiss };
  },
});
</script>

<i18n lang="json" locale="es">
{
  "body": "En breves recibirás un correo que te permitirá recuperar la contraseña de tu cuenta.",
  "close": "Ir a la portada",
  "title": "¡Correo enviado!"
}
</i18n>

<style lang="scss" scoped>
@import '@caff/cds-scss-core';

@include t-responsive--starting-at($responsive-breakpoint-tablet-medium) {
  h2,
  p {
    margin-left: auto;
    margin-right: auto;
    max-width: get-size-in-rem(310px);
  }
}
</style>
