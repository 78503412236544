<template>
  <TopNavbar>
    <template #leading>
      <div class="c-main-navbar__logo">
        <router-link :to="homeRoute">
          <FontAwesomeIcon :icon="faCaffLogo" fixed-width />
        </router-link>
      </div>

      <div class="c-main-navbar__page-title">
        <slot name="title" />
      </div>
    </template>

    <template #trailing>
      <MainNavbarLoggedInMenu v-if="isLoggedIn">
        <template #leadingAccessoryItems>
          <slot name="loggedInMenuLeadingAccessoryItems" />
          <MainNavbarDiscoverButton />
          <MainNavbarNotificationsButton />
        </template>

        <template #trailingAccessoryItems>
          <slot name="loggedInMenuTrailingAccessoryItems" />
        </template>
      </MainNavbarLoggedInMenu>
      <MainNavbarItemsGroup v-else>
        <MainNavbarDiscoverButton />
        <MainNavbarLoginButton />
        <MainNavbarSignupButton />
      </MainNavbarItemsGroup>
    </template>

    <ModalTransitionAnimation>
      <RequestPasswordRecoveryEmailModal v-if="isForgotPasswordModalOpened" />
      <ChangePasswordModal v-else-if="isChangePasswordModalOpened" />
      <RegisterModal v-else-if="isRegisterModalOpened" />
    </ModalTransitionAnimation>
  </TopNavbar>
</template>

<script lang="ts" setup>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import { faCaffLogo } from '@caff/cds-icons';
import { ModalTransitionAnimation } from '@caff/cds-modal';
import { TopNavbar } from '@caff/cds-top-navbar';

import {
  useIsForgotPasswordModalOpened,
  useIsChangePasswordModalOpened,
  useIsRegisterModalOpened,
  useIsLoggedIn,
} from '../../composition';
import { homeRoute } from '../../router';
import RequestPasswordRecoveryEmailModal from '../recover-password/RequestPasswordRecoveryEmailModal.vue';
import ChangePasswordModal from '../recover-password/ChangePasswordModal.vue';
import RegisterModal from '../register-modal/RegisterModal.vue';
import MainNavbarDiscoverButton from './MainNavbarDiscoverButton.vue';
import MainNavbarLoggedInMenu from './MainNavbarLoggedInMenu.vue';
import MainNavbarLoginButton from './MainNavbarLoginButton.vue';
import MainNavbarItemsGroup from './MainNavbarItemsGroup.vue';
import MainNavbarSignupButton from './MainNavbarSignupButton.vue';
import MainNavbarNotificationsButton from './MainNavbarNotificationsButton.vue';

const { isLoggedIn } = useIsLoggedIn();
const isForgotPasswordModalOpened = useIsForgotPasswordModalOpened();
const isChangePasswordModalOpened = useIsChangePasswordModalOpened();
const isRegisterModalOpened = useIsRegisterModalOpened();
</script>

<style lang="scss" scoped>
@import '@caff/cds-scss-core';

.c-main-navbar__logo {
  @extend %t-margin-r-m;

  font-size: get-size-in-rem(26px);

  svg {
    color: var(--colors-orange-500);
  }
}

.c-main-navbar__page-title {
  align-items: center;
  display: flex;
  flex-direction: row;

  &:deep(),
  &:deep(svg) {
    color: var(--colors-info-text);
  }

  &:deep(h1) {
    @include t-font($font-body-action);

    margin: 0;
  }

  @include t-responsive--smaller-than($responsive-breakpoint-tablet-medium) {
    display: none;
  }
}
</style>
