import type { Ref } from 'vue';
import { computed } from 'vue';

import { UseMutationReturnValue } from '@caff/frontend-use-query';
import { NotificationFrequencyType } from '@caff/notification-frequency-api-model';
import type { ThreadSlug } from '@caff/shallow-thread-api-model';

import { useAxiosMutation } from '../useAxiosQuery';
import { useThread } from './simple';

const useSetNotificationsFrequencyInThread = ({
  notificationFrequencyTypeName,
  threadSlug,
}: {
  notificationFrequencyTypeName: NotificationFrequencyType;
  threadSlug: Ref<ThreadSlug>;
}) => {
  const { reexecute } = useThread(threadSlug);

  return useAxiosMutation({
    async mutationFn({ axiosInstance }) {
      await axiosInstance.put(`/notifications/thread/${threadSlug.value}/frequency`, {
        frequency: {
          name: notificationFrequencyTypeName,
        },
      });
      await reexecute();
    },
    mutationKey: computed(() => `notifications/thread/${threadSlug.value}/frequency`),
  });
};

export const useDisableNotificationsInThread = (
  threadSlug: Ref<ThreadSlug>,
): Omit<UseMutationReturnValue<void, void, Error>, 'mutate'> & {
  disableNotificationsInThread: UseMutationReturnValue<void, void, Error>['mutate'];
} => {
  const { mutate, ...rest } = useSetNotificationsFrequencyInThread({
    notificationFrequencyTypeName: NotificationFrequencyType.never,
    threadSlug,
  });

  return {
    ...rest,
    disableNotificationsInThread: mutate,
  };
};

export const useEnableNotificationsInThread = (
  threadSlug: Ref<ThreadSlug>,
): Omit<UseMutationReturnValue<void, void, Error>, 'mutate'> & {
  enableNotificationsInThread: UseMutationReturnValue<void, void, Error>['mutate'];
} => {
  const { mutate, ...rest } = useSetNotificationsFrequencyInThread({
    notificationFrequencyTypeName: NotificationFrequencyType.immediate,
    threadSlug,
  });

  return {
    ...rest,
    enableNotificationsInThread: mutate,
  };
};
