<template>
  <article class="c-sidebar-list-widget-item">
    <!-- @slot Use this slot to render content -->
    <slot />
  </article>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'SidebarListWidgetItem',
});
</script>

<style lang="scss" scoped>
@import '@caff/cds-scss-core';

.c-sidebar-list-widget-item {
  @extend %t-padding-h-m;
  @extend %t-padding-v-s;

  border-top: 1px solid var(--colors-background);
}
</style>
