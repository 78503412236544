import { Category } from '@caff/category-api-model';
import { Image, ImageKind, S3ConfigForImageKind } from '@caff/image-isomorphic-model';

export const getAvatar = ({
  category,
  s3ConfigForImageKind,
}: {
  category: Pick<Category, 'avatar'>;
  s3ConfigForImageKind: S3ConfigForImageKind;
}): Image => {
  return new Image(
    {
      idImage: category.avatar,
      kind: ImageKind.category_avatar,
    },
    s3ConfigForImageKind,
  );
};

export const getBannerImage = ({
  category,
  s3ConfigForImageKind,
}: {
  category: Pick<Category, 'bannerImage'>;
  s3ConfigForImageKind: S3ConfigForImageKind;
}): Image => {
  return new Image(
    {
      idImage: category.bannerImage,
      kind: ImageKind.category_banner,
    },
    s3ConfigForImageKind,
  );
};
