import { RouteRecordRaw } from 'vue-router';

import { UserModerationStatus } from '@caff/user-api-model';

type UserManagerView = typeof import('../views/admin/UserManager.vue');
const UserManager = (): Promise<UserManagerView> => import('../views/admin/UserManager.vue');

const userManagerRoute = {
  path: '/admin/users/:filterByStatus',
  component: UserManager,
  props: true,
} satisfies RouteRecordRaw;

export const userManagerShowingOnlyActivatedRoute = {
  ...userManagerRoute,
  name: 'user-manager-activated',
  path: `/admin/users/${UserModerationStatus.activated}`,
  props: {
    filterByStatus: UserModerationStatus.activated,
  },
} satisfies RouteRecordRaw;

export const userManagerShowingOnlyDeactivatedRoute = {
  ...userManagerRoute,
  name: 'user-manager-deactivated',
  path: `/admin/users/${UserModerationStatus.deactivated}`,
  props: {
    filterByStatus: UserModerationStatus.deactivated,
  },
} satisfies RouteRecordRaw;
