<template>
  <ThreadSidebarListItem
    v-if="thread"
    :idThumbnail="thread.idThumbnail"
    :s3BucketConfigForImageKind="s3ConfigForImageKind"
    :title="thread.title"
    :to="route"
  >
    <template #actions>
      <ThreadAdvancedOptionsButton :key="threadSlug" :threadSlug="threadSlug" />
    </template>
  </ThreadSidebarListItem>
  <!-- TODO: Add loading and error states -->
</template>

<script lang="ts" setup>
import { computed } from 'vue';

import { ThreadSidebarListItem } from '@caff/thread-sidebar-list-item-frontend-component';
import { ThreadSlug } from '@caff/shallow-thread-api-model';

import { useS3ConfigForImageKind, useThread } from '../../composition';
import { getThreadRoute } from '../../router';
import ThreadAdvancedOptionsButton from '../thread-advanced-options-menu/ThreadAdvancedOptionsButton.vue';

const props = defineProps<{
  threadSlug: ThreadSlug;
}>();

const s3ConfigForImageKind = useS3ConfigForImageKind();

const route = computed(() => getThreadRoute(props.threadSlug));
const { thread } = useThread(computed(() => props.threadSlug));
</script>
