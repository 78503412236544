import type { Ref } from 'vue';
import { computed } from 'vue';

import type {
  PostId,
  ShallowPostWithReplies as RawShallowPostWithReplies,
} from '@caff/post-api-model';
import type { ThreadSlug } from '@caff/shallow-thread-api-model';

import { getRetryableFunction } from '../../utils/retryable';
import { captureException } from '../../utils/sentry';
import { setReply } from '../post';
import type { UseAxiosFlatPaginatedQueryResult } from '../useAxiosQuery';
import { useAxiosFlatPaginatedQuery } from '../useAxiosQuery';
import { useOnCurrentlyLoggedInUserChange } from '../useSession';
import { usePost } from './simple';

export const usePostRepliesIds = (
  parentPostId: Ref<PostId | null>,
): UseAxiosFlatPaginatedQueryResult<Array<PostId>> & {
  postRepliesIds: Ref<Array<PostId> | null>;
} => {
  const { post: parentPost } = usePost(parentPostId);

  const { data, invalidate, ...rest } = useAxiosFlatPaginatedQuery<
    Array<ThreadSlug>,
    number,
    Error
  >({
    isEnabled: computed(() => !!parentPost.value),
    getQueryKey: (cursor = 1) => computed(() => `post/${parentPostId.value}/replies/${cursor}`),
    async queryPageFn({ axiosInstance, cursor = 1, setQueryData }) {
      if (!parentPostId.value) {
        return {
          data: [],
          nextCursor: null,
        };
      }

      const parent = parentPost.value;
      if (!parent) {
        const error = new Error('Tried to fetch replies for non-existent post');

        captureException(error, {
          tags: {
            parentPostId: parentPostId.value,
          },
        });

        throw error;
      }

      const {
        data: shallowPostsWithReplies,
      }: {
        data: Array<RawShallowPostWithReplies>;
      } = await axiosInstance.get(`/post/${parentPostId.value}/replies/${cursor}`);

      await Promise.all(
        shallowPostsWithReplies.map((rawPost) =>
          setReply({
            parentId: parent.uuid,
            reply: rawPost,
            rootThreadSlug: parent.rootThreadSlug,
            setQueryData,
          }),
        ),
      );

      return {
        data: shallowPostsWithReplies.map(({ uuid }) => uuid),
        nextCursor: shallowPostsWithReplies.length > 0 ? cursor + 1 : null,
      };
    },
  });

  useOnCurrentlyLoggedInUserChange(async () => {
    await getRetryableFunction(async () => {
      await invalidate();
    });
  });

  return { ...rest, invalidate, postRepliesIds: data };
};
