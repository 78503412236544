/**
 * Like setTimeout but returning a promise.
 *
 * @param delayInMilliseconds Amount of milliseconds to wait.
 * @returns Promise that will be resolved after a certain amount of milliseconds
 */
export const asyncWait = (delayInMilliseconds: number): Promise<void> =>
  new Promise((resolve) => {
    setTimeout(resolve, delayInMilliseconds);
  });

export const waitOneFrame = (): Promise<void> =>
  new Promise((resolve) => {
    requestAnimationFrame(() => {
      requestAnimationFrame(() => {
        resolve();
      });
    });
  });
