import { RouteLocationRaw } from 'vue-router';

import { ColorSchema } from '@caff/color-schema-api-model';
import { Username, UserPermissions, User as RawUser } from '@caff/user-api-model';

import { getUserProfileRoute } from '../router';
import { NotificationFrequency } from './NotificationFrequency';

import deburr from 'lodash.deburr';
import fromPairs from 'lodash.frompairs';

export type CanonicalUsername = Username;

export const getCanonicalUsername = (username: Username): CanonicalUsername =>
  username.toLowerCase();

export class User {
  public readonly username: Username;
  public readonly displayName: string;
  public readonly idAvatar: string | null;
  public notificationFrequency: NotificationFrequency;

  constructor(rawData: RawUser) {
    this.username = rawData.username;
    this.displayName = rawData.displayName;
    this.idAvatar = rawData.idAvatar;
    this.notificationFrequency = new NotificationFrequency(rawData.notificationFrequency);
  }

  get route(): RouteLocationRaw {
    return getUserProfileRoute(this.username);
  }

  get areDisplayNameAndUsernameSimilar(): boolean {
    const simplifiedDisplayName = this.displayName
      .replace(/^[^0-9a-zA-Z_]+\s(.*)$/, '$1')
      .replace(/^(.*)\s[^0-9a-zA-Z_]+$/, '$1')
      .trim()
      .replace(' ', '_');

    return deburr(simplifiedDisplayName).toLowerCase() === this.username.toLowerCase();
  }
}

export interface RawLoggedInUser extends RawUser {
  permissions: UserPermissions[];
  preferredColorSchema: {
    name: ColorSchema;
  };
}

type UserPermissionsLookupTable = Partial<Record<UserPermissions, UserPermissions>>;

export class LoggedInUser extends User {
  public readonly permissions: UserPermissionsLookupTable;
  public readonly preferredColorSchema: {
    name: ColorSchema;
  };

  constructor(rawData: RawLoggedInUser) {
    super(rawData);
    this.permissions = fromPairs(
      rawData.permissions.map((p) => [p, p]),
    ) as UserPermissionsLookupTable;
    this.preferredColorSchema = rawData.preferredColorSchema;
  }

  withIdAvatar(idAvatar: string | null): LoggedInUser {
    const permissionKeys = Object.keys(this.permissions) as Array<
      keyof LoggedInUser['permissions']
    >;
    const permissions = permissionKeys.filter((name) => this.permissions[name]);

    return new LoggedInUser({
      username: this.username,
      displayName: this.displayName,
      notificationFrequency: {
        name: this.notificationFrequency.name,
      },
      permissions,
      preferredColorSchema: this.preferredColorSchema,
      idAvatar,
    });
  }

  get canonicalUsername(): string {
    return getCanonicalUsername(this.username);
  }

  get canHideThreads(): boolean {
    return !!this.permissions[UserPermissions.delete_thread];
  }

  get canDeleteThreads(): boolean {
    return !!this.permissions[UserPermissions.permanently_delete_thread];
  }

  get canHidePosts(): boolean {
    return !!this.permissions[UserPermissions.delete_post];
  }

  get canDeletePosts(): boolean {
    return !!this.permissions[UserPermissions.permanently_delete_post];
  }

  get canActivateUsers(): boolean {
    return !!this.permissions[UserPermissions.activate_user];
  }

  get canDeactivateUsers(): boolean {
    return !!this.permissions[UserPermissions.deactivate_user];
  }

  get canManageUsers(): boolean {
    return this.canActivateUsers || this.canDeactivateUsers;
  }
}
