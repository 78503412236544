import type { IconDefinition, IconName, IconPrefix } from '@fortawesome/fontawesome-common-types';

export const prefix = 'caff' as IconPrefix;
export const iconName = 'speaker-off' as IconName;
export const width = 14;
export const height = 14;
export const ligatures = [];
export const unicode = 'f846';
export const svgPathData =
  'm4.78988699 2.42781975c.80936372-.90784158 2.11613757-.27611883 2.20529752 1.00601376l.00481549.13981966v7.85269363c0 1.3274563-1.27886476 2.0362583-2.12157024 1.2372515l-.08854277-.091418-1.95488699-2.1928593h-1.5225c-.6578686 0-1.20269049-.57916742-1.29779264-1.33463901l-.01247931-.14361863-.00222805-.09228108v-2.61756456c0-.81636196.52052707-1.48724644 1.18609749-1.56334924l.12640251-.00718949h1.5220625zm6.96401091 2.60103204c.4898445-.51287985 1.2939759-.51287985 1.7838204 0 .4128968.43231369.4711828 1.0948155.1494012 1.60749158l-.0810156.11547727-.0684152.07828105-.6008806.62789831.6801482.65008983c.4444959.42453191.503762 1.08512813.1777984 1.57261053l-.0820608.10923666-.0957376.10197315c-.4323137.41289683-1.0948155.47118283-1.6074916.14940123l-.1154772-.08101559-.0781688-.06830793-.6300106-.60198788-.6480898.68114821c-.4245319.44449589-1.08512816.50376199-1.57261056.17779839l-.10923667-.0820608-.10197314-.09573759c-.41289688-.43231369-.47118287-1.0948155-.14940128-1.60749158l.08101564-.11547727.06819561-.07805103.6001002-.63012833-.67914821-.64808983c-.44449587-.42453191-.50376199-1.08512813-.17779835-1.57261053l.08206078-.10923666.09573757-.10197315c.43231369-.41289688 1.0948155-.47118287 1.60749161-.14940128l.1154772.08101564.0781634.06830284.628016.59999297z';

export const definition = {
  prefix,
  iconName,
  icon: [width, height, ligatures, unicode, svgPathData],
} as IconDefinition;

export const faSpeakerOff = definition;
