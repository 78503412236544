import { EMAIL as EMAIL_REGEX } from '@caff/isomorphic-regex';

import { LocalizableError } from './LocalizableError';

export const usernameMinLength = 2;
export const usernameMaxLength = 20;

export const getUsernameSyncError = (username: string): LocalizableError | null => {
  if (!username) {
    return null;
  }

  if (username.length < usernameMinLength) {
    return {
      localizableErrorMessage: 'validationError.username.minLength',
      localizableErrorMessageParams: { minLength: usernameMinLength },
    };
  }

  if (username.length > usernameMaxLength) {
    return {
      localizableErrorMessage: 'validationError.username.maxLength',
      localizableErrorMessageParams: { maxLength: usernameMaxLength },
    };
  }

  if (!/^[a-zA-Z0-9]+$/.test(username)) {
    return {
      localizableErrorMessage: 'validationError.username.symbols',
      localizableErrorMessageParams: {},
    };
  }

  return null;
};

export const getEmailSyncError = (email: string): LocalizableError | null => {
  if (!email) {
    return null;
  }

  if (!EMAIL_REGEX.test(email)) {
    return {
      localizableErrorMessage: 'validationError.email.format',
      localizableErrorMessageParams: {},
    };
  }

  return null;
};
