<template>
  <dialog class="c-modal__dialog" open ref="modalElement">
    <div class="c-modal__content" ref="modalContent">
      <slot />
    </div>
  </dialog>
</template>

<script lang="ts">
import { defineComponent, onBeforeUnmount, onMounted, ref } from 'vue';

import { onClickOutside } from './composition';
import {
  reattachModalToDocumentBody as _reattachModalToDocumentBody,
  removeModalFromDOM,
} from './modal';

export default defineComponent({
  name: 'Modal',
  emits: ['click-outside'],
  setup(props, { emit }) {
    const modalElement = ref(null as unknown as HTMLElement);
    const modalContent = ref(null as unknown as HTMLElement);

    const reattachModalToDocumentBody = () => _reattachModalToDocumentBody(modalElement.value);

    onClickOutside(modalContent, ($event) => {
      /**
       * User clicked outside the modal.
       *
       * @event click-outside
       * @type {PointerEvent}
       */
      emit('click-outside', $event);
    });

    onBeforeUnmount(() => {
      removeModalFromDOM(modalElement.value);
    });

    onMounted(() => {
      reattachModalToDocumentBody();
    });

    return {
      modalElement,
      modalContent,
    };
  },
});
</script>

<style lang="scss" scoped>
@import '@caff/cds-scss-core';

.c-modal__dialog {
  @extend %t-z-index-modal;

  align-items: center;
  background: var(--colors-box-shadow);
  border: 0;
  display: flex;
  flex-direction: row;
  height: 100vh;
  justify-content: center;
  left: 0;
  padding: 0;
  position: fixed;
  top: 0;
  width: 100vw;
}

.c-modal__content {
  @include t-box-shadow($box-shadow-popup-base);
  @include t-width-main-column;

  $border-radius: 8px;

  --border-radius: #{$border-radius};
  --border: 1px solid var(--colors-popup-border);
  --modal-vspace-to-edges: var(--forced-modal-vspace-to-edges, var(--spacing-l));

  background: var(--colors-box-background);
  border: var(--border);
  border-radius: var(--border-radius);
  margin: 0;
  max-height: calc(100vh - 2 * var(--modal-vspace-to-edges));
  max-width: var(--main-column-width);
  overflow-y: auto;

  @media only screen and (min-height: 540) {
    --modal-vspace-to-edges: var(--forced-modal-vspace-to-edges, var(--spacing-xl));
  }

  @media only screen and (min-height: 650) {
    --modal-vspace-to-edges: var(--forced-modal-vspace-to-edges, var(--spacing-xxl));
  }

  @include t-responsive--smaller-than($responsive-breakpoint-tablet-medium) {
    --border-radius: var(--forced-border-radius-in-mobile, #{$border-radius});
    --modal-vspace-to-edges: calc(var(--forced-modal-vspace-to-edges, var(--spacing-xxl) / 2));

    border: 0;
    border-radius: var(--border-radius) var(--border-radius) 0 0;
    border-top: var(--border);
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: unset;
  }
}
</style>
