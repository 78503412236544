import { EMAIL as EMAIL_REGEX } from '@caff/isomorphic-regex';

export const getEmailSyncError = (email: string): string | null => {
  if (!email) {
    return null;
  }

  if (!EMAIL_REGEX.test(email)) {
    return 'validationError.email.format';
  }

  return null;
};
