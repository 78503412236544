import { UserMention } from '@caff/mention-api-model';

import {
  MarkdownInlineNode,
  MarkdownNodeType,
  MarkdownUserMentionNode,
  ParseTokenFunction,
} from './types';

export const parseTextToken: ParseTokenFunction = (params) => {
  const token = params.tokens[params.startingNodeIndex];

  const lastCharacterAbsolutePosition =
    params.startingCharacterAbsolutePosition + token.content.length;
  const sortedAbsoluteMentionStartingPositions = Object.keys(params.mentions)
    .map((index) => parseInt(index, 10))
    .sort();

  const nodes: Array<MarkdownUserMentionNode | MarkdownInlineNode> = [];

  let absolutePositionOfNextSegment = params.startingCharacterAbsolutePosition;

  for (const absoluteMentionStartingPosition of sortedAbsoluteMentionStartingPositions) {
    if (absolutePositionOfNextSegment >= lastCharacterAbsolutePosition) {
      break;
    }

    // TODO: Right now we ignore any non-user mention
    if (!('username' in params.mentions[absoluteMentionStartingPosition])) {
      continue;
    }

    if (absoluteMentionStartingPosition < absolutePositionOfNextSegment) {
      continue;
    }
    // This mention starts after this token finishes so it belongs to a different branch of the AST
    if (absoluteMentionStartingPosition > lastCharacterAbsolutePosition) {
      break;
    }

    const mentionLength = params.mentions[absoluteMentionStartingPosition].length;
    const lastPositionOfMention = absoluteMentionStartingPosition + mentionLength;

    if (lastPositionOfMention > lastCharacterAbsolutePosition) {
      console.warn('Mention finishes after last character of this token', {
        lastCharacterAbsolutePosition,
        absoluteMentionStartingPosition,
        lastPositionOfMention,
        mentions: params.mentions,
        content: token.content,
      });

      continue;
    }

    const textBeforeMentionLength = absoluteMentionStartingPosition - absolutePositionOfNextSegment;

    if (textBeforeMentionLength) {
      const textBeforeMentionStartingPositionRelativeToParent =
        absolutePositionOfNextSegment - params.startingCharacterAbsolutePosition;

      const textBeforeMention = token.content.substring(
        textBeforeMentionStartingPositionRelativeToParent,
        textBeforeMentionStartingPositionRelativeToParent + textBeforeMentionLength,
      );

      nodes.push({
        type: MarkdownNodeType.plainText,
        content: textBeforeMention,
        sourceRange: {
          absoluteStart: absolutePositionOfNextSegment,
          length: textBeforeMention.length,
        },
      });
    }

    absolutePositionOfNextSegment += textBeforeMentionLength;

    const mentionedUsername = (params.mentions[absoluteMentionStartingPosition] as UserMention)
      .username;

    nodes.push({
      type: MarkdownNodeType.userMention,
      username: mentionedUsername,
      sourceRange: {
        absoluteStart: absoluteMentionStartingPosition,
        length: mentionLength,
      },
    });

    absolutePositionOfNextSegment += mentionLength;
  }

  if (absolutePositionOfNextSegment < lastCharacterAbsolutePosition) {
    const textAfterMentionStartingPositionRelativeToParent =
      absolutePositionOfNextSegment - params.startingCharacterAbsolutePosition;
    const textAfterMention = token.content.substring(
      textAfterMentionStartingPositionRelativeToParent,
    );

    nodes.push({
      type: MarkdownNodeType.plainText,
      content: textAfterMention,
      sourceRange: {
        absoluteStart: absolutePositionOfNextSegment,
        length: textAfterMention.length,
      },
    });
  }

  return {
    nodes: nodes,
    nodesAdvanced: 1,
    charactersAdvanced: token.content.length,
  };
};
