import { JSONSchema7 } from 'json-schema';

import { ColorSchemaDbEnum } from '@caff/db-types';

export const enum ColorSchema {
  light = ColorSchemaDbEnum.light,
  dark = ColorSchemaDbEnum.dark,
  auto = ColorSchemaDbEnum.auto,
}

export const colorSchemaByDbEnum: Record<ColorSchemaDbEnum, ColorSchema> = {
  [ColorSchemaDbEnum.auto]: ColorSchema.auto,
  [ColorSchemaDbEnum.dark]: ColorSchema.dark,
  [ColorSchemaDbEnum.light]: ColorSchema.light,
};

export interface PreferredColorSchema {
  name: ColorSchema;
}

export const PreferredColorSchemaJsonSchema: JSONSchema7 = {
  description: 'A color schema preference',
  type: 'object',
  properties: {
    name: {
      type: 'string',
      enum: [ColorSchema.light, ColorSchema.dark, ColorSchema.auto],
    },
  },
  additionalProperties: false,
  required: ['name'],
};
