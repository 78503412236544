<template>
  <form @submit.stop.prevent="submitForm()">
    <H2>{{ t('title') }}</H2>
    <P>
      <span v-if="passwordError" class="u-color-red-500">
        {{ passwordError }}
      </span>
      <template v-else>
        {{ t('password.visibleLabel') }}
      </template>
    </P>
    <Input
      :icon="faKey"
      :label="t('password.accessibilityLabel')"
      :placeholder="t('password.placeholder')"
      :modelValue="password"
      name="password"
      autocomplete="new-password"
      class="u-width-100"
      required
      type="password"
      @update:modelValue="handlePasswordInput($event)"
    />
    <P>
      <span v-if="repeatPasswordError" class="u-color-red-500">
        {{ repeatPasswordError }}
      </span>
      <template v-else>
        {{ t('repeatPassword.visibleLabel') }}
      </template>
    </P>
    <Input
      :icon="faKey"
      :label="t('repeatPassword.accessibilityLabel')"
      :placeholder="t('repeatPassword.placeholder')"
      :modelValue="repeatPassword"
      autocomplete="new-password"
      class="u-width-100"
      name="repeated-password"
      required
      type="password"
      @update:modelValue="handleRepeatPasswordInput($event)"
    />

    <Footer>
      <template #leading>
        <Button
          :disabled="isCreatingAccount"
          :type="secondary"
          data-testid="cancel-button"
          @click.stop.prevent="dismiss()"
        >
          {{ t('action.cancel') }}
        </Button>
      </template>

      <template #trailing>
        <Button
          :disabled="isSubmitDisabled"
          :loading="isCreatingAccount"
          :type="primary"
          data-testid="next-button"
          @click.stop.prevent="submitForm()"
        >
          {{ t('action.next') }}
        </Button>
        <Button
          :disabled="isCreatingAccount"
          :type="secondary"
          class="u-margin-r-xs"
          data-testid="back-button"
          @click.stop.prevent="goBack()"
        >
          <FontAwesomeIcon :icon="faArrowLeft" fixed-width />

          {{ t('action.back') }}
        </Button>
      </template>
    </Footer>
  </form>
</template>

<script lang="ts">
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { defineComponent, PropType } from 'vue';
import { useI18n } from 'vue-i18n';

import { Button, ButtonType } from '@caff/cds-button';
import { faKey, faArrowLeft } from '@caff/cds-icons';
import { Input } from '@caff/cds-input';
import { Footer, H2, P } from '@caff/cds-modal-flow';

export default defineComponent({
  name: 'PasswordStep',
  components: { Button, FontAwesomeIcon, Footer, H2, Input, P },
  props: {
    password: {
      type: String,
      required: true,
    },
    passwordError: {
      type: null as unknown as PropType<string | null>,
      required: false,
    },
    repeatPassword: {
      type: String,
      required: true,
    },
    repeatPasswordError: {
      type: null as unknown as PropType<string | null>,
      required: false,
    },
    isSubmitDisabled: {
      type: Boolean,
      required: true,
    },
    isCreatingAccount: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['password-input', 'repeat-password-input', 'dismiss', 'go-back', 'submit'],
  setup(props, { emit }) {
    const { t } = useI18n();

    const handlePasswordInput = (value: string): void => {
      /**
       * User changed the value of the password input.
       *
       * @event password-input
       * @type {String}
       */
      emit('password-input', value);
    };

    const handleRepeatPasswordInput = (value: string): void => {
      /**
       * User changed the value of the repeat password input.
       *
       * @event repeat-password-input
       * @type {String}
       */
      emit('repeat-password-input', value);
    };

    const dismiss = () => {
      /**
       * User dismissed the form.
       *
       * @event dismiss
       * @type {void}
       */
      emit('dismiss');
    };

    const goBack = () => {
      /**
       * User went to previous step of the form.
       *
       * @event go-back
       * @type {void}
       */
      emit('go-back');
    };

    const submitForm = () => {
      if (props.isSubmitDisabled) {
        return;
      }

      /**
       * User submitted the form.
       *
       * @event submit
       * @type {void}
       */
      emit('submit');
    };

    return {
      t,
      faArrowLeft,
      faKey,
      primary: ButtonType.primary,
      secondary: ButtonType.secondary,
      handlePasswordInput,
      handleRepeatPasswordInput,
      dismiss,
      goBack,
      submitForm,
    };
  },
});
</script>

<i18n lang="json" locale="es">
{
  "action": {
    "back": "Atrás",
    "cancel": "Cancelar",
    "next": "Continuar"
  },
  "password": {
    "accessibilityLabel": "Contraseña",
    "placeholder": "Contraseña",
    "visibleLabel": "Elige una constraseña de acceso"
  },
  "repeatPassword": {
    "accessibilityLabel": "Repite la contraseña",
    "placeholder": "Contraseña",
    "visibleLabel": "Repite la contraseña"
  },
  "title": "Crea tu cuenta"
}
</i18n>
