import type { IconDefinition, IconName, IconPrefix } from '@fortawesome/fontawesome-common-types';

export const prefix = 'caff' as IconPrefix;
export const iconName = 'pencil' as IconName;
export const width = 14;
export const height = 14;
export const ligatures = [];
export const unicode = 'f842';
export const svgPathData =
  'm.79123332 10.240328 2.96727334 2.9666798-.61868389.6191738c-.11131374.111295-.26227637.1738184-.41968457.1738184h-2.12656482c-.32782152 0-.59357338-.2657519-.59357338-.5935734v-2.1283919c0-.1574464.06255364-.3084425.17389643-.4197626zm7.42026086-7.41907371 2.96727332 2.96667977-6.03545414 6.03486054-2.96786691-2.96667973zm3.48825812-2.64737285 2.1264519 2.12834179c.2315375.2319724.2314489.60770389-.000198.83956706l-1.262432 1.26193064-2.96786689-2.96667977 1.26472539-1.2631254c.2317085-.23190113.6075384-.23186279.8393196-.00003432z';

export const definition = {
  prefix,
  iconName,
  icon: [width, height, ligatures, unicode, svgPathData],
} as IconDefinition;

export const faPencil = definition;
