<template>
  <Modal>
    <div class="u-padding-m">
      <ModalBanner :progress="progress" />

      <EmailStep
        v-if="currentStep === emailStep"
        :email="email"
        :isLoading="isLoading"
        :isSubmitDisabled="isRequestPasswordRecoveryEmailDisabled"
        @email-input="handleEmailInput($event)"
        @dismiss="dismiss()"
        @submit="requestPasswordRecoveryEmail()"
      >
        <template v-if="error" #emailError>
          <span class="u-color-red-500 u-margin-r-1em">{{ error }}</span>
          <i18n-t v-if="!isEmailUsed" keypath="hint.signUp.message" tag="span">
            <Button :type="link" data-testid="sign-up-button" @click.prevent.stop="signUp()">
              {{ t('hint.signUp.callToAction') }}
            </Button>
          </i18n-t>
        </template>
      </EmailStep>
      <SuccessStep
        v-else-if="currentStep === successStep"
        :title="t('success.title')"
        :body="t('success.body')"
        :close="t('success.close')"
        @dismiss="dismiss()"
      />
    </div>
  </Modal>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue';
import { useI18n } from 'vue-i18n';

import { Button, ButtonType } from '@caff/cds-button';
import { Modal } from '@caff/cds-modal';
import { ModalBanner } from '@caff/cds-modal-banner';
import { SuccessStep } from '@caff/cds-modal-flow';

import { EmailStep, ForgotPasswordModalStep, allForgotPasswordModalSteps } from './steps';

import { getEmailSyncError } from './validation';

export default defineComponent({
  name: 'ForgotPasswordModal',
  components: {
    Button,
    EmailStep,
    Modal,
    ModalBanner,
    SuccessStep,
  },
  props: {
    currentStep: {
      type: String as PropType<ForgotPasswordModalStep>,
      required: true,
      validator: (value: string): boolean => {
        if (value in allForgotPasswordModalSteps) {
          return true;
        }

        console.warn(
          `[ForgotPasswordModal] Invalid value «${value}» for currentStep, expected a value in ForgotPasswordModalStep enum`,
        );
        return false;
      },
    },
    email: {
      type: String,
      required: true,
    },
    isCheckingEmailExistance: {
      type: Boolean,
      required: true,
    },
    isEmailUsed: {
      type: Boolean,
      required: true,
    },
    isRequestingPasswordRecoveryEmail: {
      type: Boolean,
      required: true,
    },
    passwordRecoveryEmailRequestError: {
      type: null as unknown as PropType<string | null>,
      required: false,
    },
  },
  emits: ['email-input', 'dismiss', 'request-password-recovery-email', 'sign-up'],
  setup(props, { emit }) {
    const { t } = useI18n();

    const progress = computed(() => {
      switch (props.currentStep) {
        case ForgotPasswordModalStep.email:
          return 0.5;
        case ForgotPasswordModalStep.success:
          return 1;
      }

      return 0;
    });

    const isLoading = computed(
      () => props.isCheckingEmailExistance || props.isRequestingPasswordRecoveryEmail,
    );

    const handleEmailInput = (newEmail: string) => {
      /**
       * User changed email value.
       *
       * @event email-input
       * @type {string}
       */
      emit('email-input', newEmail);
    };

    const dismiss = () => {
      /**
       * User wants to dismiss this Forgot Password flow.
       *
       * @event dismiss
       * @type {void}
       */
      emit('dismiss');
    };

    const requestPasswordRecoveryEmail = () => {
      /**
       * User wants to create an account with current data.
       *
       * @event request-password-recovery-email
       * @type {void}
       */
      emit('request-password-recovery-email');
    };

    const signUp = () => {
      /**
       * User wants to sign up.
       *
       * @event sign-up
       * @type {{ email: string }}
       */
      emit('sign-up', { email: props.email });
    };

    const error = computed((): string | null => {
      if (!props.email) {
        return null;
      }

      if (!props.isEmailUsed) {
        return t('validationError.email.unused').toString();
      }

      const syncError = getEmailSyncError(props.email);

      if (syncError) {
        return t(syncError).toString();
      }

      return props.passwordRecoveryEmailRequestError || null;
    });

    const isRequestPasswordRecoveryEmailDisabled = computed((): boolean => {
      return (
        !!error.value ||
        props.isCheckingEmailExistance ||
        props.isRequestingPasswordRecoveryEmail ||
        !props.isEmailUsed
      );
    });

    return {
      t,
      link: ButtonType.link,
      emailStep: ForgotPasswordModalStep.email,
      successStep: ForgotPasswordModalStep.success,
      progress,
      isLoading,
      handleEmailInput,
      dismiss,
      requestPasswordRecoveryEmail,
      signUp,
      error,
      isRequestPasswordRecoveryEmailDisabled,
    };
  },
});
</script>

<i18n lang="json" locale="es">
{
  "hint": {
    "signUp": {
      "callToAction": "crear una cuenta",
      "message": "¿Quieres {0}?"
    }
  },
  "success": {
    "body": "En breves recibirás un correo que te permitirá recuperar la contraseña de tu cuenta.",
    "close": "Ir a la portada",
    "title": "¡Correo enviado!"
  },
  "validationError": {
    "email": {
      "format": "¡Ese correo electrónico no es válido!",
      "unused": "No existe ninguna cuenta con ese correo electrónico."
    }
  }
}
</i18n>
