import { parseBlockquoteToken } from './parseBlockquoteToken';
import { parseCodeblockToken } from './parseCodeblockToken';
import { parseEmphasisToken } from './parseEmphasisToken';
import { parseHeadingToken } from './parseHeadingToken';
import { parseImageToken } from './parseImageToken';
import { parseInlineToken } from './parseInlineToken';
import { parseLinkToken } from './parseLinkToken';
import { parseListItemToken } from './parseListItemToken';
import { parseOrderedListToken } from './parseOrderedListToken';
import { parseParagraphToken } from './parseParagraphToken';
import { parseStrikethroughToken } from './parseStrikethroughToken';
import { parseStrongToken } from './parseStrongToken';
import { parseTextToken } from './parseTextToken';
import { parseUnorderedListToken } from './parseUnorderedListToken';
import { TokenTypeParsers } from './types';

export const tokenTypeParsers: TokenTypeParsers = {
  text: parseTextToken,
  inline: parseInlineToken,
  paragraph_open: parseParagraphToken,
  link_open: parseLinkToken,
  image: parseImageToken,
  strong_open: parseStrongToken,
  em_open: parseEmphasisToken,
  s_open: parseStrikethroughToken,
  heading_open: parseHeadingToken,
  bullet_list_open: parseUnorderedListToken,
  ordered_list_open: parseOrderedListToken,
  list_item_open: parseListItemToken,
  blockquote_open: parseBlockquoteToken,
  code_block: parseCodeblockToken,
  fence: parseCodeblockToken,
};
