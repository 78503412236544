<template>
  <div class="c-login-popup-content">
    <ActivityIndicator v-if="loading" class="c-login-popup-content__activity-indicator" covering />

    <div class="c-login-popup-content__section">
      <p
        v-if="loginError"
        class="c-login-popup-content__paragraph u-color-red-500"
        data-testid="login-error"
      >
        <template v-if="isWrongLoginError">
          {{ t('error.wrongLoginData') }}
        </template>
        <template v-else-if="isAccountNotReadyYetError">
          {{ t('error.accountNotReadyYet') }}
        </template>
      </p>
      <h2 v-else class="c-login-popup-content__paragraph">
        {{ t('header.login') }}
      </h2>

      <LoginForm
        :login-error="loginError"
        :loading="loading"
        @show-password-recovery-form="showPasswordRecoveryForm()"
        @login="login($event)"
      />
    </div>

    <hr class="c-login-popup-content__separator" />

    <div class="c-login-popup-content__section">
      <Button
        :type="secondaryButtonType"
        class="u-width-100"
        data-testid="signup-button"
        @click="showRegisterForm()"
      >
        {{ t('action.signup') }}
      </Button>
    </div>

    <hr class="c-login-popup-content__separator" />

    <div class="c-login-popup-content__section">
      <Button
        :type="linkButtonType"
        class="u-width-100"
        data-testid="forgot-password-button"
        @click="showPasswordRecoveryForm()"
      >
        {{ t('action.forgotPassword') }}
      </Button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import { Button, ButtonType } from '@caff/cds-button';
import { ActivityIndicator } from '@caff/cds-activity-indicator';

import { LoginError } from '../../composition';
import LoginForm, { LoginEventPayload } from './LoginForm.vue';

const props = defineProps<{
  loginError?: string | null;
  loading?: boolean;
}>();
const emit = defineEmits<{
  showPasswordRecoveryForm: [];
  login: [LoginEventPayload];
  showRegisterForm: [];
}>();

const { t } = useI18n();

const secondaryButtonType = ButtonType.secondary;
const linkButtonType = ButtonType.link;

const isAccountNotReadyYetError = computed(
  () => props.loginError === LoginError.accountNotReadyYet,
);

const isWrongLoginError = computed(() => props.loginError === LoginError.wrongLoginData);

const showPasswordRecoveryForm = () => emit('showPasswordRecoveryForm');

const login = ({ usernameOrEmail, password, rememberLogin }: LoginEventPayload) =>
  emit('login', { usernameOrEmail, password, rememberLogin });

const showRegisterForm = () => emit('showRegisterForm');
</script>

<i18n lang="json" locale="es">
{
  "action": {
    "signup": "Regístrate en Caff",
    "forgotPassword": "¿Olvidaste tu contraseña?"
  },
  "header": {
    "login": "Inicia sesión en Caff"
  },
  "error": {
    "wrongLoginData": "¡Datos incorrectos!",
    "accountNotReadyYet": "¡La cuenta está en proceso!"
  }
}
</i18n>

<style lang="scss" scoped>
@import '@caff/cds-scss-core';

.c-login-popup-content {
  @extend %t-padding-v-m;
}

.c-login-popup-content:deep(.c-login-popup-content__activity-indicator) {
  --z-index: 2;
}

.c-login-popup-content__section {
  @extend %t-padding-h-m;
}

.c-login-popup-content__paragraph {
  @include t-font($font-body-soft);

  margin: 0 0 var(--spacing-s);
}

.c-login-popup-content__separator {
  @extend %t-margin-v-s;

  background: var(--colors-background);
  border: 0;
  height: 1px;
  width: 100%;
}
</style>
