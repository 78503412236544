import { parseNoAttributedHTMLTagToken } from './noAttributedHTMLTagParser';
import { MarkdownNodeHTMLTag, MarkdownNodeType, ParseTokenFunction } from './types';

export const parseStrikethroughToken: ParseTokenFunction = (params) =>
  parseNoAttributedHTMLTagToken({
    sourceText: params.sourceText,
    tokens: params.tokens,
    startingNodeIndex: params.startingNodeIndex,
    startingCharacterAbsolutePosition: params.startingCharacterAbsolutePosition,
    mentions: params.mentions,
    markdownNodeType: MarkdownNodeType.strikethrough,
    markdownNodeHTMLTag: MarkdownNodeHTMLTag.del,
    markdownToken: {
      prefixLength: 2,
      suffixLength: 2,
    },
    tokenTypeParsers: params.tokenTypeParsers,
  });
