<template>
  <picture class="c-responsive-image">
    <source
      v-for="{ type, srcsetEntryString } of sources"
      :key="type"
      :type="type"
      :srcset="srcsetEntryString"
      :sizes="sizesString"
    />
    <img
      :alt="alt"
      :height="height ?? undefined"
      :src="url"
      :srcset="srcset"
      :width="width"
      :sizes="sizesString"
      class="c-responsive-image__image"
    />
  </picture>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue';

import {
  imageHeightVueComponentProperty,
  imageWidthVueComponentProperty,
} from '@caff/image-frontend-model';
import { Image, ImageFormat } from '@caff/image-isomorphic-model';

export default defineComponent({
  name: 'ResponsiveImage',
  props: {
    alt: {
      type: String,
      default: '',
    },
    height: imageHeightVueComponentProperty,
    image: {
      type: Object as PropType<Image>,
      required: true,
    },
    sizes: {
      type: String,
      required: false,
    },
    width: imageWidthVueComponentProperty,
  },
  setup(props) {
    const allSources = computed(() =>
      props.image.getSrcsetSourcesForSize({
        height: props.height || null,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        width: props.width!,
      }),
    );
    const sources = computed(() => allSources.value.allSrcsetSources);
    const srcset = computed(() => allSources.value.baseSrcsetSource.srcsetEntryString);

    const url = computed(() =>
      props.image
        .getUrlForSize({
          imageSize: {
            height: props.height || null,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            width: props.width!,
          },
          imageFormat: ImageFormat.jpeg,
        })
        .toString(),
    );

    const sizesString = computed(
      () => props.sizes ?? (props.width ? `${props.width}px` : undefined),
    );

    return {
      sources,
      srcset,
      url,
      sizesString,
    };
  },
});
</script>

<style lang="scss" scoped>
.c-responsive-image,
.c-responsive-image__image {
  height: 100%;
  line-height: 0;
  width: 100%;
}

.c-responsive-image__image {
  object-fit: cover;
}
</style>
