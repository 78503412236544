import { parseNoAttributedHTMLTagToken } from './noAttributedHTMLTagParser';
import { MarkdownNodeHTMLTag, MarkdownNodeType, ParseTokenFunction } from './types';

const htmlHeadingTagToMarkdownNodeHTMLTag: Record<string, MarkdownNodeHTMLTag> = {
  h1: MarkdownNodeHTMLTag.h1,
  h2: MarkdownNodeHTMLTag.h2,
  h3: MarkdownNodeHTMLTag.h3,
  h4: MarkdownNodeHTMLTag.h4,
  h5: MarkdownNodeHTMLTag.h5,
  h6: MarkdownNodeHTMLTag.h6,
};
const htmlHeadingCharactersAdvanced: Record<string, number> = {
  h1: 1,
  h2: 2,
  h3: 3,
  h4: 4,
  h5: 5,
  h6: 6,
};

export const parseHeadingToken: ParseTokenFunction = (params) => {
  const token = params.tokens[params.startingNodeIndex];
  const markdownNodeHTMLTag = htmlHeadingTagToMarkdownNodeHTMLTag[token.tag];

  return parseNoAttributedHTMLTagToken({
    sourceText: params.sourceText,
    tokens: params.tokens,
    startingNodeIndex: params.startingNodeIndex,
    startingCharacterAbsolutePosition: params.startingCharacterAbsolutePosition,
    mentions: params.mentions,
    markdownNodeType: MarkdownNodeType.heading,
    markdownNodeHTMLTag,
    markdownToken: {
      // +1 due to space after heading prefix
      prefixLength: htmlHeadingCharactersAdvanced[token.tag] + 1,
      suffixLength: 0,
    },
    tokenTypeParsers: params.tokenTypeParsers,
  });
};
