<template>
  <DropdownMenu v-if="isAtLeastOneActionAllowed" :popupClass="popupClass">
    <template #toggleButton="passedProps">
      <slot v-bind="passedProps" />
    </template>

    <template #default="{ closePopover }">
      <MuteUserMenuEntry :username="username" @click="closePopover()" />
      <UnmuteUserMenuEntry :username="username" @click="closePopover()" />
    </template>
  </DropdownMenu>
</template>

<script lang="ts" setup>
import { computed } from 'vue';

import { DropdownMenu } from '@caff/cds-dropdown-menu';
import { Username } from '@caff/user-api-model';

import { useCurrentlyLoggedInUser, useUser } from '../../composition';
import MuteUserMenuEntry, {
  isAllowed as isDisablingNotificationsFromUserAllowed,
} from './MuteUserMenuEntry.vue';
import UnmuteUserMenuEntry, {
  isAllowed as isEnablingNotificationsFromUserAllowed,
} from './UnmuteUserMenuEntry.vue';

const props = defineProps<{
  username: Username;
  popupClass?: string | Array<string> | Record<string, boolean>;
}>();

const { currentlyLoggedInUser } = useCurrentlyLoggedInUser();
const { user } = useUser(computed(() => props.username));

const isAtLeastOneActionAllowed = computed(
  () =>
    isDisablingNotificationsFromUserAllowed(currentlyLoggedInUser.value, user.value) ||
    isEnablingNotificationsFromUserAllowed(currentlyLoggedInUser.value, user.value),
);
</script>
