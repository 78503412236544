import { RouteRecordRaw } from 'vue-router';

import { ThreadSlug } from '@caff/shallow-thread-api-model';

import { RouterMetaField } from './meta-fields';

type ThreadView = typeof import('../views/Thread.vue');
const Thread = (): Promise<ThreadView> => import('../views/Thread.vue');

export const threadRoute = {
  path: '/t/:threadSlug',
  name: 'single-thread',
  component: Thread,
  props: true,
  meta: {
    [RouterMetaField.paramsForwardedToGetSelectorToScrollTo]: ['threadSlug'],
    [RouterMetaField.getSelectorToScrollTo]: (threadSlug): string => `#thread-${threadSlug}`,
  },
} satisfies RouteRecordRaw;

export const getThreadRoute = (
  threadSlug: ThreadSlug,
): typeof threadRoute & { params: { threadSlug: ThreadSlug } } => ({
  ...threadRoute,
  params: { threadSlug },
});
