<template>
  <div>
    <H2 center>{{ t('title') }}</H2>
    <P center>{{ t('body') }}</P>

    <Footer>
      <template #leading>
        <Button :type="secondary" data-testid="dismiss-button" @click.stop.prevent="dismiss()">
          {{ t('action.close') }}
        </Button>
      </template>

      <template #trailing>
        <Button :type="primary" data-testid="start-button" @click.stop.prevent="goToStart()">
          {{ t('action.start') }}
        </Button>
        <Button
          :type="secondary"
          class="u-margin-r-xs"
          data-testid="back-button"
          @click.stop.prevent="goBack()"
        >
          <FontAwesomeIcon :icon="faArrowLeft" fixed-width />

          {{ t('action.back') }}
        </Button>
      </template>
    </Footer>
  </div>
</template>

<script lang="ts">
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';

import { Button, ButtonType } from '@caff/cds-button';
import { faArrowLeft } from '@caff/cds-icons';
import { Footer, H2, P } from '@caff/cds-modal-flow';

export default defineComponent({
  name: 'ErrorStep',
  components: { Button, FontAwesomeIcon, Footer, H2, P },
  computed: {
    primary: () => ButtonType.primary,
    secondary: () => ButtonType.secondary,
    faArrowLeft: () => faArrowLeft,
  },
  emits: ['dismiss', 'go-to-start', 'go-back'],
  setup(props, { emit }) {
    const { t } = useI18n();

    const dismiss = () => {
      /**
       * User dismissed the form.
       *
       * @event dismiss
       * @type {void}
       */
      emit('dismiss');
    };

    const goToStart = () => {
      /**
       * User went to the first step of the form.
       *
       * @event dismiss
       * @type {void}
       */
      emit('go-to-start');
    };

    const goBack = () => {
      /**
       * User went to previous step of the form.
       *
       * @event go-back
       * @type {void}
       */
      emit('go-back');
    };

    return {
      t,
      dismiss,
      goToStart,
      goBack,
    };
  },
});
</script>

<i18n lang="json" locale="es">
{
  "action": {
    "back": "Atrás",
    "close": "Salir",
    "start": "Volver al inicio"
  },
  "body": "Ha sucedido un error inesperado, puedes volver al paso anterior, volver al inicio o salir y regresar a Caff.",
  "title": "Algo ha ido mal…"
}
</i18n>

<style lang="scss" scoped>
@import '@caff/cds-scss-core';

@include t-responsive--starting-at($responsive-breakpoint-tablet-medium) {
  h2,
  p {
    margin-left: auto;
    margin-right: auto;
    max-width: get-size-in-rem(310px);
  }
}
</style>
