export const PACKAGE_NAME_WITH_VERSION = import.meta.env.VITE_PACKAGE_NAME_WITH_VERSION;

export const VERSION = import.meta.env.VITE_CI_COMMIT_SHA;

export const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN;

/**
 * Maximum amount of items to be displayed in a timeline sidebar widget.
 */
export const MAX_SIDEBAR_TIMELINE_ITEMS = 5;

export const DEFAULT_TOASTR_TIMEOUT_IN_SECONDS = 15;

export interface StrictCookiesList<T> extends Record<string, T> {
  SESSION_TOKEN: T;
}

export const COOKIE_NAMES: StrictCookiesList<string> = {
  SESSION_TOKEN: 'session-token',
};
