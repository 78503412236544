<template>
  <component
    :is="tag"
    :class="{ 'c-pill--colored': colorStyles, 'c-pill--large': large }"
    :style="colorStyles"
    v-bind="$attrs"
    class="c-pill"
  >
    <span class="c-pill__background" />
    <slot name="prefix" />
    <span class="c-pill__content">
      <slot />
    </span>
    <slot name="suffix" />
  </component>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';

import { cdsColorVueComponentProperty, rgbColorVueComponentProperty } from '@caff/cds-color';

export default defineComponent({
  name: 'Pill',
  props: {
    tag: {
      type: String,
      default: 'div',
    },
    large: {
      type: Boolean,
      default: false,
    },
    cdsColor: {
      ...cdsColorVueComponentProperty,
      required: false,
    },
    /**
     * @note Overrides `cdsColor`.
     */
    rgbColor: {
      ...rgbColorVueComponentProperty,
      required: false,
    },
  },
  setup(props) {
    const colorStyles = computed((): Record<string, string> | null => {
      if (props.rgbColor) {
        return {
          '--color': `rgb(${props.rgbColor})`,
        };
      }

      if (props.cdsColor) {
        return {
          '--color': `var(--colors-${props.cdsColor}-500)`,
        };
      }

      return null;
    });

    return { colorStyles };
  },
});
</script>

<style lang="scss" scoped>
@import '@caff/cds-scss-core';

$transition: all 300ms ease-in-out;

.c-pill {
  --border-radius: 6px;

  @include t-font($font-display-name);
  @extend %t-height-l;

  align-items: center;
  border-radius: var(--border-radius);
  display: flex;
  flex-direction: row;
  position: relative;
  width: min-content;
  white-space: nowrap;
  z-index: 0;

  &:deep(img) {
    border-radius: var(--border-radius);
    max-height: 100%;
  }
}

.c-pill--large {
  @include t-font($font-header-small);
  @extend %t-height-xl;

  .c-pill__content {
    @extend %t-padding-h-s;
  }
}

.c-pill--colored {
  .c-pill__background {
    background-color: var(--color);
    opacity: 0.13;
  }

  .c-pill__content {
    color: var(--color);
  }
}

a,
button {
  --focus-ring-size: 3px;

  outline: none;
  position: relative;
  text-decoration: none;
  transition: $transition;
  transition-property: opacity;

  .c-pill__background {
    transition: $transition;
    transition-property: background-color, opacity;
  }

  &::before {
    @include t-focus-ring(
      (
        'border-radius': var(--border-radius),
        'color': var(--color, var(--colors-background-hover)),
        'focus-ring-size': var(--focus-ring-size),
        'transition': $transition,
      )
    );
  }

  &:hover {
    text-decoration: none;
  }

  &:not(.c-pill--colored) {
    &:not(:disabled) {
      @include t-not-focus-visible {
        &:hover {
          .c-pill__background {
            background-color: var(--colors-background-hover);
          }

          .c-pill__content {
            text-decoration: none;
          }
        }
      }
    }

    @include t-focus-visible {
      &::before {
        opacity: 1;
      }
    }
  }

  &.c-pill--colored {
    &:not(:disabled) {
      @include t-not-focus-visible {
        &:hover {
          .c-pill__background {
            opacity: 0.2;
          }

          .c-pill__content {
            text-decoration: none;
          }
        }
      }
    }

    @include t-focus-visible {
      &::before {
        opacity: 0.5;
      }
    }
  }
}

.c-pill__background {
  background-color: var(--colors-background);
  border-radius: var(--border-radius);
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}

.c-pill__content {
  @extend %t-padding-h-xs;

  box-sizing: border-box;
  color: var(--colors-main-text);
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
