import {
  NotificationFrequencyType,
  NotificationFrequency as RawNotificationFrequency,
} from '@caff/notification-frequency-api-model';

export class NotificationFrequency {
  public readonly name: NotificationFrequencyType;

  constructor(params: RawNotificationFrequency) {
    this.name = params.name;
  }
}
