import type { Ref } from 'vue';
import { computed } from 'vue';

import type { PostId, Post as RawPost } from '@caff/post-api-model';

import type { PopulatedPost } from '../../models/Post';
import { getPostFromShallowReply } from '../../models/Post';
import { getKeyForPost, setPost } from '../post';
import type { UseAxiosQueryResult } from '../useAxiosQuery';
import { useAxiosQuery } from '../useAxiosQuery';
import { useOnCurrentlyLoggedInUserChange } from '../useSession';

export const usePost = (
  idPost: Ref<PostId | null>,
): UseAxiosQueryResult<PopulatedPost> & {
  post: Ref<PopulatedPost | null>;
} => {
  const {
    data: post,
    invalidate,
    ...rest
  } = useAxiosQuery({
    queryKey: computed(() => getKeyForPost(idPost)),
    async queryFn({ axiosInstance, setQueryData }) {
      if (!idPost.value) {
        return null;
      }

      const {
        data: rawPost,
      }: {
        data: RawPost;
      } = await axiosInstance.get(`/post/${idPost.value}`);

      await setPost({
        post: rawPost,
        setQueryData,
      });

      const populatedPost: PopulatedPost = {
        ...getPostFromShallowReply(rawPost),
        postParentIds: rawPost.parents.map(({ uuid }) => uuid),
        repliesIds: rawPost.replies.map(({ uuid }) => uuid),
        rootThreadSlug: rawPost.thread.slug,
      };

      return populatedPost;
    },
  });

  useOnCurrentlyLoggedInUserChange(async () => {
    await invalidate();
  });

  return {
    ...rest,
    invalidate,
    post,
  };
};
