<template>
  <button class="c-toastr-action" @click.prevent.stop="handleClick($event)">
    <FontAwesomeIcon v-if="icon" :icon="icon" fixedWidth aria-hidden />

    <slot />
  </button>
</template>

<script lang="ts" setup>
import type { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

defineProps<{
  icon?: IconDefinition;
}>();

const emit = defineEmits<{
  click: [MouseEvent];
}>();

defineSlots<{
  default(): unknown;
}>();

const handleClick = (event: MouseEvent) => emit('click', event);
</script>

<style lang="scss" scoped>
@import '@caff/cds-scss-core';

.c-toastr-action {
  @include t-font($font-body-action);
  @extend %t-margin-r-xxs;
  @extend %t-padding-h-xs;
  @extend %t-padding-v-xxs;

  background: none;
  border: 0;
  border-radius: var(--border-radius);
  color: var(--readable-over-color-500);
  cursor: pointer;

  &:hover {
    background: var(--colors-background-noticeable-over-color-500);
  }
}
</style>
