<template>
  <SidebarListWidget>
    <template #title>
      {{ t('title') }}
    </template>

    <ThreadSidebarTimelineItem
      v-for="threadSlug of featuredThreadSlugs"
      :key="threadSlug"
      :threadSlug="threadSlug"
    />

    <InfiniteScrollFooter :isLoading="isExecuting" :end="!featuredThreadSlugs.length" />
  </SidebarListWidget>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import { SidebarListWidget } from '@caff/cds-sidebar-list-widget';
import { ThreadOrder } from '@caff/shallow-thread-api-model';

import {
  useGeneralFrontpageThreadSlugs,
  useOnCurrentlyLoggedInUserChange,
} from '../../composition';
import { MAX_SIDEBAR_TIMELINE_ITEMS } from '../../config';
import InfiniteScrollFooter from '../common/InfiniteScrollFooter.vue';
import ThreadSidebarTimelineItem from './ThreadSidebarTimelineItem.vue';

const { t } = useI18n();

const threadOrder = ref(ThreadOrder.polemicFirst);

const { isExecuting, threadSlugs, reexecute } = useGeneralFrontpageThreadSlugs({
  threadOrder,
});

const featuredThreadSlugs = computed(
  () => threadSlugs.value?.slice(0, MAX_SIDEBAR_TIMELINE_ITEMS) ?? [],
);

useOnCurrentlyLoggedInUserChange(() => {
  reexecute();
});
</script>

<i18n lang="json" locale="es">
{
  "title": "Tendencias"
}
</i18n>
