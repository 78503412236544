<template>
  <form @submit.stop.prevent="submitForm()">
    <H2>{{ t('title') }}</H2>
    <P>
      <slot name="usernameError">
        {{ t('username.visibleLabel') }}
      </slot>
    </P>
    <Input
      :icon="faHandle"
      :label="t('username.accessibilityLabel')"
      :loading="isUsernameAsyncValidationLoading"
      :minlength="usernameMinLength"
      :maxlength="usernameMaxLength"
      :placeholder="t('username.placeholder')"
      :modelValue="username"
      autocomplete="username"
      name="username"
      class="u-width-100"
      required
      @update:modelValue="handleUsernameInput($event)"
    />

    <Footer>
      <template #leading>
        <Button
          :disabled="isCreatingAccount"
          :type="secondary"
          data-testid="cancel-button"
          @click.stop.prevent="dismiss()"
        >
          {{ t('action.cancel') }}
        </Button>
      </template>

      <template #trailing>
        <Button
          :disabled="isSubmitDisabled"
          :loading="isCreatingAccount"
          :type="primary"
          data-testid="next-button"
          @click.stop.prevent="submitForm()"
        >
          {{ t('action.next') }}
        </Button>
        <Button
          :disabled="isCreatingAccount"
          :type="secondary"
          class="u-margin-r-xs"
          data-testid="back-button"
          @click.stop.prevent="goBack()"
        >
          <FontAwesomeIcon :icon="faArrowLeft" fixed-width />

          {{ t('action.back') }}
        </Button>
      </template>
    </Footer>
  </form>
</template>

<script lang="ts">
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';

import { Button, ButtonType } from '@caff/cds-button';
import { faArrowLeft, faHandle } from '@caff/cds-icons';
import { Input } from '@caff/cds-input';
import { Footer, H2, P } from '@caff/cds-modal-flow';

export default defineComponent({
  name: 'UsernameUnavailableStep',
  components: { Button, FontAwesomeIcon, Footer, H2, Input, P },
  props: {
    username: {
      type: String,
      required: true,
    },
    usernameError: {
      type: String,
      required: false,
    },
    isUsernameAsyncValidationLoading: {
      type: Boolean,
      required: true,
    },
    usernameMinLength: {
      type: Number,
      required: true,
    },
    usernameMaxLength: {
      type: Number,
      required: true,
    },
    isSubmitDisabled: {
      type: Boolean,
      required: true,
    },
    isCreatingAccount: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['username-input', 'dismiss', 'go-back', 'submit'],
  setup(props, { emit }) {
    const { t } = useI18n();

    const handleUsernameInput = (value: string): void => {
      /**
       * User changed the value of the username input.
       *
       * @event username-input
       * @type {String}
       */
      emit('username-input', value);
    };

    const dismiss = () => {
      /**
       * User dismissed the form.
       *
       * @event dismiss
       * @type {void}
       */
      emit('dismiss');
    };

    const goBack = () => {
      /**
       * User went to previous step of the form.
       *
       * @event go-back
       * @type {void}
       */
      emit('go-back');
    };

    const submitForm = () => {
      if (props.isSubmitDisabled) {
        return;
      }

      /**
       * User submitted the form.
       *
       * @event submit
       * @type {void}
       */
      emit('submit');
    };

    return {
      t,
      faArrowLeft,
      faHandle,
      primary: ButtonType.primary,
      secondary: ButtonType.secondary,
      handleUsernameInput,
      dismiss,
      goBack,
      submitForm,
    };
  },
});
</script>

<i18n lang="json" locale="es">
{
  "action": {
    "back": "Atrás",
    "cancel": "Cancelar",
    "next": "Continuar"
  },
  "title": "¡Nombre de usuario no disponible!",
  "username": {
    "accessibilityLabel": "Nombre de usuario",
    "placeholder": "Nombre de usuario",
    "visibleLabel": "El nombre de usuario elegido no está disponible, utiliza uno distinto para crear con éxito tu cuenta."
  }
}
</i18n>
