import {
  DefineComponent,
  computed,
  defineComponent,
  h,
  VNodeArrayChildren,
  RenderFunction,
} from 'vue';

import { s3BucketConfigForImageKindVueComponentProperty as s3BucketConfigForImageKind } from '@caff/image-frontend-model';

import {
  MarkdownBlockNode,
  MarkdownBlockNodeWithTitle,
  MarkdownImageBlockNode,
  MarkdownInlineNode,
  MarkdownNode,
  MarkdownNodeType,
  MarkdownUserMentionNode as MarkdownUserMentionNodeType,
} from './parsers';
import {
  darkModeVueProp,
  externalUrlMetadataByUrlVueProp,
  featuresVueProp,
  mentionsVueProp,
  nodeVueProp,
  usersByLowercaseUsernameVueProp,
} from './props';

import MarkdownLinkNode from './MarkdownLinkNode.vue';
import MarkdownImageNode from './MarkdownImageNode.vue';
import MarkdownUserMentionNode from './MarkdownUserMentionNode.vue';

const plainTextNodeTypes = {
  [MarkdownNodeType.plainText]: true,
  [MarkdownNodeType.code]: true,
};
const nodeIsPlainText = (node: MarkdownNode): boolean => node.type in plainTextNodeTypes || false;
const getNodeText = (node: MarkdownNode): string | null =>
  node.type in plainTextNodeTypes ? (node as MarkdownInlineNode).content : null;

const MarkdownNodeComponentPropsDefinition = {
  darkMode: darkModeVueProp,
  externalUrlMetadataByUrl: externalUrlMetadataByUrlVueProp,
  features: featuresVueProp,
  mentions: mentionsVueProp,
  node: nodeVueProp,
  s3BucketConfigForImageKind,
  usersByLowercaseUsername: usersByLowercaseUsernameVueProp,
};

const MarkdownNodeComponent: DefineComponent<typeof MarkdownNodeComponentPropsDefinition> =
  defineComponent({
    name: 'MarkdownNode',
    components: {
      MarkdownLinkNode,
      MarkdownImageNode,
      MarkdownUserMentionNode,
    },
    props: MarkdownNodeComponentPropsDefinition,
    setup(props, { slots }) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const isPlainText = computed(() => props.node!.type === MarkdownNodeType.plainText);

      const isBlock = computed(() => !isPlainText.value);

      const isLink = computed<boolean>(() => props.node?.type === MarkdownNodeType.link);

      const isImage = computed<boolean>(() => props.node?.type === MarkdownNodeType.image);

      const isCodeBlock = computed<boolean>(() => props.node?.type === MarkdownNodeType.code);

      const isUserMention = computed<boolean>(
        () => props.node?.type === MarkdownNodeType.userMention,
      );

      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const text = computed(() => getNodeText(props.node!) ?? '');

      const childNodes = computed<Array<MarkdownNode>>(
        () => (props.node as { childNodes?: Array<MarkdownNode> }).childNodes ?? [],
      );

      const htmlTag = computed(() =>
        isBlock.value ? (props.node as MarkdownBlockNode).tag : null,
      );

      const title = computed<string | null>(() =>
        isLink.value || isImage.value ? (props.node as MarkdownBlockNodeWithTitle).title : null,
      );

      const src = computed(() =>
        isImage.value ? (props.node as MarkdownImageBlockNode).src : null,
      );

      const alt = computed(() =>
        isImage.value ? (props.node as MarkdownImageBlockNode).alt : null,
      );

      const mentionedUsername = computed(() =>
        isUserMention.value ? (props.node as MarkdownUserMentionNodeType).username : null,
      );

      const getBlockLikeNodeChildren = () =>
        childNodes.value.map((childNode, index) =>
          nodeIsPlainText(childNode)
            ? getNodeText(childNode)
            : h(
                MarkdownNodeComponent,
                {
                  key: index,
                  darkMode: props.darkMode,
                  externalUrlMetadataByUrl: props.externalUrlMetadataByUrl,
                  features: props.features,
                  mentions: props.mentions,
                  node: childNode,
                  s3BucketConfigForImageKind: props.s3BucketConfigForImageKind,
                  usersByLowercaseUsername: props.usersByLowercaseUsername,
                },
                {
                  userMentionLink({
                    user,
                    text,
                  }: {
                    user: string;
                    text: string;
                  }): VNodeArrayChildren {
                    /**
                     * @slot Use this slot to customize how links to user mentions are rendered
                     */
                    return slots.userMentionLink?.({ text, user }) ?? [];
                  },
                },
              ),
        );

      return () => {
        if (isLink.value) {
          return h(
            MarkdownLinkNode,
            {
              darkMode: props.darkMode,
              externalUrlMetadataByUrl: props.externalUrlMetadataByUrl,
              features: props.features,
              node: props.node,
              s3BucketConfigForImageKind: props.s3BucketConfigForImageKind,
            },
            getBlockLikeNodeChildren,
          );
        }

        if (isImage.value) {
          return h(MarkdownImageNode as unknown as RenderFunction, {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            src: src.value!,
            title: title.value,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            alt: alt.value!,
          });
        }

        if (isCodeBlock.value) {
          return h('code', text.value);
        }

        if (isUserMention.value) {
          return h(
            MarkdownUserMentionNode,
            {
              features: props.features,
              node: props.node,
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              username: mentionedUsername.value!,
              usersByLowercaseUsername: props.usersByLowercaseUsername,
            },
            {
              default({ user, text }: { user: string; text: string }): VNodeArrayChildren {
                /**
                 * @slot Use this slot to customize how links to user mentions are rendered
                 */
                return slots.userMentionLink?.({ text, user }) ?? [];
              },
            },
          );
        }

        if (isBlock.value) {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          return h(htmlTag.value!, null, getBlockLikeNodeChildren());
        }

        return h('span', text.value);
      };
    },
  });

export default MarkdownNodeComponent;
