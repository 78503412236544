export const isTouchable = (): boolean =>
  typeof document !== 'undefined' && 'ontouchend' in document;

export const isiOS = (): boolean => {
  const userAgent = typeof navigator !== 'undefined' ? navigator.userAgent : null;

  const isiPad = !!(isTouchable() && userAgent && /\b(\w*Macintosh\w*)\b/.test(userAgent));
  const isiPhone = !!(
    isTouchable() &&
    userAgent &&
    /\b(\w*iPhone\w*)\b/.test(userAgent) &&
    /\b(\w*Mobile\w*)\b/.test(userAgent)
  );

  return isiPad || isiPhone;
};
