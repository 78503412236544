<template>
  <form @submit.stop.prevent="submitForm()">
    <H2>{{ t('title') }}</H2>
    <P>
      <slot name="usernameError">
        {{ t('username.visibleLabel') }}
      </slot>
    </P>
    <Input
      :icon="faHandle"
      :label="t('username.accessibilityLabel')"
      :loading="isUsernameAsyncValidationLoading"
      :minlength="usernameMinLength"
      :maxlength="usernameMaxLength"
      :placeholder="t('username.placeholder')"
      :modelValue="username"
      autocomplete="username"
      name="username"
      class="u-width-100"
      required
      @update:modelValue="handleUsernameInput($event)"
    />
    <P>
      <slot name="emailError">
        {{ t('email.visibleLabel') }}
      </slot>
    </P>
    <Input
      :icon="faLetter"
      :label="t('email.accessibilityLabel')"
      :loading="isEmailAsyncValidationLoading"
      :placeholder="t('email.placeholder')"
      :modelValue="email"
      autocomplete="email"
      class="u-width-100"
      name="email"
      required
      type="email"
      @update:modelValue="handleEmailInput($event)"
    />

    <Footer>
      <template #leading>
        <Button :type="secondary" data-testid="cancel-button" @click.stop.prevent="dismiss()">
          {{ t('action.cancel') }}
        </Button>
      </template>

      <template #trailing>
        <Button
          :disabled="isSubmitDisabled"
          :loading="isLoading"
          :type="primary"
          data-testid="next-button"
          @click.stop.prevent="submitForm()"
        >
          {{ t('action.next') }}
        </Button>
      </template>
    </Footer>
  </form>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';

import { Button, ButtonType } from '@caff/cds-button';
import { faHandle, faLetter } from '@caff/cds-icons';
import { Input } from '@caff/cds-input';
import { Footer, H2, P } from '@caff/cds-modal-flow';

export default defineComponent({
  name: 'UsernameAndEmailStep',
  components: { Button, Footer, H2, Input, P },
  props: {
    email: {
      type: String,
      required: true,
    },
    isEmailAsyncValidationLoading: {
      type: Boolean,
      required: true,
    },
    username: {
      type: String,
      required: true,
    },
    isUsernameAsyncValidationLoading: {
      type: Boolean,
      required: true,
    },
    usernameMinLength: {
      type: Number,
      required: true,
    },
    usernameMaxLength: {
      type: Number,
      required: true,
    },
    isSubmitDisabled: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['username-input', 'email-input', 'dismiss', 'submit'],
  setup(props, { emit }) {
    const { t } = useI18n();

    const isLoading = computed(
      () => props.isUsernameAsyncValidationLoading || props.isEmailAsyncValidationLoading,
    );

    const handleUsernameInput = (value: string) => {
      /**
       * User changed the value of the username input.
       *
       * @event username-input
       * @type {String}
       */
      emit('username-input', value);
    };

    const handleEmailInput = (value: string) => {
      /**
       * User changed the value of the email input.
       *
       * @event email-input
       * @type {String}
       */
      emit('email-input', value);
    };

    const dismiss = () => {
      /**
       * User dismissed the form.
       *
       * @event dismiss
       * @type {void}
       */
      emit('dismiss');
    };

    const submitForm = () => {
      if (props.isSubmitDisabled) {
        return;
      }

      /**
       * User submitted the form.
       *
       * @event submit
       * @type {void}
       */
      emit('submit');
    };

    return {
      t,
      faHandle,
      faLetter,
      primary: ButtonType.primary,
      secondary: ButtonType.secondary,
      isLoading,
      handleUsernameInput,
      handleEmailInput,
      dismiss,
      submitForm,
    };
  },
});
</script>

<i18n lang="json" locale="es">
{
  "action": {
    "cancel": "Cancelar",
    "next": "Continuar"
  },
  "email": {
    "accessibilityLabel": "Correo electrónico",
    "placeholder": "Correo electrónico",
    "visibleLabel": "Indica tu correo electrónico"
  },
  "title": "Crea tu cuenta",
  "username": {
    "accessibilityLabel": "Nombre de usuario",
    "placeholder": "Nombre de usuario",
    "visibleLabel": "Elige un nombre para identificarte en Caff"
  }
}
</i18n>
