<template>
  <a
    :href="href"
    :title="title"
    :class="{
      'c-markdown-content__plain-link--hidden': areLinksHidden,
    }"
    class="c-markdown-content__plain-link"
    target="_blank"
    rel="external"
  >
    <slot />
  </a>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';

import { useAreLinksHidden } from './composition';
import { MarkdownBlockNodeWithTitle } from './parsers';
import { nodeVueProp, featuresVueProp } from './props';

export default defineComponent({
  name: 'MarkdownPlainLinkNode',
  props: {
    features: featuresVueProp,
    href: {
      type: String,
      required: true,
    },
    node: nodeVueProp,
  },
  setup(props) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const areLinksHidden = useAreLinksHidden(props.features!);

    const title = computed(() => (props.node as MarkdownBlockNodeWithTitle).title ?? undefined);

    return {
      areLinksHidden,
      title,
    };
  },
});
</script>

<style lang="scss" scoped>
.c-markdown-content__plain-link--hidden {
  display: none;
}
</style>
