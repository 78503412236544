<template>
  <footer class="c-modal-footer">
    <!-- @slot Use this slot to customize the items rendered on the right side in LTR environments -->
    <slot name="trailing" />
    <div class="c-modal-footer__separator" />
    <!-- @slot Use this slot to customize the items rendered on the left side in LTR environments -->
    <slot name="leading" />
  </footer>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Footer',
});
</script>

<style lang="scss" scoped>
@import '@caff/cds-scss-core';

.c-modal-footer {
  @extend %t-margin-t-m;

  align-items: center;
  display: flex;
  flex-direction: row-reverse;
}

.c-modal-footer__separator {
  flex-grow: 1;
  flex-shrink: 1;
}
</style>
