import type { IconDefinition, IconName, IconPrefix } from '@fortawesome/fontawesome-common-types';

export const prefix = 'caff' as IconPrefix;
export const iconName = 'arrow-reply-left' as IconName;
export const width = 14;
export const height = 14;
export const ligatures = [];
export const unicode = 'f802';
export const svgPathData =
  'm4.38791995 8.17790863 2.65983865 2.23802467c.42167284.3548814.53284137.9469596.30107927 1.4240297l-.06510368.1166677-.07334611.1029746c-.38083366.4787881-1.0566291.5776098-1.55325897.2451017l-.10350556-.0778725-5.13545879-4.32199654-.10101904-.09609349-.07209796-.08485314-.06774804-.09754856-.03446014-.05939439-.05314906-.11116528-.03051846-.08227137-.03227462-.11715725-.01690003-.09508782-.00982431-.1072363-.0001731-.0994005.01596834-.15853478.02289939-.10177508.01797292-.06099499.04302021-.11289418.0266294-.05631194.04179706-.07667589.03987836-.0608708.09142207-.11613216.07831633-.07976946 5.17571402-4.35750131c.50209377-.42256401 1.24873391-.3457626 1.65676998.16722464.34202316.42999513.33939604 1.03523689-.00005178 1.47286872l-.09187452.10571767-.07075215.0651268-2.66072307 2.23706678-.00098656.02882612h4.06166077c3.03885803 0 5.50233923 2.46348119 5.50233923 5.5023392 0 .6351275-.5148725 1.15-1.15 1.15-.5927856 0-1.0808159-.4485112-1.143252-1.0246948l-.006748-.1253052c0-1.70761678-1.3365626-3.10306267-3.02061988-3.19726986l-.18171935-.00506934h-4.06066077z';

export const definition = {
  prefix,
  iconName,
  icon: [width, height, ligatures, unicode, svgPathData],
} as IconDefinition;

export const faArrowReplyLeft = definition;
