import EmailStep from './EmailStep.vue';

export { EmailStep };

export const enum ForgotPasswordModalStep {
  email = 'email',
  success = 'success',
}

export const allForgotPasswordModalSteps: Record<ForgotPasswordModalStep, boolean> = {
  [ForgotPasswordModalStep.email]: true,
  [ForgotPasswordModalStep.success]: true,
};
