import { computed, Ref } from 'vue';

import { useModalStore } from '../store';

export const useComposer = (): {
  isComposerVisible: Ref<boolean>;
  showComposer: () => void;
  hideComposer: () => void;
} => {
  const modalStore = useModalStore();
  const isComposerVisible = computed(() => modalStore.isNewThreadComposerOpened);

  const showComposer = () => modalStore.showNewThreadComposerModal();
  const hideComposer = () => modalStore.dismissCurrentModal();

  return {
    isComposerVisible,
    showComposer,
    hideComposer,
  };
};
