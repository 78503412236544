<component
  :is="tag"
  :to="to"
  :class="{
      [`c-button--${type}`]: true,
      'c-button--is-active': active,
      'c-button--is-loading': loading,
    }"
  :disabled="disabled"
  :data-focus="focus || null"
  :data-hover="hover || null"
  class="c-button"
  @click="handleClick($event)"
>
  <span class="c-button__outline" />

  <span class="c-button__content">
    <slot />
  </span>

  <!-- @slot Use this slot to customize button's content when it's in loading status -->
  <slot name="loading-status">
    <span class="c-button__loading-status">
      <FontAwesomeIcon :icon="faSpinnerThird" spin fixed-width />
    </span>
  </slot>
</component>
