import { defineStore } from 'pinia';

import { MAXIMUM_VISIBLE_THREAD_DEPTH } from '@caff/post-api-model';

export const useBrowserPreferencesStore = defineStore('browserPreferences', {
  state: () => ({
    prefersDarkMode: false,
    maximumVisibleThreadDepth: MAXIMUM_VISIBLE_THREAD_DEPTH,
  }),
  actions: {
    setBrowserPrefersDarkMode(prefersDarkMode: boolean) {
      this.prefersDarkMode = prefersDarkMode;
    },

    setBrowserMaximumVisibleThreadDepth(maximumVisibleThreadDepth: number): void {
      this.maximumVisibleThreadDepth = maximumVisibleThreadDepth;
    },
  },
});
