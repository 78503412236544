<template>
  <label :data-value="modelValue" class="c-growing-textarea">
    <span class="c-growing-textarea__label">
      {{ labelOrPlaceholder }}
    </span>

    <textarea
      ref="textarea"
      :value="modelValue"
      :placeholder="placeholder"
      :disabled="disabled"
      :name="name"
      :autocomplete="autocomplete"
      :required="required"
      :rows="1"
      class="c-growing-textarea__input"
      @blur="onBlur($event)"
      @focus="onFocus($event)"
      @input="input($event)"
    />
  </label>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref } from 'vue';

export default defineComponent({
  name: 'GrowingTextarea',
  props: {
    modelValue: {
      type: String,
      required: false,
    },

    name: {
      type: String,
      required: false,
    },

    autocomplete: {
      type: String,
      required: false,
    },

    autofocus: {
      type: Boolean,
      default: false,
    },

    required: {
      type: Boolean,
      required: false,
    },

    placeholder: {
      type: String,
      required: true,
    },

    label: {
      type: String,
      required: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['autofocus', 'update:modelValue', 'blur', 'focus'],
  setup(props, { emit }) {
    const textarea = ref<HTMLTextAreaElement | null>(null);

    onMounted(() => {
      if (!props.autofocus) {
        return;
      }

      textarea.value?.focus();

      /**
       * Textarea was just autofocused.
       *
       * @event autofocus
       */
      emit('autofocus');
    });

    const labelOrPlaceholder = computed(() => props.label || props.placeholder);

    const input = ($event: Event) => {
      /**
       * User changed content of this textarea.
       *
       * @event update:modelValue
       * @type {String} New content of the textarea.
       */
      emit('update:modelValue', ($event.target as HTMLInputElement).value);
    };

    const onBlur = ($event: Event) => {
      /**
       * User blurred this textarea.
       *
       * @event blur
       * @type {Event}
       */
      emit('blur', $event);
    };

    const onFocus = ($event: Event) => {
      /**
       * User focused this textarea.
       *
       * @event focus
       * @type {Event}
       */
      emit('focus', $event);
    };

    return {
      textarea,
      labelOrPlaceholder,
      input,
      onBlur,
      onFocus,
    };
  },
});
</script>

<style lang="scss" scoped>
@import '@caff/cds-scss-core';

.c-growing-textarea {
  display: grid;
  overflow: hidden;

  &::after {
    // Note the weird space! Needed to preventy jumpy behavior
    content: attr(data-value) ' ';

    // This is how textarea text behaves
    white-space: pre-wrap;

    // Hidden from view, clicks, and screen readers
    visibility: hidden;
  }
}

.c-growing-textarea__label {
  @extend %t-accessibility-visually-hidden;
}

.c-growing-textarea__input {
  height: 100% !important; // This is needed in iOS
  overflow-x: hidden;
  overflow-y: auto;
  resize: none;

  &::placeholder {
    @include t-font($font-body);
  }
}

.c-growing-textarea::after,
.c-growing-textarea__input {
  @include t-font($font-body);

  background: transparent;
  border: 0;
  // Place on top of each other
  grid-area: 1 / 1 / 2 / 2;
  height: fit-content;
  margin: 0;
  max-height: calc(var(--visual-viewport-height, 100vh) - var(--editor-height, 0));
  outline: 0;
  padding: 0;
  word-break: break-word;
}
</style>
