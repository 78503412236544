<template>
  <div>
    <picture>
      <img ref="image" :src="src" :title="title" :alt="alt" />
    </picture>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'MarkdownImageNode',
  props: {
    src: {
      type: String,
      required: true,
    },
    alt: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: false,
    },
  },
});
</script>
