import { parseASTListRecursive, TokenTypeParsers } from './ASTList';
import {
  MarkdownBlockNode,
  MarkdownMentions,
  MarkdownNodeHTMLTag,
  MarkdownNodeType,
  TokenParserOutput,
  Token,
} from './types';

export const parseNoAttributedHTMLTagToken = (params: {
  sourceText: string;
  tokens: Array<Token>;
  startingNodeIndex: number;
  startingCharacterAbsolutePosition: number;
  markdownNodeType: MarkdownNodeType;
  markdownNodeHTMLTag: MarkdownNodeHTMLTag;
  mentions: MarkdownMentions;
  markdownToken: {
    prefixLength: number;
    suffixLength: number;
  };
  tokenTypeParsers: TokenTypeParsers;
}): TokenParserOutput => {
  const token = params.tokens[params.startingNodeIndex];

  const childrenMetadata = parseASTListRecursive({
    sourceText: params.sourceText,
    tokens: params.tokens,
    startingNodeIndex: params.startingNodeIndex + 1,
    startingCharacterAbsolutePosition:
      params.startingCharacterAbsolutePosition +
      params.markdownToken.prefixLength +
      token.content.length,
    mentions: params.mentions,
    tokenTypeParsers: params.tokenTypeParsers,
  });

  const node: MarkdownBlockNode = {
    type: params.markdownNodeType,
    tag: params.markdownNodeHTMLTag,
    childNodes: childrenMetadata.nodes,
    sourceRange: {
      absoluteStart: params.startingCharacterAbsolutePosition,
      length:
        params.markdownToken.prefixLength +
        childrenMetadata.parsedCharactersLength +
        params.markdownToken.suffixLength,
    },
  };

  return {
    nodes: [node],
    nodesAdvanced: 1 + childrenMetadata.parsedNodesLength + 1,
    charactersAdvanced: node.sourceRange.length,
  };
};
