<template>
  <Toastr :variant="ToastrVariant.success">
    {{ t('message') }}

    <template #actions>
      <ToastrAction @click="goToThread()">
        {{ t('goToThread') }}
      </ToastrAction>
      <ToastrAction :icon="faCheck" @click="dismiss()" />
    </template>
  </Toastr>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';

import { faCheck } from '@caff/cds-icons';
import { ThreadSlug } from '@caff/shallow-thread-api-model';

import { getThreadRoute } from '../../router';
import Toastr from './Toastr.vue';
import ToastrAction from './ToastrAction.vue';
import { ToastrVariant } from './Toastr.constants';

const props = defineProps<{
  threadSlug: ThreadSlug;
}>();

const emit = defineEmits<{
  dismiss: [];
}>();

const dismiss = () => emit('dismiss');

const router = useRouter();
const goToThread = async () => {
  await router.push(getThreadRoute(props.threadSlug));
};

const { t } = useI18n();
</script>

<i18n lang="json" locale="es">
{
  "message": "¡Tu hilo se ha publicado!",
  "goToThread": "Ir al hilo"
}
</i18n>
