export const enum ImageFormat {
  avif = 'avif',
  jpeg = 'jpeg',
  svg = 'svg',
  webp = 'webp',
}

export const ALL_IMAGE_FORMATS = [
  ImageFormat.avif,
  ImageFormat.jpeg,
  ImageFormat.svg,
  ImageFormat.webp,
];

export const MimeTypeForImageFormat: Record<ImageFormat, string> = {
  [ImageFormat.avif]: 'image/avif',
  [ImageFormat.jpeg]: 'image/jpeg',
  [ImageFormat.svg]: 'image/svg+xml',
  [ImageFormat.webp]: 'image/webp',
};

export const EXTENSION_FOR_IMAGE_FORMAT: Record<ImageFormat, string> = {
  [ImageFormat.avif]: 'avif',
  [ImageFormat.jpeg]: 'jpeg',
  [ImageFormat.svg]: 'svg',
  [ImageFormat.webp]: 'webp',
};
