import {
  MarkdownMentions,
  MarkdownNode,
  ParseTokenFunction,
  TokenParserInput,
  TokenParserOutput,
  Token,
  TokenType,
} from './types';

export type TokenTypeParsers = Record<TokenType, ParseTokenFunction>;

export type ASTListRecursiveParser = (params: {
  sourceText: string;
  tokens: Array<Token>;
  startingNodeIndex: number;
  startingCharacterAbsolutePosition: number;
  mentions: MarkdownMentions;
  tokenTypeParsers: TokenTypeParsers;
}) => {
  nodes: Array<MarkdownNode>;
  parsedNodesLength: number;
  parsedCharactersLength: number;
};

const closingTagTokenTypes = {
  paragraph_close: 'paragraph_close',
  link_close: 'link_close',
  strong_close: 'strong_close',
  em_close: 'em_close',
  s_close: 's_close',
  heading_close: 'heading_close',
  bullet_list_close: 'bullet_list_close',
  ordered_list_close: 'ordered_list_close',
  list_item_close: 'list_item_close',
  blockquote_close: 'blockquote_close',
};

const getSkipNodeOutput = (charactersAdvanced: number): TokenParserOutput => ({
  nodes: [],
  nodesAdvanced: 1,
  charactersAdvanced,
});

const parseToken = (params: TokenParserInput): TokenParserOutput | null => {
  const token = params.tokens[params.startingNodeIndex];

  if (token.hidden) {
    return getSkipNodeOutput(token.content.length);
  }

  if (token.type in closingTagTokenTypes) {
    return null;
  }

  if (params.tokenTypeParsers[token.type]) {
    return params.tokenTypeParsers[token.type](params);
  }

  console.warn('Ignoring unsupported token', token);

  return getSkipNodeOutput(token.content.length);
};

export const parseASTListRecursive: ASTListRecursiveParser = ({
  sourceText,
  tokens,
  startingNodeIndex,
  startingCharacterAbsolutePosition,
  mentions,
  tokenTypeParsers,
}) => {
  const resultingNodes: Array<MarkdownNode> = [];
  const originalNodeStartingIndex = startingNodeIndex;
  let nextNodeStartingIndex = startingNodeIndex;
  const originalCharacterAbsolutePosition = startingCharacterAbsolutePosition;
  let nextCharacterAbsolutePosition = startingCharacterAbsolutePosition;

  while (nextNodeStartingIndex < tokens.length) {
    const result = parseToken({
      sourceText,
      tokens,
      startingNodeIndex: nextNodeStartingIndex,
      startingCharacterAbsolutePosition: nextCharacterAbsolutePosition,
      mentions,
      tokenTypeParsers,
    });

    if (!result) {
      return {
        nodes: resultingNodes,
        parsedNodesLength: nextNodeStartingIndex - originalNodeStartingIndex,
        parsedCharactersLength: nextCharacterAbsolutePosition - originalCharacterAbsolutePosition,
      };
    }

    const { nodes, nodesAdvanced, charactersAdvanced } = result;
    resultingNodes.push(...nodes);
    nextNodeStartingIndex += nodesAdvanced;
    nextCharacterAbsolutePosition += charactersAdvanced;
  }

  return {
    nodes: resultingNodes,
    parsedNodesLength: nextNodeStartingIndex - originalNodeStartingIndex,
    parsedCharactersLength: nextCharacterAbsolutePosition - originalCharacterAbsolutePosition,
  };
};

export const parseASTList = ({
  mentions,
  sourceText,
  tokenTypeParsers,
  tokens,
}: {
  sourceText: string;
  tokens: Array<Token>;
  mentions: MarkdownMentions;
  tokenTypeParsers: TokenTypeParsers;
}): Array<MarkdownNode> =>
  parseASTListRecursive({
    sourceText,
    tokens,
    startingNodeIndex: 0,
    startingCharacterAbsolutePosition: 0,
    mentions,
    tokenTypeParsers,
  }).nodes;
