<template>
  <hr class="c-dropdown-menu-entry-separator" />
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'DropdownMenuEntrySeparator',
});
</script>

<style lang="scss" scoped>
@import '@caff/cds-scss-core';

.c-dropdown-menu-entry-separator {
  background-color: var(--colors-popup-inner-separator);
  border: 0;
  height: 1px;
  margin: var(--spacing-xxs) 0;
  width: 100%;
}
</style>
