<template>
  <p class="c-modal-p" :class="{ 'c-modal-p--center': center }">
    <slot />
  </p>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'P',
  props: {
    center: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<style lang="scss" scoped>
@import '@caff/cds-scss-core';

.c-modal-p {
  @include t-font($font-body-soft);
}

.c-modal-p--center {
  text-align: center;
}
</style>
