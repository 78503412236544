import { CategoryMention, UserMention } from '@caff/mention-api-model';

export interface MarkdownMentions {
  [key: number]: CategoryMention | UserMention;
}

export const enum MarkdownFeature {
  embedLinksPreviews = 'embedLinksPreviews',
  hideLinks = 'hideLinks',
  smartquotes = 'smartquotes',
  replacements = 'replacements',
  linkifyUserMentions = 'linkifyUserMentions',
  linkify = 'linkify',
  link = 'link',
  image = 'image',
  emphasis = 'emphasis',
  strikethrough = 'strikethrough',
  heading = 'heading',
  lheading = 'lheading',
  list = 'list',
  blockquote = 'blockquote',
  code = 'code',
  fence = 'fence',
}

export type MarkdownFeaturesConfig = Record<MarkdownFeature, boolean>;

export const InteractiveContentFeaturesWithEmbeds: MarkdownFeaturesConfig = {
  [MarkdownFeature.embedLinksPreviews]: true,
  [MarkdownFeature.hideLinks]: false,
  [MarkdownFeature.smartquotes]: true,
  [MarkdownFeature.replacements]: false,
  [MarkdownFeature.linkifyUserMentions]: true,
  [MarkdownFeature.linkify]: true,
  [MarkdownFeature.link]: true,
  [MarkdownFeature.image]: false,
  [MarkdownFeature.emphasis]: true,
  [MarkdownFeature.strikethrough]: true,
  [MarkdownFeature.heading]: true,
  [MarkdownFeature.lheading]: true,
  [MarkdownFeature.list]: true,
  [MarkdownFeature.blockquote]: true,
  [MarkdownFeature.code]: true,
  [MarkdownFeature.fence]: true,
};

export const InteractiveContentFeatures: MarkdownFeaturesConfig = {
  [MarkdownFeature.embedLinksPreviews]: false,
  [MarkdownFeature.hideLinks]: false,
  [MarkdownFeature.smartquotes]: true,
  [MarkdownFeature.replacements]: false,
  [MarkdownFeature.linkifyUserMentions]: true,
  [MarkdownFeature.linkify]: true,
  [MarkdownFeature.link]: true,
  [MarkdownFeature.image]: false,
  [MarkdownFeature.emphasis]: true,
  [MarkdownFeature.strikethrough]: true,
  [MarkdownFeature.heading]: true,
  [MarkdownFeature.lheading]: true,
  [MarkdownFeature.list]: true,
  [MarkdownFeature.blockquote]: true,
  [MarkdownFeature.code]: true,
  [MarkdownFeature.fence]: true,
};

export const NonInteractiveContentFeaturesShowingLinks: MarkdownFeaturesConfig = {
  [MarkdownFeature.embedLinksPreviews]: false,
  [MarkdownFeature.hideLinks]: false,
  [MarkdownFeature.smartquotes]: true,
  [MarkdownFeature.replacements]: false,
  [MarkdownFeature.linkifyUserMentions]: false,
  [MarkdownFeature.linkify]: false,
  [MarkdownFeature.link]: false,
  [MarkdownFeature.image]: false,
  [MarkdownFeature.emphasis]: true,
  [MarkdownFeature.strikethrough]: true,
  [MarkdownFeature.heading]: true,
  [MarkdownFeature.lheading]: true,
  [MarkdownFeature.list]: true,
  [MarkdownFeature.blockquote]: true,
  [MarkdownFeature.code]: true,
  [MarkdownFeature.fence]: true,
};

export const OnlyPlainTextNonInteractiveContentFeaturesHidingLinks: MarkdownFeaturesConfig = {
  [MarkdownFeature.embedLinksPreviews]: false,
  [MarkdownFeature.hideLinks]: true,
  [MarkdownFeature.smartquotes]: true,
  [MarkdownFeature.replacements]: false,
  [MarkdownFeature.linkifyUserMentions]: false,
  [MarkdownFeature.linkify]: true,
  [MarkdownFeature.link]: true,
  [MarkdownFeature.image]: false,
  [MarkdownFeature.emphasis]: false,
  [MarkdownFeature.strikethrough]: true,
  [MarkdownFeature.heading]: false,
  [MarkdownFeature.lheading]: false,
  [MarkdownFeature.list]: false,
  [MarkdownFeature.blockquote]: false,
  [MarkdownFeature.code]: false,
  [MarkdownFeature.fence]: false,
};
