import { AxiosInstance } from 'axios';

import { Deferred } from '@caff/isomorphic-promise';

// This variable is shared across different calls to the SSR service so we must
// clear it up before a new rendering process happens or we would be leaking
// settings from a previous user.
let axiosInstanceDeferred = new Deferred<AxiosInstance>();

export const setAxiosInstance = (axiosInstance: AxiosInstance): void => {
  // There might be code waiting for this promise to resolve so we resolve it
  // immediately. If it was resolved already then nothing happens.
  axiosInstanceDeferred.resolve(axiosInstance);
  // We must use a new promise from now on since the previous one might be
  // resolved already with a different client and we want to use the new client
  // from now on.
  axiosInstanceDeferred = new Deferred<AxiosInstance>();
  axiosInstanceDeferred.resolve(axiosInstance);
};

export const getAxiosInstance = (): Promise<AxiosInstance> => {
  return axiosInstanceDeferred;
};
