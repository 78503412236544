import type { IconDefinition, IconName, IconPrefix } from '@fortawesome/fontawesome-common-types';

export const prefix = 'caff' as IconPrefix;
export const iconName = 'more-menu' as IconName;
export const width = 14;
export const height = 14;
export const ligatures = [];
export const unicode = 'f840';
export const svgPathData =
  'm7 10.5c.82842712 0 1.5.6715729 1.5 1.5s-.67157288 1.5-1.5 1.5-1.5-.6715729-1.5-1.5.67157288-1.5 1.5-1.5zm0-5c.82842712 0 1.5.67157288 1.5 1.5s-.67157288 1.5-1.5 1.5-1.5-.67157288-1.5-1.5.67157288-1.5 1.5-1.5zm0-5c.82842712 0 1.5.67157288 1.5 1.5s-.67157288 1.5-1.5 1.5-1.5-.67157288-1.5-1.5.67157288-1.5 1.5-1.5z';

export const definition = {
  prefix,
  iconName,
  icon: [width, height, ligatures, unicode, svgPathData],
} as IconDefinition;

export const faMoreMenu = definition;
