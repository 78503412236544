<template>
  <component
    :is="tag"
    :class="{ ['c-bordered-box--rounded']: rounded, ['c-bordered-box--padded']: padded }"
    class="c-bordered-box"
  >
    <slot />
  </component>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'BorderedBox',
  props: {
    tag: {
      type: String,
      default: 'div',
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    padded: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<style lang="scss" scoped>
.c-bordered-box {
  background-color: var(--colors-box-background);
  box-shadow: 0 1px 2px 0 var(--colors-box-shadow);
}

.c-bordered-box--rounded {
  --border-radius: 8px;

  border-radius: var(--border-radius);
}

.c-bordered-box--padded {
  --box-padding: var(--spacing-m);

  padding: var(--box-padding);
}
</style>
