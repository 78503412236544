<template>
  <label
    class="c-input"
    :class="{
      'c-input--disabled': disabled,
      'c-input--error': error,
      'c-input--loading': loading,
    }"
  >
    <span v-if="label" class="c-input__visually-hidden">
      {{ label }}
    </span>

    <Transition name="c-input__translate-icon-animation">
      <!-- @slot Use this slot to customize the input prefix when it is in loading status -->
      <slot v-if="loading" name="loading-status">
        <FontAwesomeIcon
          key="loading-icon"
          :icon="faSpinnerThird"
          class="c-input__icon"
          :spin="loading"
          fixed-width
        />
      </slot>
      <FontAwesomeIcon
        v-else-if="icon"
        key="regular-icon"
        :icon="icon"
        class="c-input__icon"
        fixed-width
      />
    </Transition>

    <input
      v-bind="$attrs"
      :disabled="disabled"
      :loading="loading"
      :value="modelValue"
      class="c-input__field"
      @input="onInput($event)"
    />

    <span class="c-input__outline" />
  </label>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import { faSpinnerThird } from '@caff/cds-icons';

export default defineComponent({
  name: 'CaffInput',
  components: {
    FontAwesomeIcon,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: [Object, Array, String],
      required: false,
    },
    label: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: String,
      default: '',
    },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const onInput = (event: Event) => {
      /**
       * Input field content changed.
       *
       * @event update:modelValue
       * @type {string}
       */
      emit('update:modelValue', (event.target as HTMLInputElement).value);
    };

    return {
      faSpinnerThird,
      onInput,
    };
  },
});
</script>

<style lang="scss" scoped>
@import '@caff/cds-scss-core';

$transition: all 300ms ease-in-out;

.c-input {
  --border-radius: 6px;
  --focus-ring-size: 2px;

  @extend %t-height-xl;

  display: inline-block;
  position: relative;
  transition: $transition;
  transition-property: opacity;
}

.c-input--disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.c-input__visually-hidden {
  @extend %t-accessibility-visually-hidden;
}

.c-input__icon {
  color: var(--colors-info-text);
  left: var(--spacing-xs);
  position: absolute;
  top: calc(50% - 1em / 2);
  z-index: 3;
}

.c-input__translate-icon-animation-enter {
  opacity: 0;
  left: calc(2 * var(--spacing-xs));
}

.c-input__translate-icon-animation-enter-active {
  transition: $transition;
  transition-property: left, opacity, transform;
}

.c-input__translate-icon-animation-leave-active {
  transition: $transition;
  transition-property: left, opacity, transform;
}

.c-input__translate-icon-animation-leave-to {
  opacity: 0;
  left: 0;
}

.c-input__field {
  @extend %t-padding-h-s;
  @include t-font($font-body);

  background-color: var(--colors-background);
  border: 0;
  border-radius: var(--border-radius);
  box-sizing: border-box;
  height: 100%;
  outline: none;
  overflow: visible;
  padding-bottom: 0;
  padding-top: 0;
  position: relative;
  transition: $transition;
  transition-property: background-color;
  width: 100%;
  z-index: 2;

  &::placeholder {
    color: var(--colors-info-text);
  }

  @include t-focus-visible {
    background-color: var(--colors-box-background);

    & + .c-input__outline {
      opacity: 1;
    }
  }
}

.c-input__icon + .c-input__field {
  padding-left: calc(2 * var(--spacing-xs) + 1.25rem);
}

.c-input__outline {
  @include t-focus-ring(
    (
      'border-radius': var(--border-radius),
      'focus-ring-size': var(--focus-ring-size),
      'transition': $transition,
    )
  );

  background: var(--colors-background);
  z-index: 1;
}

.c-input--error .c-input__outline {
  background: var(--colors-red-500);
  opacity: 1;
}
</style>
