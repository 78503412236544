import deburr from 'lodash.deburr';

import { Image, ImageKind, S3ConfigForImageKind } from '@caff/image-isomorphic-model';
import { User } from '@caff/user-api-model';

export const areDisplayNameAndUsernameSimilar = (
  user: Pick<User, 'username' | 'displayName'>,
): boolean => {
  const simplifiedDisplayName = user.displayName
    .replace(/^[^0-9a-zA-Z_]+\s(.*)$/, '$1')
    .replace(/^(.*)\s[^0-9a-zA-Z_]+$/, '$1')
    .trim()
    .replace(' ', '_');

  return deburr(simplifiedDisplayName).toLowerCase() === user.username.toLowerCase();
};

export const getAvatar = ({
  user,
  s3ConfigForImageKind,
}: {
  user: Pick<User, 'idAvatar'>;
  s3ConfigForImageKind: S3ConfigForImageKind;
}): Image | null => {
  return user.idAvatar
    ? new Image(
        {
          idImage: user.idAvatar,
          kind: ImageKind.user_avatar,
        },
        s3ConfigForImageKind,
      )
    : null;
};
