import type { AxiosInstance } from 'axios';
import { UnwrapRef, computed, ref } from 'vue';

import type {
  QueryKey,
  UseFlatPaginatedQueryOptions,
  UseFlatPaginatedQueryReturnValue,
  UsePaginatedQueryReturnValue,
  UseQueryOptions,
  UseQueryReturnValue,
  UseMutationOptions,
  UseMutationReturnValue,
} from '@caff/frontend-use-query';
import {
  QueryClient,
  useFlatPaginatedQuery,
  useMutation,
  useQuery,
} from '@caff/frontend-use-query';

import { getAxiosInstance } from '../axios';
import { captureException } from '../utils/sentry';

export type UseAxiosQueryResult<ReturnedObjectType> = Omit<
  UseQueryReturnValue<ReturnedObjectType | null, Error>,
  'data'
>;

export const useAxiosQuery = <ReturnedValue, TheError extends Error>({
  queryKey,
  queryFn,
  queryClient,
  ...options
}: Omit<UseQueryOptions<ReturnedValue>, 'isEnabled' | 'queryFn'> & {
  queryFn: (options: {
    axiosInstance: AxiosInstance;
    setQueryData: <T>(key: UnwrapRef<QueryKey>, data: T) => Promise<void>;
  }) => Promise<ReturnedValue>;
}): UseQueryReturnValue<ReturnedValue, TheError> => {
  const client = queryClient ?? QueryClient.useDefaultClient();
  const setQueryData = client.useSetQueryData();

  return useQuery<ReturnedValue, TheError>({
    ...options,
    isEnabled: computed(() => !!queryKey.value),
    queryClient: client,
    queryKey,
    async queryFn() {
      const axiosInstance = await getAxiosInstance();

      try {
        const data = await queryFn({ axiosInstance, setQueryData });
        return data;
      } catch (error) {
        captureException(error);
        throw error;
      }
    },
  });
};

export type UseAxiosFlatPaginatedQueryResult<ReturnedObjectType> = Omit<
  UsePaginatedQueryReturnValue<ReturnedObjectType, Error>,
  'data'
>;

export const useAxiosFlatPaginatedQuery = <SinglePageReturnedValue, Cursor, TheError = Error>({
  isEnabled = ref(true),
  getQueryKey,
  queryPageFn,
  queryClient,
  ...options
}: Omit<UseFlatPaginatedQueryOptions<SinglePageReturnedValue, Cursor>, 'queryPageFn'> & {
  queryPageFn: (options: {
    axiosInstance: AxiosInstance;
    cursor?: Cursor;
    setQueryData: <T>(key: UnwrapRef<QueryKey>, data: T) => Promise<void>;
  }) => Promise<{ data: SinglePageReturnedValue; nextCursor: Cursor | null }>;
}): UseFlatPaginatedQueryReturnValue<SinglePageReturnedValue, TheError> => {
  const client = queryClient ?? QueryClient.useDefaultClient();
  const setQueryData = client.useSetQueryData();

  return useFlatPaginatedQuery<SinglePageReturnedValue, Cursor, TheError>({
    ...options,
    isEnabled,
    queryClient: client,
    getQueryKey,
    async queryPageFn(cursor) {
      const axiosInstance = await getAxiosInstance();

      try {
        const data = await queryPageFn({ axiosInstance, cursor, setQueryData });
        return data;
      } catch (error) {
        captureException(error);
        throw error;
      }
    },
  });
};

export const useAxiosMutation = <MutationFnParameter, ReturnedValue, TheError = Error>({
  mutationFn,
  ...options
}: Omit<UseMutationOptions<MutationFnParameter, ReturnedValue>, 'mutationFn'> & {
  mutationFn: (options: {
    param: MutationFnParameter;
    axiosInstance: AxiosInstance;
  }) => Promise<ReturnedValue>;
}): UseMutationReturnValue<MutationFnParameter, ReturnedValue, TheError> =>
  useMutation({
    ...options,
    async mutationFn(param) {
      const axiosInstance = await getAxiosInstance();

      try {
        const data = await mutationFn({
          axiosInstance,
          param,
        });
        return data;
      } catch (error) {
        captureException(error);
        throw error;
      }
    },
  });
