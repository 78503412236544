<template>
  <main ref="contentContainer">
    <FrontpageBox>
      <div class="c-user-frontpage__display-options-first-row">
        <PrimaryButton :to="userRoute">
          <FontAwesomeIcon :icon="faListMedium" fixed-width />

          {{ t('tabs.threads.tabTitle') }}
        </PrimaryButton>
      </div>

      <hr class="c-user-frontpage__display-options-divider" />

      <DisplayOptionsControls
        :threadOrder="threadOrder"
        padded
        class="c-user-frontpage__display-options-last-row"
        @sort-by="sortBy($event)"
      />
    </FrontpageBox>

    <RegularThreadFeedItem v-for="slug in threadSlugs" :key="slug" :threadSlug="slug" interactive />

    <InfiniteScrollFooter :isLoading="isLoading" :end="hasLoadedLastPage" />
  </main>
</template>

<script lang="ts" setup>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import { PrimaryButton } from '@caff/cds-button';
import { faListMedium } from '@caff/cds-icons';
import { Username } from '@caff/user-api-model';

import { useFrontpage, useUser } from '../../composition';
import InfiniteScrollFooter from '../common/InfiniteScrollFooter.vue';
import DisplayOptionsControls from './DisplayOptionsControls.vue';
import FrontpageBox from './FrontpageBox.vue';
import RegularThreadFeedItem from './RegularThreadFeedItem.vue';

import { FrontpageEmits, FrontpageProps } from './frontpage.mixin';

const props = defineProps<
  FrontpageProps & {
    username: Username;
  }
>();
const emit = defineEmits<FrontpageEmits>();

const { t } = useI18n();

const { contentContainer, sortBy } = useFrontpage({
  emit,
});

const { user } = useUser(computed(() => props.username));
const userRoute = computed(() => user.value?.route);
</script>

<i18n lang="json" locale="es">
{
  "actions": {
    "filter": {
      "threads": "Hilos"
    },
    "sortBy": {
      "hot": "Tendencias",
      "recent": "Nuevo"
    }
  },
  "tabs": {
    "threads": {
      "tabTitle": "Hilos",
      "header": {
        "title": "Hilos de {displayName}",
        "subtitle": "Lista de hilos publicados por {displayName}."
      }
    }
  }
}
</i18n>

<style lang="scss" scoped>
@import '@caff/cds-scss-core';

.c-user-frontpage__display-options-first-row {
  display: flex;
  flex-direction: row;
  margin: var(--spacing-m) var(--spacing-m) var(--spacing-s) var(--spacing-m);
}

.c-user-frontpage__display-options-divider {
  border: 0;
  background-color: var(--colors-background);
  height: 1px;
  margin: 0;
  width: 100%;
}

.c-user-frontpage__display-options-last-row {
  margin: var(--spacing-s) var(--spacing-m) var(--spacing-m) var(--spacing-m);
}
</style>
