<template>
  <SidebarBannerWidget>
    <template #header>
      <ModalBanner class="c-account-sidebar-widget__banner">
        <template #default="{ classes }">
          <Chill :class="classes" />
        </template>
      </ModalBanner>
    </template>

    <template v-if="currentlyLoggedInUser">
      <form
        v-if="isEditing"
        class="c-account-sidebar-widget__row"
        @submit.prevent.stop="saveChanges()"
      >
        <UpdateUserAvatar
          :loading="isUpdatingCurrentlyLoggedInUserAvatar"
          :s3BucketConfigForImageKind="s3ConfigForImageKind"
          class="c-account-sidebar-widget__avatar"
          @pick-file="saveAvatar($event)"
        />

        <div>
          <Input
            type="text"
            v-model="dirtyDisplayName"
            :error="!!dirtyDisplayNameValidationError"
            :placeholder="currentlyLoggedInUser.displayName"
            class="c-account-sidebar-widget__text-input"
          />

          <p v-if="dirtyDisplayNameValidationError" class="c-account-sidebar-widget__error-message">
            {{ dirtyDisplayNameValidationError }}
          </p>
        </div>

        <Input
          type="submit"
          :value="t('actions.saveChanges')"
          :disabled="isSaveChangesButtonDisabled"
          class="c-account-sidebar-widget__hidden-field"
        />
      </form>
      <div v-else class="c-account-sidebar-widget__row">
        <UserAvatar
          :s3BucketConfigForImageKind="s3ConfigForImageKind"
          :to="currentlyLoggedInUser.route"
          :user="currentlyLoggedInUser"
          class="c-account-sidebar-widget__avatar"
          large
        />

        <Pill :to="currentlyLoggedInUser.route" tag="router-link" large>
          {{ currentlyLoggedInUser.displayName }}
        </Pill>
      </div>

      <div class="c-account-sidebar-widget__row">
        <UserProfileLink :username="currentlyLoggedInUser.username" :interactive="!isEditing">
          @{{ currentlyLoggedInUser.username }}
        </UserProfileLink>
      </div>
    </template>
    <template v-else>
      <div class="c-account-sidebar-widget__row">
        <Avatar
          :cdsColor="AvatarColor.amber"
          name=""
          class="c-account-sidebar-widget__avatar"
          large
          rounded
        />

        <Pill :interactive="false" large />
      </div>
      <div class="c-account-sidebar-widget__row">
        <div class="c-account-sidebar-widget__username-placeholder">&nbsp;</div>
      </div>
    </template>

    <template #footer>
      <template v-if="isEditing">
        <Button
          :type="ButtonType.secondary"
          :disabled="isUpdatingUser"
          class="u-margin-r-auto"
          @click="exitEditingMode()"
        >
          <FontAwesomeIcon :icon="faCross" fixedWidth aria-hidden />
          {{ t('actions.exitProfileEditor') }}
        </Button>

        <Button
          :type="ButtonType.primary"
          :disabled="isSaveChangesButtonDisabled"
          :loading="isUpdatingUser"
          @click="saveChanges()"
        >
          <FontAwesomeIcon :icon="faCheck" fixedWidth aria-hidden />
          {{ t('actions.saveChanges') }}
        </Button>
      </template>
      <template v-else-if="currentlyLoggedInUser">
        <Button :type="ButtonType.secondary" class="u-margin-r-auto" @click="enterEditingMode()">
          <FontAwesomeIcon :icon="faPencil" fixedWidth aria-hidden />
          {{ t('actions.editProfile') }}
        </Button>
        <Button
          :type="ButtonType.tertiary"
          :active="isCurrentlyLoggedInUserProfilePageActive"
          :to="currentlyLoggedInUser.route"
          :title="t('actions.viewProfile')"
        >
          <FontAwesomeIcon :icon="faUser" fixedWidth aria-hidden />
        </Button>
        <Button
          :type="ButtonType.tertiary"
          :active="isNotificationsPageActive"
          :to="notificationsRoute"
          :title="t('actions.notifications')"
        >
          <FontAwesomeIcon :icon="faBell" fixedWidth aria-hidden />
        </Button>
        <Button
          :type="ButtonType.tertiary"
          :active="isAccountSettingsPageActive"
          :to="settingsRoute"
          :title="t('actions.settings')"
        >
          <FontAwesomeIcon :icon="faGear" fixedWidth aria-hidden />
        </Button>
      </template>
      <template v-else>
        <div class="c-account-sidebar-widget__footer-placeholder" />
      </template>
    </template>
  </SidebarBannerWidget>
</template>

<script lang="ts" setup>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { computed, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';

import { Avatar, AvatarColor } from '@caff/cds-avatar';
import { Button, ButtonType } from '@caff/cds-button';
import { faBell, faCheck, faCross, faGear, faPencil, faUser } from '@caff/cds-icons';
import { Input } from '@caff/cds-input';
import { Chill, ModalBanner } from '@caff/cds-modal-banner';
import { Pill } from '@caff/cds-pill';
import { SidebarBannerWidget } from '@caff/cds-sidebar-banner-widget';
import { UserAvatar } from '@caff/user-avatar-frontend-component';

import {
  useS3ConfigForImageKind,
  useCurrentlyLoggedInUser,
  useUpdateCurrentlyLoggedInUserAvatar,
  useUpdateCurrentlyLoggedInUserInfo,
} from '../../composition';
import { notificationsRoute, settingsRoute, userProfileRoute } from '../../router';
import { useToastrStore } from '../../store';
import { getRetryableFunction } from '../../utils/retryable';
import { UserProfileLink, UpdateUserAvatar } from '../user';

const { t } = useI18n();

const { currentlyLoggedInUser } = useCurrentlyLoggedInUser();

const s3ConfigForImageKind = useS3ConfigForImageKind();
const isEditing = ref(false);
const dirtyDisplayName = ref<string>(currentlyLoggedInUser.value?.displayName ?? '');

watch(currentlyLoggedInUser, () => {
  dirtyDisplayName.value = currentlyLoggedInUser.value?.displayName ?? '';
});

const enterEditingMode = () => {
  isEditing.value = true;
};
const exitEditingMode = () => {
  isEditing.value = false;
  dirtyDisplayName.value = currentlyLoggedInUser.value?.displayName ?? '';
};

const { updateCurrentlyLoggedInUserInfo, isExecuting: isUpdatingUser } =
  useUpdateCurrentlyLoggedInUserInfo();

const { updateAvatar, isExecuting: isUpdatingCurrentlyLoggedInUserAvatar } =
  useUpdateCurrentlyLoggedInUserAvatar();

const saveAvatar = async (file: File) => {
  await getRetryableFunction(async () => {
    if (isUpdatingCurrentlyLoggedInUserAvatar.value) {
      return;
    }

    await updateAvatar({ avatar: file });
  });
};

const route = useRoute();

const isNotificationsPageActive = computed((): boolean => route.name === notificationsRoute.name);

const isAccountSettingsPageActive = computed((): boolean => route.name === settingsRoute.name);

const isCurrentlyLoggedInUserProfilePageActive = computed(
  (): boolean => route.name === userProfileRoute.name,
);

const dirtyDisplayNameValidationError = computed((): string | null => {
  const maxLength = 20;

  if ((dirtyDisplayName.value?.length ?? 0) > maxLength) {
    return t('errors.validation.displayNameTooLong', { maxLength });
  }

  return null;
});

const isSaveChangesButtonDisabled = computed(
  (): boolean =>
    isUpdatingUser.value || !dirtyDisplayName.value || !!dirtyDisplayNameValidationError.value,
);

const saveChanges = async () => {
  await getRetryableFunction(async () => {
    if (isUpdatingUser.value || isSaveChangesButtonDisabled.value) {
      return;
    }

    await updateCurrentlyLoggedInUserInfo({
      displayName: dirtyDisplayName.value ?? undefined,
    });

    const toastrStore = useToastrStore();
    toastrStore.showSuccessToastr({ message: t('actionSuccessful') });

    exitEditingMode();
  });
};
</script>

<i18n lang="json" locale="es">
{
  "actions": {
    "editProfile": "Editar perfil",
    "exitProfileEditor": "Cancelar",
    "saveChanges": "Guardar cambios",
    "viewProfile": "Ver tu perfil",
    "notifications": "Notificaciones",
    "settings": "Cuenta de Caff"
  },
  "errors": {
    "validation": {
      "displayNameTooLong": "Tu nombre no puede superar los {maxLength} caracteres."
    }
  },
  "actionSuccessful": "Tu información pública se ha guardado correctamente."
}
</i18n>

<style lang="scss" scoped>
@import '@caff/cds-scss-core';

.c-account-sidebar-widget__banner {
  background: var(--colors-blue-500);

  &:deep(svg) {
    --fill-color: var(--colors-readable-over-color-500);

    height: get-size-in-rem(100px);
  }
}

.c-account-sidebar-widget__row {
  align-items: flex-start;
  display: flex;
  flex-direction: row;

  & + & {
    @extend %t-margin-t-s;
  }
}

.c-account-sidebar-widget__avatar {
  @extend %t-margin-r-xs;

  flex-grow: 0;
  flex-shrink: 0;
}

.c-account-sidebar-widget__text-input {
  &:deep(input) {
    @include t-font($font-header-small);
  }
}

.c-account-sidebar-widget__error-message {
  @include t-font($font-body);

  color: var(--colors-red-500);
}

.c-account-sidebar-widget__hidden-field {
  display: none !important;
}

.c-account-sidebar-widget__username-placeholder {
  @include t-font($font-body-action);

  display: inline-flex;
}

.c-account-sidebar-widget__footer-placeholder {
  @extend %t-height-l;
}
</style>
