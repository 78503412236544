import { Locale as DateLocale } from 'date-fns';

import LocalizationDefinition, { FormatDistanceLocaleTokens } from './localization';

const formatDistanceLocale: Record<
  FormatDistanceLocaleTokens,
  string | { one: string; other: string }
> = {
  lessThanXSeconds: 'ahora',

  xSeconds: '1min',

  lessThanXMinutes: {
    one: '1min',
    other: '{{count}}min',
  },

  xMinutes: {
    one: '1min',
    other: '{{count}}min',
  },

  aboutXHours: {
    one: '1h',
    other: '{{count}}h',
  },

  xHours: {
    one: '1h',
    other: '{{count}}h',
  },

  xDays: {
    one: '1d',
    other: '{{count}}d',
  },

  aboutXWeeks: {
    one: '1sem',
    other: '{{count}}sem',
  },

  xWeeks: {
    one: '1sem',
    other: '{{count}}sem',
  },

  aboutXMonths: {
    one: '1m',
    other: '{{count}}m',
  },

  xMonths: {
    one: '1m',
    other: '{{count}}m',
  },

  aboutXYears: {
    one: '1a',
    other: '{{count}}a',
  },

  xYears: {
    one: '1a',
    other: '{{count}}a',
  },

  overXYears: {
    one: '>+1a',
    other: '+{{count}}a',
  },

  almostXYears: {
    one: '1a',
    other: '{{count}}a',
  },
};

export const dateLocalizations: DateLocale & Required<Pick<DateLocale, 'formatDistance'>> = {
  formatDistance(token: FormatDistanceLocaleTokens, count: number) {
    const format = formatDistanceLocale[token];

    if (typeof format === 'string') {
      return format;
    }

    if (count === 1) {
      return format.one;
    }

    return format.other.replace('{{count}}', `${count}`);
  },
};

const definition: LocalizationDefinition = {
  language: 'es',
  dateLocalizations,
};

export default definition;
