<template>
  <section class="c-thread-action-preview">
    <header class="c-thread-action-preview__meta">
      <UserMetaSection
        v-if="thread"
        :username="thread.authorCanonicalUsername"
        class="c-thread-action-preview__user-meta"
        showAuthorBadge
      />
      <!-- TODO: Put some redacted state if thread is not available -->

      <ThreadLink
        v-if="relativePublishedDate"
        :threadSlug="threadSlug"
        interactive
        class="c-thread-action-preview__published-date"
      >
        {{ relativePublishedDate }}
      </ThreadLink>
      <!-- TODO: Put some redacted state if thread is not available -->
    </header>

    <MarkdownContent
      v-if="thread"
      :darkMode="darkMode"
      :externalUrlMetadataByUrl="{}"
      :features="NonInteractiveContentFeaturesShowingLinks"
      :markdown="thread.content"
      :s3BucketConfigForImageKind="s3ConfigForImageKind"
      :usersByLowercaseUsername="{}"
      :userMentions="[]"
    />
    <!-- TODO: Put some redacted state if thread is not available -->
  </section>
</template>

<script lang="ts" setup>
import { computed } from 'vue';

import {
  MarkdownContent,
  NonInteractiveContentFeaturesShowingLinks,
} from '@caff/markdown-content-frontend-component';
import { ThreadSlug } from '@caff/shallow-thread-api-model';

import {
  useDarkMode,
  useRelativeDate,
  useS3ConfigForImageKind,
  useThread,
} from '../../composition';
// The import below must be direct to avoid a circular dependency
import ThreadLink from '../thread/ThreadLink.vue';
import { UserMetaSection } from '../user';

const props = defineProps<{
  threadSlug: ThreadSlug;
}>();

const darkMode = useDarkMode();
const s3ConfigForImageKind = useS3ConfigForImageKind();

const { thread } = useThread(computed(() => props.threadSlug));

const relativePublishedDate = useRelativeDate(
  computed(() => thread.value && new Date(thread.value.publishedTimestamp * 1000)),
);
</script>

<style lang="scss" scoped>
@import '@caff/cds-scss-core';

.c-thread-action-preview__meta {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  overflow: hidden;

  & > * + * {
    @extend %t-margin-l-xs;
  }

  & > * {
    flex-grow: 0;
    flex-shrink: 0;
    overflow: hidden;

    &.c-thread-action-preview__user-meta {
      @extend %t-margin-r-xs;

      flex-shrink: 1;
    }
  }
}

.c-thread-action-preview__published-date {
  @include t-font($font-body-soft);

  margin-left: auto;
}
</style>
