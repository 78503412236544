import { defineStore } from 'pinia';

export const enum ModalType {
  forgotPassword = 'forgotPassword',
  changePassword = 'changePassword',
  register = 'register',
  newThreadComposer = 'newThreadComposer',
}

type Modal =
  | { type: ModalType.forgotPassword }
  | { type: ModalType.changePassword; changePasswordToken: string }
  | { type: ModalType.register }
  | { type: ModalType.newThreadComposer };

export const useModalStore = defineStore('modal', {
  state: () => ({
    currentModal: null as Modal | null,
  }),

  getters: {
    changePasswordToken(): string | null {
      if (this.currentModal?.type === ModalType.changePassword) {
        return this.currentModal.changePasswordToken;
      }

      return null;
    },

    isForgotPasswordOpened(): boolean {
      return this.currentModal?.type === ModalType.forgotPassword;
    },

    isChangePasswordOpened(): boolean {
      return this.currentModal?.type === ModalType.changePassword;
    },

    isRegisterOpened(): boolean {
      return this.currentModal?.type === ModalType.register;
    },

    isNewThreadComposerOpened(): boolean {
      return this.currentModal?.type === ModalType.newThreadComposer;
    },
  },

  actions: {
    showForgotPasswordModal() {
      this.currentModal = {
        type: ModalType.forgotPassword,
      };
    },

    showChangePasswordModal(changePasswordToken: string) {
      this.currentModal = {
        type: ModalType.changePassword,
        changePasswordToken,
      };
    },

    showRegisterModal() {
      this.currentModal = {
        type: ModalType.register,
      };
    },

    showNewThreadComposerModal() {
      this.currentModal = {
        type: ModalType.newThreadComposer,
      };
    },

    dismissCurrentModal() {
      this.currentModal = null;
    },
  },
});
