import MarkdownIt from 'markdown-it';
import StateBlock from 'markdown-it/lib/rules_block/state_block';

import { parseASTList } from './ASTList';
import { MarkdownFeature, MarkdownFeaturesConfig } from './features';
import { MarkdownMentions, MarkdownNode } from './types';
import { tokenTypeParsers } from './tokenTypeParsers';

export { MarkdownNodeType } from './types';
export type {
  MarkdownBlockNode,
  MarkdownBlockNodeWithTitle,
  MarkdownImageBlockNode,
  MarkdownInlineNode,
  MarkdownLinkBlockNode,
  MarkdownMentions,
  MarkdownNode,
  MarkdownUserMentionNode,
} from './types';
export {
  InteractiveContentFeatures,
  InteractiveContentFeaturesWithEmbeds,
  MarkdownFeature,
  NonInteractiveContentFeaturesShowingLinks,
  OnlyPlainTextNonInteractiveContentFeaturesHidingLinks,
} from './features';
export type { MarkdownFeaturesConfig } from './features';

const markdownItFeatures = {
  [MarkdownFeature.smartquotes]: 'smartquotes',
  [MarkdownFeature.replacements]: 'replacements',
  [MarkdownFeature.linkify]: 'linkify',
  [MarkdownFeature.link]: 'link',
  [MarkdownFeature.image]: 'image',
  [MarkdownFeature.emphasis]: 'emphasis',
  [MarkdownFeature.strikethrough]: 'strikethrough',
  [MarkdownFeature.heading]: 'heading',
  [MarkdownFeature.lheading]: 'lheading',
  [MarkdownFeature.list]: 'list',
  [MarkdownFeature.blockquote]: 'blockquote',
  [MarkdownFeature.code]: 'code',
  [MarkdownFeature.fence]: 'fence',
};

// Adapted from https://github.com/markdown-it/markdown-it/blob/1ecf143db02b67656d00441b0ec0be6f3315e94e/lib/rules_block/paragraph.js
const extractParagraphsWithoutTrimmingLines = (
  state: StateBlock,
  startLine: number /* , endLine: number */,
): boolean => {
  let nextLine = startLine + 1;

  const terminatorRules = state.md.block.ruler.getRules('paragraph');
  const endLine = state.lineMax;

  const oldParentType = state.parentType;
  state.parentType = 'paragraph';

  // jump line-by-line until empty one or EOF
  linesLoop: for (; nextLine < endLine && !state.isEmpty(nextLine); nextLine++) {
    // this would be a code block normally, but after paragraph
    // it's considered a lazy continuation regardless of what's there
    if (state.sCount[nextLine] - state.blkIndent > 3) {
      continue;
    }

    // quirk for blockquotes, this line should already be checked by that rule
    if (state.sCount[nextLine] < 0) {
      continue;
    }

    // Some tags can terminate paragraph without empty line.
    for (let i = 0, l = terminatorRules.length; i < l; i++) {
      if (terminatorRules[i](state, nextLine, endLine, true)) {
        break linesLoop;
      }
    }
  }

  const content = state.getLines(startLine, nextLine, state.blkIndent, false);

  state.line = nextLine;

  const paragraphOpenToke = state.push('paragraph_open', 'p', 1);
  paragraphOpenToke.map = [startLine, state.line];

  const inlineToken = state.push('inline', '', 0);
  inlineToken.content = content;
  inlineToken.map = [startLine, state.line];
  inlineToken.children = [];

  state.push('paragraph_close', 'p', -1);

  state.parentType = oldParentType;

  return true;
};

export const getMarkdownNodes = (
  markdownText: string,
  features: MarkdownFeaturesConfig,
  mentions: MarkdownMentions,
): Array<MarkdownNode> => {
  const markdownItInstance = new MarkdownIt('zero', {
    html: false,
    xhtmlOut: false,
    breaks: false,
    langPrefix: 'language-',
    linkify: true,
    typographer: true,
    quotes: '“”‘’',
    highlight: undefined,
  });

  markdownItInstance.block.ruler.at('paragraph', extractParagraphsWithoutTrimmingLines);

  const enabledFeatures = Object.entries(features)
    .filter(([featureName, isEnabled]) => isEnabled && featureName in markdownItFeatures)
    .map(([featureName]) => featureName as keyof typeof markdownItFeatures);

  markdownItInstance.enable(enabledFeatures);

  const tokens = markdownItInstance.parse(markdownText, {});

  return parseASTList({
    mentions,
    sourceText: markdownText,
    tokens,
    tokenTypeParsers: tokenTypeParsers,
  });
};
