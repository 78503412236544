import { MarkdownCodeBlockNode, MarkdownNodeType, ParseTokenFunction } from './types';

export const parseCodeblockToken: ParseTokenFunction = (params) => {
  const token = params.tokens[params.startingNodeIndex];

  const node: MarkdownCodeBlockNode = {
    type: MarkdownNodeType.code,
    content: token.content,
    sourceRange: {
      absoluteStart: params.startingCharacterAbsolutePosition,
      length: token.content.length,
    },
  };

  return {
    nodes: [node],
    nodesAdvanced: 1,
    charactersAdvanced: node.sourceRange.length + 3,
  };
};
