<template>
  <SidebarListWidgetItem class="c-thread-sidebar-list-item">
    <component :is="tag" :to="to" class="c-thread-sidebar-list-item__thread">
      <h3 class="c-thread-sidebar-list-item__title">
        {{ title }}
      </h3>
      <aside v-if="image" class="c-thread-sidebar-list-item__thumbnail">
        <ResponsiveImage :image="image" :height="height" :width="width" />
      </aside>
    </component>
    <aside class="c-thread-sidebar-list-item__actions">
      <slot name="actions" />
    </aside>
  </SidebarListWidgetItem>
</template>

<script lang="ts">
import { PropType, computed, defineComponent } from 'vue';

import { ResponsiveImage } from '@caff/cds-responsive-image';
import { SidebarListWidgetItem } from '@caff/cds-sidebar-list-widget';
import { s3BucketConfigForImageKindVueComponentProperty as s3BucketConfigForImageKind } from '@caff/image-frontend-model';
import {
  Image,
  ImageKind,
  ImageLocation,
  ImageSizeForLocation,
} from '@caff/image-isomorphic-model';

export default defineComponent({
  name: 'ThreadSidebarListItem',
  components: { ResponsiveImage, SidebarListWidgetItem },
  props: {
    s3BucketConfigForImageKind,
    idThumbnail: {
      type: null as unknown as PropType<string | null>,
      required: false,
    },
    title: {
      type: String,
      required: true,
    },
    to: {
      type: [String, Object],
      required: false,
    },
  },
  setup(props) {
    const image = computed(() =>
      props.idThumbnail
        ? new Image(
            {
              idImage: props.idThumbnail,
              kind: ImageKind.external_url_thumbnail,
            },
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            props.s3BucketConfigForImageKind!,
          )
        : null,
    );

    const { height, width } = ImageSizeForLocation[ImageLocation.sidebarThumbnail];

    const tag = computed(() => (props.to ? 'router-link' : 'div'));

    return {
      height,
      image,
      tag,
      width,
    };
  },
});
</script>

<style lang="scss" scoped>
@import '@caff/cds-scss-core';

.c-thread-sidebar-list-item {
  align-items: stretch;
  display: flex;
  flex-direction: row;
}

.c-thread-sidebar-list-item__thread {
  align-items: stretch;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  flex-shrink: 1;
  text-decoration: none;
}

a.c-thread-sidebar-list-item__thread:hover .c-thread-sidebar-list-item__title {
  text-decoration: underline;
}

.c-thread-sidebar-list-item__title {
  @include t-font($font-body);
  @include t-text-max-lines(2, var(--line-height));

  align-items: stretch;
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  height: 100%;
  margin: 0;
}

.c-thread-sidebar-list-item__thumbnail {
  @extend %t-margin-l-s;

  --border-radius: 6px;

  border-radius: var(--border-radius);
  overflow: hidden;
  flex-grow: 0;
  flex-shrink: 0;
  height: get-size-in-rem(44px);
  width: get-size-in-rem(71px);

  @include t-responsive--only-in($responsive-breakpoint-tablet-medium) {
    display: none;
  }
}

.c-thread-sidebar-list-item__actions {
  @extend %t-margin-l-s;

  align-items: center;
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  justify-content: center;
  width: var(--spacing-l);
}
</style>
