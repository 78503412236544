import { parseASTListRecursive } from './ASTList';
import {
  MarkdownBlockNode,
  MarkdownNodeHTMLTag,
  MarkdownNodeType,
  ParseTokenFunction,
} from './types';

export const parseParagraphToken: ParseTokenFunction = (params) => {
  const token = params.tokens[params.startingNodeIndex];

  const childrenMetadata = parseASTListRecursive({
    sourceText: params.sourceText,
    tokens: params.tokens,
    startingNodeIndex: params.startingNodeIndex + 1,
    startingCharacterAbsolutePosition: params.startingCharacterAbsolutePosition,
    mentions: params.mentions,
    tokenTypeParsers: params.tokenTypeParsers,
  });

  const node: MarkdownBlockNode = {
    type: MarkdownNodeType.paragraph,
    tag: MarkdownNodeHTMLTag.p,
    childNodes: childrenMetadata.nodes,
    sourceRange: {
      absoluteStart: params.startingCharacterAbsolutePosition,
      // Adding +2 because there's always a newline character at the end of a paragraph but the last
      // one (but we don't care about it because there's no more content afterwards) and the implicit
      // one when there's no explicit one (and again there's no more content afterwards)
      length: token.content.length + childrenMetadata.parsedCharactersLength + 2,
    },
  };

  return {
    nodes: [node],
    nodesAdvanced: 1 + childrenMetadata.parsedNodesLength + 1,
    charactersAdvanced: node.sourceRange.length,
  };
};
