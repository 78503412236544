<template>
  <ForgotPasswordModal
    :currentStep="currentStep"
    :email="email"
    :isCheckingEmailExistance="isCheckingEmailExistance"
    :isEmailUsed="!isEmailAvailable"
    :isRequestingPasswordRecoveryEmail="isExecuting"
    :passwordRecoveryEmailRequestError="
      passwordRecoveryEmailRequestError && t(passwordRecoveryEmailRequestError)
    "
    @email-input="onEmailInput($event)"
    @dismiss="dismissWizard()"
    @request-password-recovery-email="requestPasswordRecoveryEmail()"
    @sign-up="showRegisterModal()"
  />
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';

import {
  ForgotPasswordModal,
  ForgotPasswordModalStep,
} from '@caff/forgot-password-modal-frontend-component';

import { useIsEmailAvailable, useSendPasswordRecoveryEmail } from '../../composition';
import { useModalStore } from '../../store';

const { t } = useI18n();

const currentStep = ref(ForgotPasswordModalStep.email);
const email = ref('');
const passwordRecoveryEmailRequestError = ref<string | null>(null);
const { isExecuting: isCheckingEmailExistance, data: isEmailAvailable } =
  useIsEmailAvailable(email);

const onEmailInput = (newEmail: string) => {
  email.value = newEmail;
};

const { sendPasswordRecoveryEmail, isExecuting } = useSendPasswordRecoveryEmail();

const requestPasswordRecoveryEmail = async () => {
  if (isExecuting.value) {
    return;
  }

  passwordRecoveryEmailRequestError.value = null;

  try {
    await sendPasswordRecoveryEmail({ email: email.value });
    currentStep.value = ForgotPasswordModalStep.success;
  } catch (error) {
    passwordRecoveryEmailRequestError.value = 'error.passwordRecoveryEmailRequest.serverError';
    console.error(error);
  }
};

const modalStore = useModalStore();

const dismissWizard = async () => {
  if (isExecuting.value) {
    return;
  }

  modalStore.dismissCurrentModal();
};

const showRegisterModal = () => {
  modalStore.showRegisterModal();
};
</script>

<i18n lang="json" locale="es">
{
  "error": {
    "passwordRecoveryEmailRequest": {
      "serverError": "Ha sucedido un error inesperado."
    }
  }
}
</i18n>
