import { BrowserTracing } from '@sentry/browser';
import {
  ExtraErrorData,
  CaptureConsole,
  HttpClient,
  ReportingObserver,
} from '@sentry/integrations';
import * as Sentry from '@sentry/vue';
import { App } from 'vue';

import { isServer } from '@caff/isomorphic-is-server';
import { ServerSideRenderingSharedContext } from '@caff/server-side-rendering-context-isomorphic-model';

import { SENTRY_DSN, PACKAGE_NAME_WITH_VERSION, VERSION } from '../config';
import { isProduction } from './environment';
import { getApiBaseUrl } from './network';

export const initSentry = async ({
  app,
  initialState,
  router,
}: {
  app: App;
  initialState: ServerSideRenderingSharedContext;
  router: Parameters<typeof Sentry.vueRouterInstrumentation>[0];
}): Promise<void> => {
  if (isServer()) {
    return;
  }

  const release = isProduction(initialState) ? PACKAGE_NAME_WITH_VERSION : VERSION;

  const environment = new URL(window.location.href).host || 'unknown';

  if (SENTRY_DSN === '') {
    console.warn('Sentry DSN is not available, skipping setup');
    return;
  }

  Sentry.init({
    app,
    release,
    environment,
    dsn: SENTRY_DSN,
    logErrors: true,
    autoSessionTracking: true,
    attachStacktrace: true,
    integrations: [
      new CaptureConsole(),
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracePropagationTargets: [getApiBaseUrl(initialState).toString(), /^\//],
      }),
      new ExtraErrorData(),
      new HttpClient(),
      new ReportingObserver(),
      new Sentry.Replay(),
    ],
    replaysSessionSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0,
    tracesSampleRate: 1.0,
    trackComponents: true,
    sampleRate: 1.0,
    sendDefaultPii: true,
    tracingOptions: {
      trackComponents: true,
    },
  });
};

export const setUser = async (username: string): Promise<void> => {
  if (isServer()) {
    return;
  }

  Sentry.configureScope((scope) => {
    scope.setUser({ username });
  });
};

export const unsetUser = async (): Promise<void> => {
  if (isServer()) {
    return;
  }

  Sentry.configureScope((scope) => {
    scope.setUser(null);
  });
};

export const captureException: (typeof Sentry)['captureException'] = (...params) =>
  Sentry.captureException(...params);
